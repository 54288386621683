import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Admin from '../screens/Admin/Admin';
import WorkLevel from '../screens/Admin/Master/WorkLevel';
import AdminHeader from '../screens/Header/AdminHeader';
import Footer from '../screens/Footer/Footer';
import Themes from '../screens/Admin/Master/Themes';
import JobRole from '../screens/Admin/Master/JobRole';
import Department from '../screens/Admin/Master/Department';
import Function from '../screens/Admin/Master/Function';
import Competency from '../screens/Admin/Master/Competency';
import Customer from '../screens/Admin/Master/Customer';
import GroupCompany from '../screens/Admin/Master/GroupCompany';
import Location from '../screens/Admin/Master/Location';
import ProficiencyScale from '../screens/Admin/Master/ProficiencyScale';
import Indicators from '../screens/Admin/Master/Indicators';
import JobDescription from '../screens/Admin/Master/JobDescription';
import AddUser from '../screens/Admin/Master/AddUser';
import Promotion from '../screens/Admin/Master/promote'
import TraingprogramAdd from '../screens/Admin/Master/TrainingProgram'
import TraingprogramMapping from '../screens/Admin/Master/ProgramMapping'
import CoursemapwithTrainer from '../screens/Admin/Master/CoursemapwithTrainer'
// import Trainermapwithlocation from '../screens/Admin/Master/Trainermapwithlocation'


import Derailers from '../screens/Admin/Master/Derailers';
import MapEmployee from '../screens/Admin/Mapping/MapEmployee';
import MapThemeToWorkLevel from '../screens/Admin/Mapping/MapThemeToWorkLevel';
import MapDepartmentToFunction from '../screens/Admin/Mapping/MapDepartmentToFunction';
import MapFunctionToWorklevel from '../screens/Admin/Mapping/MapFunctionToWorklevel';
import MapCompetencyLevel from '../screens/Admin/Mapping/MapCompetencyLevel';
import MapCompetencyParams from '../screens/Admin/Mapping/MapCompetencyParams';
import MapWorkleveToCompetency from '../screens/Admin/Mapping/MapWorkleveToCompetency';
import CompFormOne from '../screens/Admin/CompetencyMatrix/CompFormOne';
import CompFormTwo from '../screens/Admin/CompetencyMatrix/CompFormTwo';

class AdminRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={'/admin/CompetencyMatrix/CompFormOne'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <CompFormOne {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/CompetencyMatrix/CompFormTwo'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <CompFormTwo {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Customer'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Customer {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Admin {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/WorkLevel'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <WorkLevel {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Themes'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Themes {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/JobRole'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <JobRole {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Department'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Department {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Function'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Function {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Competency'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Competency {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Location'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Location {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/ProficiencyScale'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <ProficiencyScale {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Indicators'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Indicators {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/JobDescription'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <JobDescription {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Users'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <AddUser {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
        <Route
          exact
          path={'/admin/Master/promotion'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Promotion {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
        <Route
          exact
          path={'/admin/Master/TraingprogramAdd'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <TraingprogramAdd {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
        <Route
          exact
          path={'/admin/Master/TraingprogramMapping'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <TraingprogramMapping {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
        <Route
          exact
          path={'/admin/Master/CoursemapwithTrainer'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <CoursemapwithTrainer {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
        {/* <Route
          exact
          path={'/admin/Master/Trainermapwithlocation'}
          render={props => (
            <div className="wrapper">
              <AdminHeader />
              <div className="content-wrapper">
                <Trainermapwithlocation {...props} />
              </div>
              <Footer />
            </div>
          )}
        /> */}

        <Route
          exact
          path={'/admin/Master/GroupCompany'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <GroupCompany {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Master/Derailers'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <Derailers {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapEmployee'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapEmployee {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapThemeToWorkLevel'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapThemeToWorkLevel {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapWorkleveToCompetency'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapWorkleveToCompetency {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapDepartmentToFunction'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapDepartmentToFunction {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapFunctionToWorklevel'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapFunctionToWorklevel {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapCompetencyLevel'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapCompetencyLevel {...props} />
              </div>
              <Footer />
            </div>
          )}
        />

        <Route
          exact
          path={'/admin/Mapping/MapCompetencyParams'}
          render={props => (
            <div className="">
              <AdminHeader />
              <div className="">
                <MapCompetencyParams {...props} />
              </div>
              <Footer />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

export default AdminRoutes;
