import React from 'react';

const Footer = () => {
  return (
    <div>
      <div className='container container-sm-fluid px-2'>
        <div className='col-12'>
          <footer className="text-center">
            <strong>
              Copyright <a href="https://www.difuza.com" target='_blank'>difuza</a>.
            </strong>
            All rights reserved.
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
