import React, { Component } from "react";
import renderHTML from "react-render-html";
import CmsContent from "../../middleWare/HrMiddleWare";
import NewVideoPlayer from "./NewVideoPlayer";
import http from "../../../src/middleWare/httpMiddleWare";
import "./videoStyle.css";
import Swal from "sweetalert2";
import HrMiddleWare from "../../middleWare/HrMiddleWare";

class VideoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      themeid: "",
      date: "",
      maxtime: 0,
      fullname:""
      // themeid: 722
    };
    let userlog = localStorage.getItem("userDetails");
    userlog = JSON.parse(userlog);
    localStorage.setItem("userId", userlog.id);
    this.state.userId = userlog.id;
    localStorage.setItem("userName", userlog.user_name);
    localStorage.setItem("userEmail", userlog.email_id);
    localStorage.setItem("userMobile", userlog.mobile_no);
  }
  async componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    await this.getContent(nextProps.match.params.id);
    this.componentWillUnmount();
  }
  onUnload = e => { // the method that will be used for both add and remove event
    this.MainFun();
    e.preventDefault();
    e.returnValue = '';

  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", this.onUnload);
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var newdate = year + "-" + month + "-" + day;
    var timesDate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds +
      "";
    localStorage.setItem("startTime", timesDate);
    localStorage.setItem("themeId", this.props.match.params.id);
    await this.getContent(this.props.match.params.id);
  }
  async componentWillMount() {
    console.log("entersd");
    let userDetails = await HrMiddleWare.getRandomData(
        "tbl_users",
        `id = ${this.state.userId}`,
        "*"
    );
    console.log(userDetails);
    let data = userDetails.data
    console.log(data, "data")
    let name = data.map((a, b) => {
        let fullName = a.name + " " + a.last_name
        this.setState({ fullname: fullName })
        console.log(fullName,"fullllname")
    })
}

  async componentWillUnmount() {
    this.MainFun()
  }
  MainFun = async () => {
    // window.removeEventListener("beforeunload", this.onUnload);
    if (localStorage.getItem('completed')) {
      localStorage.removeItem('completed')
    } else {
      let ipaddressget = await http.get("https://ipapi.co/json/", function (
        data
      ) {
        console.log(JSON.stringify(data, null, 2));
      });
      const date = new Date();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
      var day = date.getDate();
      // var day = date.getUTCDate();
      // var year = date.getUTCFullYear();
      var year = date.getFullYear();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      var newdate = year + "-" + month + "-" + day;
      var timesDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds +
        "";
      var userId = JSON.parse(localStorage.getItem("userDetails")).id;
      var max = localStorage.getItem("max");
      var total = localStorage.getItem("total");
      var startTime = localStorage.getItem("startTime");
      max = max ? max : 0;
      total = total ? total : 0;
      // var themeid = localStorage.getItem("themeId");
      // themeid = themeid ? themeid : this.props.match.params.id;
      var themeid = this.props.match.params.id;
      const userlog = new FormData();
      userlog.append("userid", userId);
      userlog.append("ipaddress", ipaddressget.data.ip);
      userlog.append("datetime", timesDate);
      userlog.append("date", newdate);
      userlog.append("startTime", startTime);
      userlog.append("max_timeline", max);
      userlog.append("pageid", themeid);
      userlog.append(
        "customerId",
        JSON.parse(localStorage.getItem("userDetails")).customerId
      );
      // let datauser = await CmsContent.addMaster("tbl_CMSuserAccessLog", userlog);
      let data = [];
      let Innerdata = {};
      Innerdata.userid = userId;
      Innerdata.maxtime = max;
      Innerdata.totaltime = total;
      Innerdata.pageid = themeid;
      data.push(JSON.stringify(Innerdata));
      const log = new FormData();
      log.append("fullList", data);
      console.log("full", data);
      await CmsContent.insertMaster("comp_videos_view_count", data);

      localStorage.setItem("startTime", timesDate);
      localStorage.setItem("themeId", this.props.match.params.id);
      localStorage.setItem("max", 0);
      // window.location.href = window.location.origin + `/vidhya/Courses/633`
    }
  }
  // componentDidUpdate(){
  //   if (localStorage.getItem('completed')) {
  //     window.removeEventListener("beforeunload", this.onUnload);  

  //   }
  // }
  async getContent(themeid) {
    let userType = await localStorage.getItem("userTypeId");

    const formData = new FormData();
    formData.append("userId", this.state.userId);
    formData.append("pageid", themeid);
    // let countResult = await CmsContent.pageViewCount(formData);
    // console.log(countResult);
    // let countResult = await CmsContent.getvideocount(
    //   this.state.userId,
    //   themeid
    // );
    let countResult = await CmsContent.getFreedom('*', 'comp_videos_view_count', `userid=${this.state.userId} and pageid=${themeid}`, 1, 'id desc')
    console.log(countResult);
    const result = await CmsContent.getThemebyid(themeid);
    // const result = await CmsContent.getVideoContentById(themeid);
    if (result && result.data && result.data.length) {
      console.log("enter if");
      if (result.data && result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0],
        });
      }
      let maxtime = Math.max.apply(Math, countResult.data.map(function (o) { return o.maxtime; }))
      this.setState({
        pageList: result.data[0],
        themeid: themeid,
        //countViews: countResult.data.resultcount,
        countViews: countResult.data.length,
        maxtime,
        playOn: countResult.data[0]
      });
    } else {
      console.log("enter else");
      Swal.fire({
        position: 'center',
        type: 'question',
        title: 'No videos found',
        showConfirmButton: false,
        timer: 3000
      });
      setTimeout(() => {
        if (userType == 5) {
          window.location.href = "/user/sugestedTraingProgram"
        } else {
          window.location.href = "/hr/sugestedTraingProgram"

        }
      }, 3000)
    }
  }
  coverContent = (con) => {
    if (this.state.pageList.linkto && this.state.pageList.linkto != '-') {
      return <a href={"https://" + `${this.state.pageList.linkto}`}>{con}</a>;
    }
    return con;
  };

  render() {
    const { pageList, date } = this.state;

    // if (!pageList) {
    //   return <PreLoader />;
    // } else    


    let height = window.innerHeight - 50;
    // {
    return (
      <React.Fragment>
        <section className="container conatiner-sm-fluid mt-100">
          <div className="my-5 col-12 content-border1">
            <div className="row justify-content-center align-items-center py-3 text ">
              <div className="mx-2 px-3">
                <h3 style={{ textAlign: 'center' }}>
                  {pageList.contentTitle1 &&
                    this.coverContent(
                      <h2 className="theme-tiile text-capitalize" style={{ fontWeight: 'bold' }}>
                        {pageList.contentTitle1}
                      </h2>
                    )}
                </h3>
              </div>
              <ul>
                {this.state.themeid && (
                  <NewVideoPlayer Video={this.state.themeid} onUnload={this.onUnload} playOn={this.state.playOn} maxtime={this.state.maxtime} fullName = {this.state.fullname} />
                )}
              </ul>
              {pageList.content1 &&
                        this.coverContent(
                          <p className="content-para px-4 " style={{backgroundColor:"var(--background-color)",color:"var(--text-color)"}}>
                            {renderHTML(pageList.content1)}
                          </p>
                        )}
            </div>
          </div>

        </section>

      </React.Fragment>
    );
    // }
  }
}

export default VideoContent;
