import React from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import ValidationView from "../../components/Form/ValidationView";
import Swal from "sweetalert2";

class AddCriticals extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = { addData: [] };
  }

  async componentWillMount() {
    try {
      let adminId = await localStorage.getItem("adminId");
      const Theme = await HrMiddleWare.getRandomData(
        " tbl_theme",
        `customerid = ${adminId} and textType = "Technical" and status = "active"`,
        "theme as label , id as value"
      );
      const competency = await HrMiddleWare.getRandomData(
        "  tbl_competency",
        `customerid = ${adminId} and textType = "Technical" and status = "active"`,
        "competencyname as label , id as value"
      );
      let data = await HrMiddleWare.getRandomData(
        "tbl_tech_comp_criticals",
        `cmpId = ${adminId} and status = 'active'`,
        "*"
      );
      console.log(competency,"competencylog");
      this.setState({
        Theme: Theme.data,
        competency: competency.data,
        addData: data.data,
        adminId
      });
    } catch (error) {
      console.log(error);
    }
  }
  themeSelect = e => {
    this.setState({ themeSelect: e });
  };
  competencySelect = e => {
    this.setState({ competencySelect: e });
  };
  definitionText = e => {
    this.setState({ definitionText: e.target.value });
  };
  importanceText = e => {
    this.setState({ importanceText: e.target.value });
  };
  knowledgeText = e => {
    this.setState({ knowledgeText: e.target.value });
  };
  performanceText = e => {
    this.setState({ performanceText: e.target.value });
  };

  column = [
    {
      Header: "Theme",
      Accessor: "theme",
      Cell: d => this.getValueFromArray(d.original.theme, this.state.Theme)
    },
    {
      Header: "Competency",
      accessor: "competency",
      Cell: d =>
        this.getValueFromArray(d.original.competency, this.state.competency)
    },
    {
      Header: "Definition",
      accessor: "defenition"
    },
    {
      Header: "Importance",
      accessor: "important"
    },
    {
      Header: "Knowledge area",
      accessor: "critical_knowledge"
    },
    {
      Header: "Performance Parameter",
      accessor: "critical_performance"
    }
  ];

  getValueFromArray = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  submitData = async () => {
    const {
      adminId,
      themeSelect,
      competencySelect,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText
    } = this.state;

    if (this.validate(themeSelect, "errorthemeSelect", "Please select Theme"))
      return true;
    if (
      this.validate(
        competencySelect,
        "errorcompetency",
        "Please select competency"
      )
    )
      return true;
    if (
      this.validate(
        definitionText,
        "errorDefinition",
        "Please select definition"
      )
    )
      return true;
    if (
      this.validate(importanceText, "errorimportant", "Please select important")
    )
      return true;
    if (
      this.validate(knowledgeText, "errorknowledge", "Please select knowledge")
    )
      return true;
    if (
      this.validate(
        performanceText,
        "errorperformanceText",
        "Please select performance"
      )
    )
      return true;

    let formData = new FormData();
    formData.append("theme", themeSelect.value);
    formData.append("competency", competencySelect.value);
    formData.append("defenition", definitionText);
    formData.append("important", importanceText);
    formData.append("critical_knowledge", knowledgeText);
    formData.append("critical_performance", performanceText);
    formData.append("cmpId", adminId);
    formData.append("status", "active");
    try {
      let result = await HrMiddleWare.addMaster(
        "tbl_tech_comp_criticals",
        formData
      );
      if (result) {
        let data = await HrMiddleWare.getRandomData(
          "tbl_tech_comp_criticals",
          `cmpId = ${adminId} and status = 'active'`,
          "*"
        );
        let d = data.data.sort((a, b) => b.id - a.id)
        this.setState({
          addData: d,
          themeSelect: "",
          competencySelect: "",
          definitionText: "",
          importanceText: "",
          knowledgeText: "",
          performanceText: ""
        });

        Swal.fire({
          position: "center",
          type: "success",
          title: "Technical Details Added ",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) { }
  };
  render() {
    const {
      Theme,
      themeSelect,
      competency,
      competencySelect,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText,
      errorthemeSelect,
      errorcompetency,
      errorDefinition,
      errorimportant,
      errorknowledge,
      errorperformanceText
    } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className="container mt-100 container-sm-fluid">
            <div className="row justify-content-center align-items-center my-5">
              <div className="col-lg-8 my-3">
                <div className="content-border1 py-4  px-3 px-sm-1">
                  <div className="col-12 text-center my-2">
                    <h3>Add Technical Details</h3>
                  </div>
                  <div className="box-tools pull-right d-none">
                    <button
                      className="btn btn-box-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <hr style={{ borderTop: "2px dashed var(--input-border-color)" }} />
                  <div className='row justify-content-between align-items-center  px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year">Theme</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <SingleSelect
                        options={Theme}
                        handleChange={this.themeSelect}
                        selectedService={themeSelect}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorthemeSelect} />
                    </div>
                  </div>
                  <div className="border33 my-3"/>
                  <div className='row justify-content-between align-items-center  px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year">Competency</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <SingleSelect
                        options={competency}
                        handleChange={this.competencySelect}
                        selectedService={competencySelect}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorcompetency} />
                    </div>
                  </div>
                  <div className="border33 my-3"/>

                  <div className='row justify-content-between align-items-center  px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year">Definition</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <textarea
                        type="text"
                        className="form-control inputss"
                        placeholder="Enter Definition Text "
                        onChange={this.definitionText}
                        value={definitionText}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorDefinition} />
                    </div>
                  </div>
                  <div className="border33 my-3"/>

                  <div className='row justify-content-between align-items-center  px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year">Importance</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <textarea
                        type="text"
                        className="form-control inputss"
                        placeholder="Enter Importance Text "
                        onChange={this.importanceText}
                        value={importanceText}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorimportant} />
                    </div>
                  </div>
                  <div className="border33 my-3"/>

                  <div className='row justify-content-between align-items-center  px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year"> Knowledge area</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <textarea
                        type="text"
                        className="form-control inputss"
                        placeholder="Enter Knowledge area Text "
                        onChange={this.knowledgeText}
                        value={knowledgeText}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorknowledge} />
                    </div>
                  </div>
                  <div className="border33 my-3"/>

                  <div className='row justify-content-between align-items-center px-0 px-md-3 px-lg-5'>
                    <div className='col-lg-1 d-none d-lg-none'></div>
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className='pl-0  my-2 my-lg-0 my-md-0 formLabel' htmlFor="year">  Performance Parameter</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                      <textarea
                        type="text"
                        className="form-control inputss"
                        placeholder="Enter  Performance Parameter Text "
                        onChange={this.performanceText}
                        value={performanceText}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-1' />
                    <div className="my-2">
                      <ValidationView errorname={errorperformanceText} />
                    </div>
                  </div>
                  <div className="border33"/>
                  <div className='row justify-content-between align-items-center my-4 px-0  px-md-3 px-lg-5'>
                    <div className='col-12 text-end px-3  px-sm-0 me-5'>
                      <button
                        className="submit-btn"
                        class="submit-btn px-4"
                        onClick={this.submitData}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {this.state.addData && (
                    <Datatable
                      data={this.state.addData}
                      columnHeading={this.column}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AddCriticals;
