import React, { Component } from "react";
import moment from "moment";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import MasterMiddleWare from "../../../middleWare/MasterMiddleWare";
import HrMiddleWare from "../../../middleWare/HrMiddleWare";
import ValidationView from "../../../components/Form/ValidationView";
import DatePicker from "../../../components/Form/DatePicker";
import Swal from "sweetalert2";
import { Datatable } from "../../../components/Datatable";
import EmployeeList from "../../Hr/EmployeeList"
import { get } from "lodash";
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import { CSVLink } from "react-csv";
import { ACCESS_POINT } from "../../../config";
import MappingMiddleware from "../../../middleWare/MappingMiddleware";


const tableName = "tbl_promotion";
class Promotion extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            //data:null,
            adminId: null,
            groupCompanyId: null,
            groupCompanyOptions: [],
            groupCompanySelected: null,
            groupCompanyName: null,
            errorGroupCompanySelected: null,
            errorUserType: null,
            erroryearId: null,
            erroremployeeId: null,
            userType: null,
            errorUserType: null,
            departmentOptions: [],
            departmentSelected: null,
            departmentId: null,
            departmentName: null,
            errorDepartmentSelected: null,
            designationOptions: [],
            designationSelected: null,
            designationId: null,
            designationName: null,
            errorDesignationSelected: null,
            updateData: false,
            firstName: null,
            errorFirstName: null,
            lastName: null,
            errorLastName: null,
            userName: null,
            errorUserName: null,
            password: null,
            errorPassword: null,
            address: null,
            errorAddress: null,
            locationOptions: [],
            locationSelected: null,
            locationId: null,
            locationName: null,
            errorLocationSelected: null,
            mobileNumber: null,
            errorMobileNumber: null,
            telephoneNumber: null,
            errorTelephoneNumber: null,
            startDate: new Date(),
            age: null,
            errorAge: null,
            dateOfJoining: new Date(),
            email: null,
            errorEmail: null,
            textType: [],
            errorTextType: null,
            behThemeOptions: [],
            behThemeSelected: null,
            behThemeId: null,
            techThemeOptions: [],
            techThemeSelected: null,
            techThemeId: null,
            jobRoleOptions: [],
            jobRoleSelected: null,
            jobRoleId: null,
            jobRoleName: null,
            workLevelOptions: [],
            workLevelSelected: null,
            workLevelId: null,
            workLevelName: null,
            qualification: null,
            errorQualification: null,
            file: null,
            errorEmployeephoto: null,
            uodateUserId: "",
            updateUserImage: "",
            modelOpen: false,
            excelHead: [
                { label: "SI.NO", key: "index" },
                { label: "Full Name", key: "name" },
                { label: "User Name", key: "user_name" },
                { label: "Address", key: "address" },
                { label: "Mobile", key: "mobile_no" },
                { label: "Dob(Age)", key: "age" },
                { label: "Date Of Joining", key: "doj" },
                { label: "Designation", key: "designation" },
                { label: "Qualification", key: "qualification" },
                { label: "Matrix Type", key: "matrixType" },
                { label: "Work Level", key: "worklevel" },
                { label: "Job Role", key: "jobRole" },
            ],
            excelHead2: [
                { label: "department", key: "id" },
                { label: "department name", key: "department" },
                { label: "status", key: "status" },
            ],
            excelHead3: [
                { label: "designation", key: "value" },
                { label: "designation name", key: "label" },
            ],
            excelHead4: [
                { label: "matrixType", key: "label" },
            ],
            excelHead5: [
                { label: "userTheme", key: "value" },
                { label: "userTheme name", key: "label" },
            ],
            excelHead6: [
                { label: "worklevel", key: "value" },
                { label: "worklevel name", key: "label" },
            ],
            excelHead7: [
                { label: "jobRole", key: "value" },
                { label: "jobRole name", key: "label" },
            ],
            excelHead8: [
                { label: "location", key: "value" },
                { label: "location name", key: "label" },
            ],
            excelHead9: [
                { label: "userType name", key: "label" },
                { label: "userTypeId", key: "value" },
                { label: "is_manager", key: "value3" },
                { label: "is_employee", key: "value1" },
                { label: "is_hr", key: "value2" },
            ],
            usertypeidopt: [
                { label: "Employee", value: 5, value1: 1 },
                { label: "Hr", value: 6, value2: 1 },
                { label: "Manager", value: 2, value3: 1 },
            ],
            behaviourltechnical: [
                { label: "Behavioral", value: 1 },
                { label: "Technical", value: 2 },
            ],
        };
    }
    column = [
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,
            accessor: "si no",
            Cell: d => this.siNo(d)
        },
        // {
        //     Header: props => <span style={{ fontWeight: "bold" }}> Full Name </span>,
        //     accessor: "name",
        //     Cell: (d) => {
        //         return (d.original.name + "  " + d.original.last_name)
        //     }

        // },
        // {
        //   Header: props => <span style={{ fontWeight: "bold" }}> Last Name </span>,
        //   accessor: "last_name"
        // },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> User Name </span>,
            accessor: "user_Name"
        },
        // {
        //     Header: props => <span style={{ fontWeight: "bold" }}> Address </span>,
        //     accessor: "address"
        // },
        // {
        //     Header: props => <span style={{ fontWeight: "bold" }}> Mobile </span>,
        //     accessor: "mobile_no"
        // },
        // {
        //     Header: props => <span style={{ fontWeight: "bold" }}> Dob(Age)  </span>,
        //     accessor: "age",
        //     Cell: (d) => {
        //         return (d.original.dob + "" + " (" + d.original.age + ") ")
        //     }
        // },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Date Of Joining </span>,
            accessor: "doj"
        },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Designation </span>,
            accessor: "designation",
            Cell: (d) => this.getValueFromArray(d.original.designation, this.state.designationOptions)
        },
        // {
        //     Header: props => <span style={{ fontWeight: "bold" }}> Qualification </span>,
        //     accessor: "qualification",
        // },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Matrix Type </span>,
            accessor: "matrixType",
        },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Work Level </span>,
            accessor: "worklevel",
            Cell: (d) => this.getValueFromArray(d.original.worklevel, this.state.workLevelOptions)
        },
        {
            Header: props => <span style={{ fontWeight: "bold" }}> Job Role </span>,
            accessor: "jobRole",
            Cell: (d) => this.getValueFromArray(d.original.jobRole, this.state.jobRoleOptions)
        },
        {
            Header: 'Edit',
            accessor: 'edit',
            Cell: d => this.edit(d)
        },
        {
            Header: 'Delete',
            accessor: 'delete',
            Cell: d => this.delete(d, tableName)
        }

    ]

    async componentDidMount() {
        // const adminId = await localStorage.getItem("adminId");
        const adminId = await localStorage.getItem("userId");

        console.log(adminId)
        this.setState({ adminId });
        this.getSelectBoxMaster(
            adminId,
            "tbl_gcompanies",
            "id",
            "groupcompanies",
            "groupCompanyOptions"
        );

        this.getSelectBoxMaster(
            adminId,
            "tbl_department",
            "id",
            "department",
            "departmentOptions"
        );

        this.getSelectBoxMaster(
            adminId,
            "tbl_designation",
            "id",
            "designation",
            "designationOptions"
        );
        this.getSelectBoxMaster(
            adminId,
            "tbl_location",
            "id",
            "location",
            "locationOptions"
        );
        this.getSelectBoxMaster(
            adminId,
            "tbl_worklevel",
            "id",
            "worklevel",
            "workLevelOptions"
        );
        this.getSelectBoxMaster(
            adminId,
            "tbl_jobRole",
            "id",
            "jobRoleName",
            "jobRoleOptions"
        );
        const groupcompanyid = localStorage.getItem("groupcompId");
        // console.log(groupcompanyid);
        this.setState({ groupCompanyId: groupcompanyid });
        var userid = localStorage.getItem("userId");
        // console.log(userid)
        let data = await HrMiddleWare.getRandomData(
            "tbl_promotion",
            `adminId = ${adminId}`,
            "*"
        );
        // console.log(data, "data");
        const { data: data1dept } = await MasterMiddleWare.getMaster('tbl_department', adminId);

        // console.log(data);
        this.setState({ data: data.data, data1dept })
        let { data: yearOptions } = await MappingMiddleware.getYear();
        if (yearOptions) {
            this.setState({
                yearOptions
            });
        }
    }



    handleCheckInput = async e => {
        const { textType, behThemeOptions, techThemeOptions } = this.state;
        const adminId = this.state.adminId;
        const value = e.target.value;

        if (textType.length > 0) {
            if (textType.includes(value)) {
                let newArray = textType.filter(e => e !== value);
                this.setState({ textType: newArray });
            } else {
                textType.push(value);
                this.setState({ textType });
            }
        } else {
            textType.push(value);
            this.setState({ textType });
        }
        try {
            textType.map(async (val, index) => {
                if (val === "Behavioral") {
                    if (behThemeOptions.length === 0) {
                        console.log("in");

                        const { data } = await MasterMiddleWare.getThemeForSelect(
                            adminId,
                            val
                        );
                        console.log(data);
                        if (data) {
                            this.setState({ behThemeOptions: data });
                        }
                    }
                } else {
                    if (techThemeOptions.length === 0) {
                        console.log("in");
                        const { data } = await MasterMiddleWare.getThemeForSelect(
                            adminId,
                            val
                        );
                        if (data) {
                            this.setState({ techThemeOptions: data });
                        }
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    onSubmit = async () => {
        const {
            groupCompanyId,
            userType,
            departmentId,
            designationId,
            firstName,
            lastName,
            userName,
            address,
            password,
            locationId,
            mobileNumber,
            telephoneNumber,
            age,
            email,
            textType,
            jobRoleId,
            workLevelId,
            yearId,
            file,
            qualification,
            changeJoiningDate,
            startDate,
            behThemeId,
            techThemeId,
            adminId,
            employeeId,
            employeeName,
        } = this.state;
        if (this.validate(groupCompanyId, "errorGroupCompanySelected", "Please Select Group company "))
            return true
        if (this.validate(employeeId, "erroremployeeId", "Please Select Employee "))
            return true
        if (this.validate(userType, "errorUserType", "Please select UserType"))
            return true;
        if (
            this.validate(
                departmentId,
                "errorDepartmentSelected",
                "Please select Department"
            )
        )
            return true;
        if (
            this.validate(
                designationId,
                "errorDesignationSelected",
                "Please select Designation"
            )
        )
            return true;
        if (this.validate(yearId, "erroryearId", "Please Select Financial year"))
            return true

        // if (
        //     await this.validateUserName(
        //         userName,
        //         "errorUserName",
        //         "Please Enter UserName"
        //     )
        // )
        //     return true;

        if (
            this.validate(
                workLevelId,
                "errorWorkLevelSelected",
                "Please Select Work Level"
            )
        )
            return true;
        if (
            this.validate(jobRoleId, "errorJobRoleSelected", "Please Select JobRole")
        )
            return true;


        let matrixType = textType.toString();
        let themesSelected = "";
        if (behThemeId) {
            themesSelected += behThemeId + ",";
        }
        if (techThemeId) {
            themesSelected += techThemeId + ",";
        }
        themesSelected = themesSelected.replace(/(^,)|(,$)/g, "");
        let dob = moment(startDate).format("YYYY-MM-DD");
        let doj = moment(changeJoiningDate).format("YYYY-MM-DD");

        const formData = new FormData();
        formData.append("user_Name", employeeName);
        formData.append("userId", employeeId);

        formData.append('yearId', yearId);
        formData.append("doj", doj);
        formData.append("designation", designationId);
        formData.append("matrixType", matrixType);
        formData.append("worklevel", workLevelId);
        formData.append("jobRole", jobRoleId);
        formData.append("userTheme", themesSelected);
        formData.append("groupcomp", groupCompanyId);
        formData.append("department", departmentId);
        formData.append("adminId", adminId);
        formData.append("userTypeId", userType);
        // formData.append("status", 'active');
        // console.log(...formData, "formdata");

        console.log([...formData]);

        // return false

        try {
            const result = await MasterMiddleWare.createMaster(tableName, formData);
            if (result) {
                Swal.fire({
                    position: "center",
                    type: "success",
                    title: "User has been saved",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.setState({
                    firstName: "",
                    lastName: "",
                    userName: "",
                    password: "",
                    address: "",
                    mobileNumber: "",
                    telephoneNumber: "",
                    dob: "",
                    email: "",
                    age: "",
                    doj: "",
                    designationId: "",
                    qualification: "",
                    file: "",
                    matrixType: "",
                    workLevelId: "",
                    jobRoleId: "",
                    themesSelected: "",
                    locationId: "",
                    departmentId: "",
                    userType: "",
                    groupCompanySelected: "",
                    locationSelected: "",
                    jobRoleSelected: "",
                    designationSelected: "",
                    workLevelSelected: "",
                    departmentSelected: "",
                    textType: [],
                    behThemeId: "",
                    techThemeId: "",
                    techThemeSelected: "",
                    behThemeSelected: ""
                });
            }
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };
    buttonEdit = value => {
        let { departmentOptions, employeeName, employeeOptions, yearOptions, yearId, designationOptions, groupCompanyOptions, locationOptions, workLevelOptions, jobRoleOptions, behThemeOptions, techThemeOptions, adminId } = this.state
        const index = value.index;
        const previousData = [...this.state.data];
        const getData = { ...previousData[index] };

        let groupCmpSelected = groupCompanyOptions.find(x => x.value == getData.groupcomp)
        if (groupCmpSelected) {
            this.getSelectBoxEmployeeMaster(
                groupCmpSelected.value,
                'tbl_users',
                'id',
                'name',
                'employeeOptions',
                getData.userId
            );
        }
        let TYPE = getData.matrixType.split(",")
        let userThemes = getData.userTheme.split(",")
        let behaThemSelected = [];
        let techThemSelected = [];
        userThemes.map((kval) => {
            TYPE.map(async (val, index) => {
                if (val === "Behavioral") {
                    const { data } = await MasterMiddleWare.getThemeForSelect(
                        adminId,
                        val
                    );
                    if (data) {
                        await this.setState({ behThemeOptions: data });
                        data.map((jval) => {
                            if (jval.value == parseInt(kval)) {
                                behaThemSelected.push(jval)
                            }
                        })
                    }
                } else {
                    const { data } = await MasterMiddleWare.getThemeForSelect(
                        adminId,
                        val
                    );
                    if (data) {
                        await this.setState({ techThemeOptions: data });
                        data.map((jval) => {
                            if (jval.value == parseInt(kval)) {
                                techThemSelected.push(jval)
                            }
                        })

                    }
                }
            });
        })

        var originalDOB = getData.dob;
        var originalDOJ = getData.doj
        var DOB = new Date(originalDOB);
        var DOJ = new Date(originalDOJ);
        let departSelected = departmentOptions.find(x => x.value == parseInt(getData.department))
        let designatSelected = designationOptions.find(x => x.value == getData.designation)
        let locatSelected = locationOptions.find(x => x.value == getData.location)
        let workLevelselected = workLevelOptions.find(x => x.value == getData.worklevel)
        let jobrollSelected = jobRoleOptions.find(x => x.value == getData.jobRole)
        let yearselected = yearOptions.find(x => x.value == getData.yearId)

        this.setState({
            index,
            uodateUserId: getData.id,
            groupCompanyId: groupCmpSelected.value,
            designationId: designatSelected.value,
            workLevelId: workLevelselected.value,
            jobRoleId: jobrollSelected.value,
            yearId: getData.yearId,
            employeeId: getData.userId,
            updateUserImage: getData.image,
            file: getData.image,
            groupCompanyOptions: groupCompanyOptions,
            groupCompanySelected: groupCmpSelected,
            updateData: getData.updateData,
            errorGroupCompanySelected: '',
            erroryearId: '',
            errorUserType: "",
            erroremployeeId: '',
            userType: getData.userTypeId,
            textType: getData.matrixType,
            errorUserType: '',
            departmentOptions: departmentOptions,
            departmentSelected: departSelected,
            departmentId: departSelected.value,
            errorDepartmentSelected: '',
            designationOptions: designationOptions,
            designationSelected: designatSelected,
            errorDesignationSelected: '',
            firstName: getData.name,
            errorFirstName: '',
            lastName: getData.last_name,
            errorLastName: '',
            userName: getData.user_name,
            errorUserName: '',
            address: getData.address,
            errorAddress: '',
            password: getData.password,
            errorPassword: '',
            locationSelected: locatSelected,
            errorLocationSelected: '',
            locationOptions: locationOptions,
            mobileNumber: getData.mobile_no,
            errorMobileNumber: '',
            telephoneNumber: getData.telphone,
            errorTelephoneNumber: '',
            startDate: DOB,
            age: getData.age,
            errorAge: '',
            dateOfJoining: DOJ,
            email: getData.email_id,
            errorEmail: '',
            // textType: getData.textType,
            errorTextType: '',
            behThemeOptions: behThemeOptions,
            behThemeSelected: behaThemSelected,
            techThemeOptions: techThemeOptions,
            techThemeSelected: techThemSelected,
            workLevelOptions: workLevelOptions,
            workLevelSelected: workLevelselected,
            errorWorkLevelSelected: '',
            jobRoleOptions: jobRoleOptions,
            jobRoleSelected: jobrollSelected,
            errorJobRoleSelected: '',
            qualification: getData.qualification,
            errorQualification: '',
            employeePhoto: getData.employeePhoto,
            errorEmployeePhoto: getData.errorEmployeePhoto,
            alertVisible: false,
            updateData: true,
            yearSelected: yearselected
        });
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    onUpdate = async () => {
        const {
            groupCompanyId,
            userType,
            departmentId,
            employeeName,
            employeeId,
            yearId,
            departmentSelected,
            designationSelected,
            workLevelSelected,
            jobRoleSelected,
            locationSelected,
            designationId,
            firstName,
            lastName,
            userName,
            address,
            password,
            locationId,
            mobileNumber,
            telephoneNumber,
            age,
            email,
            textType,
            jobRoleId,
            workLevelId,
            file,
            qualification,
            changeJoiningDate,
            startDate,
            behThemeId,
            techThemeId,
            groupCompanySelected,
            adminId,
        } = this.state;

        if (
            this.validate(
                groupCompanyId,
                'errorGroupCompanySelected',
                'Please Select GroupCompany'
            )
        )
            return true;
        if (this.validate(employeeId, "erroremployeeId", "Please Select Employee "))
            return true
        if (this.validate(userType, "errorUserType", "Please select UserType"))
            return true;
        if (
            this.validate(
                departmentId,
                "errorDepartmentSelected",
                "Please select Department"
            )
        )
            return true;
        if (
            this.validate(
                designationId,
                "errorDesignationSelected",
                "Please select Designation"
            )
        )
            return true;
        if (this.validate(yearId, "erroryearId", "Please Select Financial year"))
            return true
        if (this.validate(textType, 'errortextType', 'Please Enter competency type')) return true;
        if (
            this.validate(
                workLevelId,
                "errorWorkLevelSelected",
                "Please Select Work Level"
            )
        )
            return true;
        if (
            this.validate(jobRoleId, "errorJobRoleSelected", "Please Select JobRole")
        )
            return true;


        let matrixType = textType.toString();
        // console.log(matrixType, "matrixType");
        let themesSelected = "";
        if (behThemeId) {
            console.log(behThemeId, "behThemeId");
            themesSelected += behThemeId + ",";
        }
        if (techThemeId) {
            themesSelected += techThemeId + ",";
        }
        themesSelected = themesSelected.replace(/(^,)|(,$)/g, "");
        let dob = moment(startDate).format("YYYY-MM-DD");
        let doj = moment(changeJoiningDate).format("YYYY-MM-DD");

        // return false

        const formData = new FormData();
        formData.append("user_Name", employeeName);
        formData.append("userId", employeeId);

        formData.append('yearId', yearId);
        formData.append("doj", doj);
        formData.append("designation", designationId);
        formData.append("matrixType", matrixType);
        formData.append("worklevel", workLevelId);
        formData.append("jobRole", jobRoleId);
        if (behThemeId || techThemeId) {
            formData.append("userTheme", themesSelected);
        } formData.append("groupcomp", groupCompanyId);
        formData.append("department", departmentId);
        formData.append("adminId", adminId);
        formData.append("userTypeId", userType);
        // console.log(...formData, "formdata");

        console.log([...formData]);
        let ID = this.state.uodateUserId
        console.log(ID, "ID", tableName, "tableName");
        // console.log(previousData, "previousData");
        try {
            const result = await MasterMiddleWare.updateMaster(tableName, ID, formData);
            console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: "center",
                    type: "success",
                    title: "User has been updated",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.setState({
                    disable: false,
                    firstName: "",
                    lastName: "",
                    userName: "",
                    password: "",
                    address: "",
                    mobileNumber: "",
                    telephoneNumber: "",
                    dob: "",
                    email: "",
                    age: "",
                    doj: "",
                    designationId: "",
                    qualification: "",
                    file: "",
                    matrixType: "",
                    workLevelId: "",
                    jobRoleId: "",
                    themesSelected: "",
                    locationId: "",
                    departmentId: "",
                    userType: "",
                    groupCompanySelected: "",
                    locationSelected: "",
                    jobRoleSelected: "",
                    designationSelected: "",
                    workLevelSelected: "",
                    departmentSelected: "",
                    textType: [],
                    behThemeId: "",
                    techThemeId: "",
                    techThemeSelected: "",
                    behThemeSelected: "",
                    startDate: ""
                });
                // this.MainFun();
            }
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };
    changeJoiningDate = date => {
        this.setState({
            dateOfJoining: date
        });
    };

    behThemeSelect = selectedOption => {
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            this.setState({
                behThemeSelected: selectedOption,
                behThemeId: selectedValue
            });
        } else {
            this.setState({ behThemeSelected: "", behThemeId: "" });
        }
    };

    techThemeSelect = selectedOption => {
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            this.setState({
                techThemeSelected: selectedOption,
                techThemeId: selectedValue
            });
        } else {
            this.setState({ techThemeSelected: "", techThemeId: "" });
        }
    };

    dateofBirth = e => {
        let dob = new Date(e);
        var today = new Date();
        var age = today.getFullYear() - dob.getFullYear();
        var m = today.getMonth() - dob.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        this.setState({ startDate: dob, age: age });
    };


    handleFileUpload = (fileItems) => {
        this.setState({
            files: fileItems.map((fileItem) => fileItem.file),
        });
    };

    singlemultiselect = (e, val) => {
        this.setState({
            multi: val
        });
    };
    groupCompanySelect = selectedOption => {
        this.setState({
            groupCompanySelected: selectedOption,
            groupCompanyId: selectedOption.value,
            groupCompanyName: selectedOption.label
        });
        this.getSelectBoxEmployeeMaster(
            selectedOption.value,
            'tbl_users',
            'id',
            'name',
            'employeeOptions'
        );
    };
    getSelectBoxEmployeeMaster = async (
        adminId,
        tableName,
        forId,
        forvalue,
        stateValue,
        userEmpId
    ) => {
        const { data } = await MasterMiddleWare.getSelectBoxEmployeeMaster(
            adminId,
            tableName,
            forId,
            forvalue
        );
        if (data) {
            let filterData = []
            data.filter((a, b) => {
                if (a.is_hr == "1") {
                    let obj = {
                        value: a.value,
                        label: a.label + " (Hr) "
                    }
                    filterData.push(obj)
                }
                else if (a.is_manager == "1") {
                    let obj = {
                        value: a.value,
                        label: a.label + " (Manager) "
                    }
                    filterData.push(obj)
                }
                else if (a.is_employee == "1") {
                    let obj = {
                        value: a.value,
                        label
                            : a.label + " (Employee) "
                    }
                    filterData.push(obj)
                }
            })
            await this.setState({ [stateValue]: filterData });
        }
        if (userEmpId && data) {
            let employeSelected = data.find(x => x.value == userEmpId)
            this.setState({ employeeSelected: employeSelected, employeeName: employeSelected.label })

        }
    };
    yearSelectId = selectedOption => {
        this.setState({
            yearSelected: selectedOption,
            yearId: selectedOption.value,
            yearName: selectedOption.label,

        });
    };
    employeeSelectId = async selectedOption => {
        const { employeeOptions, yearId } = this.state;
        const previousData = [...employeeOptions];
        const filterData = previousData.filter(
            value => value.value !== selectedOption.value
        );
        this.setState({
            employeeSelected: selectedOption,
            employeeId: selectedOption.value,
            employeeName: selectedOption.label,
        });

        let userDetails = await HrMiddleWare.getRandomData(
            "tbl_users,tbl_gcompanies,tbl_userType,tbl_department,tbl_designation,tbl_jobRole,tbl_worklevel",
            `tbl_users.id = ${selectedOption.value} and tbl_users.groupcomp=tbl_gcompanies.id and tbl_users.userTypeId=tbl_userType.id and tbl_users.department=tbl_department.id and tbl_users.designation=tbl_designation.id and tbl_users.jobRole=tbl_jobRole.id and tbl_users.worklevel=tbl_worklevel.id`,
            "tbl_gcompanies.groupcompanies,tbl_designation.designation,tbl_userType.userType,tbl_department.department,tbl_jobRole.jobRoleName,tbl_worklevel.worklevel,tbl_users.name"
        );
        if (userDetails) {
            this.setState({ viewUserData: userDetails.data[0], modelOpen: true })
        }
    };
    viewDetails = () => {
        this.setState({ modelOpen: true })
    }
    handleback = () => {
        this.setState({ modelOpen: false })

    }
    render() {
        const {
            excelHead,
            groupCompanyOptions,
            groupCompanySelected,
            updateData,
            data,
            errorGroupCompanySelected,
            erroremployeeId,
            erroryearId,
            userType,
            errorUserType,
            departmentOptions,
            departmentSelected,
            errorDepartmentSelected,
            designationOptions,
            designationSelected,
            errorDesignationSelected,
            firstName,
            errorFirstName,
            lastName,
            errorLastName,
            userName,
            errorUserName,
            address,
            errorAddress,
            password,
            errorPassword,
            locationSelected,
            errorLocationSelected,
            locationOptions,
            mobileNumber,
            errorMobileNumber,
            telephoneNumber,
            errorTelephoneNumber,
            startDate,
            age,
            errorAge,
            dateOfJoining,
            email,
            errorEmail,
            textType,
            errorTextType,
            behThemeOptions,
            behThemeSelected,
            techThemeOptions,
            techThemeSelected,
            workLevelOptions,
            workLevelSelected,
            errorWorkLevelSelected,
            jobRoleOptions,
            jobRoleSelected,
            errorJobRoleSelected,
            qualification,
            errorQualification,
            employeePhoto,
            errorEmployeePhoto,
            data1dept,
            behaviourltechnical,
            usertypeidopt,
            employeeOptions,
            employeeSelected,
            yearOptions,
            yearSelected,
            viewUserData,
            modelOpen
        } = this.state;
        // console.log(viewUserData, "viewUserData");
        return (
            <React.Fragment>
                <section>
                    <div className='container container-sm-fluid mt-100'>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-lg-8 col-md-12 col-12'>
                                <div className='content-border px-2'>
                                    <div className='header-text px-lg-3'>
                                        <span>Add Promotion</span>
                                    </div>
                                    <hr />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="department">Group Company</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.groupCompanySelect}
                                                options={groupCompanyOptions}
                                                selectedService={groupCompanySelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorGroupCompanySelected} />
                                    {/* <ValidationView errorname={errorGroupCompanySelected} /> */}
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Employee</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.employeeSelectId}
                                                options={employeeOptions}
                                                selectedService={employeeSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={erroremployeeId} />
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-lg-8 col-md-8 col-12">
                                            {viewUserData && modelOpen &&
                                                <div className="userDetailCard content-border1">
                                                    <div className="text-center">
                                                        <h5 className="userCardHeading"> Details of previous position in user </h5>
                                                    </div>
                                                    <button className="btncolor" onClick={this.handleback}>back</button>
                                                    <div class="my-3">
                                                        <>

                                                            <div className="userDataContent">
                                                                <h5 className="useKey"> UserName: </h5> <p>{viewUserData.name}</p>
                                                            </div>
                                                            <div className="userDataContent">
                                                                <h5 className="useKey">Department : </h5> <p>{viewUserData.department}</p>
                                                            </div>
                                                            <div className="userDataContent">
                                                                <h5 className="useKey">JobRole : </h5> <p>{viewUserData.jobRoleName}</p>
                                                            </div>
                                                            <div className="userDataContent">
                                                                <h5 className="useKey">UserType : </h5> <p>{viewUserData.userType}</p>
                                                            </div>
                                                            <div className="userDataContent">
                                                                <h5 className="useKey">WorkLevel : </h5> <p>{viewUserData.worklevel}</p>
                                                            </div>
                                                            <div className="userDataContent">
                                                                <h5 className="useKey">Designation : </h5> <p>{viewUserData.designation}</p>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">User Type</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex alig-items-center">
                                                    <input
                                                        type="radio"
                                                        id="Employee"
                                                        name="userType"
                                                        value="5"
                                                        checked={userType == "5" && true}
                                                        onChange={this.handleUserInput}
                                                    />
                                                    <label className="formLabel">
                                                        &nbsp;Employee
                                                    </label>
                                                </div>
                                                <div className="d-flex alig-items-center">
                                                    <input
                                                        type="radio"
                                                        id="Hr"
                                                        name="userType"
                                                        value="6"
                                                        checked={userType == "6" && true}
                                                        onChange={this.handleUserInput}
                                                    />{" "}
                                                    <label className="formLabel">
                                                        &nbsp;Hr
                                                    </label>
                                                </div>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorUserType} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Department</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.departmentSelect}
                                                options={departmentOptions}
                                                selectedService={departmentSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorDepartmentSelected} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Designation</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.designationSelect}
                                                options={designationOptions}
                                                selectedService={designationSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorDesignationSelected} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Financial Year</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.yearSelectId}
                                                options={yearOptions}
                                                selectedService={yearSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={erroryearId} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Date Of Joining</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <DatePicker
                                                startDate={dateOfJoining}
                                                changeDate={this.changeJoiningDate}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={erroryearId} />

                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="function">Competency Type</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <label className="formLabel">
                                                <input
                                                    type="checkbox"
                                                    id="Behavioral"
                                                    name="textType"
                                                    value="Behavioral"
                                                    checked={textType.includes("Behavioral") && true}
                                                    onChange={this.handleCheckInput}
                                                />
                                                &nbsp;&nbsp;&nbsp;Behavioral
                                            </label>
                                            &nbsp;&nbsp;&nbsp;
                                            <label className="formLabel">
                                                <input
                                                    type="checkbox"
                                                    id="Technical"
                                                    name="textType"
                                                    value="Technical"
                                                    checked={textType.includes("Technical") && true}
                                                    onChange={this.handleCheckInput}
                                                />{" "}
                                                &nbsp;&nbsp;&nbsp;Technical
                                            </label>
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorTextType} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-1'>
                                            {textType.includes("Behavioral") && (
                                                <MultiSelect
                                                    handleChange={this.behThemeSelect}
                                                    options={behThemeOptions}
                                                    selectedService={behThemeSelected}
                                                />
                                            )}
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            {textType.includes("Technical") && (
                                                <MultiSelect
                                                    handleChange={this.techThemeSelect}
                                                    options={techThemeOptions}
                                                    selectedService={techThemeSelected}
                                                />
                                            )}
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorTextType} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="competency">Work Level</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.workLevelSelectsingal}
                                                options={workLevelOptions}
                                                selectedService={workLevelSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorWorkLevelSelected} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                            <label className='formLabel' htmlFor="competency">Job Role</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            <SingleSelect
                                                handleChange={this.jobRoleSelect}
                                                options={jobRoleOptions}
                                                selectedService={jobRoleSelected}
                                            />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <ValidationView errorname={errorJobRoleSelected} />
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-12 py-1'>
                                            {!updateData ? (
                                                <button type="button" className="submit-btn" onClick={this.onSubmit}>
                                                    Submit
                                                </button>
                                            ) : (
                                                <button type="button" className="submit-btn" onClick={this.onUpdate}
                                                >
                                                    Update
                                                </button>
                                            )}
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-2 col-12 py-5 my-5'>
                            {data && <Datatable data={data} columnHeading={this.column} />}
                        </div>
                    </div>
                </section>
                <Style>
                    {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.filepond{
  height: 82px;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 300px;
}

.filepond--credits{
  color:#f7f7f7;
}

.filepond--root :not(text) {
  font-size: 13px;
}

.multi:hover{
  color: limegreen;
}

.excelexportlink{
  white-space: nowrap;
  text-decoration: underline;
}
.excelexportlink:hover{
  white-space: nowrap;
  text-decoration: underline;
  color:black
}


`}

                </Style >
            </React.Fragment>
        );
    }
}

export default Promotion;
