import React, { Component } from "react";
import { Link } from "react-router-dom";

//import logo from '../../image/Murugappa_Group_Logo.svg.png';
import logo from "../../image/difuzaWebLogo.png";
import { ACCESS_POINT } from "../../config/index";
// import userImg from "../../image/user2-160x160.jpg";
import userImg from "../../image/person_1.png";

import "./AdminHeaderStyle.css";
import "./AdminStyle.css";
import HrMiddleWare from "../../middleWare/HrMiddleWare";

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      navshow: false,
      logout: false,
      themeshow: false,
      theme: false
    };
  }
  async componentWillMount() {
    const adminId = await localStorage.getItem("userId");
    const user = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${adminId}`,
      "*"
    );
    if (user) {
      this.setState({ user: user.data[0] });
    }
  }
  async componentDidMount() {
    const theme = localStorage.getItem('Admintheme');
    document.querySelector("body").setAttribute("data-theme", `${theme}`)
    if (theme === "light") {
      this.setState({ theme: false })
    } else if (theme === "dark") {
      this.setState({ theme: true })
    } else {
      this.setState({ theme: false })
    }
    const adminId = await localStorage.getItem("userId");
    const username = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${adminId}`,
      "*"
    );
    if (username) {
      localStorage.setItem("userlog", JSON.stringify(username.data[0]));
      this.setState({ username_img: username.data[0] });
    }
    document
      .getElementsByTagName("body")[0]
      .setAttribute("class", "hold-transition skin-blue sidebar-mini");
  }

  logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  themecolourshow = () => {
    console.log("it enter", this.state.themeshow)
    this.setState((prev) => ({
      themeshow: !prev.themeshow
    }))
  }
  changecolor = (value) => {
    console.log(value, "value")
    localStorage.setItem('Admintheme', value);
    document.querySelector("body").setAttribute("data-theme", `${value}`)
    if (value === "light") {
      this.setState({ theme: true })
    }
    else {
      this.setState({ theme: false })
    }
  }
  changeMode = (value) => {
    localStorage.setItem('Admintheme', value);
    document.querySelector("body").setAttribute("data-theme", `${value}`);
    const val = this.state.theme;
    this.setState({
      theme: !val
    })
    const check = value === "light";
    const path = window.location.pathname;
    if (path === "/hr/dashboard" || path === "/User/dahsboard") {
      window.location.reload()
    } else {
    } };

  opentree = (value) => {
    var tree1 = document.getElementById("tree1")
    var tree2 = document.getElementById("tree2")
    var tree3 = document.getElementById("tree3")
    var tree4 = document.getElementById("tree4")
    if (value === "tree1") {
      tree1.classList.toggle("d-none")
      tree2.classList.add("d-none")
      tree3.classList.add("d-none")
      tree4.classList.add("d-none")
    }
    else if (value === "tree2") {
      tree2.classList.toggle("d-none")
      tree3.classList.add("d-none")
      tree4.classList.add("d-none")
    }
    else if (value === "tree3") {
      tree3.classList.toggle("d-none")
      tree2.classList.add("d-none")
      tree4.classList.add("d-none")
    }
    else if (value === "tree4") {
      tree4.classList.toggle("d-none")
      tree2.classList.add("d-none")
      tree3.classList.add("d-none")
      tree1.classList.add("d-none")
    }
  }
  sideCollapse = () => {
    this.setState((prevState) => ({
      navshow: !prevState.navshow,
    }));
  }
  logoutBox = () => {
    this.setState((prevState) => ({
      logout: !prevState.logout,
    }));
  }
  render() {
    const { user, navshow, themeshow } = this.state;
    const userTypeId = localStorage.getItem("userTypeId");
    const user_Name = JSON.parse(localStorage.getItem("userlog"));
    // console.log(user_Name);
    const img = ACCESS_POINT + "/getpro/getprofile?fileurl=" + user.image;
    const styles = {
      width: "250px",
      height: "70px",
      marginRight: "15px",
      paddingRight: "0px",
      marginLeft: "-15px",
      marginBottom: "0px",
      paddingBottom: "18px"
    };
    const windowHeight = window.innerHeight;
    return (
      <section>
        <div className="admin-header text">
          <div className="row justify-content-between  mb-5">
            <div className="col-lg-1 col-sm-2  col-md-1 px-lg-5 px-5  col-sm-6 col-2">
              <div className="nav-icon">
                {navshow ? <i class="bi bi-x" onClick={this.sideCollapse}></i> : <i class="bi bi-list" onClick={this.sideCollapse}></i>}
              </div>
            </div>
            <div className="col-lg-7 col-md-7  d-none d-md-block d-lg-block">
              <div className="d-flex justify-content-end align-items-center">
                <div className="col-lg-6 col-md-12 text-end">
                  <div className="">
                    <div className="buttons col-12 d-flex jsutify-content-between align-items-center">
                      <div className="theme selector text">
                        <div className="theme-name">
                          <span onClick={this.themecolourshow} className="theme-header">Change theme <i class="bi bi-caret-down-fill"></i></span>
                          <div className={themeshow ? "theme-color px-5 py-2 d-block" : "d-none"}>
                            <div className="d-flex justify-content-between align-items-center my-3">
                              <div className="main-div" onClick={() => this.changecolor("blue-white")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-1"></div>
                                  <div className="color-div-2"></div>
                                </div>
                                <div className="variant1"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("green-white")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-3"></div>
                                  <div className="color-div-4"></div>
                                </div>
                                <div className="variant1"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("purple-white")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-5"></div>
                                  <div className="color-div-6"></div>
                                </div>
                                <div className="variant1"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("red-white")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-7"></div>
                                  <div className="color-div-8"></div>
                                </div>
                                <div className="variant1"></div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-3">
                              <div className="main-div" onClick={() => this.changecolor("blue-dark")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-9"></div>
                                  <div className="color-div-10"></div>
                                </div>
                                <div className="variant2"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("green-dark")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-11"></div>
                                  <div className="color-div-12"></div>
                                </div>
                                <div className="variant2"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("purple-dark")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-13"></div>
                                  <div className="color-div-14"></div>
                                </div>
                                <div className="variant2"></div>
                              </div>
                              <div className="main-div" onClick={() => this.changecolor("red-dark")}>
                                <div className="d-flex align-items-center">
                                  <div className="color-div-15"></div>
                                  <div className="color-div-16"></div>
                                </div>
                                <div className="variant2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                      {this.state.theme ? (
                    <p id="light" onClick={() => this.changeMode("light")} style={{cursor:"pointer"}}>
                      <i className="rem-2 bi bi-moon-stars-fill my-0 mx-5" style={{cursor:"pointer"}}></i>
                    </p>
                  ) : (
                    <p id="light" onClick={() => this.changeMode("dark")} style={{cursor:"pointer"}}>
                      <i className="rem-2 bi bi-brightness-high-fill my-0 mx-5" style={{cursor:"pointer"}}></i>
                    </p>
                  )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-6 col-8 text-end">
              <div className="row justify-content-between align-items-center mb-3 logout px-5" style={{cursor:"pointer"}} onClick={this.logoutBox}>
                <div className="user-icon d-flex justify-content-end justify-content-lg-start justify-content-md-start align-items-center">
                  <img src={img ? img : userImg} alt="novalid" className="user-image" />
                  <span className="text mx-2 d-none d-sm-block d-lg-block d-md-block">
                    {user_Name && user_Name.name}
                  </span>
                  <div className={this.state.logout ? "logout-box" : "d-none"}>
                    <div className="text-center">
                      <div>
                        <span style={{cursor:"pointer"}}><a><i class="bi bi-person-circle" ></i> Profile</a></span>
                      </div>
                      <div>
                        <span style={{cursor:"pointer"}}><a onClick={this.logout}><i class="bi bi-box-arrow-left" ></i> Logout</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div className={navshow ? "admin-side-nav slide-in" : "d-none slide-out"} style={{ height: windowHeight }}>
          <div className="logo-img">
            <a href=""><img src={logo} /></a>
          </div>
          <div className="d-flex mt-3 d-md-none">
                <div className="buttons d-flex justify-content-between">
                  <div className="theme selector ms-4 text">
                    <div className="theme-name">
                      <span style={{backgroundColor:"transparent" , border:"none" ,color:"var(--text-color)"}} onClick={this.themecolourshow} className="theme-header"><i class="mx-4 rem-2 bi bi-gear"></i></span>
                      <div style={{ width: "270px" }} className={themeshow ? "theme-color px-2 py-2 d-block" : "d-none"}>
                        <div className="d-flex justify-content-between align-items-center my-3">
                          <div className="main-div" onClick={() => this.changecolor("blue-white")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-1"></div>
                              <div className="color-div-2"></div>
                            </div>
                            <div className="variant1"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("green-white")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-3"></div>
                              <div className="color-div-4"></div>
                            </div>
                            <div className="variant1"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("purple-white")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-5"></div>
                              <div className="color-div-6"></div>
                            </div>
                            <div className="variant1"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("red-white")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-7"></div>
                              <div className="color-div-8"></div>
                            </div>
                            <div className="variant1"></div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-3">
                          <div className="main-div" onClick={() => this.changecolor("blue-dark")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-9"></div>
                              <div className="color-div-10"></div>
                            </div>
                            <div className="variant2"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("green-dark")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-11"></div>
                              <div className="color-div-12"></div>
                            </div>
                            <div className="variant2"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("purple-dark")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-13"></div>
                              <div className="color-div-14"></div>
                            </div>
                            <div className="variant2"></div>
                          </div>
                          <div className="main-div" onClick={() => this.changecolor("red-dark")}>
                            <div className="d-flex align-items-center">
                              <div className="color-div-15"></div>
                              <div className="color-div-16"></div>
                            </div>
                            <div className="variant2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                  {this.state.theme ? (
                      <p onClick={() => this.changeMode("light")}>
                        <i className="rem-2 bi bi-moon-stars-fill my-0 mx-5"></i>
                      </p>
                    ) : (
                      <p onClick={() => this.changeMode("dark")}>
                        <i className="rem-2 bi bi-brightness-high-fill my-0 mx-5"></i>
                      </p>
                    )}
                  </div>
                </div>
              </div>
          <div className=" admin-ul ps-2 ms-2">
            <li className="py-2 hover-li"><a href="#" onClick={() => this.opentree("tree1")}>Dashboard</a></li>
            <div className="admin-ul1 d-none slide-in" id="tree1">
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/CompetencyMatrix/CompFormOne`}
              >
                Create Competency Matrix
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Mapping/MapEmployee`}
              >
                Create Map Employees
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href="#"
                onClick={() => this.opentree("tree2")}
              >
                Behavioral
              </a></li>
              <div id="tree2" className="d-none">
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapThemeToWorkLevel"
                >
                  Map Theme to Work
                  Level
                </a></li>
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapWorkleveToCompetency"
                >
                  Map Work level
                  Competency
                </a></li>
              </div>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href="#"
                onClick={() => this.opentree("tree3")}
              >
                Technical
              </a></li>
              <div id="tree3" className="d-none">
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapDepartmentToFunction"
                >
                  Map Department &
                  Function
                </a></li>
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapFunctionToWorklevel"
                >
                  Map Function &
                  Worklevel
                </a></li>
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapCompetencyLevel"
                >
                  Map Competency Level
                </a></li>
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href="/admin/Mapping/MapCompetencyParams"
                >
                  Map Competency
                  Params
                </a></li>
              </div>
            </div>
            <li className="py-2 hover-li"><a href="#" onClick={() => this.opentree("tree4")}>Masters</a></li>
            <div className="admin-ul1 d-none navslideIn " id="tree4">
              {parseInt(userTypeId) === 1 && (
                <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                  className="nav-link"
                  href={`/admin/Master/Customer`}
                >
                  Customer
                </a></li>
              )}
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/GroupCompany`}
              >
                Group Company
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/JobRole`}
              >
                Job Role
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Department`}
              >
                Department
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/JobDescription`}
              >
                Job Description
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/WorkLevel`}
              >
                Work level
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Themes`}
              >
                Themes
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Function`}
              >
                Function
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Competency`}
              >
                Competency
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/ProficiencyScale`}
              >
                Proficiency Scale
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Indicators`}
              >
                Indicators
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Location`}
              >
                Location
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Users`}
              >
                Users
              </a></li>
              <li className="d-flex align-items-center py-2"><i class="bi bi-chevron-right"></i><a
                className="nav-link"
                href={`/admin/Master/Derailers`}
              >
                Derailers
              </a></li>
            </div>
            <li className="py-2 hover-li"><a href="#" >History</a></li>
            <li className="py-2 hover-li"><a href="/admin/Master/promotion" >Promotion</a></li>
            <li className="py-2 hover-li"><a href="/admin/Master/TraingprogramAdd" >Traingprogram</a></li>
            <li className="py-2 hover-li"><a href="/admin/Master/TraingprogramMapping" >ProgramMapping</a></li>
            <li className="py-2 hover-li"><a href="/admin/Master/CoursemapwithTrainer" >Course map with trainer</a></li>
          </div>
        </div> 
      </section>
    );
  }
}

export default AdminHeader;
