import React, { Component } from 'react';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import './style.css';
import Swal from 'sweetalert2';
import WizardPrograss from '../Master/wizardPrograssbar';


class CompFormTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      jobRoleId: null,
      jobRoleName: null,
      workLevelId: null,
      workLevelName: null,
      departmentName: null,
      matrixType: null,
      financialYearId: null,
      functionName: null,
      themeList: [],
      fullCompList: [],
      indicatorList: [],
      levelList: [],
      checkSelected: false,
      selectedCompetency: null,
      indicatorsSelected: [],
      themeSelected: null,
      indicatorClicked: false,
      alreadySelectedIndicator: [],
      originalMappedArray: []
    };
  }

  async componentDidMount() {
    let jobRoleId = await localStorage.getItem('jobRoleId');
    let jobRoleName = await localStorage.getItem('jobRoleName');
    let workLevelId = await localStorage.getItem('workLevelId');
    let workLevelName = await localStorage.getItem('workLevelName');
    let financialYearId = await localStorage.getItem('financialYearId');
    let departmentName = await localStorage.getItem('departmentName');
    let functionName = await localStorage.getItem('functionName');
    let matrixType = await localStorage.getItem('type');
    let companyId = await localStorage.getItem('userId');
    let groupcompany = await localStorage.getItem('groupcompId');

    try {
      const { data: themeList } = await MappingMiddleware.getThemeListMM(
        jobRoleId,
        workLevelId,
        companyId,
        matrixType
      );

      if (themeList) {
        const map = new Map();
        const themeData = [];
        for (const item of themeList) {
          if (!map.has(item.themeid)) {
            map.set(item.themeid, true); // set any value to Map
            themeData.push({ themeid: item.themeid, theme: item.theme });
          }
        }
        this.setState({
          themeSelected: themeList[0].themeid,
          themeList: themeData,
          fullCompList: themeList,
          jobRoleId,
          jobRoleName,
          workLevelId,
          workLevelName,
          financialYearId,
          matrixType,
          companyId,
          departmentName,
          functionName,
          groupcompany
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  changeTheme = themeId => {
    const { indicatorClicked } = this.state;
    if (!indicatorClicked) {
      this.setState({ themeSelected: themeId });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Save Changes before Proceeding!'
      });
    }
  };

  getIndicators = async competencyId => {
    const {
      companyId,
      selectedCompetency,
      indicatorClicked,
      indicatorsSelected,
      jobRoleId,
      workLevelId,
      themeSelected
    } = this.state;
    if (!indicatorClicked) {
      try {
        const {
          data: indicatorList
        } = await MappingMiddleware.getIndicatorsList(competencyId, companyId);
        if (indicatorList) {
          const map = new Map();
          const levelList = [];
          for (const item of indicatorList) {
            if (!map.has(item.levelid)) {
              map.set(item.levelid, true); // set any value to Map
              levelList.push({ levelid: item.levelid, Level: item.Level });
            }
          }
          this.setState({
            indicatorList,
            levelList,
            selectedCompetency: competencyId
          });
        }
        const {
          data: alreadySelectedIndicator
        } = await MappingMiddleware.getAlreadySelectedIndicator(
          themeSelected,
          jobRoleId,
          workLevelId,
          competencyId,
          companyId
        );
        if (alreadySelectedIndicator) {
          let indicatorIdArray = [];
          alreadySelectedIndicator.map(indic =>
            indicatorIdArray.push(indic.indicators)
          );
          this.setState({
            alreadySelectedIndicator: indicatorIdArray,
            originalMappedArray: indicatorIdArray
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (indicatorsSelected.length === 0) {
      this.setState({ indicatorClicked: false });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Save Changes before Proceeding!'
      });
    }
  };

  mapSelectedIndicators = (indicatorId, levelId) => {
    let {
      indicatorsSelected,
      alreadySelectedIndicator,
      originalMappedArray
    } = this.state;
    if (alreadySelectedIndicator.length > 0) {
      indicatorsSelected = [...alreadySelectedIndicator];
    }
    if (indicatorsSelected.length > 0) {
      if (indicatorsSelected.includes(indicatorId)) {
        let removedList = indicatorsSelected.filter(
          indicator => indicator !== indicatorId
        );
        let removedSelectedIdList = alreadySelectedIndicator.filter(
          indicator => indicator !== indicatorId
        );

        this.setState({
          indicatorsSelected: removedList,
          alreadySelectedIndicator: removedSelectedIdList,
          indicatorClicked:
            JSON.stringify(originalMappedArray.sort()) ===
              JSON.stringify(removedSelectedIdList.sort())
              ? false
              : true
        });
      } else {
        indicatorsSelected.push(indicatorId);
        alreadySelectedIndicator.push(indicatorId);
        this.setState({
          indicatorsSelected,
          alreadySelectedIndicator,
          indicatorClicked: true
        });
      }
    } else {
      indicatorsSelected.push(indicatorId);
      alreadySelectedIndicator.push(indicatorId);
      this.setState({
        indicatorsSelected,
        indicatorClicked: true,
        alreadySelectedIndicator
      });
    }
  };

  onSubmit = async () => {
    let {
      selectedCompetency,
      indicatorsSelected,
      indicatorList,
      themeSelected,
      jobRoleId,
      workLevelId,
      companyId,
      financialYearId,
      alreadySelectedIndicator,
      matrixType,
      groupcompany
    } = this.state;

    // indicatorsSelected = [...alreadySelectedIndicator];
    // console.log(indicatorsSelected);
    let indicatorWithLevel = [];
    indicatorList.map(indicatorList => {
      indicatorsSelected.filter(indicId => {
        if (parseInt(indicatorList.indicatorId) === parseInt(indicId)) {
          indicatorWithLevel.push(indicatorList);
        }
      });
    });

    const formData = new FormData();
    formData.append('theme', themeSelected);
    formData.append('worklevel', workLevelId);
    formData.append('jobRoleId', jobRoleId);
    formData.append('levelid', JSON.stringify(indicatorWithLevel));
    formData.append('competencyId', selectedCompetency);
    formData.append('indicators', JSON.stringify(indicatorWithLevel));
    formData.append('adminId', companyId);
    formData.append('type', 'framework');
    formData.append('financial_year', financialYearId);
    formData.append('categoryType', matrixType);
    formData.append('groupcompany', groupcompany);
    // console.log([...formData]);
    try {
      const result = await MappingMiddleware.mappedMatrixAdmin(formData);
      if (result) {
        this.setState({
          indicatorsSelected: [],
          indicatorClicked: false
        });
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Changes Saved Successfully',
          showConfirmButton: false,
          timer: 3000
        });
        window.location.replace('/admin/CompetencyMatrix/CompFormOne')
      }
    } catch (error) {
      console.log(error);
    }
  };

  handlePrevious = async () => {
    window.location.replace('/admin/CompetencyMatrix/CompFormOne')
  }

  render() {
    const {
      jobRoleName,
      workLevelName,
      themeList,
      fullCompList,
      levelList,
      indicatorList,
      themeSelected,
      selectedCompetency,
      alreadySelectedIndicator,
      departmentName,
      functionName,
      matrixType
    } = this.state;

    let competencyListArray = [];
    let fullPanelList = [];
    if (themeList) {
      themeList.map((themes, index) => {
        competencyListArray = [];
        fullCompList.map((themesAndComp, key) => {
          if (themesAndComp.themeid === themes.themeid) {
            competencyListArray.push(
              <div className='col-lg-12 col-md-8 col-12 my-2'>
                <div className='row justify-content evenly align-items-center'>
                  <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() => this.getIndicators(themesAndComp.competencyid)}
                      checked={
                        selectedCompetency === themesAndComp.competencyid
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                    <label className='formLabel'>
                      {themesAndComp.competencyname}&emsp;&emsp;&emsp;
                    </label>
                  </div>
                </div>
              </div>
            );
          }
        });
        fullPanelList.push(
          <div
            class={index === 0 ? `tab-pane fade in active show` : `tab-pane fade`}
            id={themes.themeid}
          >
            <div class="checkbox">{competencyListArray}</div>
          </div>
        );
      });
    }

    let indicatorArrayLevel1 = [];
    let indicatorArrayLevel2 = [];
    let indicatorArrayLevel3 = [];
    let indicatorArrayLevel4 = [];

    if (levelList) {
      levelList.map((levels, index) => {
        indicatorList.map((indicators, key) => {
          if (levels.levelid === indicators.levelid) {
            if (
              indicators.Level === 'level 1' ||
              indicators.Level === 'Level 1' ||
              indicators.Level === 'Level-1' ||
              indicators.Level === 'level-1' ||
              indicators.Level === 'Level-1'

            ) {
              indicatorArrayLevel1.push(
                <div className='col-lg-12 col-md-12 col-12 my-2'>
                  <div className='row justify-content evenly align-items-center'>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
                      <input
                        type="checkbox"
                        value=""
                        onClick={() =>
                          this.mapSelectedIndicators(
                            indicators.indicatorId,
                            indicators.levelid
                          )
                        }
                        checked={
                          alreadySelectedIndicator.includes(
                            indicators.indicatorId
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className='col-lg-10 col-md-10 col-sm-10 col-10'>
                      <label className='formLabel'>
                        {indicators.indicators}&emsp;&emsp;&emsp;
                      </label>
                    </div>
                  </div>
                </div>

              );
            } else if (
              indicators.Level === 'LEVEL 2' ||
              indicators.Level === 'Level 2' ||
              indicators.Level === 'level-2' ||
              indicators.Level === 'Level-2'

            ) {
              // console.log(2);
              indicatorArrayLevel2.push(
                <div className='col-lg-12 col-md-12 col-12 my-2'>
                  <div className='row justify-content evenly align-items-center'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                      <input
                        type="checkbox"
                        value=""
                        onClick={() =>
                          this.mapSelectedIndicators(
                            indicators.indicatorId,
                            indicators.levelid
                          )
                        }
                        checked={
                          alreadySelectedIndicator.includes(
                            indicators.indicatorId
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                      <label className='formLabel'>
                        {indicators.indicators}&emsp;&emsp;&emsp;
                      </label>
                    </div>
                  </div>
                </div>
              );
            } else if (
              indicators.Level === 'Level-3' ||
              indicators.Level === 'Level 3' ||
              indicators.Level === 'LEVEL 3' ||
              indicators.Level === 'level-3' ||
              indicators.Level === 'LEVEL3' ||
              indicators.Level === 'Level-3'

            ) {
              indicatorArrayLevel3.push(
                <div className='col-lg-12 col-md-12 col-12 my-2'>
                  <div className='row justify-content evenly align-items-center'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                      <input
                        type="checkbox"
                        value=""
                        onClick={() =>
                          this.mapSelectedIndicators(
                            indicators.indicatorId,
                            indicators.levelid
                          )
                        }
                        checked={
                          alreadySelectedIndicator.includes(
                            indicators.indicatorId
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                      <label className='formLabel'>
                        {indicators.indicators}&emsp;&emsp;&emsp;
                      </label>
                    </div>
                  </div>
                </div>
              );
            } else if (
              indicators.Level === 'LEVEL 4' ||
              indicators.Level === 'Level 4' ||
              indicators.Level === 'level-4' ||
              indicators.Level === 'Level-4'

            ) {
              // console.log(4);
              indicatorArrayLevel4.push(
                <div className='col-lg-12 col-md-12 col-12 my-2'>
                  <div className='row justify-content evenly align-items-center'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                      <input
                        type="checkbox"
                        value=""
                        onClick={() =>
                          this.mapSelectedIndicators(
                            indicators.indicatorId,
                            indicators.levelid
                          )
                        }
                        checked={
                          alreadySelectedIndicator.includes(
                            indicators.indicatorId
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                      <label className='formLabel'>
                        {indicators.indicators}&emsp;&emsp;&emsp;
                      </label>
                    </div>
                  </div>
                </div>
              );
            }
          }
        });
      });
    }

    return (
      <div>
        <section>
          <div className='container container-sm-fluid mt-100 pb-5 mb-5'>
            <div className='py-2'>
              <WizardPrograss step={16} />
            </div>
            <div className='row justify-content-center align-items-center my-2'>
              <div className='col-lg-10 col-md-12 col-12'>
                <div className='content-border px-lg-5 px-md-3 px-2'>
                  <div className='header-text'>
                    <span>Create Competency Matrix : Step 2</span>
                  </div>
                  <hr className='px-0' />
                  <div className='col-12 text-start px-2'>
                    <button className="btncolor" style={{ width: "75px", padding: "0px" }} onClick={() => this.handlePrevious()}>Back</button>
                  </div>
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>WorkLevel</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      {workLevelName}
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Job Role</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      {jobRoleName}
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <div className='col-12'>
                    <div class="">
                      <div class="">
                        <ul class="nav  nav-tabs row  align-items-center">
                          {themeList &&
                            themeList.map((themes, index) => (
                              <li
                                style={{ listStyle: "none" }}
                                class={
                                  themeSelected === themes.themeid ? `nav-items col-lg-4 col-md-4 col-sm-6 col-12 px-2` : `col-lg-4 col-md-4 col-sm-6 col-12  px-2`
                                }
                              >
                                <a
                                  href={`#${themes.themeid}`}
                                  onClick={() => this.changeTheme(themes.themeid)}
                                  data-toggle="tab"
                                  class={
                                    themeSelected === themes.themeid ? ` nav-link active px-2` : `px-2`
                                  }
                                >
                                  {themes.theme}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="panel-body">
                        <div className="tab-content">{fullPanelList}</div>
                        <br />
                        {indicatorArrayLevel1 && (
                          <div>
                            <div className="panel panel-default">
                              <div className="panel-heading">
                                <div class="row justify-content-between align-items-center">
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">Level-1</div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">Level-2</div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">Level-3</div>
                                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">Level-4</div>
                                </div>
                              </div>
                              <div className="panel-body">
                                <div class="row form-group ">
                                  <div className="col-12">
                                    {indicatorArrayLevel1}
                                  </div>
                                  <div className="col-12">
                                    {indicatorArrayLevel2}
                                  </div>
                                  <div className="col-12">
                                    {indicatorArrayLevel3}
                                  </div>
                                  <div className="col-12">
                                    {indicatorArrayLevel4}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          type="button"
                          className="submit-btn"
                          onClick={this.onSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CompFormTwo;
