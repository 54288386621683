import React, { Component } from "react";
import queryString from "query-string";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import { captialize } from "../../middleWare/Utilities";
class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      adminId: null,
      userId: null,
      indicatorList: [],
      userDetails: [],
      finalStep: null,
      submitData: [],
      comment1Res: [],
      comment2Res: [],
      indicatorChekced: [],
      indicatorListCurrentPage: [],
      alreadyAssessment: false,
      userTypeId: localStorage.getItem("userTypeId"),
      jobRoleId: localStorage.getItem("jobRoleId"),
    };
  }

  async componentDidMount() {
    const parseUrl = queryString.parse(this.props.location.search);
    let adminId = localStorage.getItem("adminId");
    let groupcompId = localStorage.getItem("groupcompId");
    let userId = parseUrl.empid;
    let jobRoleId = localStorage.getItem("jobRoleId");
    localStorage.setItem("mappedjobid", parseUrl.mappedjobid);
    console.log(parseUrl, "parseurlcomp");

    this.alreadyAssessment(
      parseUrl.empid,
      parseUrl.rateType,
      parseUrl.yearid,
      parseUrl.adminid,
      parseUrl.mappedjobid
    );
    const { data: userDetails } = await HrMiddleWare.getUserDetailsyearbased(
      userId,
      parseUrl.mappedjobid
    );
    let comment1Res = [];
    let comment2Res = [];
    if (userDetails[0].userFullIndicatorList) {
      userDetails[0].userFullIndicatorList = userDetails[0].userFullIndicatorList.filter(
        (ival, i) => {
          if (ival.indicators && ival.indicators.length) {
            return ival;
          }
        }
      );
      userDetails[0].userFullIndicatorList.map((ival, i) => {
        comment1Res.push("");
        comment2Res.push("");
      });
    }
    if (userDetails) {
      this.setState({
        adminId,
        userId,
        groupcompId,
        userDetails: userDetails,
        indicatorList: userDetails[0].userFullIndicatorList,
        comment1Res,
        comment2Res,
        finalStep: userDetails[0].userFullIndicatorList
          ? userDetails[0].userFullIndicatorList.length
          : 0,
      });
    }
  }

  alreadyAssessment = async (empid, rateType, yearid, adminid, jobroleid) => {
    try {
      const { data: alreadyAssessment } = await HrMiddleWare.alreadyAssessment(
        empid,
        rateType,
        yearid,
        adminid,
        jobroleid
      );
      if (alreadyAssessment.length > 0) {
        this.setState({
          alreadyAssessment: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleRadioButtonChange = (event, themeId, competencyId, textType) => {
    const { name, value } = event.target;
    const { submitData, currentStep } = this.state;
    var type = null;
    if (textType === "Behavioral") {
      type = 1;
    } else if (textType === "Technical") {
      type = 2;
    }
    const filterData = submitData.filter(
      (filterValue) => filterValue.indicatorId === name
    );
    if (filterData.length > 0) {
      const filterPreviousData = submitData.filter(
        (filterValue) => filterValue.indicatorId !== name
      );
      filterPreviousData.push({
        indicatorId: name,
        mark: value,
        themeId,
        competencyId,
        type,
      });
      this.setState({
        submitData: filterPreviousData,
      });
    } else {
      submitData.push({
        indicatorId: name,
        mark: value,
        themeId,
        competencyId,
        type,
      });
      this.setState({
        submitData,
      });
    }
  };

  handleComment = async (e, i, val) => {
    let { comment1Res, comment2Res } = this.state;
    if (val === "strength") {
      comment1Res[i] = e.target.value;
      this.setState({ comment1Res });
    } else if (val === "area") {
      comment2Res[i] = e.target.value;
      this.setState({ comment2Res });
    }
  };

  submitData = async () => {
    const {
      groupcompId,
      currentStep,
      indicatorList,
      submitData,
      comment2Res,
      comment1Res,
    } = this.state;
    const indicator = indicatorList[currentStep - 1].indicators;
    const parseUrl = queryString.parse(this.props.location.search);

    let l = [];
    let submitDatas = [];
    submitData.map((ival, i) => {
      if (l.indexOf(ival.competencyId) === -1) {
        l.push(ival.competencyId);
      }
      if (l.length) {
        ival.comment = comment1Res[l.length - 1];
        ival.comment2 = comment2Res[l.length - 1];
        ival.groupcompany = groupcompId;
        submitDatas.push(ival);
      } else {
        ival.comment = comment1Res[0];
        ival.comment2 = comment2Res[0];
        ival.groupcompany = groupcompId;
        submitDatas.push(ival);
      }
    });
    console.log(parseUrl, "parseurl");
    const formData = new FormData();
    formData.set("desire_rating", 5);
    formData.set("emp_id", parseUrl.empid);
    formData.set("admin_id", parseUrl.adminid);
    formData.set("indicator_id", JSON.stringify(submitDatas));
    formData.set("rateType", parseUrl.rateType);
    formData.set("financial_year_id", parseUrl.yearid);
    formData.set("jobRole", parseUrl.mappedjobid);

    try {
      console.log(formData, "formdata");
      const result = await HrMiddleWare.insertAssessmentRating(formData);
      if (result) {
        this.alreadyAssessment(
          parseUrl.empid,
          parseUrl.rateType,
          parseUrl.yearid,
          parseUrl.adminid,
          parseUrl.mappedjobid
          // parseUrl.jobroleid
        );
        this.setState({ submitData: [] });
      }
    } catch (error) {
      console.log(error);
    }
    // if (submitData.length > 0) {
    //   return submitData.map(value => {
    //     return indicator.filter(indicatorValue => {
    //       if (parseInt(indicatorValue.id) !== parseInt(value.indicatorId)) {
    //         document.querySelector(
    //           `#error_${indicatorValue.id}`
    //         ).style.display = 'block';
    //         return true;
    //       } else {
    //         document.querySelector(
    //           `#error_${indicatorValue.id}`
    //         ).style.display = 'none';
    //         return false;
    //       }
    //     });
    //   });
    // } else {
    //   const returnData = indicator.map(value => {
    //     document.querySelector(`#error_${value.id}`).style.display = 'block';
    //     return true;
    //   });
    //   if (returnData) {
    //     return true;
    //   }
    // }
  };

  _next = () => {
    let { currentStep, finalStep } = this.state;
    currentStep = currentStep >= finalStep ? finalStep : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let { currentStep } = this.state;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  previousButton() {
    let { currentStep } = this.state;
    if (currentStep !== 1) {
      return (
        <React.Fragment>
          <button
            className="btncolor"
            type="button"
            onClick={this._prev}
          >
            Previous
          </button>
        </React.Fragment>
      );
    }
    return null;
  }

  nextButton() {
    let { currentStep, finalStep } = this.state;
    if (currentStep < finalStep) {
      return (
        <React.Fragment>
          <button
            className="btncolor float-right"
            type="button"
            onClick={this._next}
          >
            Next
          </button>
        </React.Fragment>
      );
    }
    return null;
  }

  submitButton() {
    let { currentStep, finalStep } = this.state;
    if (currentStep === finalStep) {
      return (
        <React.Fragment>
          <button
            className="submit-btn float-right"
            type="button"
            onClick={this.submitData}
          >
            Save
          </button>
        </React.Fragment>
      );
    }
  }

  render() {
    const {
      indicatorList,
      userDetails,
      currentStep,
      submitData,
      indicatorChekced,
      alreadyAssessment,
      strength,
      area,
      comment1Res,
      comment2Res,
    } = this.state;
    let listView = "";
    let comment1 = [];
    let comment2 = [];
    if (indicatorList && indicatorList.length) {
      indicatorList.map((indicList, index) => {
        if (parseInt(index + 1) === currentStep) {
          comment1.push(
            <div className="row justify-content-evenly align-items-center">
              <div className="col-lg-1 d-none d-lg-none"></div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12 my-2" style={{ fontSize: "20px", fontWeight: "bold" }}>
                <span className="improve">Area of Improvement :</span>
              </div>
              <div class="col-lg-9 col-md-6 col-sm-12 col-12 my-2">
                <textarea
                  class="form-control inputss"
                  name={`step${currentStep}`}
                  id={`step${currentStep}`}
                  onChange={(e) => this.handleComment(e, index, "strength")}
                  value={comment1Res[index]}
                ></textarea>
                <div className="col-lg-1 d-none d-lg-none"></div>
              </div>
            </div>
          );
          comment2.push(
            <div className="row justify-content-evenly align-items-center">
              <div className="col-lg-1 d-none d-lg-none"></div>
              <div class="col-lg-3 col-md-6 col-sm-12 col-12 my-2" style={{ fontSize: "20px", fontWeight: "bold" }}>
                <span className="improve">Strengths :</span>
              </div>
              <div class="col-lg-9 col-md-6 col-sm-12 col-12 my-2">
                <textarea
                  class="form-control inputss"
                  name={`step${currentStep}`}
                  id={`step${currentStep}`}
                  onChange={(e) => this.handleComment(e, index, "area")}
                  value={comment2Res[index]}
                ></textarea>
              </div>
              <div className="col-lg-1 d-none d-lg-none"></div>
            </div>
          );
          listView = (
            <div>
              <div className="row" >
                <div
                  style={{
                    marginTop: "10px",
                    padding: " 8px 15px",
                    backgroundColor: "#D3D3D3",
                    color: "#000"
                  }}
                >
                  Type &nbsp;
                  <i
                    class="fa fa-chevron-right"
                    aria-hidden="true"
                  ></i>
                  &nbsp;
                  {indicList.textType}
                </div>
              </div>
              <div className="row">

                <div style={{
                  padding: " 8px 15px",

                }}>
                  Theme &nbsp;
                  <i
                    class="fa fa-chevron-right"
                    aria-hidden="true"
                    style={{ fontSize: 18 }}
                  ></i>
                  &nbsp;
                  {indicList.theme}
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    padding: " 8px 15px",
                    backgroundColor: "#D3D3D3",
                    color: "#000"

                  }}
                >
                  Competency &nbsp;
                  <i
                    class="fa fa-chevron-right"
                    aria-hidden="true"
                    style={{ fontSize: 18 }}
                  ></i>
                  &nbsp;
                  {indicList.competencyname}
                </div>
              </div>
              <br />
              {indicList.indicators.map((value) => {
                let checked1 = "false";
                let checked2 = "false";
                let checked3 = "false";
                let checked4 = "false";
                let checked5 = "false";

                submitData.map((indicIdSelect, index) => {
                  if (
                    parseInt(indicIdSelect.indicatorId) === parseInt(value.id)
                  ) {
                    checked1 = parseInt(indicIdSelect.mark) === 1 && "true";
                  }
                  if (
                    parseInt(indicIdSelect.indicatorId) === parseInt(value.id)
                  ) {
                    checked2 = parseInt(indicIdSelect.mark) === 2 && "true";
                  }
                  if (
                    parseInt(indicIdSelect.indicatorId) === parseInt(value.id)
                  ) {
                    checked3 = parseInt(indicIdSelect.mark) === 3 && "true";
                  }
                  if (
                    parseInt(indicIdSelect.indicatorId) === parseInt(value.id)
                  ) {
                    checked4 = parseInt(indicIdSelect.mark) === 4 && "true";
                  }
                  if (
                    parseInt(indicIdSelect.indicatorId) === parseInt(value.id)
                  ) {
                    checked5 = parseInt(indicIdSelect.mark) === 5 && "true";
                  }
                });
                return (
                  <React.Fragment>
                    <div style={{ marginTop: "100px" }}>
                      <div className="row justify-content-between align-items-center">
                        <div className="col-12">
                          <p className="value-indicators">{value.indicators}</p>
                        </div>
                        <div className="col-12">
                          <div className="checkboxesDiv ValidId col-12">
                            <div className="d-sm-flex d-lg-flex d-md-flex align-items-center">
                              <div className="d-flex align-items-center my-2">
                                <div className="mx-1">
                                  <label className="radio-inline">
                                    <span className="lable-text"> 1</span>
                                  </label>
                                </div>
                                <input
                                  type="radio"
                                  name={`${value.id}`}
                                  value="1"
                                  onChange={(e) =>
                                    this.handleRadioButtonChange(
                                      e,
                                      indicList.themeid,
                                      indicList.competencyid,
                                      indicList.textType
                                    )
                                  }
                                  checked={checked1 == "true" ? true : false}
                                />
                                {/*outside */}
                                <span className="outside" style={{}}>
                                  <span className="inside"></span>
                                </span>{" "}
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div className="mx-1">
                                  <label className="radio-inline">
                                    <span className="lable-text">2</span>
                                  </label>
                                </div>
                                {checked2 == "true" ? (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="2"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="2"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={false}
                                  />
                                )}
                                {/*outside */}
                                <span className="outside" style={{}}>
                                  <span className="inside" ></span>
                                </span>{" "}
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div className="mx-1">
                                  <label className="radio-inline">
                                    <span className="lable-text"> 3</span>
                                  </label>
                                </div>
                                {checked3 == "true" ? (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="3"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="3"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={false}
                                  />
                                )}
                                {/*outside */}
                                <span className="outside" >
                                  <span className="inside"></span>
                                </span>{" "}
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div className="mx-1">
                                  <label className="radio-inline">
                                    <span className="lable-text"> 4</span>
                                  </label>
                                </div>
                                {checked4 == "true" ? (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="4"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="4"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={false}
                                  />
                                )}
                                {/*outside */}
                                <span className="outside" >
                                  <span className="inside"></span>
                                </span>{" "}
                              </div>
                              <div className="d-flex align-items-center my-2">
                                <div className="mx-1">
                                  <label className="radio-inline">
                                    <span className="lable-text"> 5</span>
                                  </label>
                                </div>
                                {checked5 == "true" ? (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="5"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    name={`${value.id}`}
                                    value="5"
                                    onChange={(e) =>
                                      this.handleRadioButtonChange(
                                        e,
                                        indicList.themeid,
                                        indicList.competencyid,
                                        indicList.textType
                                      )
                                    }
                                    checked={false}
                                  />
                                )}
                                {/*outside */}
                                <span className="outside" >
                                  <span className="inside"></span>
                                </span>{" "}
                              </div>
                            </div>
                            <span
                              id={`error_${value.id}`}
                              style={{
                                display: "none",
                                marginLeft: "10px",
                                color: "red",
                              }}
                            >
                              Select any one
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        }
      });
    } else {
      listView = (
        <React.Fragment>
          <h2 className="comp-head">Competency and Indicators has not been mapped.</h2>
        </React.Fragment>
      );
    }
    let height = window.innerHeight;
    if (alreadyAssessment) {
      // if assesment is completed
      return (
        <React.Fragment>
          {userDetails.length > 0 && (
            <section id="" style={{ height: height }}>
              <section className="wrapper " id="wrapper">
                <>
                  <div
                    className=""
                    style={{ paddingTop: "1px", paddingBottom: "400px" }}
                  >
                    <div className="coin">
                      <div className="side heads">
                        <img
                          style={{ position: "fixed" }}
                          src="https://upload.wikimedia.org/wikipedia/commons/7/73/Flat_tick_icon.svg"
                        ></img>

                        <svg
                          xmlns="https://www.svgrepo.com/show/43432/tick.svg"
                          xmlSpace="preserve"
                          width="100%"
                          height="100%"
                          version="1.1"
                          shapeRendering="geometricPrecision"
                          textRendering="geometricPrecision"
                          imageRendering="optimizeQuality"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          viewBox="0 0 4091.27 4091.73"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        ></svg>
                      </div>
                      <div className="side tails">
                        <img
                          style={{ position: "fixed" }}
                          src="https://upload.wikimedia.org/wikipedia/commons/7/73/Flat_tick_icon.svg"
                        ></img>

                        <svg
                          xmlns="https://www.svgrepo.com/show/43432/tick.svg"
                          className="svg_back"
                          xmlSpace="preserve"
                          width="100%"
                          height="100%"
                          version="1.1"
                          shapeRendering="geometricPrecision"
                          textRendering="geometricPrecision"
                          imageRendering="optimizeQuality"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          viewBox="0 0 4091.27 4091.73"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        ></svg>
                      </div>
                    </div>
                    <br></br>
                    <center>
                      <h1 style={{ color: "var(--text-color)" }}>
                        Assessment of {captialize(userDetails[0].name)}
                        &nbsp; completed
                      </h1>
                      <br />
                      <button
                        class="btn logout-btn"
                        onClick={() =>
                          window.location.replace(
                            this.state.userTypeId == 6
                              ? "/hr/dashboard"
                              : "/user/dashboard"
                          )
                        }
                      >
                        Dashboard
                      </button>
                    </center>
                  </div>
                </>
              </section>
            </section>
          )}{" "}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {userDetails.length > 0 && (
            <section id="" style={{ height: height }}>
              <section className="">
                <div className="container-fluid px-1 mt-100"
                >
                  <div className="content-border1">
                    {/* <div className="col-sm-12 padding-0 bg-white"> */}
                    <div class="my-3 px-lg-5 px-1 px-md-2">
                      {alreadyAssessment ? (
                        <div className="col-12">
                          <h1 className="wiz-head" style={{ marginTop: "50px" }}>
                            Self Assessment of {captialize(userDetails[0].name)}
                            &nbsp; completedd
                          </h1>
                        </div>
                      ) : (
                        <section className="text">
                          <div className="col-12">
                            <h1 style={{ marginTop: "70px" }} className="wiz-head" >
                              Assesment For {captialize(userDetails[0].name)}{" "}
                            </h1>
                          </div>
                          {/* <p>Step {this.state.currentStep} </p> */}
                          <div className="row justify-content-between">
                            <div className="col-lg-4 col-12 col-md-6">
                              <h3>Behavioural Theme</h3>
                              {userDetails[0].userBehTheme.map(
                                (behThem, index) => (
                                  <p className="theme-text" >{`${parseInt(index) + 1}) ${behThem.theme
                                    }`}</p>
                                )
                              )}
                            </div>
                            <div className="col-lg-4 col-12 col-md-6">
                              <h3>Technical Theme</h3>
                              {userDetails[0].userTechTheme.map(
                                (techThem, index) => (
                                  <p className="theme-text" >{`${parseInt(index) + 1}) ${techThem.theme
                                    }`}</p>
                                )
                              )}
                            </div>
                            <div className="col-lg-4 col-12 col-md-6">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-6">
                                  <div className="d-flex justify-content-center align-items-center fs-5">
                                    <div className="my-2">
                                      <p><i class="bi bi-1-circle"></i></p>
                                    </div>
                                    <div className="mx-2">
                                      <p>Lowest</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="d-flex justify-content-center align-items-center fs-5">
                                    <div className="my-2">
                                      <p><i class="bi bi-5-circle"></i></p>
                                    </div>
                                    <div className="mx-2">
                                      <p>Highest</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>{listView}</div>
                          <br />
                          <br />
                          <div>
                            <div className="row">{comment1}</div>
                          </div>
                          <br />
                          <br />
                          <div className="row">{comment2}</div>
                          <br />
                          {this.previousButton()}
                          {/* {indicatorList.indicators &&  */}
                          {this.nextButton()}
                          {this.submitButton()}
                          <br /> <br /> <br /> <br />
                        </section>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </section>
          )}
        </React.Fragment>
      );
    }
  }

}

export default WizardForm;
