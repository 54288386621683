import React, { Component } from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import image from "../../image/18-128.png";
import LoginModal from "../../components/Modal/Modal";
import PreLoader from './preloader'
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompetencyPDF from "../PDF/CompetencyPDF";
import queryString from "query-string";
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import "./style.css";
import { CSVLink } from "react-csv";
import CompetencyReport from "../PDF/CompetencyReport";
import SugeestImg from "./VidhyaOnlinenew.png"


class ComptencyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      section2Data: [],
      elearnReport: [],
      ElearnData: [],
      userId: queryString.parse(this.props.location.search).id,
      excelHead: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "COMPETENCY NAME", key: "compname" },
        { label: "Indicators", key: "indicators" },
        { label: "PEER", key: "peer" },
        { label: "REPORTEE", key: "reportee" },
        { label: "SELF", key: "self" },
        { label: "SUPERVISOR", key: "supervisor" }
      ],
    };
  }
  async componentWillMount() {
    let userId = null;
    let JobId = localStorage.getItem("jobRoleId")
    let yearid = localStorage.getItem("yearId")
    // let adminId = localStorage.getItem("adminId")
    if (this.state.userId) {
      userId = this.state.userId;
    } else {
      userId = await localStorage.getItem("userId");
    }
    let adminId = await localStorage.getItem("adminId");
    try {
      let section2Data = await HrMiddleWare.section2(userId);
      let userData = await HrMiddleWare.getUserDetailsyearbased(userId, JobId);
      let section1Data = await HrMiddleWare.section1(userId);

      if (section1Data) {
        section1Data.data.map(async (ival) => {
          let GetThemeid = await HrMiddleWare.getRandomData(
            "tbl_cm_theme_worklevel_competency",
            `competencyid = ${ival.cmpid} and jobRoleId=${userData.data[0].jobRole} and worklevelid =${userData.data[0].worklevel}`,
            "*"
          );
          if (GetThemeid.data) {
            GetThemeid.data.map(async (kval) => {
              let GetThemeName = await HrMiddleWare.getRandomData(
                "tbl_theme",
                `id= ${kval.themeid}`,
                "*"
              );
              if (GetThemeName.data) {
                GetThemeName.data.map((jval) => {
                  if (ival.cmpid == kval.competencyid && kval.themeid === jval.id) {
                    ival.themeName = jval.theme
                  }
                })
              }
            })

          }

        })
      }
      // console.log(getThemeId, "getThemeId");
      let compList = [
        ...userData.data[0].userBehComp,
        ...userData.data[0].userTechComp
      ];

      let userDetails = await HrMiddleWare.getRandomData(
        "tbl_users",
        `id = ${userId}`,
        "*"
      );

      let jobRole = await HrMiddleWare.getRandomData(
        "tbl_jobRole",
        `id = ${userData.data[0].jobRole} and customerid = ${adminId} and adminId = ${adminId}`,
        "*"
      );
      let jobRoleName = jobRole.data[0].jobRoleName;



      let MappedDerail = await HrMiddleWare.getRandomData(
        "Tblderailers_map,tbl_derailers",
        `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  
        Tblderailers_map.designationid = ${userDetails.data[0].designation} and
         Tblderailers_map.customerId = ${adminId} and 
         tbl_derailers.id=Tblderailers_map.derailersid`,
        "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
      );
      // console.log(MappedDerail.data)
      ///console.log(MappedDerail.data)
      let behaviourderailers = [];
      let technicalderailers = [];
      let Behavioralpdf = [];
      let Technicalpdf = [];
      MappedDerail.data.map((ival, v) => {
        if (ival.userId) {
          // console.log(userId)
          // console.log(ival.userId.includes(userId))
          if (ival.userId.includes(userId) == true) {
            // console.log(ival.textType);
            if (ival.textType == "Technical") {
              // console.log(ival)
              technicalderailers.push(<div>
                <p>{ival.derailers}</p>
              </div>)
              Technicalpdf.push(ival.derailers)
            }
            else if (ival.textType == "Behavioral") {
              // console.log(ival)
              behaviourderailers.push(<div>
                <p>{ival.derailers}</p>
              </div>)
              Behavioralpdf.push(ival.derailers)
            }


          }
        }
      })
      // console.log(userDetails.data[0].jobRole);
      let jobdescription = await HrMiddleWare.getRandomData(
        "tbl_jobdescription",
        `jobRole = ${userDetails.data[0].jobRole} and
   department = ${userDetails.data[0].department} and
   customerid = ${adminId} and status ='active'`,
        "*"
      );

      if (jobdescription.data.length) {
        this.setState({ jobdescription: jobdescription.data[0].jobdescription })
      }
      else {
        this.setState({ jobdescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque vitae felis in malesuada. Nulla viverra odio vitae condimentum iaculis. Sed at egestas erat, eu maximus nibh. Pellentesque non lobortis augue, in tincidunt enim. Sed imperdiet, justo non maximus feugiat, arcu elit vestibulum purus, vel semper turpis sem a velit. Quisque vitae lobortis erat. Quisque in quam eu dolor sollicitudin viverra. Nulla sit amet felis vitae neque mollis tempus. Mauris quis velit nec diam finibus porttitor. Suspendisse luctus interdum maximus. Sed rutrum imperdiet feugiat. Pellentesque dictum elit sed quam posuere bibendum. Curabitur porta volutpat sapien, sit amet vulputate lacus gravida ac.' })
      }

      await this.setState({ behaviourderailers, technicalderailers, Behavioralpdf, Technicalpdf })
      await this.setState({ userData: userData.data[0] });
      await this.setState({
        section1Data: section1Data.data,
        compList,
        section2Data: section2Data.data,
        jobRoleName,
        userDetails: userDetails.data[0]
      });
      // console.log(yearid, "yearid", userId, "userId", section1Data, "section1Data");
      let { data } = await HrMiddleWare.gettrainingprgmmap(yearid, adminId, section1Data)
      const vdhyaProgramresult = await HrMiddleWare.getFreedom(
        "*",
        "tbl_mapping_page",
        `categoryId= 310 and customerid=807`,
        1, 1);
      let filtereddata
      await Promise.all(data.map((ival) => {
        filtereddata = vdhyaProgramresult.data.filter(item => ival.vidhyaProgramId.some(item2 => item.pageId == item2))
        console.log("filtereddata", filtereddata);
        if (filtereddata) {
          filtereddata.map((jval) => {
            let label = jval.subCategoryName
            let pageId = jval.pageId
            let type = "Elearn"
            ival.trainingprograms.push({ label, pageId, type })

          })
        }

      }))

      await this.setState({ ElearnData: data })
      // await this.getContentElearn();

    } catch (error) {
      console.error(error);
    }
  }
  column = [
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Type </span>,
      accessor: "textType",
      // Cell: d => this.type(d)
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Theme </span>,
      accessor: "themeName",
      // Cell: d => this.theme(d)
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Competency </span>,
      accessor: "comp"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> SE </span>,
      accessor: "self"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> SP </span>,
      accessor: "supervisor"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> RE </span>,
      accessor: "reportee"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> PR </span>,
      accessor: "peer"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> CS </span>,
      accessor: "customer"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Avg </span>,
      accessor: "avg"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> View Comments </span>,
      accessor: "cmpid",
      Cell: d => this.view(d, "comments")
    }
  ];
  ElearnColumn = [
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Program Name </span>,
      accessor: "name",
      width: 300,
      // Cell: d => this.type(d)
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Program Status </span>,
      accessor: "quiz",
      className: "text-center"

      // Cell: d => this.theme(d)
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Program Score </span>,
      accessor: "totalPercentage",
      className: "text-center"

    },

  ];

  view = (d, modalWindowId) => {
    return (
      <LoginModal
        buttonTitle="View"
        title="Over All Strength & Area of Improvement"
        id="Comments"
        extraClass=" btncolor w-100"
        onClick={() => this.commentCon(d)}
        extraStyle={{ backgroundColor: "#56528F", width: "100%" }}
        maxwidth="modal-lg"
        bodyText={this.state.arr}
      />
    );
  };
  commentCon = async d => {
    let arr = [];
    let arr2 = []
    d = d.original;
    console.log(d, "ddd");
    if (d.seComment && d.seComment.length) {
      d.seComment.map((ival, i) => {
        arr2.push({
          commentedby: ival.commentedby,
          area: ival.area,
          strength: ival.strength
        })
      });
    }
    if (d.csComment && d.csComment.length) {
      d.csComment.map((ival, i) => {
        arr2.push({
          commentedby: ival.commentedby,
          area: ival.area,
          strength: ival.strength
        })
      });
    }
    if (d.prComment && d.prComment.length) {
      d.prComment.map((ival, i) => {
        arr2.push({
          commentedby: ival.commentedby,
          area: ival.area,
          strength: ival.strength
        })
      });
    }
    if (d.reComment && d.reComment.length) {
      d.reComment.map((ival, i) => {
        arr2.push({
          commentedby: ival.commentedby,
          area: ival.area,
          strength: ival.strength
        })
      });
    }
    if (d.spComment && d.spComment.length) {
      d.spComment.map((ival, i) => {
        arr2.push({
          commentedby: ival.commentedby,
          area: ival.area,
          strength: ival.strength
        })
      });
    }
    this.iMcon(arr2)
    console.log(arr2, "arrr -after ");
    arr.push(this.iMcon(arr2))
    await this.setState({ arr });
  };
  iMcon = (array) => {
    return (
      <div>
        <div className="w-100">
          <div className="col-12">
            <div className="reportTable">
              <table style={{ width: "100%" }} className="report-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Strength</th>
                    <th>Area of Improvement</th>
                  </tr>
                </thead>

                <tbody>
                  {array.map((ival, index) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{ival.commentedby}</td>
                          <td>{ival.area}</td>
                          <td>{ival.strength}</td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  type = d => {
    let { compList } = this.state;
    // console.log(compList, "compList");
    // console.log(d, "d");
    let ar = "-";
    compList.filter((ival, i) => {
      if (d.original.cmpid == ival.competencyid) {
        ar = ival.textType;
      }
    });
    return ar;
  };
  theme = d => {
    let { compList } = this.state;
    // console.log(compList, "compList");
    let ar = "-";
    compList.filter((ival, i) => {
      if (d.original.cmpid == ival.competencyid) {
        ar = ival.theme;
      }
    });
    return ar;
  };
  column1 = [
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Theme </span>,
      accessor: "theme",
      width: 170
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Competency </span>,
      accessor: "compname",
      Cell: d => this.fileView(d.original),
      width: 270

    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Indicators </span>,
      accessor: "indicators",
      width: 250
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Self </span>,
      accessor: "self"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Customer </span>,
      accessor: "customer"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Peer </span>,
      accessor: "peer"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Reportee </span>,
      accessor: "reportee"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Supervisor </span>,
      accessor: "supervisor"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Avg </span>,
      accessor: "avg"
    }
  ];
  columnElearn = [
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Type </span>,
      accessor: "matrixType",
      // Cell: d => this.type(d)
    },

    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Competency </span>,
      accessor: "competencyname"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> Avg </span>,
      accessor: "avg"
    },
    {
      Header: props => <span style={{ color: "var(--text-color)" }}> View program </span>,
      accessor: "cmpid",
      Cell: d => this.viewElearn(d, "commentsElearn")
    }
  ];
  fileView = d => {
    if (d) {
      return (
        <div className="tooltip-container">
          <div className="tooltip-trigger"> {d.compname}</div>
          <div className="tooltip-content">{d.CF}-{d.Important}</div>
        </div>
      );
    }
  };
  async commentConElearn(d) {
    let { ElearnData } = this.state;
    let DATA = [d.original];
    let elearnReport = [];

    if (DATA) {
      let wait = DATA.map(async (ival) => {
        if (ival.trainingprograms) {
          let innerWait = ival.trainingprograms.map(async (jval) => {
            if (jval?.type === "Elearn") {
              await this.processElearnProgram(jval, elearnReport);
            }
          });

          await Promise.all(innerWait);
        }
      });

      await Promise.all(wait);
      console.log(elearnReport, "elearnReport");

      if (elearnReport.length) {
        this.setState({ elearnReport });
      }
    }
  }

  async processElearnProgram(jval, elearnReport) {
    let videoOverlay = null;
    const result = await HrMiddleWare.getVideoContentById(jval.pageId);
    let array = [];

    if (result?.data?.[0]?.videoOverlay) {
      videoOverlay = JSON.parse(result.data[0].videoOverlay);

      let wait1 = videoOverlay.map(async (jsonContent) => {
        if (jsonContent?.quizId) {
          await this.processQuizContent(jsonContent, jval.label, array);
        }
      });

      await Promise.all(wait1);
      this.processQuizArray(array, elearnReport);
    }
  }

  async processQuizContent(jsonContent, subCategoryName, array) {
    let userId = await localStorage.getItem("userId");
    let quizId = JSON.parse(jsonContent.quizId);

    try {
      let { data: quizAttended1 } = await HrMiddleWare.CompQuizAttended(quizId, userId);
      if (quizAttended1) {
        quizAttended1.subCategoryName = subCategoryName;
        array.push(quizAttended1);
      }
    } catch (error) {
      console.log(error);
    }
  }

  processQuizArray(array, elearnReport) {
    if (array.length > 0) {
      let attendPercentage = array.reduce((sum, kval) => sum + kval.quizPercent, 0);
      let quizLength = array.length;
      let name = array[0].subCategoryName;

      let object = {
        name,
        attendPercentage,
        quizLength,
        totalPercentage: attendPercentage / quizLength,
        quiz: this.determineQuizStatus(attendPercentage, quizLength)
      };

      elearnReport.push(object);
      console.log(object, "object");
    }
  }

  determineQuizStatus(attendPercentage, quizLength) {
    if (attendPercentage === quizLength * 100) {
      return "Completed";
    } else if (attendPercentage === 0) {
      return "UnTouched";
    } else {
      return "InCompleted";
    }
  }

  // async commentConElearn(d) {
  //   let { ElearnData } = this.state;
  //   let DATA =[d.original]

  //   let elearnReport = [];
  //   let quizReport = [];

  //   if (DATA) {
  //     let wait = DATA.map(async (ival) => {
  //       if (ival.trainingprograms) {
  //         let innerWait = ival.trainingprograms.map(async (jval) => {
  //           if (jval && jval.type == "Elearn") {
  //             let videoOverlay = null;
  //             const result = await HrMiddleWare.getVideoContentById(jval.pageId);
  //             let array = [];
  //             if (result) {
  //               if (result.data[0].videoOverlay) {
  //                 videoOverlay = JSON.parse(result.data[0].videoOverlay);
  //                 let wait1 = videoOverlay.map(async (jsonContent, index) => {
  //                   if (jsonContent) {
  //                     if (jsonContent.quizId) {
  //                       let userId = await localStorage.getItem("userId");
  //                       let quizId = JSON.parse(jsonContent.quizId);
  //                       try {
  //                         let { data: quizAttended1 } = await HrMiddleWare.CompQuizAttended(quizId, userId);
  //                         if (quizAttended1) {
  //                           quizAttended1.subCategoryName = jval.label;
  //                           array.push(quizAttended1);
  //                         }
  //                       } catch (error) {
  //                         console.log(error);
  //                       }
  //                     }
  //                   }
  //                 });
  //                 await Promise.all(wait1);
  //                 if (array.length > 0) {
  //                   let newArray = array;
  //                   let attendPercentage = 0;
  //                   let quizLength
  //                   let name
  //                   // let totalPercentage
  //                   // let quiz
  //                   console.log(newArray, "newArray");
  //                   newArray.forEach((kval) => {
  //                     // console.log(kval, "kval");
  //                     console.log(kval.quizPercent);
  //                     attendPercentage = attendPercentage + kval.quizPercent;
  //                     quizLength = newArray.length
  //                     name = kval.subCategoryName
  //                   });
  //                   let object = {}
  //                   object.name = name
  //                   object.attendPercentage = attendPercentage
  //                   object.quizLength = quizLength
  //                   object.totalPercentage = attendPercentage / quizLength
  //                   // console.log(quizLength + "00", "dddd");
  //                   if (quizLength + "00" == attendPercentage) {
  //                     object.quiz = "Completed"
  //                   } else if (attendPercentage == 0) {
  //                     object.quiz = "UnTouched"
  //                   } else {
  //                     object.quiz = "InCompleted"

  //                   }
  //                   console.log(object,"object");
  //                   // Promise.all(object)

  //                   if (object) {
  //                     elearnReport.push(object)
  //                   }


  //                 }
  //               }
  //             }
  //           }
  //         });
  //         console.log(elearnReport,"elearnReport");

  //         await Promise.all(innerWait);
  //         console.log(ival.trainingprograms, "ival.trainingprograms");
  //       }
  //     });
  //     await Promise.all(wait);
  //     console.log(elearnReport,"elearnReport");
  //     if (elearnReport && elearnReport.length) {
  //       this.setState({ elearnReport })
  //     }

  //   }

  // }

  content = section1Data => {
    let arr1 = [];
    section1Data.map((ival, l) => {
      let arr = [];
      arr.push(<td>{ival.comp}</td>);
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 6; j++) {
          if (i === 0 && j === Math.round(ival.self)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 1 && j === Math.round(ival.reportee)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 2 && j === Math.round(ival.supervisor)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 3 && j === Math.round(ival.peer)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 4 && j === Math.round(ival.customer)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 5 && j === Math.round(ival.avg)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (
            ((j === i) === 0 && isNaN(ival.self)) ||
            (j === 0 && i === 1 && isNaN(ival.reportee)) ||
            (j === 0 && i === 2 && isNaN(ival.supervisor)) ||
            (j === 0 && i === 3 && isNaN(ival.peer)) ||
            (j === 0 && i === 4 && isNaN(ival.customer))
          ) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else {
            arr.push(<td>-</td>);
          }
        }
      }
      arr1.push(<tr>{arr}</tr>);
    });
    return arr1;
  };
  loops = () => {
    let arr = [];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        arr.push(<td>{j}</td>);
      }
    }
    return arr;
  };
  viewElearn = (d, modalWindowId) => {
    console.log(d, "d viewElearn");
    let { Programs, AllPrograms, elearnReport } = this.state
    // console.log(elearnReport, "elearnReport");
    // console.log(this.ElearnColumn, "this.ElearnColumn");
    return (
      <LoginModal
        buttonTitle="View"
        title="Training programs"
        id="commentsElearn"
        extraClass=" btncolor"
        onClick={() => this.commentConElearn(d)}
        extraStyle={{ backgroundColor: "#56528F" }}
        maxwidth="modal-lg"
        // bodyText={this.state.arr}
        bodyText={
          <>
            {elearnReport &&
              <Datatable data={elearnReport} columnHeading={this.ElearnColumn} />
            }
          </>
        }
      />
    );
  };


  viewfun = () => {
    let { userDetails } = this.state;
    let year = localStorage.getItem("yearId");
    // console.log(`http://localhost/mdc/competency-reactjs/src/php/examples/competencyreportpdf.php?userid=${userDetails.id}&companyid=${userDetails.customerid}&yearid=${year}`)
    window.location.href = `http://localhost/mdc/competency-reactjs/src/php/examples/competencyreportpdf.php?userid=${userDetails.id}&companyid=${userDetails.customerid}&yearid=${year}`;
    // document.getElementsByClassName("Hidebutton")[0].style.display = "none ";
    // //document.getElementsByClassName('testclass')[0].style.display = 'none ';
    // const input = document.getElementById("main-content");
    // html2canvas(input).then(canvas => {
    //   const imgData = canvas.toDataURL("image/png");
    // });
    // html2canvas(input).then(canvas => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   var width = pdf.internal.pageSize.getWidth();
    //   var height = pdf.internal.pageSize.getHeight();
    //   pdf.addImage(imgData, "PNG", 1, 1, width, height);
    //   setTimeout(function () {
    //     //alert('Hello');
    //     document.getElementsByClassName("Hidebutton")[0].style.display =
    //       "block ";
    //     //   document.getElementsByClassName('testclass')[0].style.display = 'block ';
    //   }, 2000);

    //   pdf.save("download.pdf");
    // });
  };
  render() {
    const {
      userData,
      section1Data,
      section2Data,
      jobRoleName,
      userDetails,
      compList,
      ElearnData
    } = this.state;
    console.log(ElearnData, "elearn");

    if (!userData) {
      return (<PreLoader />)
    }
    else {
      // console.log('sdafsadfasdfasdfsdfsdf')
      let height = window.innerHeight - 50;
      return (
        <section>
          <div style={{ marginTop: "100px" }} className="container-sm-fluid px-0 px-lg-5">
            <div className="col-12 my-3">
              <div className="content-border1">
                <div className="header-text text-center">
                  <h2> Competency Report of {userData && userData.name}</h2>
                </div>
                <div className="box-tools pull-right d-none">
                  <button
                    className="btncolor"
                    data-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
                {userData && userDetails && section1Data && section2Data && (
                  <div className="col-12 text-end px-2 px-lg-5 my-3">
                    <PDFDownloadLink
                      style={{ color: "white" }}
                      document={
                        <CompetencyReport
                          section1Data={section1Data}
                          section2Data={section2Data}
                          compList={compList}
                          Behavioralpdf={this.state.Behavioralpdf}
                          Technicalpdf={this.state.Technicalpdf}
                          data={this.state.userDetails}
                          jobRoleName={this.state.jobRoleName}
                          jobdescription={this.state.jobdescription}

                        />
                      }
                      fileName={`${userDetails.name}_CompetencyReport.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                      }
                      <button type="button" className="submit-btn">
                        Download PDF
                      </button>
                    </PDFDownloadLink>
                  </div>
                )}
                <div className="col-12 text-end px-2 px-lg-5 my-3">
                  <div className="header-text text-center my-3">
                    <h3>Section 1 - Summary of Rating</h3>
                  </div>
                  {section1Data && (
                    <Datatable data={section1Data} columnHeading={this.column} />
                  )}
                  <div className="" style={{ paddingTop: 20 }}>
                    <div className="col-12" style={{ fontSize: "15px" }}>
                      <b style={{ color: "var(--red-textcolor)" }}>SE - </b> &nbsp;&nbsp;
                      <b style={{ color: "var(--text-color)" }}>Self</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b style={{ color: "var(--red-textcolor)" }}>RE - </b> &nbsp;&nbsp;
                      <b style={{ color: "var(--text-color)" }}>Reportees</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b style={{ color: "var(--red-textcolor)" }}>SP - </b> &nbsp;&nbsp;
                      <b style={{ color: "var(--text-color)" }}>Supervisior</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b style={{ color: "var(--red-textcolor)" }}>CS - </b> &nbsp;&nbsp;
                      <b style={{ color: "var(--text-color)" }}>Customer</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <b style={{ color: "var(--red-textcolor)" }}>PR - </b> &nbsp;&nbsp;
                      <b style={{ color: "var(--text-color)" }}>Peer</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-12 text-center">
                  <div className="header-text ">
                    <h3>Section 2 - Total Rating Average Report</h3>
                  </div>
                </div>
                <div className="col-12 text-end px-2 px-lg-5 my-3">
                  {section2Data && (
                    <CSVLink
                      headers={this.state.excelHead}
                      data={this.state.section2Data}
                      filename={"my-file.xls"}
                      className=" btncolor my-2"
                      target="_blank"
                    // filename={"my-file.xls"}
                    // target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  )}
                  <br />
                  <br />
                  <br />
                  {section2Data && (
                    <Datatable data={section2Data} columnHeading={this.column1} />
                  )}
                </div>
                <div className="col-12 text-center header-text ">
                  <h3>Section 3 - Mapped Derailers</h3>
                </div>
                <div className="col-12 text-end px-2 px-lg-5 my-3">

                  <ul class="nav nav-tabs ">

                    <li className="nav-item"><a className="nav-link active " data-toggle="tab" href="#Behaviour">Behaviour Derailers</a></li>
                    <li className=" nav-item"><a className="nav-link" data-toggle="tab" href="#Technical">Technical Derailers</a></li>

                  </ul>
                </div>
                <div class="tab-content">

                  <div id="Behaviour" class="px-5 tab-pane fade in active show  fs-15">
                    <br />
                    {this.state.behaviourderailers}
                  </div>
                  <div id="Technical" class="px-5 tab-pane fade in fs-15">
                    <br />
                    {this.state.technicalderailers}

                  </div>

                </div>
                <div className="col-12">
                  <div className="col-12 header-text  text-center">
                    <h3>Section 4 - Frequency Distribution Report</h3>
                  </div>
                </div>
                <div className="col-12 text-end px-2 px-lg-5 my-3" style={{
                  overflowX: window.innerWidth <= 1200 ? "scroll" : "hidden"
                }}>

                  <br />
                  <table
                    className="table table-bordered dt-responsive nowrap example"
                    cellspacing="0"
                    width="100%"
                    style={{ border: "1px solid #080808" }}
                  >
                    <thead>
                      <tr align="center">
                        <th className="table-head-color" >Competency Name</th>
                        <th className="table-head-color" colspan="6">Self </th>
                        <th className="table-head-color" colspan="6 ">Reportees</th>
                        <th className="table-head-color" colspan="6">Supervisior</th>
                        <th className="table-head-color" colspan="6">Peer</th>
                        <th className="table-head-color" colspan="6">Customer</th>
                        <th className="table-head-color" colspan="6">Average</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr align="center">
                        <td></td>
                        {this.loops()}
                      </tr>
                      {section1Data && this.content(section1Data)}
                    </tbody>
                  </table>
                  <br></br>
                </div>
                <div className="col-12">
                  <div>
                    <div class="centered header-color">
                      <div className="row">
                        <div className="col-sm-2 " />
                        <div className="col-sm-8 ">
                          <h3>Section 5 - Elearn video quiz Report</h3>
                        </div>
                        <div className="col-12 text-end px-2 px-lg-5 my-3">
                          {/* {ElearnData && (
                          <CSVLink
                            headers={this.state.excelHead}
                            data={this.state.ElearnData}
                            filename={"my-file.xls"}
                            className="btn btncolor"
                            target="_blank"
                          // filename={"my-file.xls"}
                          // target="_blank"
                          >
                            Generate Excel
                          </CSVLink>
                        )} */}
                        </div>
                      </div>
                      {/* <div className="row">
                    <div className="col-sm-10 " />
                    <div className="col-sm-1">
                      <button type="button" className="btn btncolor">
                        Print
                      </button>
                    </div>
                    <div className="col-sm-1"></div>
                  </div> */}
                    </div>
                    <br />
                    {ElearnData && (
                      <Datatable data={ElearnData} columnHeading={this.columnElearn} />
                    )}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section >
      );


    }
  }
}

export default ComptencyReport;
