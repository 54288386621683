import React, { Component } from 'react';
import Datatable from '../../components/Datatable/Datatable'
// import CmsContent from '../../MiddleWare/CmsContent';
import SingleSelect from "../../components/Form/SingleSelect";
import FormMiddleWare from '../../components/Form/FormMiddleware';
import HrMiddleWare from "../../middleWare/HrMiddleWare";

import moment from 'moment';
import { Style } from 'react-style-tag';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import LoginModal from '../../../components/Modal/Modal';
class Quiztable extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerid: JSON.parse(localStorage.getItem('Admin')),
            serviceid: 8,
            page2: 0,
            // userName: localStorage.getItem("userName"),
            // userName: JSON.parse(localStorage.getItem("userDetails")).userName,
            userId: localStorage.getItem("userId"),
            userDetails: JSON.parse(localStorage.getItem("userDetails"))
            ,
            UserList: [],
            tableValue: []
        };
    }

    async componentDidMount() {
        try {
            if (!this.state.userId) {
                console.error("User details are missing");
                return;
            }
            let QuizList = await HrMiddleWare.getFreedom(
                'tbl_question.id as value, tbl_question.quizName as label, tbl_question.contentJson',
                'tbl_question, comp_tbl_quizAnswer',
                `tbl_question.customerid=807 and comp_tbl_quizAnswer.userId=${this.state.userId} and tbl_question.id = comp_tbl_quizAnswer.quizId`,
                1,
                1
            );
            console.log(QuizList.data, "dta");

            this.setState({ QuizList: QuizList.data });
        } catch (error) {
            console.error("Error fetching quiz list:", error);
        }
    }
    async componentWillMount() {
        try {
            if (!this.state.userId) {
                console.error("User details are missing");

                return;
            }

            let QuizList = await HrMiddleWare.getFreedom(
                'tbl_question.id as value, tbl_question.quizName as label, tbl_question.contentJson',
                'tbl_question, comp_tbl_quizAnswer',
                `tbl_question.customerid=807 and comp_tbl_quizAnswer.userId=${this.state.userId} and tbl_question.id = comp_tbl_quizAnswer.quizId`,
                1,
                1
            );

            this.setState({ QuizList: QuizList.data });
        } catch (error) {
            console.error("Error fetching quiz list:", error);
        }
    }

    column = [
        {
            Header: 'Attempts',
            accessor: 'count',
            Cell: d => this.firstFunction(d.original, d.original.count)
        },
        {
            Header: 'Score',
            accessor: 'score',
            Cell: d => this.firstFunction(d.original, d.original.score)
        },
        {
            Header: 'Total Questions',
            accessor: 'total',
            Cell: d => this.firstFunction(d.original, d.original.total)
        },
        {
            Header: 'View Report',
            accessor: 'total',
            Cell: d => this.firstFunctionButton(d.original, 'View')
        },
        {
            Header: 'Certificate',
            accessor: 'certificate',
            Cell: d => this.deside(d.original)
        },

    ];
    column1 = [
        {
            Header: 'Score',
            accessor: 'score',
        },
        {
            Header: 'Total Questions',
            accessor: 'total',
        },
        {
            Header: 'Attended on',
            accessor: 'time',
            Cell: d => this.secondFunction(d.original)
        },
        {
            Header: 'View Answer',
            accessor: 'view',
            Cell: d => this.dataTableButton('submit-btn', 'View', () => {
                this.ViewOne(d.original);
            })
        },
    ];
    column2 = [
        {
            Header: 'Question',
            accessor: 'question',
            Cell: d => this.answerUI(d.original, 'Question')
        },
        {
            Header: 'Response',
            accessor: 'answer',
            Cell: d => this.answerUI(d.original, 'Response')
        },
        {
            Header: 'Correct Answer',
            accessor: 'correct',
            Cell: d => this.answerUI(d.original, 'correct')
        },
    ];
    answerUI = (d, header) => {
        if (header == 'Question') {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.question}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.question}</p>
            }
        }
        if (header == 'Response') {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.answer}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.answer}</p>
            }
        }
        if (header == 'correct') {
            // if (d.answer.includes(d.correct)) {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.correct}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.correct}</p>
            }
        }
    }
    deside = d => {
        let val = d.score * 100 / d.total;
        if (val >= 100) {
            return (this.dataTableButton('btn submit-btn', 'View Certificate', () => {
                this.ViewCertificate(d);
                // console.log("view certificate");
            }))
        } else {
            return <center>Incomplete</center>
        }
    };
    ViewCertificate = async d => {
        const userid = localStorage.getItem("userId")
        const Userdetails = await HrMiddleWare.userDetails(userid);
        const name = Userdetails.data[0].name + " " + Userdetails.data[0].last_name
        window.open(`Certificate/${btoa(this.state.selectQuiz.label)}/${btoa(name)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
    }
    regEx = (str) => {
        str = str.replace(/\s/g, '');
        return str
    }
    ViewOne = (d) => {
        try {
            console.log(d, 'd');
            let answer = JSON.parse(d.answerJson)
            let question = JSON.parse(this.state.selectQuiz.contentJson);
            let postAnswer = d.postAns ? JSON.parse(d.postAns) : null

            let finalSingleValue = [];
            let con = false;
            question.map((ival, i) => {
                answer.map((mval, ind) => {
                    if ((ival.questionId == mval.questionId) && (ival.questionId != 'undefined') && (mval.questionId != null)) {
                        console.log(ival.questionId, mval.questionId);
                        //         con = true;
                        //     }else{
                        //         con = false;
                        //     }            
                        // if(con){
                        if (ival.type === 'multiple') {
                            let ans1 = [];
                            answer[ind].answer.map((jval, j) => {
                                let ans = jval.split('option').pop();
                                ans1.push(ival.options[ans - 1]);
                            });
                            let postAns = [];
                            if (postAnswer && postAnswer[ind].answer) {
                                postAnswer[ind].answer.map((jval, j) => {
                                    let ans = jval.split('option').pop();
                                    postAns.push(ival.options[ans - 1]);
                                });
                                postAns = postAns.toString();
                            } else {
                                postAns = '-';
                            }

                            let actualAnswer = [];
                            if (ival.answer) {
                                ival.answer.split(',').map((jval, j) => {
                                    let cA = jval.split('option').pop();
                                    actualAnswer.push(ival.options[cA - 1]);
                                });
                            }

                            finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), postanswer: postAns, result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
                        } else {

                            let postAns = (postAnswer && postAnswer[ind] && postAnswer[ind].answer) ? postAnswer[ind].answer.toLowerCase() : '-';
                            finalSingleValue.push({ question: ival.question, answer: answer[ind].answer.toLowerCase(), correct: ival.answer.toLowerCase(), postanswer: postAns, result: answer[ind].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
                        }
                    }
                });
            });
            // this.resetState();
            this.setState({ finalSingleValue, page2: 2, prePost: d.prepost ? 'Pre' : 'Post', date: this.secondFunction(d) });

        } catch (error) {
            console.log(error);
        }
    }
    firstFunction = (d, value) => {
        return <p href="#" style={{ textAlign: 'center', cursor: "pointer" }} onClick={() => { this.doFunction(d) }}>{value}</p>
    }
    firstFunctionButton = (d, value) => {
        return <button class='btn btncolor' style={{ textAlign: 'center' }} onClick={() => { this.doFunction(d) }}>{value}</button>
    }
    secondFunction(date) {

        // return <p style={{ textAlign: 'center' }}>

        return moment(date.time).format('DD-MM-YYYY')
        // </p>
    }
    doFunction = async (d) => {
        let selectQuiz = this.state.selectQuiz;
        try {
            const userid = localStorage.getItem("userId")
            let selectQuiz = this.state.selectQuiz;
            let singleUser = await HrMiddleWare.getFreedom(
                'comp_tbl_quizAnswer.userName as label,comp_tbl_quizAnswer.userId as value,comp_tbl_quizAnswer.total as score,comp_tbl_quizAnswer.totalQue as total,comp_tbl_quizAnswer.createdAt as time,comp_tbl_quizAnswer.quizanswer as answerJson,comp_tbl_quizAnswer.prepost,comp_tbl_quizAnswer.postanswer as postAns,comp_tbl_quizAnswer.posttotal',
                'comp_tbl_quizAnswer,tbl_user_web',
                `comp_tbl_quizAnswer.userId = ${userid} and comp_tbl_quizAnswer.quizId=${selectQuiz.value} and comp_tbl_quizAnswer.userId=${d.value} and comp_tbl_quizAnswer.serviceid=${this.state.serviceid}`,
                'comp_tbl_quizAnswer.id',
                'comp_tbl_quizAnswer.id DESC');
            this.setState({ page2: 1, singleUser: singleUser.data });
        } catch (error) {
            console.error(error);
        }
    }
    // doFunction = async (d) => {
    //     try {
    //         let selectQuiz = this.state.selectQuiz;
    //         let singleUser = await CmsContent.getFreedom(
    //             'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
    //             'tbl_quizAnswer,tbl_user_web',
    //             `tbl_user_web.id=tbl_quizAnswer.userId  and tbl_quizAnswer.userId=${this.state.userId} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
    //             'tbl_quizAnswer.id',
    //             'tbl_quizAnswer.id DESC');
    //         this.setState({ page2: 1, singleUser: singleUser.data, selectQuiz });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    // resetState = () => this.setState({ page2: 1, singleUser: [], finalSingleValue: [], date: [] })
    // selectQuiz = async selectQuiz => {
    //     try {
    //         let UserList = await CmsContent.getFreedom(
    //             'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
    //             'tbl_quizAnswer,tbl_user_web',
    //             `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizid=${selectQuiz.value}  and tbl_quizAnswer.userId=${this.state.userId} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
    //             'tbl_quizAnswer.id',
    //             'tbl_quizAnswer.id DESC');
    //         this.resetState();
    //         console.log(UserList.data);
    //         this.setState({ selectQuiz, singleUser: UserList.data });
    //         // UserList.data.map((ival, i) => {
    //         //     if (ival.value == this.state.userId) {
    //         //         this.doFunction()
    //         //     }
    //         // })
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], date: [] })
    selectQuiz = async selectQuiz => {
        try {
            let UserList = await HrMiddleWare.getFreedom(
                'comp_tbl_quizAnswer.userName as label,comp_tbl_quizAnswer.userId as value, count(comp_tbl_quizAnswer.userId) as count,SUM(comp_tbl_quizAnswer.total) as score,comp_tbl_quizAnswer.totalQue as total,comp_tbl_quizAnswer.createdAt as time',
                'comp_tbl_quizAnswer,tbl_user_web',
                `tbl_user_web.id=comp_tbl_quizAnswer.userId and comp_tbl_quizAnswer.userId=${this.state.userId} and comp_tbl_quizAnswer.quizId=${selectQuiz.value} and comp_tbl_quizAnswer.serviceid=${this.state.serviceid}`,
                'comp_tbl_quizAnswer.userId',
                'comp_tbl_quizAnswer.id DESC');

            this.resetState();
            this.setState({ selectQuiz, tableValue: UserList.data })
            // this.setState({ selectQuiz, UserList: UserList.data });
        } catch (error) {
            console.error(error);
        }
    };


    OnBack = val => {
        this.setState({ page2: val - 1 })
    }
    render() {
        const { tableValue, UserList, QuizList, page2, singleUser, finalSingleValue, userName, prePost, date } = this.state;
        var tableData = null
        var column = null
        if (page2 === 0) {
            tableData = tableValue;
            column = this.column;
        } else if (page2 === 1) {
            tableData = singleUser;
            column = this.column1;
        } else if (page2 === 2) {
            tableData = finalSingleValue;
            column = this.column2;
        }
        return (
            <React.Fragment>
                <section className='mt-100'>
                    <div className='container container-sm-fluid'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='col-lg-12 col-md-12 col-12 text'>
                                <div className='content-border px-3'>
                                    <div className=' py-3'>
                                        <h3 className='px-3'>Quiz report</h3>
                                        <hr />
                                    </div>
                                    <div className='row justify-content-between align-items-center py-3'>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 py-1'>
                                            <label htmlFor="exampleInputEmail1">Select Course to view report</label>
                                        </div>
                                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-1'>
                                            <SingleSelect options={QuizList} handleChange={this.selectQuiz} value={this.state.box} placeholder="Select Quiz" />
                                        </div>
                                        <div className='col-lg-1 d-none d-lg-block' />
                                    </div>
                                    <div className='row justify-content-between align-items-center py-3'>
                                        <div className="col-lg-2 col-md-2 col-12 p-0" >
                                            {page2 !== 0 && this.dataTableButton('submit-btn', 'Back', () => {
                                                this.OnBack(page2);
                                            })}
                                        </div>

                                        <div className="col-lg-10 col-md-10 col-12 h2"><center>{page2 === 2 && `${this.state.selectQuiz.label}'s response on ${date}`}</center></div>
                                    </div>
                                    <div className='py-3 '>
                                        {
                                            (page2 === 0 && tableData && tableData.length) ?
                                                <div className='text-center'>
                                                    <div className='px-3'>
                                                        <h3 class="card-title">{this.state.selectQuiz.label}</h3>
                                                    </div>
                                                    <div className=''>
                                                        <div>
                                                            <h4 class="card-subtitle my-3">Attempts: {tableData[0].count}</h4>
                                                            <h4 class="card-subtitle my-3">Score: {tableData[0].score}</h4>
                                                            <h4 class="card-subtitle my-3">Total: {tableData[0].total}</h4>
                                                            <a href="#" class="card-link">{this.firstFunctionButton(tableData[0], 'View Response')}</a>
                                                            <a href="#" class="card-link ">{this.deside(tableData[0])}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        tableData && tableData.length ?
                                                            <div>
                                                                <Datatable data={tableData} columnHeading={column} />
                                                            </div>
                                                            :
                                                            <div>

                                                            </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Style>
                    {`
                `}
                </Style>
            </React.Fragment>
        );
    }
}

export default Quiztable;

