import React from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import Datatable from "../../components/Datatable/Datatable";
import LoginModal from "../../components/Modal/Modal";
import PreLoader from './preloader';
import { CSVLink } from "react-csv";
import "./style.css";
import Hradduser from "./Hradduser";
import Mappingemployee from "./Mappingemployee";
import CompetencyMatrix from "./CompetencyMatrix";
import { SingleSelect } from "../../components/Form";
// import BehaviouralCompetencies from "./BehaviouralCompetencies";
// const inputRef = React.useRef(null);

class HrWizardForm extends FormMiddleWare {

    constructor(props) {
        super(props);
        this.state = {
            Step: 1,
            addUserColumn: [

                { label: "name", value: 1, type: "text" },
                { label: "last_name", value: 2, type: "text" },
                { label: "user_name", value: 3, type: "text" },
                { label: "password", value: 4, type: "text" },
                { label: "address", value: 5, type: "text" },
                { label: "mobile_no", value: 6, type: "text" },
                { label: "telphone", value: 7, type: "text" },
                { label: "dob", value: 8, type: "date" },
                { label: "email_id", value: 9, type: "text" },
                { label: "age", value: 10, type: "text" },
                { label: "doj", value: 11, type: "date" },
                { label: "designation", value: 12, type: "selectbox" },
                { label: "qualification", value: 1, type: "text" },
                { label: "matrixType", value: 2, type: "selectbox" },
                { label: "worklevel", value: 3, type: "selectbox" },
                { label: "jobRole", value: 4, type: "selectbox" },
                { label: "userTheme", value: 5, type: "selectbox" },
                { label: "location", value: 6, type: "selectbox" },
                { label: "department", value: 7, type: "selectbox" },
                { label: "userTypeId", value: 8, type: "selectbox" },
                { label: "is_manager", value: 9, type: "selectbox" },
                { label: "is_employee", value: 10, type: "selectbox" },
                { label: "is_hr", value: 11, type: "selectbox" },

            ]
        };
    }

    componentWillMount() {
    }

    nextUpdate = (step) => {
        this.setState({ Step: step })
    }



    render() {
        const { data, Step, } = this.state;
        let height = window.innerHeight;


        return (

            <>
                <div>
                    <section id="">
                        <section className="" style={{ marginTop: "80px" }}>
                            <div className="container container-sm-fluid">

                                <div className="col-12  py-3 progress-stephr " >
                                   
                                    <div className={`steps ${Step >= 1 ? "actives" : ""} ${Step - 1 >= 1 ? "Arrows" : ""}`} onClick={() => this.setState({ Step: 1 })}>Step 1</div>
                                    <div className={`steps ${Step >= 2 ? "actives" : ""} ${Step - 1 >= 2 ? "Arrows" : ""}`} onClick={() => this.setState({ Step: 2 })}>Step 2</div>
                                    <div className={`steps ${Step >= 3 ? "actives" : ""} ${Step - 1 >= 3 ? "Arrows" : ""}`} onClick={() => this.setState({ Step: 3 })} >Step 3</div>

                                </div>
                            </div>
                        </section>
                    </section>
                    <div className="" >

                        {
                            Step == 1 && <>

                                <Hradduser wizardBtns={true} StepFun={this.nextUpdate} />
                            </>

                        }
                        {
                            Step == 2 && <>
                                <Mappingemployee wizardBtns={true} StepFun={this.nextUpdate} />
                            </>

                        }
                        {
                            Step == 3 && <>
                                <CompetencyMatrix wizardBtns={true} StepFun={this.nextUpdate} />
                            </>

                        }

                    </div>
                </div>

                {/* <div>
                    <div className='wizard-gropubtn'>
                        {Step > 1 &&
                            <button className="btn btn-primary" style={{ width: "100px", padding: "10px" }} onClick={() => this.setState({ Step: Step - 1 })}>Previous</button>
                        }
                        {Step > 2 &&
                            <button className="btn btn-primary" style={{ width: "100px", padding: "10px" }} onClick={() => window.location.replace("dashboard")}>Finish</button>
                        }
                        {Step <= 2 &&
                            <button className="btn btn-primary" style={{ width: "100px", padding: "10px" }} onClick={() => this.setState({ Step: Step + 1 })}>Next</button>
                        }

                    </div>
                </div> */}

            </>
        )


    }
}

export default HrWizardForm;
