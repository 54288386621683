import React from 'react';
import Select from 'react-select';
import './Style.css';
import PropTypes from 'prop-types';

const SingleSelect = ({ errorServices, handleChange, selectedService,disabled, options, width, ...res }) => {
  const customStyle = {
    placeholder: (provided) => ({
      ...provided,
      opacity: 0.7,
      fontSize: "15px",
      color: "var(--text-color)",
    }),
    control: (provided) => ({
      ...provided,
      padding: "5px 3px",
      borderRadius: "4px",
      background: "transparent",
      border: "1px solid var(--input-border-color)",
      color: "var(--text-color)"

    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      border: "1px solid #cccccc",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#333333",
      '&:hover': {
        backgroundColor: "#e0e0e0",
        color: "#000000",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--text-color)",
    })


  }
  return (
    <React.Fragment>
      <Select 
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      className={width} clearValue value={selectedService} styles={customStyle} onChange={handleChange} options={options} {...res} isDisabled={disabled} placeholder="Select"/>
    </React.Fragment>
  );
};

SingleSelect.propTypes = {
  options: PropTypes.array,
  handleChange: PropTypes.func,
  selectedService: PropTypes.object
};

export default SingleSelect;
