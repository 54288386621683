import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ startDate, changeDate, disabled = false }) => {
  const customStyle ={
    placeholder:(provided)=>({
      ...provided,
      opacity:0.5,
      fontSize:"15px",
      color:"var(--text-color)",
      background:"transparent"
    }),
    control:(provided)=>({
      ...provided,
      padding:"3px 0px",
      borderRadius:"4px",
      background:"transparent",
      border:"1px solid var(--input-border-color)",
      color:"var(--text-color)",
      "&focus":{
        background:"transparent"
      }

    })
  }
  return (
    <ReactDatePicker
      showYearDropdown
      scrollableYearDropdown
      showMonthDropdown
      useShortMonthInDropdown
      dropdownMode="select"
      styles={customStyle}
      selected={startDate}
      onSelect={changeDate}
      onChange={changeDate}
      disabled={disabled}
      timeIntervals={1}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/MM/yyyy" 
      className="date-picker-input date-pick fs-6 py-2 px-2"
    />
  );
};

export default DatePicker;
