import React, { Component } from "react";
import queryString from "query-string";
import LoginModal from "../../components/Modal/Modal";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import BehaviouralCompetencies from "./BehaviouralCompetencies";
import BehaviouralCompetencies from "./BehaviouralCompetencies";
import TechnicalCompetencies from "./TechnicalCompetencies";
import ProfileCompReport from "./ProfileCompReport";
import ProfileChart from "./ProfileChart";
import Datatable from "../../components/Datatable/Datatable";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import ProfileCompMap from "./ProfileCompMap";
import PreLoader from './preloader'
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import ConsolidatePDF from "../PDF/ConsolidatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { ACCESS_POINT } from "../../config";


class ViewProfile extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: queryString.parse(this.props.location.search).id,
      techBehType: [
        { label: "Technical", value: 2 },
        { label: "Behavioral", value: 1 }
      ],
      des: [],
      file: [],
      yearSelect: [],
      typeSelect: [],
      showTable: false,
      // showTableBeh : false,
      // showTableTech : false,
      pdfyearId: localStorage.getItem("yearId"),
      btncolorchgs: 'danger'
    };
  }

  async componentWillMount() {
    const userId = this.state.userId;
    const adminId = await localStorage.getItem("adminId");
    console.log(adminId);
    const userData = await HrMiddleWare.getUserDetails(userId);
    console.log(userData.data)
    this.setState({ uservalue: userData.data })
    let userDetails = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    console.log(userDetails.data[0],"details1");
    let work = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `id = ${userDetails.data[0].worklevel}`,
      "*"
    );
    let jobRole = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `id = ${userDetails.data[0].jobRole} `,
      "*"
    );
    let designation = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `id = ${userDetails.data[0].designation} `,
      "*"
    );
    await this.setState({ designationid: userDetails.data[0].designation })
    let department = await HrMiddleWare.getRandomData(
      "tbl_department",
      `id = ${userDetails.data[0].department}`,
      "*"
    );
    let Theme = await HrMiddleWare.getRandomData(
      "tbl_theme",
      `customerid = ${userDetails.data[0].adminId} and adminId = ${userDetails.data[0].adminId}`,
      // `id = ${userDetails.data[0].userTheme}`,
      "*"
    );
    let descrip = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `'function' = ${userId}`,
      "*"
    );
    console.log(descrip);
    let workLevel = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `customerid = ${adminId} and adminId = ${adminId}`,
      "worklevel as label , id as value"
    );

    let comp = await HrMiddleWare.getRandomData(
      "tbl_competency",
      `customerid = ${adminId} and adminId = ${adminId}`,
      "*"
    );
    let deptList = await HrMiddleWare.getRandomData(
      "tbl_department",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "department as label , id as value"
    );
    let derailers = await HrMiddleWare.getRandomData(
      "tbl_derailers",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "derailers as label , id as value"
    );

    // let MappedDerail = await HrMiddleWare.getRandomData(
    //   "Tblderailers_map,tbl_derailers",
    //   `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and Tblderailers_map.jobroleid = ${userData.data[0].jobRole} and  Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
    //   "*"
    // );

    let MappedDerail = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  designationid = ${userDetails.data[0].designation} and Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );

    console.log(MappedDerail)
    let mapDerail = "";
    if (MappedDerail.data) {
      MappedDerail.data.map((item, i) => {
        console.log(item);
        if (item.userId != null) {
          console.log(item.userId.includes(this.state.userId))

          if (item.userId.includes(this.state.userId) == true) {
            mapDerail += item.derailers + ", ";
          }
        }



      });
      mapDerail = mapDerail.replace(/,\s*$/, "");
    }
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      // this.setState({ yearList: yearList.data });
    }
    const jobList = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "jobRoleName as label , id as value"
    );

    let L1 = "";
    if (userData.data) {
      let themeID = userDetails.data[0].userTheme.toString();
      let split = themeID.split(",");
      let themeLabel = [];
      split.map((item, i) => {
        let json = Theme.data.filter((element, key) => {
          return element.id == item;
        });
        themeLabel.push({ label: json[0].theme });
        L1 += themeLabel[i].label + ", ";
      });
      L1 = L1.replace(/,\s*$/, "");
    }
    let L2 = "";
    if (userData.data[0].userFullIndicatorList) {
      userData.data[0].userFullIndicatorList.map((item, i) => {
        L2 += item.competencyname + ", ";
      });
      L2 = L2.replace(/,\s*$/, "");
    }

    let jobdescription = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `jobRole = ${userDetails.data[0].jobRole} and
       department = ${userDetails.data[0].department} and
       customerid = ${adminId} and status ='active'`,
      "*"
    );
    // console.log(jobdescription);
    let pdfIdp = await HrMiddleWare.getRandomData(
      "idp_goals",
      `createdById=${userDetails.data[0].id} and yearId=${this.state.pdfyearId} and customerid=${adminId}`,
      "*"
    );

    if (jobdescription.data.length) {
      this.setState({ jobdescription: jobdescription.data[0].jobdescription })
    }
    else {
      this.setState({ jobdescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque vitae felis in malesuada. Nulla viverra odio vitae condimentum iaculis. Sed at egestas erat, eu maximus nibh. Pellentesque non lobortis augue, in tincidunt enim. Sed imperdiet, justo non maximus feugiat, arcu elit vestibulum purus, vel semper turpis sem a velit. Quisque vitae lobortis erat. Quisque in quam eu dolor sollicitudin viverra. Nulla sit amet felis vitae neque mollis tempus. Mauris quis velit nec diam finibus porttitor. Suspendisse luctus interdum maximus. Sed rutrum imperdiet feugiat. Pellentesque dictum elit sed quam posuere bibendum. Curabitur porta volutpat sapien, sit amet vulputate lacus gravida ac.' })
    }

    let mapped_derailers = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  designationid = ${userDetails.data[0].designation} and Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );

    this.setState({ mapped_derailers: mapped_derailers.data })
    console.log(mapped_derailers)



    await this.setState({
      userDetails: userDetails.data[0],
      work: work.data[0].worklevel,
      jobRole: jobRole.data[0].jobRoleName,
      jobRoleid: jobRole.data[0].id,

      designation: designation.data[0].designation,
      department: department.data[0].department,
      L1,
      L2,
      userData: userData.data[0],
      userId,
      adminId,
      descrip: descrip.data,
      comp: comp.data,
      derailers: derailers.data,
      yearList: yearList.data,
      workLevel: workLevel.data,
      jobList: jobList.data,
      deptList: deptList.data,
      mapDerail,
      pdfIdp: pdfIdp.data
    });
  }

  column = [
    {
      id: "row",
      Header: "SI.No",
      accessor: "id",
      maxWidth: "100",
      Cell: row => {
        return <div>{row.index + 1}</div>;
      }
    },
    {
      Header: "Job Description",
      accessor: "jobdescription"
    },
    {
      Header: "Description",
      accessor: "describtion"
    },
    {
      Header: "File",
      accessor: "fileupload",
      Cell: d => this.fileView(d.original.fileupload)
    }
  ];


  de_mapped_column = [
    {
      Header: "derailers",
      accessor: "derailers"
    },
    {
      Header: "status",
      accessor: "description",
      Cell: d => this.returnvalue(d.original)
    }

  ];


  returnvalue = e => {
    //console.log(e.userId.includes(this.state.userId)) 
    console.log(this.state.userId)

    let check = '';
    if (e.userId) {

      check = e.userId.includes(this.state.userId)


    }


    return (<button className={check == true ? 'btn btn-danger' : 'btn btn-success'} onClick={() => this.selectedfunction(e, check == true ? 'DeSelected' : 'Selected')}> {check == true ? 'Selected' : 'Select'}   </button>)

  }


  selectedfunction = async (e, selected) => {
    let user = e.userId.includes(this.state.userId)

    if (user == false) {
      var array = JSON.parse(e.userId);
      array.push(this.state.userId)
      e.userId = array;
    }
    else {
      var array = JSON.parse(e.userId);

      var filteredAry = array.filter(e => e !== this.state.userId)
      e.userId = filteredAry;

    }


    var formData = new FormData();
    formData.append("userid", JSON.stringify(e.userId));

    var updateMaster = await MasterMiddleWare.updateMastervalue(
      "Tblderailers_map",
      e.id,
      formData
    );

    if (updateMaster) {

      console.log(`Tblderailers_map.worklevelid = ${this.state.userData.worklevel} and  designationid = ${this.state.designationid} and Tblderailers_map.customerId = ${this.state.adminId} and tbl_derailers.id=Tblderailers_map.derailersid`)

      let mapped_derailers = await HrMiddleWare.getRandomData(
        "Tblderailers_map,tbl_derailers",
        `Tblderailers_map.worklevelid = ${this.state.userData.worklevel} and  designationid = ${this.state.designationid} and Tblderailers_map.customerId = ${this.state.adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
        "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
      );

      await this.setState({ mapped_derailers: mapped_derailers.data })
      console.log(this.state.mapped_derailers)


    }






  }

  fileView = d => {
    if (d) {
      return (
        <div>
          <center>
            <button class="btn logout-btn" onClick={() => window.open(ACCESS_POINT +
              "/getpro/getprofile?fileurl=" + `${d}`, '_blank')}> view </button>
          </center>
        </div>
      );
    }
  };

  jobText = e => {
    this.setState({ jobText: e.target.value });
  };
  descriptionText = e => {
    this.setState({ descriptionText: e.target.value });
  };
  onSubmit = async () => {
    const { filename, jobText, descriptionText, adminId, userId, jobRoleid } = this.state;
    const formData = new FormData();
    formData.append("describtion", descriptionText);
    formData.append("jobdescription", jobText);
    formData.append("function", userId);
    formData.append("jobRole", jobRoleid);
    formData.append("fileupload", filename);
    formData.append("customerid", adminId);
    formData.append("status", "active");
    try {
      const result = await MasterMiddleWare.createMaster(
        "tbl_jobdescription",
        formData
      );
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "Job Description Added ",
          showConfirmButton: false,
          timer: 1500
        });
        // let valueArray = {};
        // valueArray.id = result.data.insertId;
        // valueArray.jobdescription = jobText.trim();
        // valueArray.describtion = descriptionText.trim();
        // const newData = [valueArray, ...this.state.descrip];
        let descrip = await HrMiddleWare.getRandomData(
          "tbl_jobdescription",
          `function = ${userId}`,
          "*"
        );
        let newData = descrip.data.sort((a, b) => b.id - a.id);
        await this.setState({
          descrip: newData,
          descriptionText: "",
          jobText: "",
          filename: []
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  typeSelect = e => {
    this.setState({ typeSelect: e });

    let comp = this.state.comp;

    let compOption = [];
    comp.map((item, i) => {
      if (e.label == item.textType) {
        compOption.push({ label: item.competencyname, value: item.id });
      }
    });
    this.setState({ compOption });
  };
  compSelect = e => {
    this.setState({ compSelect: e });
  };
  derailer = e => {
    this.setState({ derailerSelected: e });
  };
  derailersText = e => {
    this.setState({ derailersText: e.target.value });
  };
  desText = e => {
    this.setState({ desText: e.target.value });
  };

  mapDerailers = async () => {
    const { typeSelect, compSelect, derailerSelected, adminId } = this.state;
    const formData = new FormData();
    formData.append("themeid", compSelect.value);
    formData.append("customerId", adminId);
    formData.append("derailersid", derailerSelected.value);
    try {
      const result = await HrMiddleWare.addMaster("Tblderailers_map", formData);
      if (result) {
        this.setState({
          typeSelect: "",
          compSelect: "",
          derailerSelected: ""
        });
        Swal.fire({
          position: "center",
          type: "success",
          title: "Derailers Mapped",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addDerailers = async () => {
    const {
      typeSelect,
      compSelect,
      derailerSelected,
      adminId,
      derailersText,
      desText
    } = this.state;
    const formData = new FormData();
    formData.append("derailers", derailersText);
    formData.append("description", desText);
    formData.append("status", "active");
    formData.append("customerId", adminId);
    formData.append("adminId", adminId);
    formData.append("createdAt", 1);
    try {
      const result = await HrMiddleWare.addMaster("tbl_derailers", formData);
      if (result) {
        this.setState({
          typeSelect: "",
          compSelect: "",
          derailerSelected: "",
          derailersText: "",
          desText: ""
        });
        Swal.fire({
          position: "center",
          type: "success",
          title: "Derailers Added ",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  wrklvl = async e => {
    let typeSelect = this.state.typeSelect;
    const {
      data: functionOptions
    } = await MappingMiddleware.getMapDepartmentToFunctionData(
      this.state.adminId,
      typeSelect.value
    );
    this.setState({ wrkSelected: e, functionOptions });
  };

  functionSelect = async selectedOption => {
    const { adminId, departmentId, workLevelOptionsBefore } = this.state;
    const functionId = selectedOption.value;
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
    try {
      const {
        data: workLevelOptions
      } = await MappingMiddleware.getMapDepartmentToWorklevelData(
        adminId,
        departmentId,
        functionId
      );

      if (workLevelOptions) {
        this.setState({ workLevelOptions });
      }
    } catch (error) {
      console.error(error);
    }
  };

  departmentSelect = async selectedOption => {
    const { adminId } = this.state;
    const departmentId = selectedOption.value;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    try {
      const {
        data: functionOptions
      } = await MappingMiddleware.getMapDepartmentToFunctionData(
        adminId,
        departmentId
      );
      if (functionOptions) {
        this.setState({
          functionOptions,
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  jobSelect = e => {
    const {
      wrkSelected,
      yearSelect,
      typeSelect,
      financialType,
      departmentLabelName,
      functionLabelName
    } = this.state;
    if (e) {
      let type = typeSelect.label === "Behavioral" ? 1 : 2;
      localStorage.setItem("jobRoleId", e.value);
      localStorage.setItem("jobRoleName", e.label);
      localStorage.setItem("workLevelId", wrkSelected.value);
      localStorage.setItem("workLevelName", wrkSelected.label);
      localStorage.setItem("financialYearId", yearSelect.value);
      localStorage.setItem("departmentName", departmentLabelName);
      localStorage.setItem("functionName", functionLabelName);
      localStorage.setItem("type", type);

      this.setState({ showTable: true });
    }
  };



  TechWrkLvl = e => {
    this.setState({ wrkSelected: e });
    // const {
    //     wrkSelected,
    //     yearSelect,
    //     typeSelect,
    //       financialType,
    //       departmentLabelName,
    //       functionLabelName
    //   } = this.state;

    //   let type = typeSelect.label === 'Behavioral' ? 1 : 2;
    //       localStorage.setItem('jobRoleId', e.value);
    //       localStorage.setItem('jobRoleName', e.label);
    //       localStorage.setItem('workLevelId', wrkSelected.value);
    //       localStorage.setItem('workLevelName', wrkSelected.label);
    //       localStorage.setItem('financialYearId', yearSelect.value);
    //       localStorage.setItem('departmentName', departmentLabelName);
    //       localStorage.setItem('functionName', functionLabelName);
    //       localStorage.setItem('type', type);

    //       this.setState({showTableBeh : false , showTableTech : true});
  };

  //   Moveon = () =>{
  //       console.log(1212)
  //     return(
  //         <div>
  //             <Redirect to={{
  //                 pathname: '/hr/ConsolidateReport',
  //                 state: { userId: '167' }
  //             }}
  //             />
  //         </div>
  //     )
  //   } 

  ConsReport = async => {

    window.open(`/hr/Hr_userConsreport/${this.state.userId}`, '_blank');
  }

  render() {
    let borderstyle = {
      border: "0px"
    };
    const {
      userDetails,
      userId,
      userData,
      typeSelect,
      compOption,
      work,
      jobRole,
      designation,
      department,
      workLevel,
      techBehType,
      L1,
      L2,
      descriptionText,
      jobText,
      derailers,
      derailersText,
      desText,
      yearList,
      yearSelect,
      jobList,
      deptList,
      pdfIdp
    } = this.state;
    console.log(userDetails,"details");
if (!userDetails) {
      return (<div><PreLoader />  </div>)
    }
    else {
      let height = window.innerHeight - 50;
      return (
        <section>
          <div className=" container-sm-fluid mt-100 px-3">
            <div className="row justify-content-center align-items-center my-3 my-lg-5">
              <div className="content-border px-3 py-5">
                <div className="row justify-content-evenly align-items-start">
                  {userDetails && (
                    <div className="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-12">
                      <div className="content-border1">
                        <div className="cons-user-image col-12 text-center my-3">
                          <img
                            src={
                              ACCESS_POINT +
                              "/getpro/getprofile?fileurl=" +
                              `${userDetails && userDetails.image}`
                            }
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px"
                            }}
                            alt="user profile"
                          />
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-person-circle"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.name}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2 text-start">
                            <p class="bi bi-envelope my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.email_id}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-phone my-0"><span></span></p>
                          </div>
                          <div className="col-8  text-start">
                            <p>{userDetails.mobile_no}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-geo-alt my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.address}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-bar-chart my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{work}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-calendar my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.age}<span>(Age)</span></p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-telephone my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.telphone}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-mortarboard my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{userDetails.qualification}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-card-list my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{designation}</p>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-center align-items-center cons-user-details px-3">
                          <div className="col-2  text-start">
                            <p class="bi bi-person-workspace my-0"><span></span></p>
                          </div>
                          <div className="col-8 text-start">
                            <p>{jobRole}</p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  )}
                  <div className="col-lg-9 col-md-8 col-sm-12 col-12">
                    <div className="content-border1 px-2 px-lg-4 mb-3">
                      <div className="cons-jd my-2">
                        <h3>Job Description</h3>
                        <hr />
                      </div>
                      <div className="cons-jd">
                        <p>
                          {this.state.jobdescription}
                        </p>
                      </div>
                    </div>

                    <div className="content-border1 px-2 px-lg-4 py-2 my-3">
                      <div className="col-12 text-end">
                        <button type="button" className="submit-btn" onClick={this.ConsReport}>Consolidated Report</button>
                      </div>
                      <div className="py-2 my-2">
                        <div className="row justify-content-between align-items-center">
                          <ul className="nav nav-tabs">

                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#recent-activity"
                                style={{ color: "#5a508e" }}
                              >
                                Map Extra Competencies
                              </a>
                            </li>


                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#profile"
                                style={{ color: "#5a508e" }}
                              >
                                {userDetails && userDetails.name}'s Profile
                              </a>
                            </li>
                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#behComp"
                                style={{ color: "#5a508e" }}
                              >
                                Behavioural Competencies
                              </a>
                            </li>
                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#techComp"
                                style={{ color: "#5a508e" }}
                              >
                                Technical Competencies
                              </a>
                            </li>
                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Competency-Report"
                                style={{ color: "#5a508e" }}
                              >
                                Competencies Report
                              </a>
                            </li>
                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Assesment-Graph"
                                style={{ color: "#5a508e" }}
                              >
                                Assesment Graph
                              </a>
                            </li>
                            <li className="nav-item col-lg-2 col-md-3 col-sm-6 col- my-2">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Job-Description"
                                style={{ color: "#5a508e" }}
                              >
                                Job Description
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="py-2 my-2">
                        <div className="tab-content">
                          <div
                            id="recent-activity"
                            className="tab-pane fade in show"
                          >
                            <div className="col-lg-6 col-sm-12 col-12 col-md-6">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  Themes
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  Competencies
                                </div>
                              </div>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  Year
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <SingleSelect
                                    options={yearList}
                                    handleChange={d =>
                                      this.selectBoxStore(
                                        "yearSelect",
                                        d
                                      )
                                    }
                                    selectedService={yearSelect}
                                  />
                                </div>
                              </div>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  Type
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <SingleSelect
                                    options={techBehType}
                                    handleChange={d =>
                                      this.selectBoxStore(
                                        "typeSelect",
                                        d
                                      )
                                    }
                                    selectedService={typeSelect}
                                  />
                                </div>
                              </div>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  {typeSelect.label ===
                                    "Behavioral" && (
                                      <div>
                                        <div>Work Level</div>
                                        <SingleSelect
                                          options={workLevel}
                                          handleChange={this.wrklvl}
                                          selectedService={
                                            this.state.wrkSelected
                                          }
                                        />
                                        <br />
                                      </div>
                                    )}
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  {typeSelect.label ===
                                    "Technical" && (
                                      <div>
                                        <div>Department</div>
                                        <SingleSelect
                                          options={deptList}
                                          handleChange={
                                            this.departmentSelect
                                          }
                                          selectedService={
                                            this.state
                                              .departmentSelected
                                          }
                                        />
                                        <br />
                                        <div>Function</div>
                                        <SingleSelect
                                          options={
                                            this.state.functionOptions
                                          }
                                          handleChange={
                                            this.functionSelect
                                          }
                                          selectedService={
                                            this.state.functionSelected
                                          }
                                        />
                                        <br />
                                        <div>Work Level</div>
                                        <SingleSelect
                                          options={
                                            this.state.workLevelOptions
                                          }
                                          handleChange={this.TechWrkLvl}
                                          selectedService={
                                            this.state.wrkSelected
                                          }
                                        />
                                        <br />
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  Job Role
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                  <SingleSelect
                                    options={jobList}
                                    handleChange={this.jobSelect}
                                  />
                                </div>
                              </div>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                  {this.state.showTable == true && (
                                    <div>
                                      <ProfileCompMap
                                        userId={this.state.adminId}
                                      />
                                    </div>
                                  )}
                                </div>

                              </div>
                            </div>

                          </div>
                          <div id="profile" className="tab-pane fade in">
                            <div style={{ marginTop: 20, marginLeft: 30 }}>
                              <LoginModal
                                buttonTitle="Add/Map Derailers"
                                title="Add MapDerailers"
                                id="behComp1"
                                extraClass="btn btncolor"
                                extraStyle={{
                                  backgroundColor: "#f44336",
                                  textAlign: "center"
                                }}
                                bodyText={
                                  <div>
                                    <ul className="nav nav-tabs">
                                      <li className=" nav-item">
                                        <a data-toggle="tab" href="#map" className="nav-link active">
                                          Map Derailers
                                        </a>
                                      </li>
                                      <li className=" nav-item">
                                        <a data-toggle="tab" href="#add" className="nav-link ">
                                          Enter Derailers
                                        </a>
                                      </li>
                                    </ul>
                                    <div className="tab-content">
                                      <div
                                        id="map"
                                        className="tab-pane fade in show py-3 my-2"
                                      >
                                        {'derailer_map'}
                                        {this.state.mapped_derailers && (

                                          <Datatable
                                            data={this.state.mapped_derailers}
                                            columnHeading={this.de_mapped_column}
                                          />

                                        )}
                                      </div>
                                      <div
                                        id="add"
                                        className="tab-pane fade in"
                                      >
                                        <div className="py-5 my-3">
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-3">
                                              Matrix Type
                                            </div>
                                            <div className="col-8">
                                              <SingleSelect
                                                options={techBehType}
                                                handleChange={this.typeSelect}
                                                selectedService={typeSelect}
                                              />
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-3">
                                              Competencies
                                            </div>
                                            <div className="col-8">
                                              <SingleSelect
                                                options={compOption}
                                                handleChange={this.compSelect}
                                                selectedService={
                                                  this.state.compSelect
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-3">
                                              Derailer
                                            </div>
                                            <div className="col-8">
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.derailersText}
                                                value={derailersText}
                                              />
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-3">
                                              Description
                                            </div>
                                            <div className="col-8">
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.desText}
                                                value={desText}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12 text-end">
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                              class="btncolorsubmit"
                                              onClick={this.addDerailers}
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                }
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 ">
                              <div className="content-border px-lg-3 px-2 py-2">
                                <div className="profile-content">
                                  <h2> {userDetails && userDetails.name}'s
                                    Profile</h2>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> User Name :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> {userDetails.name}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>Email Id :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{userDetails.email_id}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> Mobile No :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{userDetails.mobile_no}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> Address :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> {userDetails.address}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>  Age :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> {userDetails.age}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>Telphone No :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> {userDetails.telphone}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> Qualification :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{userDetails.qualification}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-content">
                                  <h2>  Mapped Theme & Competency</h2>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>Department :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{department}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> JobRole :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{jobRole}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>  Worklevel :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{work}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>Themes :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{L1}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-content">
                                  <h2> Mapped Derailers</h2>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>Themes :</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{L1}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-evenly align-items-center">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span> Derailers:</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-content">
                                      <span>{this.state.mapDerail}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="behComp" className="tab-pane fade in">
                            {userData && userDetails && (
                              <BehaviouralCompetencies
                                id={this.state.userId}
                                data={userData}
                                userDetails={userDetails}
                              />
                            )}
                          </div>
                          <div id="techComp" className="tab-pane fade in">
                            <div>
                              {userData && userDetails && (
                                <TechnicalCompetencies
                                  id={this.state.userId}
                                  data={userData}
                                  adminId={this.state.adminId}
                                  userDetails={userDetails}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            id="Competency-Report"
                            className="tab-pane fade in"
                          >
                            <div>
                              {userData && userDetails && (
                                <ProfileCompReport
                                  // userId = {`/hr/ViewProfile?id=${this.state.userId}`}
                                  userid={this.state.userId}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            id="Assesment-Graph"
                            className="tab-pane fade in"
                          >
                            <div className="col-12">
                              <button style={{ marginLeft: 30, marginTop: 20 }}
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  window.open(
                                    "/hr/ConsolidateReport" +
                                    "?" +
                                    "id=" +
                                    this.state.userId,
                                    "_blank"
                                  )
                                }
                              >
                                Consolidated Report
                              </button>
                              {userData && userDetails && (
                                <ProfileChart userId={this.state.userId} />
                              )}
                            </div>
                          </div>
                          <div
                            id="Job-Description"
                            className="tab-pane fade in"
                          >
                            <LoginModal
                              buttonTitle="Upload File"
                              title="Add Extra jobDescription"
                              id="fileextra"
                              extraClass="btn btncolor"
                              extraStyle={{
                                backgroundColor: "#f44336",
                                textAlign: "center"
                              }}
                              bodyText={
                                <div>
                                  <div className="row justify-content-between align-items-center py-2">
                                    <div className="col-lg-3 col-md-6 col-12">
                                      <label htmlFor="exampleInputEmail1">
                                        Job Description
                                      </label>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-12">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Job Description Text "
                                        onChange={this.jobText}
                                        value={jobText}
                                      />
                                    </div>
                                  </div>
                                  <div className="row justify-content-between align-items-center py-2">
                                    <div className="col-lg-3 col-md-6 col-12">
                                      <label htmlFor="exampleInputEmail1">
                                        Description
                                      </label>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-12">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Job Description Text "
                                        onChange={this.descriptionText}
                                        value={descriptionText}
                                      />
                                    </div>

                                  </div>
                                  <div className="row justify-content-between align-items-center py-2">
                                    <div className="col-lg-3 col-md-6 col-12">
                                      <label htmlFor="exampleInputEmail1">
                                        File
                                      </label>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-12">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        name="filename"
                                        onChange={e => this.selectImage(e)}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-end col-12">
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={this.onSubmit}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                  <div style={{ marginBottom: 20 }}> </div>
                                  {this.state.descrip && (

                                    <Datatable
                                      data={this.state.descrip}
                                      columnHeading={this.column}
                                    />

                                  )}
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }



  }
}

export default ViewProfile;
