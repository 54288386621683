import React, { Component } from 'react';
import LoginModal from "../../components/Modal/Modal";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import ValidationView from "../../components/Form/ValidationView";
import './framwork.css';
var atob = require('atob');

class DictionaryWiz extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  async componentWillMount() {

    try {

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <section>
        <div className='mt-100  container container-sm-fluid'>
          <div className='row justify-content-center align-items-center my-lg-5 my-2'>
            <div className='col-lg-10 col-12 px-5'>
              <div className='content-border1 ' >
                <div className='row justify-content-center  align-items-center' style={{ width: "100%", height: "80vh" }}>
                  <div className='col-lg-4 col-md-4 col-sm-12 col-12 text-sm-center'>
                    <button
                      type="button"
                      className="btndefault btn-primary"
                      style={{ padding: "18px" }}
                      onClick={() =>
                        window.open(
                          "/hr/Dictionary",
                          "_blank"
                        )
                      }
                    >
                      Behavioral Competency
                    </button>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-12 col-12 text-sm-center'>
                    <button
                      type="button"
                      className="submit-btn "
                      style={{ padding: "18px" }}
                      onClick={() =>
                        window.open(
                          "/hr/TechCompDictionary",
                          "_blank"
                        )
                      }
                    >
                      Technical Competency
                    </button>
                  </div>
                  <div className='col-lg-4 col-md-4 col-sm-12 col-12 text-sm-center'>
                    <button
                      type="button"
                      className="btndefault btn-primary"
                      style={{ padding: "18px" }}
                      onClick={() =>
                        window.open(
                          "/hr/Newdictionary",
                          "_blank"
                        )
                      }
                    >
                      Behaviour Dictionary-2
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DictionaryWiz;