import React from 'react';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import SingleSelect from '../../../components/Form/SingleSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';
import WizardPrograss from '../Master/wizardPrograssbar';
import WizardNextPreviousButton from '../Master/wizardNextPreviousButton';
class MapCompetencyLevel extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      themeOptions: [],
      themeSelected: null,
      themeId: null,
      themeLabelName: null,
      errorthemeSelected: null,
      competencyOptions: [],
      competencySelected: null,
      competencyId: null,
      competencyLabelName: null,
      errorcompetencySelected: null,
      workLevelOptionsBefore: [],
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      errorworkLevelSelected: null,
      jobroleOptions: [],
      jobroleSelected: null,
      jobroleId: null,
      jobroleLabelName: null,
      errorjobroleSelected: null,
      updateData: false,
      groupCompanyOptions: [],
      groupCompanySelected: null,
      errorGroupCompanySelected: null,
      data: [],
      importantce: [
        { label: "Critical", value: "Critical" },
        { label: "Essential", value: "Essential" },
        { label: "Desirable", value: "Desirable" }
      ],
      tence: [
        { label: "Future", value: "Future" },
        { label: "Current", value: "Current" }
      ],
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(adminId, 'tbl_worklevel', 'id', 'worklevel', 'workLevelOptionsBefore');
    this.getSelectBoxMaster(adminId, 'tbl_jobRole', 'id', 'jobRoleName', 'jobroleOptions');
    this.getTableMaster(adminId);
    this.getThemeMaster(adminId);
    let { data: yearOptions } = await MappingMiddleware.getYear();
    if (yearOptions) {
      this.setState({
        yearOptions
      });
    }

    const {
      data: groupCompanyOptions
    } = await MappingMiddleware.getGroupCompanyForSelect(adminId);
    if (groupCompanyOptions) {
      this.setState({ groupCompanyOptions });
    }
    const {
      data: departmentOptions
    } = await MappingMiddleware.getDepartmentForSelect(adminId);
    if (departmentOptions) {
      this.setState({ departmentOptions });
    }

  }

  getTableMaster = async adminId => {
    try {
      const { data } = await MappingMiddleware.getThemeWorkLevelCompetencyData(adminId);
      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getThemeMaster = async adminId => {
    try {
      const { data: themeOptions } = await MappingMiddleware.getThemeMaster(adminId);
      if (themeOptions) {
        this.setState({
          themeOptions
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  column = [
    {
      Header: 'Theme',
      accessor: 'theme'
    },
    {
      Header: 'Competency',
      accessor: 'competencyname'
    },
    {
      Header: 'Work Level',
      accessor: 'worklevel'
    },
    {
      Header: 'Job Role',
      accessor: 'jobRoleName'
    }
  ];

  themeSelect = async selectedOption => {
    const { adminId } = this.state;
    this.setState({
      themeSelected: selectedOption,
      themeId: selectedOption.value,
      themeLabelName: selectedOption.label
    });
    try {
      const { data: competencyOptions } = await MappingMiddleware.getMapthemeTocompetencyData(adminId);
      if (competencyOptions) {
        this.setState({
          competencyOptions,
          competencySelected: null,
          competencyId: null,
          competencyLabelName: null,
          errorcompetencySelected: null,
          workLevelOptions: [],
          workLevelSelected: null,
          workLevelId: null,
          workLevelLabelName: null,
          errorworkLevelSelected: null,
          jobroleSelected: null,
          jobroleId: null,
          jobroleLabelName: null,
          errorjobroleSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  competencySelect = async selectedOption => {
    const { adminId, themeId, workLevelOptionsBefore } = this.state;
    const competencyId = selectedOption.value;
    console.log(workLevelOptionsBefore,"workoptiosbefore");
    this.setState({
      competencySelected: selectedOption,
      competencyId: selectedOption.value,
      competencyLabelName: selectedOption.label,
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      errorworkLevelSelected: null,
      jobroleSelected: null,
      jobroleId: null,
      jobroleLabelName: null,
      errorjobroleSelected: null
    });
    try {
      const { data: workLevelOptions } = await MappingMiddleware.getMapThemeCompetencyToWorklevelData(adminId, themeId, competencyId);
      let previousFunctionOptions = [...workLevelOptionsBefore];
      console.log(workLevelOptions,"workleveloptions");
      if (workLevelOptions) {
        if (workLevelOptions.length > 0) {
          workLevelOptions.map(filterId => {
            console.log(filterId,"worklvelvalue");
            previousFunctionOptions = previousFunctionOptions.filter(value => {
              if (filterId.value !== value.value) {
                return value;
              }
              return null;
            });
            return null;
          });
          console.log(previousFunctionOptions,"afterfilter");
          this.setState({
            workLevelOptions: previousFunctionOptions
          });
        } else {
          this.setState({
            workLevelOptions: workLevelOptionsBefore
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  groupCompanySelect = async selectedOption => {
    const { adminId, workLevelOptionsBefore, workLevelId } = this.state;
    this.setState({
      groupCompanySelected: selectedOption,
      groupCompanyId: selectedOption.value,
      groupCompanyLabelName: selectedOption.label,
    });
  }

  departmentSelect = selectedOption => {
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
  };

  workLevelSelect = async selectedOption => {
    this.setState({
      workLevelSelected: selectedOption,
      workLevelId: selectedOption.value,
      workLevelLabelName: selectedOption.label
    });
  };

  jobroleSelect = async selectedOption => {
    this.setState({
      jobroleSelected: selectedOption,
      jobroleId: selectedOption.value,
      jobroleLabelName: selectedOption.label
    });
  };

  onSubmit = async () => {
    const { themeId, competencyId, workLevelId, jobroleId, adminId, yearSelected, groupCompanyId, departmentId, important, cF, erroryearSelected } = this.state;
    if (this.validate(groupCompanyId, 'errorGroupCompanySelected', 'Please Select GroupCompany')) return true;
    if (this.validate(yearSelected, 'erroryearSelected', 'Please Select FinancialYear')) return true;
    if (this.validate(departmentId, 'errorDepartmentSelected', 'Please Select Department')) return true;
    if (this.validate(themeId, 'errorthemeSelected', 'Please Select Theme')) return true;
    if (this.validate(competencyId, 'errorcompetencySelected', 'Please Select Competency')) return true;
    if (this.validate(workLevelId, 'errorworkLevelSelected', 'Please Select Work Level')) return true;
    if (this.validate(jobroleId, 'errorjobroleSelected', 'Please Select Job Role')) return true;
    if (this.validate(important, "errorimportants", "Please select Importance"))
      return true;
    if (this.validate(cF, "errorcFs", "Please select current")) return true;
    const formData = new FormData();
    formData.set('groupcompany', groupCompanyId);
    formData.set('departmentid', departmentId);
    formData.append("imp", important.value);
    formData.append("cF", cF.value);

    formData.append('themeid', themeId);
    formData.append('competencyid', competencyId);
    formData.append('worklevelid', workLevelId);
    formData.append('jobroleId', jobroleId);
    formData.append('financial_year', yearSelected.value);
    formData.append('cmid', adminId);
    formData.append('type', 2);

    try {
      const result = await MappingMiddleware.mapCompetencyToWorklevel(formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          themeSelected: null,
          themeId: null,
          themeLabelName: null,
          errorthemeSelected: null,
          competencyOptions: [],
          competencySelected: null,
          competencyId: null,
          competencyLabelName: null,
          errorcompetencySelected: null,
          workLevelOptions: [],
          workLevelSelected: null,
          workLevelId: null,
          workLevelLabelName: null,
          errorworkLevelSelected: null,
          jobroleSelected: null,
          jobroleId: null,
          jobroleLabelName: null,
          errorjobroleSelected: null,
          yearSelected: "",
          groupCompanyId: "",
          groupCompanySelected: "",
          departmentId: "",
          departmentSelected: "",
          important: "",
          cF: ""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  yearSelectId = selectedOption => {
    this.setState({
      yearSelected: selectedOption,
    });
  };

  render() {
    const {
      themeOptions,
      themeSelected,
      errorthemeSelected,
      competencyOptions,
      competencySelected,
      errorcompetencySelected,
      workLevelOptions,
      workLevelSelected,
      errorworkLevelSelected,
      jobroleOptions,
      jobroleSelected,
      errorjobroleSelected,
      updateData,
      data,
      yearOptions,
      yearSelected,
      erroryearSelected,
      groupCompanyOptions,
      groupCompanySelected,
      errorGroupCompanySelected,
      departmentOptions,
      departmentSelected,
      errorDepartmentSelected,
      importantce,
      important,
      errorimportants,
      tence,
      cF,
      errorcFs,
    } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className='container container-sm-fluid mt-100 pb-5 mb-5'>
            <div className='py-2'>
              <WizardPrograss step={15} />
            </div>
            <div className='row justify-content-center align-items-center my-2'>
              <div className='col-lg-10 col-md-12 col-12'>
                <div className='content-border px-lg-5 px-md-3 px-2'>
                  <div className='header-text'>
                    <span>Map Technical Competency</span>
                  </div>
                  <hr className='px-0' />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>GroupCompany</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.groupCompanySelect}
                        options={groupCompanyOptions}
                        selectedService={groupCompanySelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorGroupCompanySelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Financial Year</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.yearSelectId}
                        options={yearOptions}
                        selectedService={yearSelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={erroryearSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Department</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.departmentSelect}
                        options={departmentOptions}
                        selectedService={departmentSelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorDepartmentSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Theme</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect handleChange={this.themeSelect} options={themeOptions} selectedService={themeSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorthemeSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Competency</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect handleChange={this.competencySelect} options={competencyOptions} selectedService={competencySelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorcompetencySelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Work Level</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect handleChange={this.workLevelSelect} options={workLevelOptions} selectedService={workLevelSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorworkLevelSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Job Role</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect handleChange={this.jobroleSelect} options={jobroleOptions} selectedService={jobroleSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorjobroleSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Competency Importance</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={d =>
                          this.selectBoxStore("important", d)
                        }
                        options={importantce}
                        selectedService={important}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorimportants} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'> Current / Future</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={d =>
                          this.selectBoxStore("cF", d)
                        }
                        options={tence}
                        selectedService={cF}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorcFs} />
                  <div className='row justify-content-between align-items-center '>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      {!updateData ? (
                        <button type="button" className="submit-btn" onClick={this.onSubmit}>
                          Submit
                        </button>
                      ) : (
                        <button type="button" className="submit-btn" onClick={this.onUpdate}>
                          Update
                        </button>
                      )}
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                      <WizardNextPreviousButton step={15} nextPage={"CompetencyMatrix/CompFormOne"} previousPage={"Mapping/MapWorkleveToCompetency"} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                </div>
              </div>
            </div>
            <div className='my-5 '>
              {data && <Datatable data={data} columnHeading={this.column} />}

            </div>          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapCompetencyLevel;
