import React from 'react';
import { FormMiddleWare } from '../../../components/Form';




class WizardPrograss extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Step: this.props.step,
      StepPages: [

        { label: "Master/GroupCompany", value: 1, step: " 1" },
        { label: "Master/JobRole", value: 2, step: " 2" },
        { label: "Master/Department", value: 3, step: " 3" },
        { label: "Master/JobDescription", value: 4, step: " 4" },
        { label: "Master/WorkLevel", value: 5, step: " 5" },
        { label: "Master/Themes", value: 6, step: " 6" },
        { label: "Master/Function", value: 7, step: " 7" },
        { label: "Master/Competency", value: 8, step: " 8" },
        { label: "Master/ProficiencyScale", value: 9, step: " 9" },
        { label: "Master/Indicators", value: 10, step: " 10" },
        { label: "Master/Location", value: 11, step: " 11" },
        { label: "Master/Users", value: 12, step: " 12" },
        { label: "Mapping/MapEmployee", value: 13, step: " 13" },
        { label: "Mapping/MapWorkleveToCompetency", value: 14, step: " 14" },
        { label: "Mapping/MapCompetencyLevel", value: 15, step: " 15" },
        { label: "CompetencyMatrix/CompFormOne", value: 16, step: " 16" },


      ]
    };
  }
  navigatePage = (e) => {
    let { Step, StepPages } = this.state
    let stepname = e.target.textContent

    StepPages.map((ival) => {
      if (ival.step === stepname) {
        window.location.replace(`/admin/${ival.label}`)
      }
    })
  }



  render() {
    let { Step, StepPages } = this.state
    // console.log(this.props.step, "step");

    return (
      <React.Fragment>
        <div className="progress-step " style={{color:"black"}}>
          <div className={`step ${Step >= 1 ? "active" : ""} ${Step - 1 >= 1 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="GroupCompany" > <span  style={{zIndex:"3"}}>1</span></div>
          <div className={`step ${Step >= 2 ? "active" : ""} ${Step - 1 >= 2 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="JobRole" > <span style={{zIndex:"3"}}>2</span></div>
          <div className={`step ${Step >= 3 ? "active" : ""} ${Step - 1 >= 3 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Department" > <span style={{zIndex:"3"}}>3</span></div>
          <div className={`step ${Step >= 4 ? "active" : ""} ${Step - 1 >= 4 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="JobDescription" > <span style={{zIndex:"3"}}>4</span></div>
          <div className={`step ${Step >= 5 ? "active" : ""} ${Step - 1 >= 5 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="WorkLevel" > <span style={{zIndex:"3"}}>5</span></div>
          <div className={`step ${Step >= 6 ? "active" : ""} ${Step - 1 >= 6 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Themes" > <span style={{zIndex:"3"}}>6</span></div>
          <div className={`step ${Step >= 7 ? "active" : ""} ${Step - 1 >= 7 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Function" > <span style={{zIndex:"3"}}>7</span></div>
          <div className={`step ${Step >= 8 ? "active" : ""} ${Step - 1 >= 8 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Competency" > <span style={{zIndex:"3"}}>8</span></div>
          <div className={`step ${Step >= 9 ? "active" : ""} ${Step - 1 >= 9 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="ProficiencyScale" > <span style={{zIndex:"3"}}>9</span></div>
          <div className={`step ${Step >= 10 ? "active" : ""} ${Step - 1 >= 10 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Indicators" > <span style={{zIndex:"3"}}>10</span></div>
          <div className={`step ${Step >= 11 ? "active" : ""} ${Step - 1 >= 11 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Location" > <span style={{zIndex:"3"}}>11</span></div>
          <div className={`step ${Step >= 12 ? "active" : ""} ${Step - 1 >= 12 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="UserAdd" > <span style={{zIndex:"3"}}>12</span></div>
          <div className={`step ${Step >= 13 ? "active" : ""} ${Step - 1 >= 13 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="MapEmployee" > <span style={{zIndex:"3"}}>13</span></div>
          <div className={`step ${Step >= 14 ? "active" : ""} ${Step - 1 >= 14 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Map Behavioral Competency" > <span style={{zIndex:"3"}}>14</span></div>
          <div className={`step ${Step >= 15 ? "active" : ""} ${Step - 1 >= 15 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Map Technical Competency" > <span style={{zIndex:"3"}}>15</span></div>
          <div className={`step ${Step >= 16 ? "active" : ""} ${Step - 1 >= 16 ? "Arrow" : ""}`} onClick={(e) => this.navigatePage(e)} title="Create Competency Matrix" > <span style={{zIndex:"3"}}>16</span></div>
        </div>
        <div class="arrow-container">
          <div class="arrow"></div>
        </div>


      </React.Fragment>
    );
  }
}

export default WizardPrograss;
