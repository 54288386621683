import React, { Component } from "react";
import { Route } from "react-router-dom";
import User from "../screens/User/User";
import UserHeader from "../screens/Header/UserHeader";
import WizardForm from "../screens/Hr/WizardForm";
import Dashboard from "../screens/User/Dashboard";
import Updateprofile from "../screens/User/Updateprofile";
import UserViewHistoryIdp from "../screens/User/viewHistoryIdp";
import Addidppage from "../screens/User/Addidppage";
import ManageReporteeidp from "../screens/User/ManageReporteeIdp"
import ComptencyReport from "../screens/Hr/ComptencyReport";
import ConsolidateReport from "../screens/Hr/ConsolidateReport";
import Organogram from "../screens/Hr/Organogram";
import IDPHistory from "../screens/Hr/IDPHistory";
import Traingprogram from "../screens/Hr/sugestedTraingProgram";
import QrScanner from "../screens/Hr/QrScanner";
import VideoContent from "../screens/Hr/VideoContent";

export default class UserRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={"/user"}
          render={props => (
            <div>
              <UserHeader />
              <User {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/user/dashboard"}
          render={props => (
            <div>
              <UserHeader />
              <Dashboard {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/user/Addidppage"}
          render={props => (
            <div>
              <UserHeader />
              <Addidppage {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/user/manageIdppage/:id?"}
          render={props => (
            <div>
              <UserHeader />
              <ManageReporteeidp {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/user/ViewHistory/:id?"}
          render={props => (
            <div>
              <UserHeader />
              <UserViewHistoryIdp {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/user/wizardForm"}
          render={props => (
            <div>
              <UserHeader />
              <WizardForm {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/Report"}
          render={props => (
            <div>
              <UserHeader />
              <ComptencyReport {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/ConsolidateReport"}
          render={props => (
            <div>
              <UserHeader />
              <ConsolidateReport {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/Organogram"}
          render={props => (
            <div>
              <UserHeader />
              <Organogram {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/Updateprofile"}
          render={props => (
            <div>
              <UserHeader />
              <Updateprofile {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/IDPHistory"}
          render={props => (
            <div>
              <UserHeader />
              <IDPHistory {...props} />
            </div>
          )}
        />
         <Route
          exact
          path={"/user/QrScanner"}
          render={props => (
            <div>
              <UserHeader />
              <QrScanner {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/sugestedTraingProgram"}
          render={props => (
            <div>
              <UserHeader />
              <Traingprogram {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/user/elearnVideoContenct/:id"}
          render={props => (
            <div>
              <UserHeader />
              <VideoContent {...props} />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}
