import React, { Component } from "react";
import {
    PDFViewer,
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet, Font, Link,
} from "@react-pdf/renderer";
import certificate from "../../image/newCertificate1.png"
import icon from "../../image/favIcon3.jpg"
import sign from "../../image/signature.jpg"
// import Certificate from "../../image/Certificate.png"
import moment from "moment";

export default class CmsCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerid: localStorage.getItem("userId"),
            serviceid: localStorage.getItem("currentService"),
        };
    }

    render() {
        const {
            match: { params }
        } = this.props;

        const username = atob(params.user);
        const quizname = atob(params.quiz);
        const date = moment(atob(params.time)).format("DD-MM-YYYY");

        return (
            <div style={{ height: "100vh", backgroundColor: "#e0c380" }}>
                <PDFViewer style={{ width: "100%", height: "100%" }}>
                    <Document>
                        <Page>
                            <Image style={styles.image} src={certificate} />

                                <Image style={styles.icon} src={icon} />

                            <Image style={styles.sign} src={sign} />
                            <Link style={styles.link} src="https://elearning.difuza.com/">e-learning.difuza.com</Link>
                            <Text style={styles.username}>{username}</Text>
                            <View style={styles.view}>
                                <Text style={styles.module}>who has successfully completed the E-LEARNING module</Text>
                                <Text style={[styles.quizname]}>
                                    {quizname} <Text style={{ fontFamily: "Times-Roman", fontSize: 18 }}>on</Text> {date}
                                </Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        );
    }
}
Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});
const styles = StyleSheet.create({
    view: {
        position: "absolute",
        top: "53%",
        width: "100%",
        left: "-60px"
    },

    username: {
        margin: 5,
        fontSize: 40,
        alignSelf: "center",
        justifyContent: "center",
        fontFamily: "Times-Roman",
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)',
        position: "absolute",
        top: "40%",
        marginLeft: "100%",
        textTransform: "capitalize"
    },
    link: {
        position: "absolute",
        top: "50%",
        right: "30px",
        transform: ' rotate(90deg)',
        textDecoration: "none",
        color: "black",
        fontSize: 13,
    },
    module: {
        textAlign: "center",
        fontFamily: "Times-Roman",
        transform: ' rotate(90deg)',
        fontSize: 20,
        left: "-10px"
    },
    quizname: {
        // alignSelf: "center",
        margin: "auto",
        marginLeft: "260px",
        fontFamily: "Times-Roman",
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)',
        top: "-200px",
        fontSize: 20,
        textAlign: "center"
    },

    image: {
        width: "auto",
        height: "830px",
        // transform: ' rotate(270deg)'
        // top: "25%"
    },
    iconss: {
        width: "100px",
        height: "100px",
        position: "absolute",
        top: "16px",
        objectFit: "cover",
        right: "14px",
        transform: "rotate(90deg)",
        // overflow:"hidden"
    },
    icon: {
        width: "100px",
        height: "100px",
        position: "absolute",
        top: "16px",
        right: "14px",
        transform: "rotate(90deg)",
        borderRadius: 50,
    },
    sign: {
        width: "100px",
        height: "90px",
        bottom: "100px",
        left: "95px",
        transform: "rotate(90deg)",
        position: "absolute",
    }
});
