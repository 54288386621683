import React, { Component } from 'react';
import { FormMiddleWare, SingleSelect } from '../../../components/Form';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import WizardPrograss from '../Master/wizardPrograssbar';
import WizardNextPreviousButton from '../Master/wizardNextPreviousButton';
import ValidationView from '../../../components/Form/ValidationView';


class CompFormOne extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      financialType: null,
      jobRoleOptions: [],
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null,
      financialYearOptions: [],
      financialYearSelected: null,
      financialYearId: null,
      financialYearName: null,
      workLevelOptionsBefore: [],
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentLabelName: null,
      functionOptions: [],
      functionSelected: null,
      functionId: null,
      functionLabelName: null,
      errorfinancialYearSelected: null,
      errorworkLevelSelected: null,
      errorjobRoleSelected: null,
      errorfinancialType: null,
      groupCompanyOptions: [],
      groupCompanySelected: null,
      errorGroupCompanySelected: null,

    };
  }

  onSubmit = () => {
    const {
      jobRoleId,
      jobRoleLabelName,
      workLevelId,
      workLevelLabelName,
      financialYearId,
      financialType,
      departmentLabelName,
      functionLabelName,
      groupCompanyId
    } = this.state;
    if (this.validate(financialType, 'errorfinancialType', 'Please Select FinancialType')) return true;
    if (this.validate(groupCompanyId, 'errorGroupCompanySelected', 'Please Select GroupCompany')) return true;
    if (this.validate(financialYearId, 'errorfinancialYearSelected', 'Please Select FinancialYear')) return true;
    if (this.validate(workLevelId, 'errorworkLevelSelected', 'Please Select Worklevel')) return true;
    if (this.validate(jobRoleId, 'errorjobRoleSelected', 'Please Select Jobrole')) return true;

    let type = financialType === 'Behavioral' ? 1 : 2;
    localStorage.setItem('jobRoleId', jobRoleId);
    localStorage.setItem('jobRoleName', jobRoleLabelName);
    localStorage.setItem('workLevelId', workLevelId);
    localStorage.setItem('workLevelName', workLevelLabelName);
    localStorage.setItem('financialYearId', financialYearId);
    localStorage.setItem('departmentName', departmentLabelName);
    localStorage.setItem('functionName', functionLabelName);
    localStorage.setItem('groupcompId', groupCompanyId);
    localStorage.setItem('type', type);
    window.location = '/admin/CompetencyMatrix/CompFormTwo';
  };

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(
      adminId,
      'tbl_jobRole',
      'id',
      'jobRoleName',
      'jobRoleOptions'
    );
    // this.getSelectBoxMaster(
    //   adminId,
    //   'tbl_financial_years',
    //   'id',
    //   'to_year',
    //   'financialYearOptions'
    // );
    this.getSelectBoxMaster(
      adminId,
      'tbl_department',
      'id',
      'department',
      'departmentOptions'
    );
    this.getSelectBoxMaster(
      adminId,
      'tbl_worklevel',
      'id',
      'worklevel',
      'workLevelOptionsBefore'
    );
    // this.getSelectBoxMaster(
    //   adminId,
    //   "tbl_worklevel",
    //   "id",
    //   "worklevel",
    //   "workLevelOptions"
    // );
    let { data: financialYearOptions } = await MappingMiddleware.getYear();
    this.setState({ financialYearOptions })
    const {
      data: groupCompanyOptions
    } = await MappingMiddleware.getGroupCompanyForSelect(adminId);
    if (groupCompanyOptions) {
      this.setState({ groupCompanyOptions });
    }
  }

  handleFinancialType = e => {
    const { adminId } = this.state;
    if (e.target.name === 'financialType') {
      // if (e.target.value === 'Behavioral') {
      this.getSelectBoxMaster(
        adminId,
        'tbl_worklevel',
        'id',
        'worklevel',
        'workLevelOptions'
      );
      // }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  departmentSelect = async selectedOption => {
    const { adminId } = this.state;
    const departmentId = selectedOption.value;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    try {
      const {
        data: functionOptions
      } = await MappingMiddleware.getMapDepartmentToFunctionData(
        adminId,
        departmentId
      );
      if (functionOptions) {
        this.setState({
          functionOptions,
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  functionSelect = async selectedOption => {
    const { adminId, departmentId, workLevelOptionsBefore } = this.state;
    const functionId = selectedOption.value;
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
    // try {
    //   const {
    //     data: workLevelOptions
    //   } = await MappingMiddleware.getMapDepartmentToWorklevelData(
    //     adminId,
    //     departmentId,
    //     functionId
    //   );

    //   if (workLevelOptions) {
    //     this.setState({ workLevelOptions });
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  render() {
    const {
      financialType,
      jobRoleOptions,
      jobRoleSelected,
      financialYearOptions,
      financialYearSelected,
      workLevelOptions,
      workLevelSelected,
      departmentOptions,
      departmentSelected,
      functionOptions,
      functionSelected,
      errorfinancialYearSelected,
      errorworkLevelSelected,
      errorjobRoleSelected,
      errorfinancialType,
      groupCompanyOptions,
      groupCompanySelected,
      errorGroupCompanySelected
    } = this.state;
    return (
      <div>
        <section>
          <div className='container container-sm-fluid mt-100 pb-5 mb-5'>
            <div className='py-2'>
              <WizardPrograss step={16} />
            </div>
            <div className='row justify-content-center align-items-center my-2'>
              <div className='col-lg-10 col-md-12 col-12'>
                <div className='content-border px-lg-5 px-md-3 px-2'>
                  <div className='header-text'>
                    <span>Create Competency Matrix : Step 1</span>
                  </div>
                  <hr className='px-0' />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Financial Type</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                          <input
                            type="radio"
                            name="financialType"
                            onChange={this.handleFinancialType}
                            value="Behavioral"
                            className=' w-0'
                            checked={financialType === 'Behavioral' && true}
                          />
                          <label className='formLabel'>
                            &nbsp;&nbsp;&nbsp;Behavioral
                          </label>
                        </div>
                        <div className='d-flex align-items-center'>
                          <input
                            type="radio"
                            name="financialType"
                            onChange={this.handleFinancialType}
                            value="Technical"
                            checked={financialType === 'Technical' && true}
                          />
                          <label className='formLabel'>
                            &nbsp;&nbsp;&nbsp;Technical
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorfinancialType} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>GroupCompany</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.groupCompanySelect}
                        options={groupCompanyOptions}
                        selectedService={groupCompanySelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorGroupCompanySelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Financial Year</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.financialYearSelect}
                        options={financialYearOptions}
                        selectedService={financialYearSelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorfinancialYearSelected} />

                  {financialType && (
                    <div>
                      <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-1 d-none d-lg-block' />
                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                          <label className='formLabel'>Work Level</label>
                        </div>
                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                          <SingleSelect
                            handleChange={this.workLevelSelectsingal}
                            options={workLevelOptions}
                            selectedService={workLevelSelected}
                          />
                        </div>
                        <div className='col-lg-1 d-none d-lg-block' />
                      </div>
                      <ValidationView errorname={errorworkLevelSelected} />
                    </div>
                  )}
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text '>
                      <label className='formLabel'>Job Role</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <SingleSelect
                        handleChange={this.jobRoleSelect}
                        options={jobRoleOptions}
                        selectedService={jobRoleSelected}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorjobRoleSelected} />
                  <div className='row justify-content-between align-items-center '>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                      <button
                        type="button"
                        className="submit-btn"
                        onClick={this.onSubmit}
                      >
                        Submit & Proceed To Step 2
                      </button>
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                      <WizardNextPreviousButton step={16} nextPage={"Mapping/MapEmployee"} previousPage={"Mapping/MapCompetencyLevel"} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CompFormOne;
