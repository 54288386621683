import React, { Component } from "react";
import queryString from "query-string";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Datatable from "../../components/Datatable/Datatable";

class IDPHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: queryString.parse(this.props.location.search).id,
      goalId: queryString.parse(this.props.location.search).goal
    };
  }
  async componentWillMount() {
    try {
      const Id = this.state.Id;
      let adminId = await localStorage.getItem("adminId");
      let groupcompId = await localStorage.getItem("groupcompId");
      let userId = await localStorage.getItem("userId");

      const user = await HrMiddleWare.getRandomData(
        "tbl_users",
        1,
        "id as value , name as label"
      );
      const BehGoal = await HrMiddleWare.getRandomData(
        "idp_goals",
        `customerid = ${Id} and empid = ${userId} and type = "Behavioural"`,
        "*"
      );
      const TechGoal = await HrMiddleWare.getRandomData(
        "idp_goals",
        `customerid = ${Id} and empid = ${userId} and type = "Technical"`,
        "*"
      );
      const BehGoalEdit = await HrMiddleWare.getRandomData(
        "idp_goals_edit",
        `goalid = ${this.state.goalId} and type = "Behavioural"`,
        "*"
      );
      const TechGoalEdit = await HrMiddleWare.getRandomData(
        "idp_goals_edit",
        `goalid = ${this.state.goalId} and  type = "Technical"`,
        "*"
      );
      this.setState({
        user: user.data,
        BehGoal: BehGoal.data,
        TechGoal: TechGoal.data,
        BehGoalEdit: BehGoalEdit.data,
        TechGoalEdit: TechGoalEdit.data
      });
    } catch (error) {
      console.error(error);
    }
  }

  column = [
    {
      Header: "Goal Details",
      accessor: "editgoalname"
    },
    {
      Header: "criteriaCompletion",
      accessor: "Criteria_Completion"
    },
    {
      Header: "scheduledStartDate",
      accessor: "scheduled_start_date"
    },
    {
      Header: "reasonDeviation",
      accessor: "reason_deviation"
    },
    {
      Header: "Status",
      accessor: "statuschange"
    },
    {
      Header: "% of Completion",
      accessor: "percentage"
    },
    {
      id: "editedbyid",
      Header: "Edited By",
      accessor: "editedbyid",
      Cell: d => this.getValueFromArray(d.original.editedbyid, this.state.user)
    },
    {
      Header: "Edited On",
      accessor: "createdAt",
      Cell: d => this.date(d.original.createdAt)
    }
  ];

  date = d => {
    let date = new Date(d);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();

    return day + "-" + month + "-" + year + " " + h + ":" + m + ":" + s;
  };

  getValueFromArray = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  render() {
    const { BehGoalEdit, TechGoalEdit } = this.state;
    // console.log(BehGoalEdit);
    return (
      <section>
        <div className="container contianer-sm-fluid mt-100">
          <div className="row justify-content-center align-items-center text">
            <div className="col-lg-10 col-md-12 col-12">
              <div className="content-border1 px-lg-4 px-2 py-4">
                <div className="col-12 my-2">
                  <h3>Behavioural IDP Review History</h3>
                  <div id="appendlogdatatablebehavioural"></div>
                  <div
                    id="example_wrapper"
                    class="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="dataTables_length"
                          id="example_length"
                        ></div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          id="example_filter"
                          class="dataTables_filter"
                        ></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="co-12">
                        {BehGoalEdit && (
                          <Datatable
                            data={BehGoalEdit}
                            columnHeading={this.column}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-2">
                  <h3>Technical IDP Review History</h3>
                  <div id="appendlogdatatablebehavioural"></div>
                  <div
                    id="example_wrapper"
                    class="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="dataTables_length"
                          id="example_length"
                        ></div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          id="example_filter"
                          class="dataTables_filter"
                        ></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        {TechGoalEdit && (
                          <Datatable
                            data={TechGoalEdit}
                            columnHeading={this.column}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default IDPHistory;
