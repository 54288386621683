import React, { Component } from "react";
import { FormMiddleWare, SingleSelect, MultiSelect } from "../../components/Form";
import { Style } from "react-style-tag";
import Datatable from "../../components/Datatable/Datatable";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import Swal from 'sweetalert2';
import ValidationView from "../../components/Form/ValidationView";




// import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import Datatable from "../../components/Datatable/Datatable";
const tableName = `tbl_trainingprgm_map`;



class HrTraingprogramMapping extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            matrixType: null,
            alertVisible: false,
            updateData: false,
            showDatatable: true,
            minScoreOption: [{ label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            ],
        }
    }

    async componentWillMount() {
        try {
            const adminId = await localStorage.getItem('adminId');
            let { data } = await MasterMiddleWare.gettrainingprgmmap(tableName, adminId)
            // console.log(data,"data");

            const vdhyaProgramresult = await HrMiddleWare.getFreedom(
                "*",
                "tbl_mapping_page",
                `categoryId= 310 and customerid=807`,
                1, 1);
            let filtereddata
            console.log(vdhyaProgramresult, "vdhyaProgramresult");
            await Promise.all(data.map((ival) => {
                filtereddata = vdhyaProgramresult.data.filter(item => ival.vidhyaProgramId.some(item2 => item.pageId == item2))
                // console.log(filtereddata, "filtereddata");
            }))
            data.map((ival) => {
                filtereddata.map((jval) => {
                    ival.trainingprograms.push(jval.subCategoryName)
                })
            })

            await Promise.all(data.map((ival) => {
                let trainingprograms = "";
                ival.trainingprograms.map(values => (trainingprograms += `${values},`));
                ival.trainingprograms = trainingprograms.replace(/,\s*$/, "");

            }))


            this.setState({
                adminId,
                data,
                vdhyaProgramresult
            });

        } catch (error) {
            console.log(error);
        }

    }

    column = [
        {
            Header: 'Si No',
            accessor: 'si no',
            Cell: d => this.siNo(d)
        },
        {
            Header: 'Competency',
            accessor: 'competencyname'
        },
        {
            Header: 'Trainingprogram',
            accessor: 'trainingprograms'
        },
        {
            Header: 'CompetencyType',
            accessor: 'matrixType'
        },
        {
            Header: 'MinScore',
            accessor: 'Score'
        },
        {
            Header: 'Edit',
            accessor: 'edit',
            Cell: d => this.edit(d)
        },
        {
            Header: 'Delete',
            accessor: 'delete',
            Cell: d => this.delete(d, tableName)
        }
    ];

    siNo = d => {
        return <label>{d.index + 1}</label>;
    };

    handleRadioInput = async e => {
        let { data } = this.state
        this.setState({ [e.target.name]: e.target.value });
        const targetValue = e.target.value;
        const adminId = this.state.adminId;
        try {
            let { data: yearOptions } = await MappingMiddleware.getYear();
            const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(targetValue, adminId);


            // const competencyOptions = competencyOption.filter(option1 =>
            //     !data.some(option2 => option1.value === Number(option2.value))
            // );


            // if (competencyOptions) {
            this.setState({
                yearOptions,
                competencyOptions,
                competencySelected: null,
                CmpId: null
            });
            // }
        } catch (error) {
            console.error(error);
        }
    };

    compSelect = async selectedOption => {
        let { data, matrixType, yearSelected } = this.state
        this.setState({
            competencySelected: selectedOption,
            compvalue: selectedOption.value
        });
        if (selectedOption) {
            // let filter = 
            let PreScore = []
            if (data && data.length) {
                data.map((ival) => {
                    if (ival.matrixType === matrixType && yearSelected.value === ival.yearId && ival.competencyname === selectedOption.label) {
                        PreScore.push(ival.Score)
                    }
                })
            }
            console.log(PreScore, "PreScore");
            let minScoreOption = [{ label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            ]
            if (PreScore) {
                let filter = minScoreOption.filter(x => !PreScore.some(y => x.value == y))
                console.log(filter, "filter");
                this.setState({ minScoreOption: filter })
            }
        }
        // let { adminId } = this.state
        // const { data: programsOption } = await MasterMiddleWare.selectBoxTrainingProgram(adminId);
        // console.log(programsOption, "programsOption");
        // this.setState({
        //     programsOption
        // });
    };

    classroomProgramsSelect = selectedOption => {
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");

            // console.log(selectedOption, "selectedOption");
            // console.log(selectedValue, "selectedValue");

            this.setState({
                classroomProgramsSelected: selectedOption,
                classroomProgramsId: selectedValue
            });
        } else {
            this.setState({ behThemeSelected: "", behThemeId: "" });
        }
    };

    elearnProgramsSelect = selectedOption => {
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");

            // console.log(selectedOption, "selectedOption");
            // console.log(selectedValue, "selectedValue");

            this.setState({
                elearnProgramsSelected: selectedOption,
                elearnProgramsId: selectedValue
            });
        } else {
            this.setState({ behThemeSelected: "", behThemeId: "" });
        }
    };


    yearSelectId = selectedOption => {
        this.setState({
            yearSelected: selectedOption,
            yearId: selectedOption.value,
            yearName: selectedOption.label,
        });
    };

    onSubmit = async () => {
        const { adminId, yearId, matrixType, compvalue, minScore, classroomProgramsId, elearnProgramsId } = this.state;
        if (this.validate(matrixType, 'errorCompetencytype', 'Please select competency type')) return true;
        if (this.validate(yearId, 'errorYear', 'Please select financial year')) return true;
        if (this.validate(compvalue, 'errorCompetency', 'Please select competency')) return true;
        if (this.validate(minScore, 'errorminScore', 'Please select minimum score')) return true;
        // if (this.validate(classroomProgramsId, 'errorclassroomPrograms', 'Please select classroom training programs')) return true;
        // if (this.validate(elearnProgramsId, 'errorElearnPrograms', 'Please select elearn training programs')) return true;
        // console.log(matrixType, "matrixType", yearId, "yearId", compvalue, "compvalue", minScore, "minScore");
        // console.log(classroomProgramsId, "classroomProgramsId", elearnProgramsId, "elearnProgramsId");
        let programsId
        if (classroomProgramsId && elearnProgramsId) {
            programsId = classroomProgramsId + "," + elearnProgramsId
        } else if (classroomProgramsId) {
            programsId = classroomProgramsId
        } else if (elearnProgramsId) {
            programsId = elearnProgramsId
        }
        console.log(programsId, "programsId");

        // return false
        const formData = new FormData();
        formData.append('matrixType', matrixType);
        formData.append('Competency', compvalue);
        formData.append('Score', minScore);
        formData.append('trainingprograms', programsId);
        formData.append('yearId', yearId);
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        formData.append('status', 'active');
        console.log([...formData], "formdata");
        // const previousData = [...this.state.data];
        // return false
        try {
            // console.log(tableName, "tableName");
            // return false
            const result = await MasterMiddleWare.createMaster(tableName, formData);
            console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram mapping has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    matrixType: '',
                    competencySelected: '',
                    programsSelected: '',
                    yearSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            // this.setState({
            //     data: previousData
            // });
            console.log(error);
        }
    };

    buttonEdit = async (value) => {
        // console.log(value,"value");
        let { departmentOptions, employeeName, employeeOptions, yearId, designationOptions, groupCompanyOptions,
            locationOptions, workLevelOptions, jobRoleOptions, behThemeOptions, techThemeOptions, minScoreOption, vdhyaProgramresult, ProgramNames
        } = this.state

        let { competencyOptions, programsOption, adminId } = this.state
        const index = value.index;
        const previousData = [...this.state.data];
        const getData = { ...previousData[index] };

        let TYPE = getData.matrixType
        let trainingprogramsId = getData.trainingprogramsId.split(",")
        let programsSelected = [];
        let classroomProgramsSelected = [];
        let elearnProgramsSelected = [];

        trainingprogramsId.map(async (kval) => {
            if (TYPE === "Behavioral") {
                const { data } = await MasterMiddleWare.selectBoxTrainingProgram(
                    adminId
                );
                // console.log(data,"data");
                if (data && vdhyaProgramresult) {
                    await this.setState({ classroomprgmOptions: data });
                    data.map((jval) => {
                        if (jval.value == parseInt(kval)) {
                            classroomProgramsSelected.push(jval)
                        }
                    })
                    let selectedValue = "";
                    classroomProgramsSelected.map(values => (selectedValue += `${values.value},`));
                    selectedValue = selectedValue.replace(/,\s*$/, "");
                    await this.setState({ classroomProgramsId: selectedValue });

                    vdhyaProgramresult.data.map((jval) => {
                        if (jval.pageId == parseInt(kval)) {
                            let label = jval.subCategoryName
                            let value = jval.pageId
                            elearnProgramsSelected.push({ label, value })
                        }
                    })
                    let selectedValue1 = "";
                    elearnProgramsSelected.map(values => (selectedValue1 += `${values.value},`));
                    selectedValue1 = selectedValue1.replace(/,\s*$/, "");
                    await this.setState({ elearnProgramsId: selectedValue1 });
                    this.GetVidhyaTrainingPrograms()
                }
            } else {
                const { data } = await MasterMiddleWare.selectBoxTrainingProgram(
                    adminId
                );
                if (data) {
                    await this.setState({ classroomprgmOptions: data });
                    data.map((jval) => {
                        if (jval.value == parseInt(kval)) {
                            classroomProgramsSelected.push(jval)
                        }
                    })
                    let selectedValue = "";
                    classroomProgramsSelected.map(values => (selectedValue += `${values.value},`));
                    selectedValue = selectedValue.replace(/,\s*$/, "");
                    await this.setState({ classroomProgramsId: selectedValue });

                    vdhyaProgramresult.data.map((jval) => {
                        if (jval.pageId == parseInt(kval)) {
                            let label = jval.subCategoryName
                            let value = jval.pageId
                            elearnProgramsSelected.push({ label, value })
                        }
                    })
                    let selectedValue1 = "";
                    elearnProgramsSelected.map(values => (selectedValue1 += `${values.value},`));
                    selectedValue1 = selectedValue1.replace(/,\s*$/, "");
                    await this.setState({ elearnProgramsId: selectedValue1 });

                    this.GetVidhyaTrainingPrograms()

                }
            }

        })


        let { data: yearOptions } = await MappingMiddleware.getYear();
        let yearselected = yearOptions.find(x => x.value == getData.yearId)
        const { data: competencyOption } = await MasterMiddleWare.selectBoxCompetency(TYPE, adminId);
        let competencySelected = competencyOption.find(x => x.value == parseInt(getData.value))
        let minScoreSelected = minScoreOption.find(x => x.value == parseInt(getData.Score))
        // console.log(minScoreSelected,"minScoreSelected");


        this.setState({
            index,
            updateId: getData.id,
            yearId: getData.yearId,
            compvalue: getData.value,
            minScore: getData.Score,
            matrixType: TYPE,
            programsId: getData.trainingprograms,
            programsSelected: programsSelected,
            competencySelected: competencySelected,
            elearnProgramsSelected: elearnProgramsSelected,
            classroomProgramsSelected: classroomProgramsSelected,
            minScoreSelected: minScoreSelected,
            yearSelected: yearselected,
            alertVisible: false,
            updateData: true,
        });
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    onUpdate = async () => {
        const { adminId, yearId, matrixType, compvalue, updateId, minScore, classroomProgramsId, elearnProgramsId } = this.state;
        if (this.validate(matrixType, 'errorCompetencytype', 'Please select competency type')) return true;
        if (this.validate(yearId, 'errorYear', 'Please select financial year')) return true;
        if (this.validate(compvalue, 'errorCompetency', 'Please select competency')) return true;
        // if (this.validate(programsId, 'errorPrograms', 'Please select training programs')) return true;
        if (this.validate(minScore, 'errorminScore', 'Please select minimum score')) return true;
        let programsId
        if (classroomProgramsId && elearnProgramsId) {
            programsId = classroomProgramsId + "," + elearnProgramsId
        } else if (classroomProgramsId) {
            programsId = classroomProgramsId
        } else if (elearnProgramsId) {
            programsId = elearnProgramsId
        }
        console.log(programsId, "programsId");

        const formData = new FormData();
        formData.append('matrixType', matrixType);
        formData.append('Competency', compvalue);
        formData.append('Score', minScore);
        formData.append('trainingprograms', programsId);
        formData.append('yearId', yearId);
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        formData.append('status', 'active');
        console.log([...formData], "formdata");

        // const previousData = [...this.state.data];
        // return false
        try {
            // console.log(tableName, "tableName");
            // return false
            const result = await MasterMiddleWare.updateMaster(tableName, updateId, formData);
            console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram mapping has been Updated',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    matrixType: '',
                    competencySelected: '',
                    programsSelected: '',
                    yearSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            // this.setState({
            //     data: previousData
            // });
            console.log(error);
        }
    };

    minScoreSelect = async selectedOption => {
        this.setState({
            minScoreSelected: selectedOption,
            minScore: selectedOption.value
        });
        let { adminId } = this.state
        const { data: classroomprgmOptions } = await MasterMiddleWare.selectBoxTrainingProgram(adminId);
        // console.log(classroomprgmOptions, "programsOption");
        this.setState({
            classroomprgmOptions
        });
        this.GetVidhyaTrainingPrograms()
    };

    GetVidhyaTrainingPrograms = async () => {

        try {
            // geting cource list
            const result = await HrMiddleWare.getFreedom(
                "*",
                "tbl_mapping_page",
                `categoryId= 310 and customerid=807`,
                1, 1);
            console.log(result, "resultresult");

            // let result = await HrMiddleWare.getCoursesList(JSON.parse(this.state.vidhyaUser), 310);
            // console.log(result, "result");
            // console.log(result.data, 'dat..');
            if (result && result.data) {
                this.setState({ vidhyaClusterData: result.data })
                let vidhyaClusterData = result.data
                let ProgramNames = []
                if (vidhyaClusterData) {
                    let wait = await vidhyaClusterData.map((ival) => {
                        if (ival.subCategoryName && ival.pageId) {
                            let label = ival.subCategoryName
                            let value = ival.pageId
                            ProgramNames.push({ label, value })
                        }
                    })
                    Promise.all(wait)
                    this.setState({ ProgramNames })
                }
            }

        } catch (error) { console.log(error) }
    }

    render() {
        // let { userData, section1Data } = this.state
        const { errorCompetency, competencySelected, updateData, programsOption, competencyOptions, programsSelected, errorPrograms, yearOptions, yearSelected, errorYear, errorCompetencytype,
            matrixType, showDatatable, data, minScoreSelected, minScoreOption, errorminScore, ProgramNames, errorclassroomPrograms, errorElearnPrograms,
            classroomprgmOptions, elearnProgramsSelected, classroomProgramsSelected } = this.state;
        // console.log(ProgramNames, "ProgramNames");
        return (
            <React.Fragment>
                <section>
                    <div className="mt-5 container container -sm-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-10 col-md-12 col-sm-12 col-12 text my-5">
                                <div className="content-border1 ">
                                    <div className="m-2 my-3 text-center">
                                        <h3>Training Program</h3>
                                    </div>
                                    <div className="box-tools pull-right d-none">
                                        <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                                            <i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                    <hr style={{ borderTop: "2px dashed var(--input-border-color)" }} />

                                    <div className="row justify-content-between px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Competency Type</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <div className="d-block d-lg-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <input type="radio" id="Behavioral" name="matrixType" value="Behavioral" checked={matrixType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                                                    <label className='formLabel'>
                                                        &nbsp;Behavioural
                                                    </label>
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div className="d-flex align-items-center">
                                                    <input type="radio" id="Technical" name="matrixType" value="Technical" checked={matrixType === 'Technical' && true} onChange={this.handleRadioInput} />
                                                    <label className='formLabel'>
                                                        &nbsp;Techinical
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 my-2">
                                            <ValidationView errorname={errorCompetencytype} />
                                        </div>
                                    </div>
                                    <div className="border33 my-3" />
                                    <div className="row justify-content-between px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Financial Year</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <SingleSelect
                                                handleChange={this.yearSelectId}
                                                options={yearOptions}
                                                selectedService={yearSelected}
                                            />
                                        </div>
                                        <div className="col-12 my-2">
                                            {/* <span className="errorMsg">{errorYear}</span> */}
                                            <ValidationView errorname={errorYear} />

                                        </div>
                                    </div>
                                    <div className="border33 my-3" />
                                    <div className="row justify-content-between px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Competency</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <SingleSelect handleChange={this.compSelect} options={competencyOptions} selectedService={competencySelected} />
                                        </div>
                                        <div className="col-12 my-2">
                                            {/* <span className="errorMsg">{errorCompetency}</span> */}
                                            <ValidationView errorname={errorCompetency} />

                                        </div>
                                    </div>
                                    <div className="border33 my-33" />

                                    <div className="row justify-content-between px-3 px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Min Score</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <SingleSelect handleChange={this.minScoreSelect} options={minScoreOption} selectedService={minScoreSelected} />
                                        </div>
                                        <div className="col-12 my-2">
                                            {/* <span className="errorMsg">{errorminScore}</span> */}
                                            <ValidationView errorname={errorminScore} />

                                        </div>
                                    </div>
                                    <div className="border33 my-3" />
                                    <div className="row justify-content-between px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Classroom training programs</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <MultiSelect handleChange={this.classroomProgramsSelect} options={classroomprgmOptions} selectedService={classroomProgramsSelected} />
                                        </div>
                                        <div className="col-12 my-2">
                                            {/* <span className="errorMsg">{errorclassroomPrograms}</span> */}
                                            <ValidationView errorname={errorclassroomPrograms} />

                                        </div>
                                    </div>
                                    <div className="border33 my-3" />
                                    <div className="row justify-content-between px-3 align-items-start my-2">
                                        <div className="col-lg-1 d-none d-lg-block" />
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0 px-md-5">
                                            <label className="formLabel" htmlFor="indicators">Elearn training programs</label>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                                            <MultiSelect handleChange={this.elearnProgramsSelect} options={ProgramNames} selectedService={elearnProgramsSelected} />
                                        </div>
                                        <div className="col-12 my-2">
                                            {/* <span className="errorMsg">{errorElearnPrograms}</span> */}
                                            <ValidationView errorname={errorElearnPrograms} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-evenly align-items-center">
                                        <div className="col-12 text-end px-3">
                                            {!updateData ? (
                                                <button type="button" className="submit-btn" onClick={this.onSubmit}>
                                                    Submit
                                                </button>
                                            ) : (
                                                <button type="button" className="submit-btn" onClick={this.onUpdate}>
                                                    Update
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Style>
                    {`/*
      Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
      Template Version: 1.0
      Author: Carlos Alvarez
      Website: http://blacktie.co
      Premium: http://www.gridgum.com
      */
      
      /* Import fonts */
      @import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);
      
      /* BASIC THEME CONFIGURATION */
      .filepond{
        height: 82px;
        background-color: #f1f1f1;
        border-radius: 20px;
        width: 300px;
      }
      
      .filepond--credits{
        color:#f7f7f7;
      }
      
      .filepond--root :not(text) {
        font-size: 13px;
      }
      
      .multi:hover{
        color: limegreen;
      }
      #main-content {
        margin-left: 50px !important;
        margin-top: 50px
    }
    
     
    @media only screen and (max-width: 600px) {
        .wrapper {
          margin-top: 60px !important; 
        }
      }
      
      
      `}

                </Style >
            </React.Fragment>
        );

    }
}

export default HrTraingprogramMapping;