import React, { Component } from "react";
import moment from "moment";
import SingleSelect from "../../components/Form/SingleSelect";
import MultiSelect from "../../components/Form/MultiSelect";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import ValidationView from "../../components/Form/ValidationView";
import DatePicker from "../../components/Form/DatePicker";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Swal from "sweetalert2";
import "./dateisues.css";
import Datatable from "../../components/Datatable/Datatable";
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import { CSVLink } from "react-csv";
import { ACCESS_POINT } from "../../config";


const tableName = "tbl_users";
class Hradduser extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: null,
      groupCompanyOptions: [],
      groupCompanySelected: null,
      groupCompanyId: null,
      groupCompanyName: null,
      errorGroupCompanySelected: null,
      userType: null,
      errorUserType: null,
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentName: null,
      errorDepartmentSelected: null,
      designationOptions: [],
      designationSelected: null,
      designationId: null,
      designationName: null,
      errorDesignationSelected: null,
      updateData: false,
      firstName: null,
      errorFirstName: null,
      lastName: null,
      errorLastName: null,
      userName: null,
      errorUserName: null,
      password: null,
      errorPassword: null,
      address: null,
      errorAddress: null,
      locationOptions: [],
      locationSelected: null,
      locationId: null,
      locationName: null,
      errorLocationSelected: null,
      mobileNumber: null,
      errorMobileNumber: null,
      telephoneNumber: null,
      errorTelephoneNumber: null,
      startDate: new Date(),
      age: null,
      errorAge: null,
      dateOfJoining: new Date(),
      email: null,
      errorEmail: null,
      textType: [],
      errorTextType: null,
      behThemeOptions: [],
      behThemeSelected: null,
      behThemeId: null,
      techThemeOptions: [],
      techThemeSelected: null,
      techThemeId: null,
      jobRoleOptions: [],
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleName: null,
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelName: null,
      qualification: null,
      errorQualification: null,
      file: null,
      errorEmployeephoto: null,
      disable: false,
      path: false,
      uodateUserId: "",
      updateUserImage: "",
      excelHead: [
        { label: "SI.NO", key: "index" },
        { label: "Full Name", key: "name" },
        { label: "User Name", key: "user_name" },
        { label: "Address", key: "address" },
        { label: "Mobile", key: "mobile_no" },
        { label: "Dob(Age)", key: "age" },
        { label: "Date Of Joining", key: "doj" },
        { label: "Designation", key: "designation" },
        { label: "Qualification", key: "qualification" },
        { label: "Matrix Type", key: "matrixType" },
        { label: "Work Level", key: "worklevel" },
        { label: "Job Role", key: "jobRole" },
      ],
      excelHead2: [
        { label: "department", key: "id" },
        { label: "department name", key: "department" },
        { label: "status", key: "status" },
      ],
      excelHead3: [
        { label: "designation", key: "value" },
        { label: "designation name", key: "label" },
      ],
      excelHead4: [
        { label: "matrixType", key: "label" },
      ],
      excelHead5: [
        { label: "userTheme", key: "value" },
        { label: "userTheme name", key: "label" },
      ],
      excelHead6: [
        { label: "worklevel", key: "value" },
        { label: "worklevel name", key: "label" },
      ],
      excelHead7: [
        { label: "jobRole", key: "value" },
        { label: "jobRole name", key: "label" },
      ],
      excelHead8: [
        { label: "location", key: "value" },
        { label: "location name", key: "label" },
      ],
      excelHead9: [
        { label: "userType name", key: "label" },
        { label: "userTypeId", key: "value" },
        { label: "is_manager", key: "value3" },
        { label: "is_employee", key: "value1" },
        { label: "is_hr", key: "value2" },
      ],
      usertypeidopt: [
        { label: "Employee", value: 5, value1: 1 },
        { label: "Hr", value: 6, value2: 1 },
        { label: "Manager", value: 2, value3: 1 },
      ],
      behaviourltechnical: [
        { label: "Behavioral", value: 1 },
        { label: "Technical", value: 2 },
      ],
      excelHeadmultidata: [
        // { label: "SI.NO", key: "id" },
        { label: "name", key: "FirstName" },
        { label: "last_name", key: "LastName" },
        { label: "user_name", key: "Username" },
        { label: "password", key: "Password" },
        { label: "address", key: "Address" },
        { label: "mobile_no", key: "Mobile_no" },
        { label: "telphone", key: "Telphone" },
        { label: "dob", key: "Date_Of_Birth" },
        { label: "email_id", key: "Email_id" },
        { label: "age", key: "Age" },
        { label: "doj", key: "Date_Of_Joining" },
        { label: "designation", key: "Designation" },
        { label: "qualification", key: "Qualification" },
        { label: "matrixType", key: "CompetencyType" },
        { label: "worklevel", key: "Worklevel" },
        { label: "jobRole", key: "JobRole" },
        { label: "userTheme", key: "userTheme" },
        { label: "location", key: "Location" },
        { label: "department", key: "Department" },
        { label: "userTypeId", key: "Usertype" },
        { label: "is_employee", key: "Employee" },
        { label: "is_manager", key: "Manager" },
        { label: "is_hr", key: "Hr" },
      ],
      addUserColumn: [
        { label: "FirstName", value: 1, type: "text" },
        { label: "LastName", value: 2, type: "text" },
        { label: "Username", value: 3, type: "text" },
        { label: "Password", value: 4, type: "text" },
        { label: "Address", value: 5, type: "textarea" },
        { label: "Mobile_no", value: 6, type: "number" },
        { label: "Telphone", value: 7, type: "number" },
        { label: "Date_Of_Birth", value: 8, type: "date" },
        { label: "Email_id", value: 9, type: "text" },
        { label: "Age", value: 10, type: "number" },
        { label: "Date_Of_Joining", value: 11, type: "date" },
        { label: "Qualification", value: 12, type: "text" },
        { label: "Designation", value: 13, type: "selectbox", selectOptions: [] },
        {
          label: "CompetencyType", value: 14, type: "multiSelectbox", selectOptions: [
            { label: "Behavioral", value: 1 },
            { label: "Technical", value: 2 }
          ]
        },
        { label: "userTheme", value: 15, type: "multiSelectbox", selectOptions: [] },
        { label: "Worklevel", value: 16, type: "selectbox", selectOptions: [] },
        { label: "JobRole", value: 17, type: "selectbox", selectOptions: [] },
        { label: "Location", value: 18, type: "selectbox", selectOptions: [] },
        { label: "Department", value: 19, type: "selectbox", selectOptions: [] },
        { label: "Usertype", value: 20, type: "selectbox", selectOptions: [] },
        { label: "Employee", value: 21, type: "selectbox", selectOptions: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }] },
      ],
      statusArray: [],
      multyIndicatorsAdd: true,
      userAddRow: "",
      RowCount: "",
      showDatatable: true,
      submitDataAlert: false,
    };
  }
  column = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,
      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Full Name </span>,
      accessor: "name",
      Cell: (d) => {
        return (d.original.name + "  " + d.original.last_name)
      }

    },
    // {
    //   Header: props => <span style={{ fontWeight: "bold" }}> Last Name </span>,
    //   accessor: "last_name"
    // },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> User Name </span>,
      accessor: "user_name"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Address </span>,
      accessor: "address"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Mobile </span>,
      accessor: "mobile_no"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Dob(Age)  </span>,
      accessor: "age",
      Cell: (d) => {
        return (d.original.dob + "" + " (" + d.original.age + ") ")
      }
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Date Of Joining </span>,
      accessor: "doj"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Designation </span>,
      accessor: "designation",
      Cell: (d) => this.getValueFromArray(d.original.designation, this.state.designationOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Qualification </span>,
      accessor: "qualification",
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Matrix Type </span>,
      accessor: "matrixType",
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Work Level </span>,
      accessor: "worklevel",
      Cell: (d) => this.getValueFromArray(d.original.worklevel, this.state.workLevelOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Job Role </span>,
      accessor: "jobRole",
      Cell: (d) => this.getValueFromArray(d.original.worklevel, this.state.jobRoleOptions)
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }

  ]

  async componentDidMount() {
    this.MainFun();
  }
  MainFun = async () => {
    const path = window.location.pathname;
    console.log(path);
    if (path === "/hr/Users") {
      this.setState((prevState) => ({
        path: !prevState.path,
      }));
    }
    const adminId = await localStorage.getItem("adminId");
    console.log(adminId);
    this.setState({ adminId });
    this.getSelectBoxMaster(
      adminId,
      "tbl_gcompanies",
      "id",
      "groupcompanies",
      "groupCompanyOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_department",
      "id",
      "department",
      "departmentOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_designation",
      "id",
      "designation",
      "designationOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_location",
      "id",
      "location",
      "locationOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_worklevel",
      "id",
      "worklevel",
      "workLevelOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_jobRole",
      "id",
      "jobRoleName",
      "jobRoleOptions"
    );

    const groupcompanyid = localStorage.getItem("groupcompId");
    // console.log(groupcompanyid);
    this.setState({ groupCompanyId: groupcompanyid });
    var userid = localStorage.getItem("userId");
    // console.log(userid);
    // const data = await MasterMiddleWare.
    let data = await HrMiddleWare.getRandomData(
      "tbl_users",
      `groupcomp = ${groupcompanyid}`,
      "*"
    );
    const { data: data1dept } = await MasterMiddleWare.getMaster('tbl_department', adminId);

    // console.log(data);
    this.setState({ data: data.data, data1dept })

    //style issue
    document.getElementsByClassName("inputtest")[0].style.width = "inherit";
    // document.getElementsByClassName("inputtest")[0].style.height = "inherit";
    // document.getElementsByClassName("inputtest")[0].style.opacity = "inherit";
  }

  handleCheckInput = async e => {
    const { textType, behThemeOptions, techThemeOptions } = this.state;
    const adminId = this.state.adminId;
    const value = e.target.value;

    if (textType.length > 0) {
      if (textType.includes(value)) {
        let newArray = textType.filter(e => e !== value);
        this.setState({ textType: newArray });
      } else {
        textType.push(value);
        this.setState({ textType });
      }
    } else {
      textType.push(value);
      this.setState({ textType });
    }
    try {
      textType.map(async (val, index) => {
        if (val === "Behavioral") {
          if (behThemeOptions.length === 0) {
            console.log("in");

            const { data } = await MasterMiddleWare.getThemeForSelect(
              adminId,
              val
            );
            if (data) {
              this.setState({ behThemeOptions: data });
            }
          }
        } else {
          if (techThemeOptions.length === 0) {
            console.log("in");
            const { data } = await MasterMiddleWare.getThemeForSelect(
              adminId,
              val
            );
            if (data) {
              this.setState({ techThemeOptions: data });
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };


  buttonEdit = value => {
    let { departmentOptions, designationOptions, locationOptions, workLevelOptions, jobRoleOptions, behThemeOptions, techThemeOptions, adminId } = this.state
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };

    let TYPE = getData.matrixType.split(",")
    let userThemes = getData.userTheme.split(",")
    let behaThemSelected = [];
    let techThemSelected = [];

    userThemes.map((kval) => {
      TYPE.map(async (val, index) => {
        if (val === "Behavioral") {
          const { data } = await MasterMiddleWare.getThemeForSelect(
            adminId,
            val
          );
          if (data) {
            await this.setState({ behThemeOptions: data });
            data.map((jval) => {
              if (jval.value == parseInt(kval)) {
                behaThemSelected.push(jval)
              }
            })
          }
        } else {
          const { data } = await MasterMiddleWare.getThemeForSelect(
            adminId,
            val
          );
          if (data) {
            await this.setState({ techThemeOptions: data });
            data.map((jval) => {
              if (jval.value == parseInt(kval)) {
                techThemSelected.push(jval)
              }
            })

          }
        }
      });
    })

    var originalDOB = getData.dob;
    var originalDOJ = getData.doj
    var DOB = new Date(originalDOB);
    var DOJ = new Date(originalDOJ);
    let departSelected = departmentOptions.find(x => x.value == parseInt(getData.department))
    let designatSelected = designationOptions.find(x => x.value == getData.designation)
    let locatSelected = locationOptions.find(x => x.value == getData.location)
    let workLevelselected = workLevelOptions.find(x => x.value == getData.worklevel)
    let jobrollSelected = jobRoleOptions.find(x => x.value == getData.jobRole)


    this.setState({
      index,
      uodateUserId: getData.id,
      updateUserImage: getData.image,
      file: getData.image,
      groupCompanyOptions: getData.groupCompanyOptions,
      groupCompanySelected: getData.groupCompanySelected,
      updateData: getData.updateData,
      errorGroupCompanySelected: '',
      userType: getData.userTypeId,
      textType: getData.matrixType,
      errorUserType: '',
      departmentOptions: departmentOptions,
      departmentSelected: departSelected,
      errorDepartmentSelected: '',
      designationOptions: designationOptions,
      designationSelected: designatSelected,
      errorDesignationSelected: '',

      firstName: getData.name,
      errorFirstName: '',
      lastName: getData.last_name,
      errorLastName: '',
      userName: getData.user_name,
      errorUserName: '',
      address: getData.address,
      errorAddress: '',
      password: getData.password,
      errorPassword: '',
      locationSelected: locatSelected,
      errorLocationSelected: '',
      locationOptions: locationOptions,
      mobileNumber: getData.mobile_no,
      errorMobileNumber: '',
      telephoneNumber: getData.telphone,
      errorTelephoneNumber: '',
      startDate: DOB,
      age: getData.age,
      errorAge: '',
      dateOfJoining: DOJ,
      email: getData.email_id,
      errorEmail: '',
      // textType: getData.textType,
      errorTextType: '',
      behThemeOptions: behThemeOptions,
      behThemeSelected: behaThemSelected,
      techThemeOptions: techThemeOptions,
      techThemeSelected: techThemSelected,
      workLevelOptions: workLevelOptions,
      workLevelSelected: workLevelselected,
      errorWorkLevelSelected: '',
      jobRoleOptions: jobRoleOptions,
      jobRoleSelected: jobrollSelected,
      errorJobRoleSelected: '',
      qualification: getData.qualification,
      errorQualification: '',
      employeePhoto: getData.employeePhoto,
      errorEmployeePhoto: getData.errorEmployeePhoto,
      alertVisible: false,
      updateData: true
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

  };
  onUpdate = async () => {
    const {
      groupCompanyId,
      userType,
      departmentId,
      departmentSelected,
      designationSelected,
      workLevelSelected,
      jobRoleSelected,
      locationSelected,
      designationId,
      firstName,
      lastName,
      userName,
      address,
      password,
      locationId,
      mobileNumber,
      telephoneNumber,
      age,
      email,
      textType,
      jobRoleId,
      workLevelId,
      file,
      qualification,
      changeJoiningDate,
      startDate,
      behThemeId,
      techThemeId,
      adminId,
    } = this.state;


    if (this.validate(firstName, "errorFirstName", "Please Enter First Name"))
      return true;
    if (this.validate(lastName, "errorLastName", "Please Enter Last Name"))
      return true;
    if (this.validate(userName, "errorUserName", "Please Enter UserName"))
      return true;

    if (this.validate(password, "errorPassword", "Please Enter password"))
      return true;
    if (this.validate(address, "errorAddress", "Please Enter Address"))
      return true;

    if (
      this.validate(
        mobileNumber,
        "errorMobileNumber",
        "Please Enter Mobile Number"
      )
    )
      return true;

    if (
      this.validate(
        telephoneNumber,
        "errorTelephoneNumber",
        "Please Enter Telephone Number"
      )
    )
      return true;
    if (this.validate(age, "errorAge", "Please Enter Age")) return true;
    if (this.validate(email, "errorEmail", "Please Enter Email")) return true;
    // if (await this.validateEmail(email, "errorEmail", "Invalid Email-id"))
    //   return true;
    if (textType.length === 0) {
      this.setState({ errorTextType: "select competency Type" });
      return false;
    } else {
      this.setState({ errorTextType: "" });
    }

    if (
      this.validate(
        qualification,
        "errorQualification",
        "Please Enter Qualification"
      )
    )
      return true;
    if (this.validate(file, "errorfile", "Please Select Image")) return true;


    let matrixType = textType.toString();
    console.log(matrixType, "matrixType");
    let themesSelected = "";
    if (behThemeId) {
      console.log(behThemeId, "behThemeId");
      themesSelected += behThemeId + ",";
    }
    if (techThemeId) {
      themesSelected += techThemeId + ",";
    }
    themesSelected = themesSelected.replace(/(^,)|(,$)/g, "");
    let dob = moment(startDate).format("YYYY-MM-DD");
    let doj = moment(changeJoiningDate).format("YYYY-MM-DD");
    console.log(doj, "doj");

    // return false
    this.setState({ disable: true });

    const formData = new FormData();
    formData.append("name", firstName);
    formData.append("last_name", lastName);
    formData.append("user_name", userName);
    formData.append("password", password);
    formData.append("address", address);
    formData.append("mobile_no", mobileNumber);
    formData.append("telphone", telephoneNumber);
    formData.append("dob", dob);
    formData.append("email_id", email);
    formData.append("age", age);
    formData.append("doj", doj);
    formData.append("designation", designationSelected.value);
    formData.append("qualification", qualification);
    formData.append("image", file);
    formData.append("matrixType", matrixType);
    formData.append("worklevel", workLevelSelected.value);
    formData.append("jobRole", jobRoleSelected.value);
    if (behThemeId || techThemeId) {
      formData.append("userTheme", themesSelected);
    }
    formData.append("groupcomp", groupCompanyId);
    formData.append("location", locationSelected.value);
    formData.append("department", departmentSelected.value);
    formData.append("customerid", adminId);
    formData.append("adminId", adminId);
    formData.append("userTypeId", userType);
    // const previousData = [...this.state.data];

    if (userType === "4") {
      formData.append("is_manager", 1);
    } else if (userType === "5") {
      formData.append("is_employee", 1);
    } else if (userType === "6") {
      formData.append("is_hr", 1);
    }
    let ID = this.state.uodateUserId
    console.log([...formData]);
    // console.log(previousData, "previousData");
    try {
      const result = await MasterMiddleWare.updateMaster(tableName, ID, formData);
      console.log(result, "result");
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "User has been updated",
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          disable: false,
          firstName: "",
          lastName: "",
          userName: "",
          password: "",
          address: "",
          mobileNumber: "",
          telephoneNumber: "",
          dob: "",
          email: "",
          age: "",
          doj: "",
          designationId: "",
          qualification: "",
          file: "",
          matrixType: "",
          workLevelId: "",
          jobRoleId: "",
          themesSelected: "",
          locationId: "",
          departmentId: "",
          userType: "",
          groupCompanySelected: "",
          locationSelected: "",
          jobRoleSelected: "",
          designationSelected: "",
          workLevelSelected: "",
          departmentSelected: "",
          textType: [],
          behThemeId: "",
          techThemeId: "",
          techThemeSelected: "",
          behThemeSelected: "",
          startDate: ""
        });
        this.MainFun();
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  onSubmit = async () => {
    const {
      groupCompanyId,
      userType,
      departmentId,
      designationId,
      firstName,
      lastName,
      userName,
      address,
      password,
      locationId,
      mobileNumber,
      telephoneNumber,
      age,
      email,
      textType,
      jobRoleId,
      workLevelId,
      file,
      qualification,
      changeJoiningDate,
      startDate,
      behThemeId,
      techThemeId,
      adminId
    } = this.state;

    if (this.validate(userType, "errorUserType", "Please select UserType"))
      return true;
    if (
      this.validate(
        departmentId,
        "errorDepartmentSelected",
        "Please select Department"
      )
    )
      return true;
    if (
      this.validate(
        designationId,
        "errorDesignationSelected",
        "Please select Designation"
      )
    )
      return true;
    if (this.validate(firstName, "errorFirstName", "Please Enter First Name"))
      return true;
    if (this.validate(lastName, "errorLastName", "Please Enter Last Name"))
      return true;
    if (this.validate(userName, "errorUserName", "Please Enter UserName"))
      return true;
    if (
      await this.validateUserName(
        userName,
        "errorUserName",
        "Please Enter UserName"
      )
    )
      return true;
    if (this.validate(password, "errorPassword", "Please Enter password"))
      return true;
    if (this.validate(address, "errorAddress", "Please Enter Address"))
      return true;
    if (
      this.validate(
        locationId,
        "errorLocationSelected",
        "Please Select Location"
      )
    )
      return true;
    if (
      this.validate(
        mobileNumber,
        "errorMobileNumber",
        "Please Enter Mobile Number"
      )
    )
      return true;
    if (
      await this.validateMobile(
        mobileNumber,
        "errorMobileNumber",
        "Invalid mobile Number"
      )
    )
      return true;
    if (
      this.validate(
        telephoneNumber,
        "errorTelephoneNumber",
        "Please Enter Telephone Number"
      )
    )
      return true;
    if (this.validate(age, "errorAge", "Please Enter Age")) return true;
    if (this.validate(email, "errorEmail", "Please Enter Email")) return true;
    if (await this.validateEmail(email, "errorEmail", "Invalid Email-id"))
      return true;
    if (textType.length === 0) {
      this.setState({ errorTextType: "select competency Type" });
      return false;
    } else {
      this.setState({ errorTextType: "" });
    }
    if (
      this.validate(
        workLevelId,
        "errorWorkLevelSelected",
        "Please Select Work Level"
      )
    )
      return true;
    if (
      this.validate(jobRoleId, "errorJobRoleSelected", "Please Select JobRole")
    )
      return true;
    if (
      this.validate(
        qualification,
        "errorQualification",
        "Please Enter Qualification"
      )
    )
      return true;
    if (this.validate(file, "errorfile", "Please Select Image")) return true;

    let matrixType = textType.toString();
    let themesSelected = "";
    if (behThemeId) {
      themesSelected += behThemeId + ",";
    }
    if (techThemeId) {
      themesSelected += techThemeId + ",";
    }
    themesSelected = themesSelected.replace(/(^,)|(,$)/g, "");
    let dob = moment(startDate).format("YYYY-MM-DD");
    let doj = moment(changeJoiningDate).format("YYYY-MM-DD");

    this.setState({ disable: true });

    const formData = new FormData();
    formData.append("name", firstName);
    formData.append("last_name", lastName);
    formData.append("user_name", userName);
    formData.append("password", password);
    formData.append("address", address);
    formData.append("mobile_no", mobileNumber);
    formData.append("telphone", telephoneNumber);
    formData.append("dob", dob);
    formData.append("email_id", email);
    formData.append("age", age);
    formData.append("doj", doj);
    formData.append("designation", designationId);
    formData.append("qualification", qualification);
    formData.append("image", file);
    formData.append("matrixType", matrixType);
    formData.append("worklevel", workLevelId);
    formData.append("jobRole", jobRoleId);
    formData.append("userTheme", themesSelected);
    formData.append("groupcomp", groupCompanyId);
    formData.append("location", locationId);
    formData.append("department", departmentId);
    formData.append("customerid", adminId);
    formData.append("adminId", adminId);
    formData.append("userTypeId", userType);
    // const previousData = [...this.state.data];

    if (userType === "4") {
      formData.append("is_manager", 1);
    } else if (userType === "5") {
      formData.append("is_employee", 1);
    } else if (userType === "6") {
      formData.append("is_hr", 1);
    }
    // console.log([...formData]);
    console.log([...formData]);
    // console.log(previousData, "previousData");
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      console.log(result, "result");
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "User has been saved",
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          disable: false,
          firstName: "",
          lastName: "",
          userName: "",
          password: "",
          address: "",
          mobileNumber: "",
          telephoneNumber: "",
          dob: "",
          email: "",
          age: "",
          doj: "",
          designationId: "",
          qualification: "",
          file: "",
          matrixType: "",
          workLevelId: "",
          jobRoleId: "",
          themesSelected: "",
          locationId: "",
          departmentId: "",
          userType: "",
          groupCompanySelected: "",
          locationSelected: "",
          jobRoleSelected: "",
          designationSelected: "",
          workLevelSelected: "",
          departmentSelected: "",
          textType: [],
          behThemeId: "",
          techThemeId: "",
          techThemeSelected: "",
          behThemeSelected: "",
          startDate: ""
        });
        this.MainFun();
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  changeJoiningDate = date => {
    this.setState({
      dateOfJoining: date
    });
  };

  behThemeSelect = selectedOption => {
    if (selectedOption) {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      this.setState({
        behThemeSelected: selectedOption,
        behThemeId: selectedValue
      });
    } else {
      this.setState({ behThemeSelected: "", behThemeId: "" });
    }
  };

  techThemeSelect = selectedOption => {
    if (selectedOption) {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      this.setState({
        techThemeSelected: selectedOption,
        techThemeId: selectedValue
      });
    } else {
      this.setState({ techThemeSelected: "", techThemeId: "" });
    }
  };

  dateofBirth = e => {
    let dob = new Date(e);
    var today = new Date();
    var age = today.getFullYear() - dob.getFullYear();
    var m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    this.setState({ startDate: dob, age: age });
  };

  submitcsv = async (e) => {
    await this.setState({ btnDisablecsv: true })
    // const groupcompId = await localStorage.getItem('groupcompId');
    const { textType, competencyId, themeId, indicators, Description, adminId, files, groupCompanyId } = this.state;
    // console.log(files, "files");
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    formData.append('groupcomp', groupCompanyId)
    console.log(...formData, "formData");
    console.log(tableName, "tableName");
    // return false
    try {
      const result = await MasterMiddleWare.csvUploadhr(tableName, formData);
      console.log(result, "result");
      // const result = await MasterMiddleWare.createMaster(tableName, formData);
      let Timing = 3000
      if (result.data.Message == "success") {
        if (result.data.csvHeaderErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvHeaderErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else if (result.data.csvDataErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvDataErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else {

          Swal.fire({
            position: "center",
            type: "success",
            title: "User has been saved",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.setState({
          disable: false,
          firstName: "",
          lastName: "",
          userName: "",
          password: "",
          address: "",
          mobileNumber: "",
          telephoneNumber: "",
          dob: "",
          email: "",
          age: "",
          doj: "",
          designationId: "",
          qualification: "",
          file: "",
          matrixType: "",
          workLevelId: "",
          jobRoleId: "",
          themesSelected: "",
          locationId: "",
          departmentId: "",
          userType: "",
          groupCompanySelected: "",
          locationSelected: "",
          jobRoleSelected: "",
          designationSelected: "",
          workLevelSelected: "",
          departmentSelected: "",
          textType: [],
          behThemeId: "",
          techThemeId: "",
          techThemeSelected: "",
          behThemeSelected: "",
          startDate: "",
          btnDisablecsv: false,
          files: ''
        });
        setTimeout(() => {
          window.location.reload()
        }, Timing);
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleFileUpload = (fileItems) => {
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  singlemultiselect = (e, val) => {
    this.setState({
      multi: val
    });
  };

  handleSelectChange = async (value, rowIndex, columnLabel) => {
    let { adminId } = this.state
    console.log(value, "value");
    console.log(rowIndex, "rowIndex");
    console.log(columnLabel, "columnLabel");

    let CompetencyTypevalues = []
    if (columnLabel === "CompetencyType") {
      value.map((val) => {
        CompetencyTypevalues.push(val.label)
      })
    }
    let userTheme = "";
    if (columnLabel === "userTheme") {
      value.map((ival) => {
        userTheme += ival.value;
        userTheme += ',';
        // userTheme.push( `${ival.value}`)
      })
      userTheme = userTheme.replace(/(^,)|(,$)/g, "");
    }

    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "CompetencyType" ? CompetencyTypevalues : columnLabel === "userTheme" ? userTheme : value.value } : row
      )
    }));
    if (columnLabel === "CompetencyType") {
      const { data: worklevelOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_worklevel", adminId);
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, WorklevelOption: worklevelOptions } : row
        )
      }));

      const { data: userThemeOptions } = await MasterMiddleWare.selectBoxTheme(CompetencyTypevalues, adminId, value);
      // this.setState({userThemeOptions})
      // let behaviouralUserThemeOptions=[]
      // let technicalUserThemeOptions=[]
      // userThemeOptions.map((ival)=>{
      //   console.log(ival,"ival");
      //   if (ival.textType === "Behavioral") {
      //     behaviouralUserThemeOptions.push(ival)
      //   } else {
      //     technicalUserThemeOptions.push(ival)
      //   }
      // })
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, userThemeOption: userThemeOptions } : row
        )
      }));

    }
    if (columnLabel === "Worklevel") {
      const { data: jobRoleOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_jobRole", adminId);
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, JobRoleOption: jobRoleOptions } : row
        )
      }));
    }
    // if (columnLabel === "jobRole") {
    //   this.setState(prevState => ({
    //     statusArray: prevState.statusArray.map((row, index) =>
    //       index === rowIndex ? { ...row, userThemeOption: this.state.userThemeOptions } : row
    //     )
    //   }));
    // }
    if (columnLabel === "JobRole") {
      const { data: locationOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_location", adminId);
      console.log(locationOptions, "locationOptions");
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, LocationOption: locationOptions } : row
        )
      }));
    }
    if (columnLabel === "Location") {
      const { data: departmentOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_department", adminId);
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, DepartmentOption: departmentOptions } : row
        )
      }));
    }
    if (columnLabel === "Department") {
      const { data: userTypeIdOptions } = await MasterMiddleWare.selectBoxUserType("tbl_userType");
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, UsertypeOption: userTypeIdOptions } : row
        )
      }));
    }
  };

  addRow = (value) => {
    console.log(value, "values");
    let { userAddRow, statusArray } = this.state
    // this.setState({ statusArray: [] })
    let addIndex = value ? statusArray.length : 0

    const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

      Date_Of_Birth: "",
      Date_Of_Joining: "",
      FirstName: "",
      LastName: "",
      Username: "",
      Password: "",
      Address: "",
      Mobile_no: "",
      Telphone: "",
      Email_id: "",
      Age: "",
      Qualification: "",

      Designation: "",
      DesignationSelect: "",
      DesignationOption: this.state.designationOptions,
      CompetencyType: "",
      CompetencyTypeSelect: "",
      CompetencyTypeOption: [
        { label: "Behavioral", value: 1 },
        { label: "Technical", value: 2 }
      ],
      Worklevel: "",
      WorklevelSelect: "",
      WorklevelOption: [],
      JobRole: "",
      JobRoleSelect: "",
      JobRoleOption: [],
      userTheme: "",
      userThemeSelect: "",
      userThemeOption: [],
      // behaviouralUserThemeSelet:"",
      // behaviouralUserThemeOption:[],
      // technicalUserThemeSelect:"",
      // technicalUserThemeOption:[],
      Location: "",
      LocationSelect: "",
      LocationOption: [],
      Department: "",
      DepartmentSelect: "",
      DepartmentOption: [],
      Usertype: "",
      UsertypeSelect: "",
      UsertypeOption: [],
      Employee: "",
      EmployeeSelect: "",
      EmployeeOption: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }],
      Manager: 0,
      Hr: 0,
      index: value ? addIndex : index

    }));
    console.log(newItems, "newItems");
    this.setState(prevState => ({
      statusArray: [...prevState.statusArray, ...newItems]
    }));

    console.log(statusArray, "statusArray");
  }

  handleInputChange = (e, rowIndex, columnLabel) => {
    const { value } = e.target;
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel]: value } : row
      )
    }));
    if (columnLabel == "Date_Of_Birth") {
      let dob = new Date(value);
      var today = new Date();
      var age = today.getFullYear() - dob.getFullYear();
      var m = today.getMonth() - dob.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, Age: age } : row
        )
      }));
    }
  };

  addMultySubmit = async () => {
    let { statusArray, adminId, groupCompanyId } = this.state
    let statusArray1 = []
    console.log(statusArray, "statusArray");
    // return false
    statusArray.map((ival) => {
      let result = {}
      result.FirstName = ival.FirstName;
      result.LastName = ival.LastName;
      result.Username = ival.Username;
      result.Password = ival.Password;
      result.Address = ival.Address;
      result.Mobile_no = ival.Mobile_no;
      result.Telphone = ival.Telphone;
      result.Date_Of_Birth = ival.Date_Of_Birth;
      result.Email_id = ival.Email_id;
      result.Age = ival.Age;
      result.Date_Of_Joining = ival.Date_Of_Joining;
      result.Groupcompany = groupCompanyId;
      result.Designation = ival.Designation;
      result.Qualification = ival.Qualification;
      result.CompetencyType = ival.CompetencyType;
      result.Worklevel = ival.Worklevel;
      result.JobRole = ival.JobRole;
      result.userTheme = ival.userTheme;
      result.Location = ival.Location;
      result.Department = ival.Department;
      result.Usertype = ival.Usertype;
      result.Manager = ival.Manager;
      result.Employee = ival.Employee;
      result.Hr = ival.Hr;
      result.index = ival.index;
      statusArray1.push(result);
    })
    return new Promise((resolve, reject) => {
      this.setState({ btnDisablecsv: true, submitDataAlert: true });

      setTimeout(() => {
        this.setState({ submitDataAlert: false });
        resolve();
      }, 3000);
    })

      .then(() => {
        const formData = new FormData();
        formData.append("statusArray", JSON.stringify(statusArray1));
        // formData.append('groupcompId', groupcompId)
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        const previousData = [...this.state.data];
        return MasterMiddleWare.insertMuldidatahr(tableName, formData);
      })
      .then(result => {
        // try {
        //   const result = await MasterMiddleWare.insertMuldidatahr(tableName, formData);
        //   // console.log(result, "result");
        //   // return false

        if (result.data.Message == "success") {
          if (result.data.fillDataErrorMsg) {
            const maxLength = 29;
            const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
            console.log(wrappedErrorMsg, "wrappedErrorMsg");
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: wrappedErrorMsg,
              showConfirmButton: false,
              timer: 6000
            });
            // Swal.fire({
            //   position: 'center',
            //   type: 'warning',
            //   title: `${result.data.fillDataErrorMsg}`,
            //   showConfirmButton: false,
            //   timer: 2000

            // });
          } else {

            Swal.fire({
              position: "center",
              type: "success",
              title: "User has been saved",
              showConfirmButton: false,
              timer: 1500
            });
            this.getMaster(tableName);
            this.setState({
              alertVisible: true,
              btnDisablecsv: false,
              statusArray: "",
              userAddRow: ""
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
          }

        }
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  render() {
    const {
      groupCompanyOptions,
      groupCompanySelected,
      updateData,
      errorGroupCompanySelected,
      userType,
      errorUserType,
      departmentOptions,
      departmentSelected,
      errorDepartmentSelected,
      designationOptions,
      designationSelected,
      errorDesignationSelected,
      firstName,
      errorFirstName,
      lastName,
      errorLastName,
      userName,
      errorUserName,
      address,
      errorAddress,
      password,
      errorPassword,
      locationSelected,
      errorLocationSelected,
      locationOptions,
      mobileNumber,
      errorMobileNumber,
      telephoneNumber,
      errorTelephoneNumber,
      startDate,
      age,
      errorAge,
      dateOfJoining,
      email,
      errorEmail,
      textType,
      errorTextType,
      behThemeOptions,
      behThemeSelected,
      techThemeOptions,
      techThemeSelected,
      workLevelOptions,
      workLevelSelected,
      errorWorkLevelSelected,
      jobRoleOptions,
      jobRoleSelected,
      errorJobRoleSelected,
      qualification,
      errorQualification,
      employeePhoto,
      errorEmployeePhoto,
      excelHead,
      data,
      data1dept,
      behaviourltechnical,
      usertypeidopt,
      addUserColumn,
      userAddRow,
      RowCount,
      statusArray,
      showDatatable,
      path
    } = this.state;
    const inputstyle = {
      width: "inherit",
      height: "inherit",
      opacity: "inherit"
    };
    const designationOptionsData = designationOptions.sort((a, b) => a.label.localeCompare(b.label));

    console.log(statusArray, "statusArray");

    return (
      <React.Fragment>
        {/* Root styles are given in the header.css  */}
        <section id="hradduser">
          <div className={`  ${path ? "mt-100" : ""}  container container-sm-fluid `}>
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-10 col-12">
                <div className="content-border1 px-3 px-sm-1">
                  <div className="text-center my-3">
                    <h2 className="add-user">Add user</h2>
                  </div>
                  <hr style={{ borderTop: "2px dashed var(--input-border-color)" }} />
                  <div>
                    {this.state.multyIndicatorsAdd ?
                      <div>
                        <div className="row my-3 px-4 justify-content-between align-items-center">
                          <div className="col-lg-1 d-none d-lg-block"></div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-1" onClick={(e) => this.singlemultiselect(e, 1)}>
                            <label className='multi formLabel' style={{ fontSize: '16px' }} htmlFor="competency">Add single user</label>
                          </div>
                          <div className="col-lg-6  col-md-6 col-sm-6 col-12 my-1" onClick={(e) => this.singlemultiselect(e, 2)}>
                            <label className='multi formLabel' style={{ fontSize: '16px' }} htmlFor="competency">Add multiple user</label>
                          </div>
                        </div>
                        <div className="border33" />

                        {this.state.multi == 2 ?
                          <>
                            <div className="row justify-content-between align-items-center px-lg-5 px-0 px-sm-0">
                              <div className="col-lg-6  col-md-6 col-sm-12 col-12">
                                <label className="px-0 formLabel" htmlFor="exampleInputEmail1">
                                  Upload files:
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12" >
                                <FilePond
                                  // type="file"
                                  files={this.state.files}
                                  // className="custom-file-input"
                                  id="customFile"
                                  // accept='.csv'
                                  acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                                  // onChange={this.fileupload}
                                  // files={this.state.Testimonial_image}
                                  onupdatefiles={this.handleFileUpload}
                                  allowReorder={true}
                                  allowMultiple={false}
                                  width={10}
                                  maxFileSize={'300KB'}
                                  // imageValidateSizeMaxWidth={550}
                                  // imageValidateSizeMaxHeight={355}
                                  imagePreviewMinHeight="150"
                                  imagePreviewMaxHeight="150"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                                <button
                                  type="button"
                                  className="btncolor"
                                  onClick={(e) => this.submitcsv(e)}
                                  disabled={this.state.btnDisablecsv ? true : false}
                                >
                                  {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                                </button>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                                <button className='btncolor' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create user CSV file</button>
                              </div>
                            </div>
                            <div className="boorder33" />
                          </>
                          :
                          <>
                            <div className="row my-3 px-4  justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="formLabel">User type</label>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <div className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    id="Employee"
                                    class="inputtest"
                                    name="userType"
                                    value="5"
                                    checked={userType === "5" && true}
                                    onChange={this.handleUserInput}
                                  />
                                  <label className="formLabel">
                                    &nbsp;Employee
                                  </label>
                                </div>
                              </div>
                              <div className="my-2 ">
                                <ValidationView errorname={errorUserType} />
                              </div>
                            </div>
                            <div className="border33 my-3" />
                            <div className="row px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className=" col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Department</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <SingleSelect
                                  handleChange={this.departmentSelect}
                                  options={departmentOptions}
                                  selectedService={departmentSelected}
                                />
                              </div>
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="my-2 ">
                                <ValidationView errorname={errorDepartmentSelected} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-2 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Designation</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <SingleSelect
                                  handleChange={this.designationSelect}
                                  options={designationOptionsData}
                                  selectedService={designationSelected}
                                />
                              </div>
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorDesignationSelected} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">First name</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="text"
                                  className="inputss  form-control"
                                  name="firstName"
                                  id="firstName"
                                  placeholder="Please enter First Name"
                                  value={firstName}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorFirstName} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Last name</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="text"
                                  className="inputss  form-control"
                                  name="lastName"
                                  id="lastName"
                                  placeholder="Please enter Last Name"
                                  value={lastName}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorLastName} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">User name</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="text"
                                  className="inputss form-control"
                                  name="userName"
                                  id="userName"
                                  placeholder="Please enter User Name"
                                  value={userName}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorUserName} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Password</label>
                              </div>
                              <div className="col-lg-5 py-2 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="password"
                                  className=" inputss  form-control"
                                  name="password"
                                  id="password"
                                  placeholder="Please enter password"
                                  value={password}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorPassword} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Address</label>
                              </div>
                              <div className="col-lg-5  col-md-6 col-sm-12 col-12 px-2">
                                <textarea
                                  className="inputss  form-control"
                                  rows="3"
                                  id="address"
                                  name="address"
                                  placeholder="Please enter Address"
                                  onChange={this.handleUserInput}
                                  value={address}
                                ></textarea>
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorAddress} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1  d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Location</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <SingleSelect
                                  handleChange={this.locationSelect}
                                  options={locationOptions}
                                  selectedService={locationSelected}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorLocationSelected} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Mobile no</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="number"
                                  className="form-control  inputss"
                                  name="mobileNumber"
                                  id="mobileNumber"
                                  placeholder="Please enter mobileNumber"
                                  value={mobileNumber}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorMobileNumber} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Telephone</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="number"
                                  className="form-control  inputss"
                                  name="telephoneNumber"
                                  id="telephoneNumber"
                                  placeholder="Please enter telephoneNumber"
                                  value={telephoneNumber}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorTelephoneNumber} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Date of birth</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <DatePicker
                                  startDate={startDate}
                                  changeDate={this.dateofBirth}

                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row px-4 my-3 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Age</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="number"
                                  className="form-control inputss"
                                  name="age"
                                  id="age"
                                  placeholder="Please enter Age"
                                  value={age}
                                  onWheel={(e) => e.target.blur()}
                                // onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorAge} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Email</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="text"
                                  className="form-control inputss my-1 inputss"
                                  name="email"
                                  id="email"
                                  placeholder="Please enter email"
                                  value={email}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorEmail} />
                              </div>
                            </div>
                            <div className='border33' />
                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Date of joining</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <DatePicker
                                  startDate={dateOfJoining}
                                  changeDate={this.changeJoiningDate}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row my-3 px-4  justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel">Competency Type</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <label>
                                  <input
                                    type="checkbox"
                                    id="Behavioral"
                                    style={inputstyle}
                                    name="textType"
                                    className=""
                                    value="Behavioral"
                                    checked={textType.includes("Behavioral") && true}
                                    onChange={this.handleCheckInput}
                                  />
                                  &nbsp;&nbsp;&nbsp;Behavioral
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label>
                                  <input
                                    type="checkbox"
                                    id="Technical"
                                    style={inputstyle}
                                    name="textType"
                                    value="Technical"
                                    checked={textType.includes("Technical") && true}
                                    onChange={this.handleCheckInput}
                                  />{" "}
                                  &nbsp;&nbsp;&nbsp;Technical
                                </label>
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorTextType} />
                              </div>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                {textType.includes("Behavioral") && (
                                  <MultiSelect
                                    handleChange={this.behThemeSelect}
                                    options={behThemeOptions}
                                    selectedService={behThemeSelected}
                                  />
                                )}
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                {textType.includes("Technical") && (
                                  <MultiSelect
                                    handleChange={this.techThemeSelect}
                                    options={techThemeOptions}
                                    selectedService={techThemeSelected}
                                  />
                                )}
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row my-3 px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Work level</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <SingleSelect
                                  handleChange={this.workLevelSelectsingal}
                                  options={workLevelOptions}
                                  selectedService={workLevelSelected}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorWorkLevelSelected} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Job role</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <SingleSelect
                                  handleChange={this.jobRoleSelect}
                                  options={jobRoleOptions}
                                  selectedService={jobRoleSelected}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorJobRoleSelected} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Qualification</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={{ opacity: "inherit" }}
                                  type="text"
                                  className="form-control py-2 inputss"
                                  name="qualification"
                                  id="qualification"
                                  placeholder="Please enter Qualification"
                                  value={qualification}
                                  onChange={this.handleUserInput}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="my-2">
                                <ValidationView errorname={errorQualification} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row px-4 justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <label className="pl-0 formLabel" htmlFor="competency">Employee photo</label>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-2">
                                <input
                                  style={inputstyle}
                                  type="file"
                                  name="file"
                                  id="file"
                                  onChange={this.selectImage}
                                />
                              </div>
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-3 col-md-6 col-sm-6 col-12" >
                                {updateData ?
                                  <>
                                    <img src={ACCESS_POINT + "/getpro/getprofile?fileurl=" + this.state.updateUserImage} style={{ width: "70px" }} />
                                  </>
                                  :
                                  <>
                                  </>}
                              </div>
                              <div className="my-2">
                                <ValidationView errorname={errorQualification} />
                              </div>
                            </div>
                            <div className='border33' />

                            <div className="row  justify-content-between align-items-center">
                              <div className="col-lg-1 d-none d-lg-block"></div>
                              <div className="col-lg-6 col-12">
                                {!updateData ? (
                                  <button
                                    type="button"
                                    className="submit-btn "
                                    disabled={this.state.disable}
                                    onClick={this.onSubmit}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="submit-btn"
                                    onClick={this.onUpdate}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                            {
                              this.props && this.props.wizardBtns &&
                              <div className="text-end pe-5 me-3">
                                <button className="btncolor" style={{ width: "149px", padding: "6px", marginRight: "-60px" }} onClick={() => this.setState({ Step: this.props.StepFun(2) })}>Next</button>
                              </div>
                            }
                          </>}
                      </div>
                      :
                      <div>
                        <button className='btncolor' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                        <br></br>
                        <div className="row justify-content-between align-items-center px-lg-2 px-md-2 px-0 px-sm-0">
                          <div className="col-lg-1 d-none d-lg-block"></div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12 my-2">
                            <label className="formLabel" htmlFor="indicators pl-0">How many users add ?</label>
                          </div>
                          <div className="col-lg-5 col-md-6 col-sm-12 col-12 px-0 px-md-2 px-lg-2 my-2">
                            <input type="number" className="form-control inputss" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                          </div>
                          <div className="col-lg-1 d-none d-lg-block"></div>
                          <div className="col-lg-4 d-none d-lg-block" />
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <button className='btncolor my-sm-2 my-2' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                          </div>
                          <div className="col-lg-1 d-none d-lg-block"></div>
                        </div>
                        <div className="col-lg-12">
                          <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>S.No </th>

                                  {addUserColumn.map((index) => (
                                    <th key={index}> {index.label}</th>
                                  ))}
                                </tr>
                              </thead>

                              <tbody>
                                {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                                  // console.log(ival,"type",i,"i")

                                  <tr title={i + 1}>
                                    <td>{i + 1}</td>
                                    {addUserColumn.map((jval, index) => (
                                      <>
                                        <td  >{jval && jval.type === "textarea" ? <>
                                          <textarea
                                            style={{ width: "280px" }}
                                            // type="text"
                                            className="inputss"
                                            value={ival[jval.label]}
                                            onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                          /></> : jval.type === "selectbox" ?
                                          <div style={{ width: "280px" }}>
                                            <SingleSelect
                                              handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                              options={ival[jval.label + 'Option']}
                                              selectedService={ival[jval.label + "Select"]}
                                            // disabled={jval.value == 9 && is_managerSelect.value==1}
                                            />

                                            {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                          </div>
                                          : jval.type === "multiSelectbox" ?
                                            <div style={{ width: "280px" }}>
                                              <MultiSelect
                                                handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                options={ival[jval.label + 'Option']}
                                                selectedService={ival[jval.label + "Select"]}
                                              // disabled={jval.value == 9 && is_managerSelect.value==1}
                                              />

                                              {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                            </div>
                                            : jval.type === "date" ?
                                              <input className="date-pick" type="date" value={ival[jval.label]} style={{ width: "280px" }}
                                                onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                              ></input>
                                              : jval.type === "number" ?
                                                <input className="inputss" type="number" value={ival[jval.label]} style={{ width: "280px" }}
                                                  onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                ></input>
                                                : jval.type === "text" ?
                                                  <input className="inputss" type="text" value={ival[jval.label]} style={{ width: "280px" }}
                                                    onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                  ></input>
                                                  :
                                                  <>
                                                  </>}
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                ))}

                              </tbody>
                            </table>
                            {this.state.submitDataAlert ?
                              <div className='loader1'></div>
                              :
                              <>
                                {statusArray && statusArray.length > 0 &&

                                  <div className="row justify-content-between align-items-center my-2">
                                    <div className="col-lg-3" >
                                      <button className='btncolor' onClick={() => this.addRow(1)}>Add one</button>
                                    </div>
                                    <div className="col-lg-3 subbtnhr my-2" style={{}}>
                                      <button type="button" className="submit-btn" onClick={this.addMultySubmit}>
                                        Submit
                                      </button>
                                    </div>
                                    <div className="col-lg-3" >
                                      <CSVLink
                                        headers={this.state.excelHeadmultidata}
                                        data={statusArray}
                                        filename={"AddMultiUser.xls"}
                                        className=" btncolor "
                                        target="_blank"
                                      >
                                        Generate Excel
                                      </CSVLink>
                                    </div>


                                  </div>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

export default Hradduser;
