import React from 'react';
import MasterMiddleWare from '../../middleWare/MasterMiddleWare';
//import { Datatable } from '../../components/Datatable';
import { FormMiddleWare } from '../../components/Form';
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import ValidationView from '../../components/Form/ValidationView';
import Swal from 'sweetalert2';
import "./style.css";
const tableName = `tbl_users`;
class Updateprofile extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      username: null,
      email_id: "",
      mobileno: null,
      file: null,
      errorusername: null,
      erroremail: null,
      errormobileno: null,
      errorrrruserprofile: null,
      alertVisible: false,
      updateData: false,
      index: null
    };
  }

  async componentWillMount() {
    this.getUserDetails();
    this.getMaster(tableName);
  }
  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'UserName',
      accessor: 'user_name'
    },
    {
      Header: 'Email',
      accessor: 'email_id'
    },
    {
      Header: 'Mobile Number',
      accessor: 'mobileno'
    },
    {
      Header: 'User Profile',
      accessor: 'userprofile'
    },


  ];


  getUserDetails = async () => {
    let adminId = await localStorage.getItem("adminId");
    let groupcompId = await localStorage.getItem("groupcompId");
    let userId = await localStorage.getItem("userId");
    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );

    const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);
    if (userDetails) {
      this.setState({
        adminId,
        userId,
        groupcompId,


      });
    }
  };
  onUpdate = async () => {
    const { username, email_id, mobileno, file, adminId } = this.state;
    console.log(username, email_id, mobileno, file, adminId)
    if (this.validate(username, 'errorusername', 'Please Enter Username')) return true;
    if (this.validate(email_id, 'erroremail', 'Please Enter Email')) return true;
    if (this.validate(mobileno, 'errormobileno', 'Please Enter Mobilenumber')) return true;
    if (this.validate(file, 'erroruserprofile', 'Please Select Image')) return true;

    const formData = new FormData();
    formData.append('user_name', username);
    formData.append('email_id', email_id);
    formData.append('mobile_no', mobileno);
    formData.append('image', file);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    const previousData = [...this.state.data];
    var id = localStorage.getItem('userId')
    try {
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'profile has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          username: ''
        });
        window.location.reload()

      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };
  selectImage = e => {
    this.setState({ file: e.target.files[0] });
    this.setState({ fileName: e.target.files[0].name });
    // console.log(e.target.files[0]);
  };

  buttonEdit = value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      index,
      username: getData.username,
      email_id: getData.email_id,
      mobileno: getData.mobileno,
      userprofile: getData.userprofile,
      errorusername: '',
      erroremail: '',
      errormobileno: '',
      erroruserprofile: '',
      alertVisible: false,
      updateData: true
    });
  };


  render() {
    // console.log("calling");
    const { data, username, email_id, mobileno, file, getUserDetails, errorusername, erroremail, errormobileno, erroruserprofile, updateData } = this.state;
    const inputstyle = {
      width: "inherit",
      height: "inherit",
      opacity: "inherit"
    };
    return (
      <React.Fragment>
        <section className='mt-100'>
          <div className='container container-sm-fluid'>
            <div className='row justify-content-center align-items-center my-5 py-5 text '>
              <div className='col-lg-8 col-12'>
                <div className='content-border1'>
                  <div className=''>
                    <h3 className='col text-center my-3' >Update Profile</h3>
                  </div>
                  <div className='row justify-content-between align-items-center px-3'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-12 my-2'>
                      <label style={{ paddingLeft: "0px" }} htmlFor="username">UserName</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12 my-2'>
                      <input type="text" className="form-control inputss" name="username" id="username" placeholder="Please enter Username" value={username} onChange={this.handleUserInput} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-12 my-2'>
                      <ValidationView errorname={errorusername} />
                    </div>
                  </div>
                  <div className='row justify-content-between align-items-center px-3'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-12 my-2'>
                      <label style={{ paddingLeft: "0px" }} htmlFor="username">EMAIL</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12 my-2'>
                      <input type="text" className="form-control inputss" name="email_id" id="email_id" placeholder="Please enter Email" value={email_id} onChange={this.handleUserInput} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-12 my-2'>
                      <ValidationView errorname={erroremail} />
                    </div>
                  </div>
                  <div className='row justify-content-between align-items-center px-3'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-12 my-2'>
                      <label style={{ paddingLeft: "0px" }} htmlFor="username">MobileNumber</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12 my-2'>
                      <input type="text" className="form-control inputss" name="mobileno" id="mobileno" placeholder="Please enter mobileno" value={mobileno} onChange={this.handleUserInput} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-12 my-2'>
                      <ValidationView errorname={errormobileno} />
                    </div>
                  </div>
                  <div className='row justify-content-between align-items-center px-3'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-12 my-2'>
                      <label style={{ paddingLeft: "0px" }} htmlFor="username">User Profile</label>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12 my-2'>
                      <input
                        style={inputstyle}
                        className='inputss'
                        type="file"
                        name="file"
                        id="file"
                        imagePreviewMinHeight="50"
                        imagePreviewMaxHeight="50"
                        onChange={this.selectImage}
                      />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-12 my-2'>
                      <ValidationView errorname={erroruserprofile} />
                    </div>
                  </div>
                  <div className='col-12 text-end px-3 px-lg-5 pb-3'>
                    <button type="button" className="submit-btn" onClick={this.onUpdate}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Updateprofile;
