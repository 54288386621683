import React from 'react';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import SingleSelect from '../../../components/Form/SingleSelect';
import MultiSelect from '../../../components/Form/MultiSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';

class MapFunctionToWorklevel extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentLabelName: null,
      errordepartmentSelected: null,
      functionOptions: [],
      functionSelected: null,
      functionId: null,
      functionLabelName: null,
      errorfunctionSelected: null,
      workLevelOptionsBefore: [],
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      errorworkLevelSelected: null,
      updateData: false,
      data: []
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(adminId, 'tbl_department', 'id', 'department', 'departmentOptions');
    this.getSelectBoxMaster(adminId, 'tbl_worklevel', 'id', 'worklevel', 'workLevelOptionsBefore');
    this.getTableMaster(adminId);
  }

  getTableMaster = async adminId => {
    try {
      const { data } = await MappingMiddleware.getFunctiontoWorklevelData(adminId);
      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  column = [
    {
      Header: 'Department',
      accessor: 'departmentName'
    },
    {
      Header: 'Function',
      accessor: 'functionname'
    },
    {
      Header: 'Work Level',
      accessor: 'worklevelName'
    }
  ];

  departmentSelect = async selectedOption => {
    const { adminId } = this.state;
    const departmentId = selectedOption.value;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    try {
      const { data: functionOptions } = await MappingMiddleware.getMapDepartmentToFunctionData(adminId, departmentId);
      if (functionOptions) {
        this.setState({
          functionOptions,
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null,
          workLevelOptions: [],
          workLevelSelected: null,
          workLevelId: null,
          workLevelLabelName: null,
          errorworkLevelSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  functionSelect = async selectedOption => {
    const { adminId, departmentId, workLevelOptionsBefore } = this.state;
    const functionId = selectedOption.value;
    if (selectedOption) {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      await this.setState({
        functionSelected: selectedOption,
        functionId: selectedValue
      });
    } else {
      await this.setState({ functionSelected: "", functionId: "" });
    }

    try {
      const { data: workLevelOptions } = await MappingMiddleware.getMapDepartmentToWorklevelData(adminId, departmentId, functionId);
      let previousFunctionOptions = [...workLevelOptionsBefore];
      if (workLevelOptions) {
        if (workLevelOptions.length > 0) {
          workLevelOptions.map(filterId => {
            previousFunctionOptions = previousFunctionOptions.filter(value => {
              if (filterId.value !== value.value) {
                return value;
              }
              return null;
            });
            return null;
          });
          this.setState({
            workLevelOptions: previousFunctionOptions
          });
        } else {
          this.setState({
            workLevelOptions: workLevelOptionsBefore
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  workLevelSelect = async (selectedOption) => {

    if (selectedOption) {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      await this.setState({
        workLevelSelected: selectedOption,
        workLevelId: selectedValue
      });
    } else {
      await this.setState({ workLevelSelected: "", workLevelId: "" });
    }
  };

  onSubmit = async () => {
    const { departmentId, functionId, workLevelId, adminId } = this.state;
    if (this.validate(departmentId, 'errordepartmentSelected', 'Please Select Department')) return true;
    if (this.validate(functionId, 'errorfunctionSelected', 'Please Select Function')) return true;
    if (this.validate(workLevelId, 'errorworkLevelSelected', 'Please Select Work Level')) return true;
    const functionList = JSON.stringify(functionId.split(','))
    const workLevelList = JSON.stringify(workLevelId.split(','))
    const formData = new FormData();
    formData.append('department', departmentId);
    formData.append('function', functionId);
    formData.append('worklevel', workLevelId);
    formData.append('cmid', adminId);

    try {
      const result = await MappingMiddleware.mapFunctionToWorklevel(formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          departmentSelected: null,
          departmentId: null,
          departmentLabelName: null,
          errordepartmentSelected: null,
          functionOptions: [],
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null,
          workLevelOptions: [],
          workLevelSelected: null,
          workLevelId: null,
          workLevelLabelName: null,
          errorworkLevelSelected: null
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      departmentOptions,
      departmentSelected,
      errordepartmentSelected,
      functionOptions,
      functionSelected,
      errorfunctionSelected,
      workLevelOptions,
      workLevelSelected,
      errorworkLevelSelected,
      updateData,
      data
    } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className='container container-sm-fluid mt-100'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-lg-8 col-md-12 col-12'>
                <div className='content-border px-2'>
                  <div className='header-text px-lg-3'>
                    <span>Map Function & Worklevel</span>
                  </div>
                  <hr />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                      <label htmlFor="department">Department</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      <SingleSelect handleChange={this.departmentSelect} options={departmentOptions} selectedService={departmentSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errordepartmentSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                      <label htmlFor="function">Function</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      <MultiSelect handleChange={this.functionSelect} options={functionOptions} selectedService={functionSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorfunctionSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                      <label htmlFor="function">Work Level</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      <MultiSelect handleChange={this.workLevelSelect} options={workLevelOptions} selectedService={workLevelSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorworkLevelSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      {!updateData ? (
                        <button type="button" className="submit-btn" onClick={this.onSubmit}>
                          Submit
                        </button>
                      ) : (
                        <button type="button" className="submit-btn" onClick={this.onUpdate}>
                          Update
                        </button>
                      )}
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-2 col-12 py-5 my-5'>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapFunctionToWorklevel;
