import React, { Component } from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import LoginModal from "../../components/Modal/Modal";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import ValidationView from "../../components/Form/ValidationView";
import DatePicker from "../../components/Form/DatePicker";
import Swal from "sweetalert2";
import moment from "moment";
import "./style.css";
import { ACCESS_POINT } from "../../config";
import { Bar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import ContentEditable from "react-contenteditable";
import { Datatable } from "../../components/Datatable";
import { CSVLink } from "react-csv";
import imagesrc from '../Hr/images.jpeg'
import ManageIDP1 from '../User/Addidppage'


export default class zDashboard extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      UserDown: "",
      adminId: null,
      userDetails: [],
      idpTable: [],
      behTech: [
        { label: "Behavioural", value: 1 },
        { label: "Technical", value: 2 }
      ],
      startDate: new Date(),
      endDate: new Date(),
      UpdateValue: {},
      scheduled_start_date: new Date(),
      scheduled_end_date: new Date(),
      start_date: null,
      end_date: null,
      BehData: [],
      TechData: [],
      BehCompHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" }
      ],
      TechCompHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "TECHNICAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" }
      ],
      BehIndi: [],
      TechIndi: [],
      chart1: [],
      label2: [],
      BehIndiHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" },
        { label: "INDICATORS", key: "indicators" }
      ],
      TechIndiHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "TECHNICAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" },
        { label: "INDICATORS", key: "indicators" }
      ],
      status: [
        { label: "In Progress", value: 0 },
        { label: "In Review", value: 1 },
        { label: "New", value: 2 },
        { label: "Cancelled", value: 3 }
      ],
      selectBox: [],
      modal: "modal",
      checktheme: false
    };
  }
  componentDidMount() {
    const val = localStorage.getItem("Usertheme")
    this.setState({ checktheme: val === "dark" ? true : false })
  }
  async componentWillMount() {
    this.getUserDetails();
    this.getChartData();
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }
    setTimeout(() => {
      this.getChartData();
    }, 1000);
  }

  column = [
    {
      Header: "IDP type",
      accessor: "type"
      // Cell: d => (d.original.type === 1 ? "Behavioral" : "Technaical")
    },
    {
      Header: "Goal",
      accessor: "goalsname",
      Cell: d => this.otherEdits(d, d.original.goalsname, "goalsname")
    },
    {
      Header: "Criteria",
      accessor: "Criteria_Completion",
      Cell: d =>
        this.otherEdits(
          d,
          d.original.Criteria_Completion,
          "Criteria_Completion"
        )
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: d => this.date(d)
    },
    {
      Header: "Progress",
      accessor: "percentage",
      Cell: d => this.otherEdits(d, d.original.percentage, "percentage")
    },
    {
      Header: "Reason for Deviation",
      accessor: "reason_deviation",
      Cell: d =>
        this.otherEdits(d, d.original.reason_deviation, "reason_deviation")
    },
    {
      Header: "Comments",
      accessor: "comment",
      Cell: d => this.otherEdits(d, d.original.comment, "comment")
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: d => this.otherEdits(d, d.original.status, "status")
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.editable(d)
    },
    {
      Header: "IDP Edit History",
      accessor: "IDP Edit History",
      Cell: d => this.view(d)
    }
  ];
  view = d => {
    return (
      <button
        type="button"
        className=" table-btn "
        style={{ padding: "5px 10px", margin: "5px" }}
        onClick={() =>
          window.open(
            "/hr/IDPHistory" +
            "?" +
            "id=" +
            d.original.customerid +
            "&" +
            "goal=" +
            d.original.id,
            "_blank"
          )
        }
      >
        View IDP
      </button>
    );
  };
  editable = d => {
    if (this.state.editable[d.index]) {
      var editUpdate = "Edit";
      var stylez = "table-warning-btn";
    } else {
      var editUpdate = "Update";
      var stylez = "table-btn";
    }
    return (
      <button
        type="button"
        className={stylez}
        onClick={() => this.buttonEdit(d, editUpdate)}
      >
        {editUpdate}
      </button>
    );
  };
  date = d => {
    if (this.state.editable[d.index] || this.state.currentContent) {
      let i = d.index;

      let data =
        this.state.editable[d.index] == true
          ? new Date(d.original.scheduled_start_date)
          : this.state.currentContent.scheduled_start_date;

      let data1 =
        this.state.editable[d.index] == true
          ? new Date(d.original.scheduled_end_date)
          : this.state.currentContent.scheduled_end_date;

      let data2 =
        this.state.editable[d.index] == true
          ? d.original.actual_end_date
            ? new Date(d.original.actual_end_date)
            : new Date()
          : this.state.currentContent.actual_end_date;

      return (
        <div>
          <div className=" form-group ">
            <div style={{ fontSize: 12 }}>Start Date :</div>
            <div>
              <DatePicker
                startDate={data}
                disabled={this.state.editable[d.index]}
                changeDate={e =>
                  this.handleChanges(e, "scheduled_start_date", i)
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div style={{ fontSize: 12 }}>End Date :</div>
            <div>
              <DatePicker
                startDate={data1}
                disabled={this.state.editable[d.index]}
                changeDate={e => this.handleChanges(e, "scheduled_end_date", i)}
              />
            </div>
          </div>
          <div className="form-group ">
            <div style={{ fontSize: 12 }}>Actual End Date :</div>
            <div>
              <DatePicker
                startDate={data2}
                disabled={this.state.editable[d.index]}
                changeDate={e => this.handleChanges(e, "actual_end_date", i)}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  otherEdits = (d, data, fromHere) => {
    let i = d.index;
    if (fromHere === "percentage") {
      data = data ? data + "%" : "0%";
    }
    if (fromHere === "status") {
      return (
        <div>
          {this.state.editable[i] ? (
            data
          ) : (
            <SingleSelect
              options={this.state.status}
              handleChange={e => this.handleChanges(e, "status", i)}
              selectedService={this.state.selectBox}
              disabled={this.state.editable[i]}
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          {this.state.editable[i] ? (
            data
          ) : (
            <input
              type="text"
              placeholder={data}
              value={this.state.UpdateValue[fromHere]}
              disabled={this.state.editable[i]}
              onChange={d => this.handleChanges(d, fromHere, i)}
            />
          )}
        </div>
      );
    }
  };
  handleChanges = (d, here, i) => {
    if (d && here) {
      let UpdateValue = this.state.UpdateValue;
      if (
        here === "scheduled_end_date" ||
        here === "scheduled_start_date" ||
        here == "actual_end_date"
      ) {
        UpdateValue[here] = d;
        let currentContent = this.state.currentContent;
        currentContent[here] = d;
        this.setState({ currentContent });
      } else if (here === "status") {
        UpdateValue[here] = d.label;
        this.setState({ selectBox: d });
      } else {
        UpdateValue[here] = d.target.value;
      }
    }
  };
  buttonEdit = async (d, updateEdit) => {
    let i = d.index;
    let editable = this.state.editable;
    editable[i] = true;
    if (updateEdit === "Edit") {
      let currentContent = d.original;
      currentContent.scheduled_start_date = currentContent.scheduled_start_date
        ? new Date(currentContent.scheduled_start_date)
        : new Date();
      currentContent.scheduled_end_date = currentContent.scheduled_end_date
        ? new Date(currentContent.scheduled_end_date)
        : new Date();
      currentContent.actual_end_date = currentContent.actual_end_date
        ? new Date(currentContent.actual_end_date)
        : new Date();
      this.setState({ currentContent });
      editable[d.index] = false;
    } else {
      let id = d.original.id;
      let body = {};
      body.body = this.state.UpdateValue;
      body.editedBy = parseInt(this.state.userId);
      body.customerid = this.state.adminId;
      body.type = d.original.type;
      body.yearId = d.original.yearId;

      let result = await MasterMiddleWare.updateGoal(body, id);
      if (result) {
        // editable[d.index] = true;
        let currentContent = this.state.currentContent;
        let UpdateValue = this.state.UpdateValue;
        Object.keys(UpdateValue).map((ival, i) => {
          currentContent[ival] = UpdateValue[ival];
        });
        let idpTable = this.state.idpTable;
        idpTable[i] = currentContent;
        this.setState({ idpTable, UpdateValue: {} });
      }
    }
    this.setState({ editable, index: d.index });
  };

  getUserDetails = async () => {
    let adminId = await localStorage.getItem("adminId");
    let groupcompId = await localStorage.getItem("groupcompId");
    let userId = await localStorage.getItem("userId");
    let jobId = await localStorage.getItem("jobRoleId")

    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    let job = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `id = ${userData.data[0].jobRole}`,
      "jobRoleName as jobRoleName"
    );
    let designation = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `id = ${userData.data[0].designation}`,
      "designation as designation"
    );

    // let descrip = await HrMiddleWare.getRandomData(
    //   "tbl_jobdescription",
    //   `function = ${userId}`,
    //   "*"
    // );
    let descrip = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `jobRole = ${userData.data[0].jobRole} and
       department = ${userData.data[0].department} and
       customerid = ${adminId} and status ='active'`,
      "*"
    );

    // let derailers = await HrMiddleWare.getRandomData(
    //   "Tblderailers_map,tbl_derailers",
    //   `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and Tblderailers_map.jobroleid = ${userData.data[0].jobRole} and  Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
    //   "*"
    // );

    let derailers = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.userid = ${userData.data[0].id} and  Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "*"
    );
    console.log(`Tblderailers_map.userid = ${userData.data[0].id} and  Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`)
    console.log(derailers)

    let mapped_derailers = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  designationid = ${userData.data[0].designation} and Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );
    console.log(mapped_derailers)

    let behaviourderailers = [];
    let technicalderailers = []
    mapped_derailers.data.map(value => {
      if (value.userId) {
        console.log(userId)
        console.log(value.userId.includes(userData.data[0].id))
        if (value.userId.includes(userData.data[0].id) == true) {

          if (value.textType == "Technical") {
            console.log(value)
            technicalderailers.push(<div>


              <li style={{ fontWeight: "bold", padding: "5px" }}>
                {value.derailers}</li>

            </div>)

          }
          else if (value.textType == "Behavioral") {
            console.log(value)
            behaviourderailers.push(<div>


              <li style={{ fontWeight: "bold", padding: "5px" }}>
                {value.derailers}</li>


            </div>)

          }


        }


      }

    })
    console.log(technicalderailers)

    if (technicalderailers.length == 0) {
      technicalderailers.push(<p>No Derailers Mapped</p>);
    }

    if (behaviourderailers.length == 0) {
      behaviourderailers.push(<p>No Derailers Mapped</p>);
    }



    await this.setState({ behaviourderailers, technicalderailers })


    let derail = [];
    if (derailers.data && derailers.data.length > 0) {
      derailers.data.map((item, i) => {
        derail.push(
          <p
            className="pTagControl"
            style={{ fontWeight: "bold", padding: "5px" }}
          >
            {item.derailers}
          </p>
        );
      });
    } else {
      derail.push(<p>No Derailers Mapped</p>);
    }
    const { data: userDetails } = await HrMiddleWare.getUserDetailsyearbased(userId, jobId);

    // const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);
    if (userDetails) {
      this.setState({
        adminId,
        userId,
        groupcompId,
        userDetails: userDetails,
        job: job.data,
        designation: designation.data,
        descrip: descrip.data,
        derailers: derailers.data,
        derail
      });
    }
  };

  getChartData = async () => {
    const { dataChart, compChart } = this.state;
    let userId = await localStorage.getItem("userId");
    const { data } = await HrMiddleWare.getChartData(userId);
    console.log(data, "dataaa");
    if (data) {
      console.log(data, "data")
      this.setState({ chart1: data });
    }
    const result = await HrMiddleWare.getCompetencyChartData(userId);
    if (result) {
      let labelsArray = [];
      let dataArray = [];
      result.data.map(list => {
        labelsArray.push(list.x);
        dataArray.push(list.y);
      });
      this.setState({ label2: labelsArray })
      this.setState({ chart2: dataArray })
      console.log(this.state.chart2, "datum")

    }
  };

  onSubmit = async () => {
    const {
      financialYearId,
      behTechSelected,
      behTechId,
      goal,
      criteria,
      userId,
      adminId,
      endDate,
      startDate
    } = this.state;
    if (this.validate(behTechId, "errorbehTechSelected", "Please select type"))
      return true;
    if (
      this.validate(
        financialYearId,
        "errorfinancialYearSelected",
        "Please select year"
      )
    )
      return true;
    if (this.validate(goal, "errorgoal", "Please enter goal")) return true;
    if (this.validate(criteria, "errorcriteria", "Please enter criteria"))
      return true;

    try {
      let formData = new FormData();
      formData.append("type", behTechSelected.label);
      // formData.append("type", behTechId);
      formData.append("Criteria_Completion", criteria);
      formData.append("goalsname", goal);
      formData.append("yearId", financialYearId);
      formData.append("createdById", userId);
      formData.append("customerid", adminId);
      formData.append(
        "scheduled_start_date",
        moment(startDate).format("YYYY-MM-DD")
      );
      formData.append(
        "scheduled_end_date",
        moment(endDate).format("YYYY-MM-DD")
      );

      let result = await MasterMiddleWare.createMaster("idp_goals", formData);
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "IDP Added",
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          behTechSelected: [],
          criteria: "",
          goal: "",
          financialYearId: [],
          modal: "close"
        });
        this.yearSelect(this.state.yearSelected);
      }
    } catch (error) {
      console.log(error);
    }
  };

  yearSelect = async selectedOption => {
    try {
      let result = await HrMiddleWare.getRandomData(
        "idp_goals",
        `createdById=${this.state.userId} and yearId=${selectedOption.value}`,
        "*"
      );
      let dTable = result.data.map((ival, i) => {
        return true;
      });
      if (result) {
        this.setState({
          idpTable: result.data,
          editable: dTable,
          yearSelected: selectedOption
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  AddIdppage = async () => {
    window.open('/user/Addidppage', '_blank');
  }

  reportYearSelect = async selectedOption => {
    try {
      let result = await HrMiddleWare.getRandomData(
        "tbl_map_employee,tbl_users",
        `tbl_map_employee.is_supervisor=1 and tbl_map_employee.empid=${this.state.userId} and tbl_map_employee.finyear_id=${selectedOption.value} and tbl_users.id=tbl_map_employee.mappedto`,
        "tbl_users.id as value,tbl_users.user_name as label"
      );
      this.setState({
        reportees: result.data,
        reporteeYear: selectedOption.value
      });
    } catch (error) {
      console.log(error);
    }
  };
  reporteeYearSelect = async selectedOption => {
    try {
      const result = await HrMiddleWare.getRandomData(
        "idp_goals",
        `createdById=${selectedOption.value} and yearId=${this.state.reporteeYear}`,
        "*"
      );
      let dTable = result.data.map((ival, i) => {
        return true;
      });
      if (result) {
        this.setState({ idpTable: result.data, editable: dTable, UserDown: selectedOption.value });
      }
    } catch (error) {
      console.log(error);
    }
  };

  commentCon = async () => {
    const { userdata, descrip } = this.state;
    let des = [];
    let file = [];
    console.log(descrip);
    if (
      descrip.length > 0 &&
      (descrip[0].fileupload != undefined ||
        descrip[0].fileupload != null ||
        descrip[0].fileupload != "")
    ) {
      let upload =
        "/murugappa_drag/web/uploadfiles/fileuploads/" + descrip[0].fileupload;
      des.push(<div>{descrip[0].describtion}</div>);
      file.push(
        <div className="row form-group">
          <div className="col-sm-5" />
          <div className="fa fa-download col-sm-5">
            <a
              href={`${ACCESS_POINT}/getpro/getprofile?fileurl=${descrip[0].fileupload}`}
              download
            >
              <span style={{ marginLeft: "5px" }}>Click Here to Download</span>
            </a>
          </div>
          <div className="col-sm-2" />
        </div>
      );
    } else {
      des.push(<div>Description Not upload</div>);
    }
    await this.setState({ des, file });
  };

  excelComp = async type => {
    const { userDetails } = this.state;
    let BehData = [];
    let TechData = [];
    if (
      userDetails &&
      (!userDetails[0].userTechComp || userDetails[0].userTechComp == [])
    ) {
      TechData.push({
        index: 1,
        theme: "NA",
        competencyname: "NA",
        compDescription: "NA",
        compImportant: "NA"
      });
    } else {
      userDetails[0].userTechComp.map((item, i) => {
        TechData.push({
          index: i + 1,
          theme: item.theme,
          competencyname: item.competencyname,
          compDescription: item.compDescription,
          compImportant: item.compImportant
        });
      });
    }
    if (
      userDetails &&
      (!userDetails[0].userBehComp || userDetails[0].userBehComp == [])
    ) {
      BehData.push({
        index: 1,
        theme: "NA",
        competencyname: "NA",
        compDescription: "NA",
        compImportant: "NA"
      });
    } else {
      userDetails[0].userBehComp.map((item, i) => {
        BehData.push({
          index: i + 1,
          theme: item.theme,
          competencyname: item.competencyname,
          compDescription: item.compDescription,
          compImportant: item.compImportant
        });
      });
    }
    await this.setState({ BehData: BehData, TechData: TechData });
  };

  excelIndi = async type => {
    const { userDetails } = this.state;
    let BehIndi = [];
    let TechIndi = [];
    if (
      userDetails &&
      (!userDetails[0].userBehIndicators ||
        userDetails[0].userBehIndicators == [])
    ) {
      BehIndi.push({
        index: 1,
        theme: "NA",
        competencyname: "NA",
        compDescription: "NA",
        compImportant: "NA",
        indicators: "NA"
      });
    } else {
      userDetails[0].userBehIndicators.map((item, i) => {
        item.indicators.map((element, key) => {
          BehIndi.push({
            index: i + 1,
            theme: item.theme,
            competencyname: item.competencyname,
            compDescription: item.compDescription,
            compImportant: item.compImportant,
            indicators: element.indicators
          });
        });
      });
    }
    if (
      userDetails &&
      (!userDetails[0].userTechIndicators ||
        userDetails[0].userTechIndicators == [])
    ) {
      TechIndi.push({
        index: 1,
        theme: "NA",
        competencyname: "NA",
        compDescription: "NA",
        compImportant: "NA",
        indicators: "NA"
      });
    } else {
      userDetails[0].userTechIndicators.map((item, i) => {
        item.indicators.map((element, key) => {
          TechIndi.push({
            index: i + 1,
            theme: item.theme,
            competencyname: item.competencyname,
            compDescription: item.compDescription,
            compImportant: item.compImportant,
            indicators: element.indicators
          });
        });
      });
    }
    this.setState({ BehIndi: BehIndi, TechIndi: TechIndi });
  };

  ManageReportee = async () => {
    console.log(this.state.UserDown)
    window.open(`/user/manageIdppage/${this.state.UserDown}`, '_blank');
  }

  render() {
    const {
      userDetails,
      dataChart,
      options,
      compChart,
      yearList,
      financialYearSelected,
      behTech,
      behTechSelected,
      goal,
      criteria,
      errorcriteria,
      errorgoal,
      errorfinancialYearSelected,
      errorbehTechSelected,
      startDate,
      endDate,
      job,
      designation,
      idpTable,
      reportYearSelected,
      reportees,
      derail,
      checktheme
    } = this.state;
    const datum = {
      labels: ["Supervisor", "Self", "Reportee", "Customer", "Peer"],
      datasets: [
        {
          label: 'My Dataset',
          fontColor: this.state.checktheme ? "#fff" : "black",// Optional: Add a label for the dataset
          backgroundColor: [
            '#287e8f',
            '#f5b640',
            '#15cab6',
            '#ef8a5a',
            '#0f488c',
          ],
          hoverBackgroundColor: [
            '#225a65', // Hover color for 'supervisor'
            '#daa23a', // Hover color for 'self'
            '#12a493', // Hover color for 'reportee'
            '#cd7045', // Hover color for 'customer'
            '#0e3b71', // Hover color for 'peer'
          ],
          data: this.state.chart1.length > 0 ? this.state.chart1 : [],
        }

      ]
    };
    const option2 = {
      legend: {
        display: false
      },
      title: {
        display: true,
        fontColor: this.state.checktheme ? "#fff" : "black",
        fontWeight: 700
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 5,
              stepSize: 1,
              fontSize: 15,
              fontColor: this.state.checktheme ? "#fff" : "black",
              fontWeight: 700
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              color: this.state.checktheme ? "#fff" : "black",
            },
          }
        ],
        xAxes: [
          {
            ticks: {
              fontSize: 15,
              fontColor: this.state.checktheme ? "#fff" : "black",
              fontWeight: 700
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              color: this.state.checktheme ? "#fff" : "black",
            },
          }
        ],
      }
    };

    return (
      <React.Fragment>
        <section>
          <div className="container-fluid px-0 px-lg-5 px-md-2">
            <div className="row justify-content-center align-items-center text">
              <div className="col-lg-12 col-12 mt-100">
                <div className="content-border1 py-lg-4 px-lg-2">
                  <div className="dash-header my-2 text-center">
                    <h2>Dashboard</h2>
                  </div>
                  <hr />
                  <div className="">
                    <h2>Themes & Competencies Mapped</h2>
                  </div>
                  <div className="text-end col-12 px-4">
                    {job && designation && (
                      <LoginModal
                        buttonTitle="Job Description"
                        title="Job Description"
                        id="Comments"
                        extraClass=" btncolor "
                        onClick={this.commentCon}
                        extraStyle={{ backgroundColor: "#56528F" }}
                        maxwidth="modal-lg"
                        bodyText={
                          <div className="ps-5 pe-2 modal-text">
                            <div className="text-start">
                              <h5>Designation :  {designation[0].designation}</h5>
                            </div>
                            <div className="text-start">
                              <h5>Job role :   {job[0].jobRoleName}</h5>
                            </div>
                            <div className="text-start">
                              <h5>Description :  {this.state.des}</h5>
                            </div>
                            <div className="text-start">
                              {this.state.file}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                  <div className="col-lg-10 col-md-10 col-12 my-4 pe-5">
                    <ul className="nav nav-tabs ">
                      <li className=" nav-item col-sm-3 col-md-3 col-lg-3 col-12 ">
                        <a
                          class=" nav-link active header-color "
                          data-toggle="tab"
                          href="#techTheme"
                        >
                          Technical Themes
                        </a>
                      </li>
                      <li className=" nav-item col-sm-3 col-md-3 col-lg-3 col-12 ">
                        <a
                          class=" nav-link header-color "
                          data-toggle="tab"
                          href="#behTheme"
                        >
                          Behavioural Themes
                        </a>
                      </li>
                      <li className=" nav-item col-sm-3 col-md-3 col-lg-3 col-12 ">
                        <a
                          class=" nav-link header-color "
                          data-toggle="tab"
                          href="#techComp"
                        >
                          Technical Competencies
                        </a>
                      </li>
                      <li className=" nav-item col-sm-3 col-md-3 col-lg-3 col-12 ">
                        <a class=" nav-link header-color " data-toggle="tab" href="#behComp">
                          Behavioural Competencies
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div id="techTheme" className="tab-pane fade  active show px-3 col-12">
                      <h3>TECHNICAL THEMES</h3>
                      {userDetails[0] &&
                        userDetails[0].userTechTheme.map((techThem) => (
                          <p className="pTagControl">{techThem.theme}</p>
                        ))}
                    </div>
                    <div id="behTheme" className="tab-pane fade col-12">
                      <h3>BEHAVIOURAL THEMES</h3>
                      {userDetails[0] &&
                        userDetails[0].userBehTheme.map((behThem) => (
                          <p className="pTagControl">{behThem.theme}</p>
                        ))}
                    </div>
                    <div id="techComp" className="tab-pane fade">
                      <div className="d-flex flex-wrap  align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-4">
                          <h3>TECHNICAL COMPETENCIES</h3>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <CSVLink
                            headers={this.state.TechCompHeaders}
                            data={this.state.TechData}
                            onClick={this.excelComp}
                            filename={"my-file.xls"}
                            className=" btncolor"
                            target="_blank"
                          // filename={"my-file.xls"}
                          // target="_blank"
                          >
                            Generate Excel
                          </CSVLink>
                        </div>
                      </div>
                      <div className="col-12">
                        {userDetails[0] &&
                          userDetails[0].userTechComp.map(techComp => (
                            <div>
                              <div className="row align-items-center pTagControl ">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Theme
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content1">
                                  {techComp.theme}
                                </div>
                              </div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Name
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content1">
                                  {techComp.competencyname}
                                </div>
                              </div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Definition
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content1">
                                  {techComp.compDescription}
                                </div>
                              </div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Why it's important
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content1">
                                  {techComp.compImportant}
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}
                      </div>
                    </div>
                    <div id="behComp" className="tab-pane fade">
                      <div className="d-flex flex-wrap  align-items-center">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                          <h3>BEHAVIOURAL COMPTENCIES</h3>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                          <CSVLink
                            headers={this.state.BehCompHeaders}
                            data={this.state.BehData}
                            onClick={this.excelComp}
                            filename={"Behavioural_Compentencies.xls"}
                            className=" btncolor"
                            target="_blank"
                          >
                            Generate Excel
                          </CSVLink>
                        </div>
                      </div>
                      <div className="col-12">
                        {userDetails[0] &&
                          userDetails[0].userBehComp.map(behComp => (
                            <div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Theme
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">{behComp.theme}</div>
                              </div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Name
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  {behComp.competencyname}
                                </div>
                              </div>
                              <div className="row  align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Definition
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                                  {behComp.compDescription}
                                </div>
                              </div>
                              <div className="row  align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Why it's important
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  {behComp.compImportant}
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}
                      </div>

                    </div>
                  </div>
                  <hr />
                  <div className="my-3">
                    <h2>Indicators & Derailers Mapped</h2>
                  </div>
                  <hr />
                  <div className="text my-2">
                    <ul class="nav nav-tabs">
                      <li className=" nav-item  col-lg-3 col-md-4 col-sm-6 col-12 ">

                        <a
                          class="header-color active nav-link"
                          data-toggle="tab"
                          href="#behIndicator"
                        >
                          Behavioural Indicators
                        </a>
                      </li>
                      <li className=" nav-item col-lg-3 col-md-4 col-sm-6 col-12 ">


                        <a
                          class="header-color nav-link"

                          data-toggle="tab"
                          href="#techIndicator"
                        >
                          Technical Indicators
                        </a>
                      </li>
                      <li className=" nav-item col-lg-3 col-md-4 col-sm-6 col-12 ">

                        <a
                          class="header-color nav-link"
                          data-toggle="tab"
                          href="#derailers"
                        >
                          Derailers
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <br />
                    <div id="behIndicator" class="tab-pane fade in active show">
                      <div className="row  align-items-center pTagControl">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                          <h3>BEHAVIOURAL INDICATORS</h3>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
                          <CSVLink
                            headers={this.state.BehIndiHeaders}
                            data={this.state.BehIndi}
                            onClick={this.excelIndi}
                            filename={"my-file.xls"}
                            className=" btncolor"
                            target="_blank"
                          >
                            Generate Excel
                          </CSVLink>
                        </div>
                      </div>
                      <div className="col-12">
                        {userDetails[0] &&
                          userDetails[0].userBehIndicators &&
                          userDetails[0].userBehIndicators.length > 0 &&
                          userDetails[0].userBehIndicators.map(behComp => (
                            <div>
                              <div className="row align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Theme
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">{behComp.theme}</div>
                              </div>
                              <div className="row  align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Name
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  {behComp.competencyname}
                                </div>
                              </div>
                              <div className="row   align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Competency Definition
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  {behComp.compDescription}
                                </div>
                              </div>
                              <div className="row  align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Why it's important
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  {behComp.compImportant}
                                </div>
                              </div>
                              <div className="row   align-items-center pTagControl">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                  Indicators
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                  <span className="tab-tex-content">
                                    {behComp.indicators.length > 0
                                      ? "Indicator"
                                      : "No indicators Mapped"}
                                  </span>
                                  {behComp.indicators.length > 0 &&
                                    behComp.indicators.map(
                                      (indicList, index) => (
                                        <p className="pTagControl">{`${parseInt(
                                          index
                                        ) + 1}) ${indicList.indicators}`}</p>
                                      )
                                    )}
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))}
                      </div>
                    </div>
                    <div id="techIndicator" class="tab-pane fade">
                      <div className="row">
                        <div className="col-sm-6">
                          <h3>TECHNICAL INDICATORS</h3>
                        </div>

                        <div className="col-sm-6">
                          <CSVLink
                            headers={this.state.TechIndiHeaders}
                            data={this.state.TechIndi}
                            onClick={this.excelIndi}
                            filename={"my-file.xls"}
                            className=" btncolor"
                            target="_blank"
                          >
                            Generate Excel
                          </CSVLink>
                        </div>
                      </div>
                      {userDetails[0] &&
                        userDetails[0].userTechIndicators &&
                        userDetails[0].userTechIndicators.length > 0 &&
                        userDetails[0].userTechIndicators.map(techComp => (
                          <div>
                            <div className="row align-items-center pTagControl">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                Theme
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">{techComp.theme}</div>
                            </div>
                            <div className="row align-items-center pTagControl">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                Competency Name
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                {techComp.competencyname}
                              </div>
                            </div>
                            <div className="row  align-items-center pTagControl">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                Competency Definition
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                {techComp.compDescription}
                              </div>
                            </div>
                            <div className="row  align-items-center pTagControl">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                Why it's important
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                {techComp.compImportant}
                              </div>
                            </div>
                            <div className="row  align-items-center pTagControl">
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content">
                                Indicators
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                <span className="tab-tex-content">
                                  {techComp.indicators.length > 0
                                    ? "Indicator"
                                    : "No indicators Mapped"}
                                </span>
                                {techComp.indicators.length > 0 &&
                                  techComp.indicators.map(
                                    (indicList, index) => (
                                      <p className="pTagControl">{`${parseInt(
                                        index
                                      ) + 1}) ${indicList.indicators}`}</p>
                                    )
                                  )}
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                    </div>
                    <div id="derailers" class="tab-pane fade">
                      <h3>Derailers</h3>
                      <div className="row align-items-center">
                        <div className=" col-lg-4 col-md-6 col-sm-12 col-12 tab-tex-content"> {this.state.behaviourderailers}</div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12"> {this.state.technicalderailers}</div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-3">
                    <h1>Employee Chart</h1>
                    <Bar
                      data={datum}
                      width={100}
                      height={30}
                      options={option2}
                    />
                  </div>
                  <hr />
                  <div className="col-lg-8  col-md-10 col-sm-12 col-12  px-lg-2">
                    <ul class="nav nav-tabs">
                      <li class="nav-item ">
                        <a className="nav-link active header-color" data-toggle="tab" href="#idp">
                          IDP
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link header-color"
                          data-toggle="tab"
                          href="#reporteeIdp"
                        >
                          Reportees IDP
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 py-5 my-5">
                    <div className="tab-content px-2">
                      <div id="idp" class="tab-pane fade in active show my-2">
                        <div className="row justify-content-evenly align-items-center px-2 px-lg-5">
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <h3>IDP</h3>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <button
                              type="button"
                              className="submit-btn"
                              onClick={this.AddIdppage}
                            >
                              Manage IDP </button>
                          </div>
                          <div className="col-lg-4 d-none d-lg-none"></div>
                        </div>
                        <div className="row justify-content-between align-items-center px-2 px-lg-5">
                          <div className="col-12">
                            <h3 style={{ color: "#2d5986", }}> IDP </h3>
                          </div>
                          <div className="col-12">
                            <ManageIDP1 report={true} idpYear={this.state.idpYear} />
                          </div>
                          <div className="col-lg-4 d-none d-lg-none"></div>
                        </div>
                      </div>
                      <div id="reporteeIdp" class="tab-pane fade">
                        <h3>Reportees IDP</h3>
                        <div className="row justify-content-evenly align-items-center">
                          <div className="col-lg-2 d-none d-lg-none"></div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <SingleSelect
                              handleChange={this.reportYearSelect}
                              options={yearList}
                              selectedService={reportYearSelected}
                            />
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <SingleSelect
                              handleChange={this.reporteeYearSelect}
                              options={reportees}
                              selectedService={reportYearSelected}
                            />
                          </div>
                          <div className="col-lg-2 d-none d-lg-none"></div>
                        </div>
                        {this.state.UserDown ? <div className="row form-group ">
                          <div className="col-sm-5" />
                          <div className="col-sm-4">
                            <button
                              type="button"
                              className="submit-btn"
                              onClick={this.ManageReportee}
                            >
                              Manage IDP </button></div></div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
