import React from 'react';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import SingleSelect from '../../../components/Form/SingleSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';

class MapDepartmentToFunction extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      functionOptionsBefore: [],
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentLabelName: null,
      errordepartmentSelected: null,
      functionOptions: [],
      functionSelected: null,
      functionId: null,
      functionLabelName: null,
      errorfunctionSelected: null,
      updateData: false,
      data: []
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(adminId, 'tbl_department', 'id', 'department', 'departmentOptions');
    this.getSelectBoxMaster(adminId, 'tbl_function', 'id', 'functionname', 'functionOptionsBefore');
    this.getTableMaster(adminId);
  }

  getTableMaster = async adminId => {
    try {
      const { data } = await MappingMiddleware.getDepartmentToFunctionData(adminId);
      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  column = [
    {
      Header: 'Department',
      accessor: 'departmentName'
    },
    {
      Header: 'Function',
      accessor: 'functionname'
    },

    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.editMap(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.deleteMap(d)
    }
  ];

  editMap = value => {
    return (
      <button type="button" className="table-warning-btn" onClick={() => this.buttonEdit(value)}>
        Edit
      </button>
    );
  };

  deleteMap = value => {
    return (
      <button type="button" className="table-danger-btn" onClick={() => this.buttonDeleteId(value)}>
        Delete
      </button>
    );
  };

  buttonDeleteId = async value => {
    const id = value.original.id;
    const previousData = [...this.state.data];
    const data = previousData.filter(value => value.id !== id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(async result => {
      if (result.value) {
        try {
          this.setState({
            data
          });
          const result = await MappingMiddleware.deleteMapDepartmentToFunction(id);
          if (result) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          }
        } catch (error) {
          Swal.fire('Cancelled', 'Something Went Wrong :)', 'error');
          this.setState({
            data: previousData
          });
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file is safe :)', 'error');
        this.setState({
          data: previousData
        });
      }
    });
  };

  buttonEdit = value => {
    const { data, functionOptionsBefore } = this.state;
    const index = value.index;
    const previousData = [...data];
    const getData = previousData[index];
    this.setState({
      departmentSelected: { label: getData.departmentName, value: getData.department },
      departmentId: getData.department,
      departmentLabelName: getData.departmentName,
      functionSelected: { label: getData.functionname, value: getData.function },
      functionId: getData.function,
      functionLabelName: getData.functionname,
      updateData: true,
      index
    });

    let previousFunctionOptions = [...functionOptionsBefore];
    let filterPreviousData = previousData.filter(value => value.department === getData.department);
    if (filterPreviousData.length > 0) {
      filterPreviousData = filterPreviousData.filter(value => value.function !== getData.function);
      if (filterPreviousData.length > 0) {
        filterPreviousData.map(filterId => {
          previousFunctionOptions = previousFunctionOptions.filter(value => {
            if (filterId.function !== value.value) {
              return value;
            }
            return null;
          });
          return null;
        });
        this.setState({ functionOptions: previousFunctionOptions });
      } else {
        this.setState({ functionOptions: previousFunctionOptions });
      }
    } else {
      this.setState({ functionOptions: previousFunctionOptions });
    }
  };

  departmentSelect = async selectedOption => {
    const { functionOptionsBefore, data } = this.state;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    let previousFunctionOptions = [...functionOptionsBefore];
    const previousData = [...data];
    let filterPreviousData = previousData.filter(value => value.department === selectedOption.value);
    if (filterPreviousData.length > 0) {
      filterPreviousData.map(filterId => {
        previousFunctionOptions = previousFunctionOptions.filter(value => {
          if (filterId.function !== value.value) {
            return value;
          }
          return null;
        });
        return null;
      });
      this.setState({ functionOptions: previousFunctionOptions, functionSelected: null, functionId: null, functionLabelName: null });
    } else {
      this.setState({ functionOptions: previousFunctionOptions, functionSelected: null, functionId: null, functionLabelName: null });
    }
  };

  functionSelect = async selectedOption => {
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
  };

  onSubmit = async () => {
    const { departmentId, functionId, adminId } = this.state;
    if (this.validate(departmentId, 'errordepartmentSelected', 'Please Select Department')) return true;
    if (this.validate(functionId, 'errorfunctionSelected', 'Please Select Function')) return true;
    const formData = new FormData();
    formData.append('department', departmentId);
    formData.append('function', functionId);
    formData.append('cmid', adminId);

    try {
      const result = await MappingMiddleware.mapDepartmentToFunction(formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          departmentSelected: null,
          departmentId: null,
          departmentLabelName: null,
          errordepartmentSelected: null,
          functionOptions: [],
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onUpdate = async () => {
    const { index, departmentId, functionId, adminId } = this.state;

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;

    if (this.validate(departmentId, 'errordepartmentSelected', 'Please Select Department')) return true;
    if (this.validate(functionId, 'errorfunctionSelected', 'Please Select Function')) return true;
    const formData = new FormData();
    formData.append('department', departmentId);
    formData.append('function', functionId);
    formData.append('cmid', adminId);

    try {
      const result = await MappingMiddleware.updateDepartmentToFunction(id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          departmentSelected: null,
          departmentId: null,
          departmentLabelName: null,
          errordepartmentSelected: null,
          functionOptions: [],
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null,
          updateData: false
        });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  render() {
    const { departmentOptions, departmentSelected, errordepartmentSelected, functionOptions, functionSelected, errorfunctionSelected, updateData, data } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className='container container-sm-fluid mt-100'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-lg-8 col-md-12 col-12'>
                <div className='content-border px-2'>
                  <div className='header-text px-lg-3'>
                    <span>Map Theme To WorkLevel</span>
                  </div>
                  <hr />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                      <label htmlFor="department">Department</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      <SingleSelect handleChange={this.departmentSelect} options={departmentOptions} selectedService={departmentSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errordepartmentSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                      <label htmlFor="function">Function</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      <SingleSelect handleChange={this.functionSelect} options={functionOptions} selectedService={functionSelected} />
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                  <ValidationView errorname={errorfunctionSelected} />
                  <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-1 d-none d-lg-block' />
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 py-1'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12 py-1'>
                      {!updateData ? (
                        <button type="button" className="submit-btn" onClick={this.onSubmit}>
                          Submit
                        </button>
                      ) : (
                        <button type="button" className="submit-btn " onClick={this.onUpdate}>
                          Update
                        </button>
                      )}
                    </div>
                    <div className='col-lg-1 d-none d-lg-block' />
                  </div>
                </div>
              </div>
            </div>
            <div className='px-2 col-12 py-5 my-5'>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapDepartmentToFunction;
