import React, { Component } from "react";
import { FormMiddleWare, SingleSelect } from "../../../components/Form";
import { Style } from "react-style-tag";
import Swal from 'sweetalert2';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FilePond } from 'react-filepond';
import { CSVLink } from "react-csv";
import HrMiddleWare from "../../../middleWare/HrMiddleWare";



// import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import Datatable from "../../components/Datatable/Datatable";


const tableName = `tbl_trainingprogram`;

class TraingprogramAdd extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            trainingProgram: null,
            errorTrainingProgram: null,
            trainingprogramvalue: null,
            errorTrainingprogramvalue: null,
            alertVisible: false,
            updateData: false,
            showDatatable: true,
            multyprogramsAdd: true,
            statusArray: [],
            userAddRow: "",
            RowCount: "",
            showDatatable: true,
            submitDataAlert: false,
            multyIndicatorsAdd:true,
            leveOption: [{ label: "Score-1", value: 1 },
            { label: "Score-2", value: 2 },
            { label: "Score-3", value: 3 },
            { label: "Score-4", value: 4 },
            ],
            addUserColumn: [

                { label: "TrainingProgram", value: 1, type: "selectbox", selectOptions: [] },
                {
                    label: "Score", value: 2, type: "selectbox", selectOptions: [
                        { label: "Score-1", value: 1 },
                        { label: "Score-2", value: 2 },
                        { label: "Score-3", value: 3 },
                        { label: "Score-4", value: 4 },
                    ]
                },
            ],
            addUserColumn1: [

                { label: "TrainingPrograms", value: 1, type: "text" }
            ],
            vidhyaUser: localStorage.getItem("userDetailsVidhya"),
            importvidhya: false,
        }
    }
    column = [
        {
            Header: 'Si No',
            accessor: 'si no',
            Cell: d => this.siNo(d)
        },
        {
            Header: 'Training program name',
            accessor: 'trainingProgram'
        },
        {
            Header: 'Training program Value',
            accessor: 'trainingprogramvalue'
        },
        // {
        //   Header: 'Edit',
        //   accessor: 'edit',
        //   Cell: d => this.edit(d)
        // },
        // {
        //   Header: 'Delete',
        //   accessor: 'delete',
        //   Cell: d => this.delete(d, tableName)
        // }
    ];

    siNo = d => {
        return <label>{d.index + 1}</label>;
    };

    async componentWillMount() {
        try {
            const adminId = await localStorage.getItem("userId");
            const yearId = await localStorage.getItem("yearId");
            const { data } = await MasterMiddleWare.getMaster(tableName, adminId);
            if (data) {
                this.setState({
                    data,
                    adminId
                });
            }
            this.GetUserData1();
        } catch (error) {
            console.error(error);
        }
    }


    levelSelect = selectedOption => {
        this.setState({
            trainingprogramvalueSelected: selectedOption,
            trainingprogramvalue: selectedOption.value
        });
    };

    onSubmit = async () => {
        const { trainingProgram, trainingprogramvalue, adminId, yearId } = this.state;
        // const adminId = await localStorage.getItem("userId");
        if (this.validate(trainingProgram, 'errorTrainingProgram', 'Please enter training program')) return true;
        // if (this.validate(trainingprogramvalue, 'errorTrainingprogramvalue', 'Please select training program value')) return true;
        const formData = new FormData();
        formData.append('trainingProgram', trainingProgram);
        // formData.append('trainingprogramvalue', trainingprogramvalue);
        // formData.append('yearId', yearId);
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        formData.append('status', 'active');
        // console.log([...formData], "formdata");
        const previousData = [...this.state.data];
        try {
            // console.log(tableName, "tableName");
            const result = await MasterMiddleWare.createMaster(tableName, formData);
            // console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    trainingProgram: '',
                    // trainingprogramvalueSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            this.setState({
                data: previousData
            });
            console.log(error);
        }
    };

    singlemultiselect = (e, val) => {
        if (val == "2") {
            this.setState({ showDatatable: false })
        }
        this.setState({
            multi: val
        });
    };

    addRow = (value) => {
        console.log(value, "values");
        let { userAddRow, statusArray } = this.state
        // this.setState({ statusArray: [] })
        let addIndex = value ? statusArray.length : 0

        const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
            TrainingPrograms: "",
            TrainingProgram: "",
            TrainingProgramSelect: "",
            TrainingProgramOption: this.state.ProgramNames,
            // Score: "",
            // ScoreSelect: "",
            // ScoreOption: [
            //     { label: "Score-1", value: 1 },
            //     { label: "Score-2", value: 2 },
            //     { label: "Score-3", value: 3 },
            //     { label: "Score-4", value: 4 },
            // ],
            index: value ? addIndex : index

        }));
        this.setState(prevState => ({
            statusArray: [...prevState.statusArray, ...newItems]
        }));

        console.log(statusArray, "statusArray");
    }

    handleInputChange = (e, rowIndex, columnLabel) => {
        const { value } = e.target;
        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel]: value } : row
            )
        }));
    };

    handleSelectChange = async (value, rowIndex, columnLabel) => {
        let { adminId } = this.state
        // console.log(value, "value");
        // console.log(rowIndex, "rowIndex");
        // console.log(columnLabel, "columnLabel");
        // return false
        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "TrainingProgram" || columnLabel === "TrainingPrograms" ? value.label : value.value } : row
            )
        }));
        // if (columnLabel === "TrainingProgram") {
        //     const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(value.label, adminId);

        //     // console.log(competencyOptions, "competencyOptions");
        //     this.setState(prevState => ({
        //         statusArray: prevState.statusArray.map((row, index) =>
        //             index === rowIndex ? { ...row, CompetencyOption: competencyOptions } : row
        //         )
        //     }));
        // }
    };

    GetUserData = async () => {
        // const EmplyoeeId = 112001
        // const Password = "112001"
        const EmplyoeeId = 10123
        const Password = "coromandel"
        try {

            const Employee = await HrMiddleWare.getFreedom("*", "cmndl_tbl_Employee", `employee_id= '${EmplyoeeId}' and customerid=807 and status = 0`, 1, 1);
            // console.log(Employee,"Employee");
            if (Employee && Employee.data.length) {
                if (Employee && Employee.data.length) {
                    if (Employee.data[0].is_register == 1 && Employee.data[0].userid) {
                        const getuserdet = await HrMiddleWare.getFreedom(
                            "*",
                            "tbl_user_web",
                            `id=${Employee.data[0].userid} and status = "active"`,
                            1, 1
                        )
                        // console.log(getuserdet,"getuserdet");
                        if (getuserdet && getuserdet.data && getuserdet.data.length) {
                            if (Password === getuserdet.data[0].withoutmd5) {
                                getuserdet.data[0].Employeeid = EmplyoeeId
                                localStorage.setItem("userDetailsVidhya", JSON.stringify(getuserdet.data[0]));
                                // geting cource list
                                // console.log(this.state.vidhyaUser, "vidhyaUser");
                                let result = await HrMiddleWare.getCoursesList(JSON.parse(this.state.vidhyaUser), 310);
                                // console.log(result, "result");
                                // console.log(result.data, 'dat..');
                                if (result && result.data) {
                                    this.setState({ vidhyaClusterData: result.data })
                                    let vidhyaClusterData = result.data
                                    let ProgramNames = []
                                    if (vidhyaClusterData) {
                                        let wait = await vidhyaClusterData.map((ival) => {
                                            if (ival.data) {
                                                ival.data.map((jval) => {
                                                    if (jval.data) {
                                                        jval.data.map((kval) => {
                                                            // console.log(kval,"kval");
                                                            if (kval.subCategoryName) {
                                                                let label = kval.subCategoryName
                                                                let value = kval.pageId
                                                                ProgramNames.push({ label, value })
                                                                // ProgramNames.push(kval.subCategoryName)
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        Promise.all(wait)
                                        this.setState({ ProgramNames })
                                    }
                                }
                            } else {
                                this.setState({ errorUser: true, errorMsg: 'Incorrect password' })
                            }
                        } else {
                            this.setState({ errorUser: true, errorMsg: 'User Not Found' })
                        }

                    } else {
                        // this.setState({ modal15: true })
                        // this.register()
                    }
                }
            } else {
                // window.location.href = '/'
                this.setState({ errorUser: true, errorMsg: 'User Not Found' })
            }
        } catch (error) { console.log(error) }
    }

    GetUserData1 = async () => {

        try {
            // geting cource list
            const result = await HrMiddleWare.getFreedom(
                "*",
                "tbl_mapping_page",
                `categoryId= 310 and customerid=807`,
                1, 1);
            console.log(result, "resultresult");

            // let result = await HrMiddleWare.getCoursesList(JSON.parse(this.state.vidhyaUser), 310);
            // console.log(result, "result");
            // console.log(result.data, 'dat..');
            if (result && result.data) {
                this.setState({ vidhyaClusterData: result.data })
                let vidhyaClusterData = result.data
                let ProgramNames = []
                if (vidhyaClusterData) {
                    let wait = await vidhyaClusterData.map((ival) => {
                        if (ival.subCategoryName && ival.pageId) {
                            let label = ival.subCategoryName
                            let value = ival.pageId
                            ProgramNames.push({ label, value })
                        }
                    })
                    Promise.all(wait)
                    this.setState({ ProgramNames })
                }
            }

        } catch (error) { console.log(error) }
    }

    addMultySubmit = async () => {
        let { statusArray, adminId } = this.state
        let statusArray1 = []
        // console.log(statusArray,"statusArray");
        statusArray.map((ival) => {
            let result = {}
            if (ival.TrainingPrograms != "") {
                result.TrainingPrograms = ival.TrainingPrograms;
                // result.Score = ival.Score;
            } else {
                result.TrainingProgram = ival.TrainingProgram;
                // result.Score = ival.Score;
                result.TrainingProgramSelect = ival.TrainingProgramSelect;
            }

            result.index = ival.index;
            statusArray1.push(result);
        })
        // console.log(statusArray1,"statusArray1");
        return new Promise((resolve, reject) => {
            this.setState({ submitDataAlert: true });
            setTimeout(() => {
                this.setState({ submitDataAlert: false });
                resolve();
            }, 3000);
        })
            .then(() => {
                const formData = new FormData();
                // console.log(statusArray,"statusArray");
                // return false
                formData.append("statusArray", JSON.stringify(statusArray1));
                // formData.append('groupcompId', groupcompId)
                formData.append('adminId', adminId);
                formData.append('customerid', adminId);
                const previousData = [...this.state.data];
                return MasterMiddleWare.insertMuldidata(tableName, formData);
            })
            .then(result => {
                if (result.data.Message == "success") {
                    if (result.data.fillDataErrorMsg) {
                        const maxLength = 29;
                        const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
                        console.log(wrappedErrorMsg, "wrappedErrorMsg");
                        Swal.fire({
                            position: 'center',
                            type: 'warning',
                            title: wrappedErrorMsg,
                            showConfirmButton: false,
                            timer: 6000
                        });
                        // Swal.fire({
                        //   position: 'center',
                        //   type: 'warning',
                        //   title: `${result.data.fillDataErrorMsg}`,
                        //   showConfirmButton: false,
                        //   timer: 2000

                        // });
                    } else {

                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Training Programs has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        this.getMaster(tableName);
                        this.setState({
                            alertVisible: true,
                            btnDisablecsv: false,
                            statusArray: "",
                            userAddRow: ""
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                    }

                }
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    render() {
        // let { userData, section1Data } = this.state
        const { errorTrainingProgram, errorTrainingprogramvalue, updateData, trainingProgram, leveOption, trainingprogramvalueSelected,
            showDatatable, data, statusArray, addUserColumn, addUserColumn1, userAddRow, ProgramNames } = this.state;
        // console.log(data, "data");
        // console.log(ProgramNames, "ProgramNames");
        return (
            <React.Fragment>
                <section className='container container-sm-fluid mt-100 pb-5 mb-5'>
                    <div className='row justify-content-center align-items-center my-2'>
                        <div className='col-lg-10 col-md-12 col-12'>
                            <div className='content-border px-lg-5 px-md-3 px-2'>
                                <div className=' header-text '>
                                    <span>Training Program</span>
                                </div>
                                <hr className='px-0' />
                                <div className='row justify-content-between align-items-center py-2 text'>
                                    <div className='col-lg-1 d-none d-lg-block' />
                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 text py-2'>
                                        <div className="" onClick={(e) => this.singlemultiselect(e, 1)} >
                                            <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">Add single training program</label>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                        <div className="" onClick={(e) => this.singlemultiselect(e, 2)}>
                                            <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency"> Add multiple training programs</label>
                                        </div>
                                    </div>
                                    <div className='col-lg-1 d-none d-lg-block' />
                                </div>
                                {this.state.multyIndicatorsAdd ?
                                    <div>
                                        {this.state.multi === 2 ?
                                            <div>
                                                <div>
                                                    <div className='row justify-content-between align-items-center py-2'>
                                                        <div className='col-lg-1 d-none d-lg-block' />
                                                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                                            <label htmlFor="exampleInputEmail1">
                                                                Upload Files:
                                                            </label>
                                                        </div>
                                                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                            <FilePond
                                                                // type="file"
                                                                files={this.state.files}
                                                                // className="custom-file-input"
                                                                id="customFile"
                                                                // accept='.csv'
                                                                acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                                                                // onChange={this.fileupload}
                                                                // files={this.state.Testimonial_image}
                                                                onupdatefiles={this.handleFileUpload}
                                                                allowReorder={true}
                                                                allowMultiple={false}
                                                                width={10}
                                                                maxFileSize={'300KB'}
                                                                // imageValidateSizeMaxWidth={550}
                                                                // imageValidateSizeMaxHeight={355}
                                                                imagePreviewMinHeight="150"
                                                                imagePreviewMaxHeight="150"
                                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                            />
                                                        </div>
                                                        <div className='col-lg-1 d-none d-lg-block' />
                                                    </div>
                                                    <div className='row justify-content-between align-items-center py-2'>
                                                        <div className='col-lg-1 d-none d-lg-block' />
                                                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 text py-2 '>
                                                            <button className='btncolor ' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create  jobrole CSV file</button>
                                                        </div>
                                                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                            <button
                                                                type="button"
                                                                className="btncolor"
                                                                onClick={(e) => this.submitcsv(e)}
                                                                disabled={this.state.btnDisablecsv ? true : false}
                                                            >
                                                                {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                                {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                                                            </button>
                                                        </div>
                                                        <div className='col-lg-1 d-none d-lg-block' />
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div className='py-3'>
                                                <div className='row justify-content-between align-items-center py-2'>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                                        <label htmlFor="jobRoleName">Traing program</label>
                                                    </div>
                                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                        <input type="text" className="form-control inputss" name="trainingProgram" id="trainingProgram" placeholder="Please enter traing program name" value={trainingProgram} onChange={this.handleUserInput} />
                                                    </div>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                </div>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                                                    </div>
                                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                                                        <span className="errorMsg">{errorTrainingProgram}</span>
                                                    </div>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                </div>
                                                <div className='row justify-content-between align-items-center '>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                                                    </div>
                                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                        {!updateData ? (
                                                            <button type="button" className="submit-btn " onClick={this.onSubmit}>
                                                                Submit
                                                            </button>
                                                        ) : (
                                                            <button type="button" className="submit-btn success" onClick={this.onUpdate}>
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                </div>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                                                    </div>
                                                    <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                                                    </div>
                                                    <div className='col-lg-1 d-none d-lg-block' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            <div className='col-12 text-start px-2 px-lg-5 px-md-2'>
                                                <button className='btncolor text-start' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                                            </div>
                                            <br></br>
                                            <div className='row justify-content-between align-items-center py-2'>
                                                <div className='col-lg-1 d-none d-lg-block' />
                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                                    <label htmlFor="indicators">How many training Programs add ?</label>
                                                </div>
                                                <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                    <input type="number" className="form-control inputss" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                                                </div>
                                                <div className='col-lg-1 d-none d-lg-block' />
                                            </div>
                                            <div className='row justify-content-between align-items-center py-2'>
                                                <div className='col-lg-1 d-none d-lg-block' />
                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                                </div>
                                                <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                    <button className='btncolor' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                                                </div>
                                                <div className='col-lg-1 d-none d-lg-block' />
                                            </div>

                                            {this.state.submitDataAlert ?
                                                <div className='loader1'></div>
                                                :
                                                <>
                                                    {statusArray && statusArray.length > 0 && (
                                                        <>
                                                            <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>S.No </th>

                                                                            {addUserColumn1.map((index) => (
                                                                                <th key={index}> {index.label}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                                                                            // console.log(ival,"type",i,"i")

                                                                            <tr>
                                                                                <td style={{ textAlign: "center" }} >{i + 1}</td>
                                                                                {addUserColumn1.map((jval, index) => (
                                                                                    <>
                                                                                        <td style={{ textAlign: "center" }}  >{jval && jval.type === "text" ? <>
                                                                                            <textarea 
                                                                                            className="inputss"
                                                                                                style={{ height: "50px", width: "280px" }}
                                                                                                // type="text"
                                                                                                value={ival[jval.label]}
                                                                                                onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                            /></> : jval.type === "selectbox" ?
                                                                                            <div style={{ width: "280px" }}>
                                                                                                <SingleSelect
                                                                                                    handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                                                                    options={ival[jval.label + 'Option']}
                                                                                                    selectedService={ival[jval.label + "Select"]}
                                                                                                // disabled={false}
                                                                                                />

                                                                                                {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                                                            </div>
                                                                                            :
                                                                                            <>
                                                                                            </>}
                                                                                        </td>
                                                                                    </>
                                                                                ))}
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                                {this.state.submitDataAlert ?
                                                                    <div className='loader1'></div>
                                                                    :
                                                                    <>
                                                                        {statusArray && statusArray.length > 0 &&
                                                                            <div className='row justify-content-between align-items-center py-2'>
                                                                                <div className='col-lg-1 d-none d-lg-block' />
                                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                                                                    <button className='btncolor' onClick={() => this.addRow(1)}>Add one</button>
                                                                                </div>
                                                                                <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                                                                    <button type="button" className=" submit-btn" onClick={this.addMultySubmit}>
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                                <div className='col-lg-1 d-none d-lg-block' />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>

                                                        </>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className='col-12 px-lg-5 px-md-3 px-2 py-5 mb-5'>
                    {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}
                </div>
                <Style>
                    {`/*
      Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
      Template Version: 1.0
      Author: Carlos Alvarez
      Website: http://blacktie.co
      Premium: http://www.gridgum.com
      */
      
      /* Import fonts */
      @import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);
      
      /* BASIC THEME CONFIGURATION */
      .filepond{
        height: 82px;
        background-color: #f1f1f1;
        border-radius: 20px;
        width: 300px;
      }
      
      .filepond--credits{
        color:#f7f7f7;
      }
      
      .filepond--root :not(text) {
        font-size: 13px;
      }
      
      .multi:hover{
        color: limegreen;
      }
      
      
      `}

                </Style >
            </React.Fragment>
        );

    }
}

export default TraingprogramAdd;