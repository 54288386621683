

import React from 'react';
import HrMiddleWare from '../../middleWare/HrMiddleWare';
import MappingMiddleware from '../../middleWare/MappingMiddleware';
import { SingleSelect, FormMiddleWare } from '../../components/Form';
import { Bar } from 'react-chartjs-2';
import './style.css';

export default class ProfileChart extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      adminId: null,
      groupcompId: null,
      userDetails: [],
      dataChart: {
        labels: ['Supervisor', 'Self', 'Reportee', 'Customer', 'Peer'],
        datasets: [
          {
            backgroundColor: '5cb85c',
            hoverBackgroundColor: '5cb85c',
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          fontColor: '#634aad'
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 5,
                stepSize: 1
              }
            }
          ]
        }
      },
      compChart: {
        labels: [],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: '5cb85c',
            hoverBackgroundColor: '5cb85c',
            data: []
          }
        ]
      },
      behTech: [{ label: 'Behavioural', value: 1 }, { label: 'Technical', value: 2 }],
      startDate: new Date(),
      endDate: new Date(),
      UpdateValue: {},
      scheduled_start_date: new Date(),
      scheduled_end_date: new Date(),
      start_date: null,
      end_date: null,
      BehData: [],
      TechData: [],
      CompHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" }
      ],
      BehIndi: [],
      TechIndi: [],
      IndiHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" },
        { label: "INDICATORS", key: "indicators" }
      ]
    };
  }

  async componentWillMount() {
    this.getUserDetails();
    this.getChartData();

    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }

  }

  getUserDetails = async () => {
    let adminId = await localStorage.getItem('adminId');
    let groupcompId = await localStorage.getItem('groupcompId');
    let userId = this.state.userId;
    // await localStorage.getItem('userId');
    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    let job = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `id = ${userData.data[0].jobRole}`,
      "jobRoleName as jobRoleName"
    );
    let designation = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `id = ${userData.data[0].designation}`,
      "designation as designation"
    );
    let descrip = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `jobRole = ${userData.data[0].jobRole} and
       department = ${userData.data[0].department} and
       customerid = ${adminId} and status ='active'`,
      "*"
    );
    const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);
    if (userDetails) {
      this.setState({ adminId, userId, groupcompId, userDetails, userData: userData.data, job: job.data, designation: designation.data, descrip: descrip.data });
    }
  };

  getChartData = async () => {
    const { dataChart, compChart } = this.state;
    let userId = this.state.userId;
    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    // await localStorage.getItem('userId');
    const value1 = [userId,userData.data[0].jobRole]
    const { data } = await HrMiddleWare.getChartDataEmployee(value1);
    if (data) {
      dataChart.datasets[0].data = data;
      this.setState({ dataChart });
    }
    const result = await HrMiddleWare.getCompetencyChartDataemployee(value1);
    if (result) {
      let labelsArray = [];
      let dataArray = [];
      result.data.map(list => {
        labelsArray.push(list.x);
        dataArray.push(list.y);
      });
      compChart.datasets[0].data = dataArray;
      console.log(labelsArray,"for map");
      const labels = labelsArray.map(label => 
        label.charAt(0).toUpperCase() + label.slice(1)
      );
      compChart.labels = labels;
      console.log(compChart.labels,"competency labels");
      this.setState({ compChart });
    }
  };

  render() {
    const { dataChart, options, compChart } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className='col-12'>
            <div className='content-border1'>
              <div className=''>
                <div className='py-2 text-center header-text'>
                  <h4>Themes & Competencies Mapped</h4>
                </div>
                <div className='py-3 px-2 px-lg-4'>
                  <h3>Employee Chart</h3>
                  <Bar
                    data={dataChart}
                    width={100}
                    height={30}
                    options={options}
                  />
                </div>
                <div className='py-3 px-2 px-lg-4'>
                  <h3>Competency Chart</h3>
                  <Bar
                    data={compChart}
                    width={100}
                    height={30}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
