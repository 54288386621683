import React, { Component } from "react";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import Swal from "sweetalert2";
import LoginModal from "../../components/Modal/Modal";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Dictionary from "./Dictionary";
import "./style.css";

class ViewIndicatorList extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      lvlSelect: [],
      groupcompany: localStorage.getItem("groupcompId"),
      disabledIndi: this.props.disabledIndi,
      work: this.props.work,
      type: this.props.type,
      comp: this.props.comp,
      indicatorsSelected: [],
      indicatorList: this.props.indicatorList,
      levelList: this.props.levelList,
      alreadySelectedIndicator: this.props.alreadySelectedIndicator,
      originalMappedArray: this.props.originalMappedArray,
      matrixType: this.props.techBehTypeSelected,
      selectedCompetency: this.props.CMcompSelect,
      themeSelected: this.props.CMthemeSelect,
      jobRoleId: this.props.CMjobSelect,
      workLevelId: this.props.CMworkSelect,
      companyId: this.props.companyid,
      financialYearId: this.props.CMyearList,
      levelType: this.props.levelType,
      button: "Submit",
      indicators: null,
      Description: null,
      index: null,
      disableSubmit: null,
      cmpdatalist: this.props.cmpdatalist,
      themeNamelabel: this.props.themeNamelabel,
      checking: null,
      comptencySelected: this.props.competency
    };
  }

  mapSelectedIndicators = (indicatorId, levelId) => {
    const { alreadySelectedIndicator, originalMappedArray } = this.state;
    let indicatorsSelected = [];
    if (alreadySelectedIndicator.length > 0) {
      indicatorsSelected = [...alreadySelectedIndicator];
    }
    if (indicatorsSelected.length > 0) {
      if (indicatorsSelected.includes(indicatorId)) {
        let removedList = indicatorsSelected.filter(
          indicator => indicator !== indicatorId
        );

        let removedSelectedIdList = alreadySelectedIndicator.filter(
          indicator => indicator !== indicatorId
        );
        this.setState({
          indicatorsSelected: removedList,
          alreadySelectedIndicator: removedSelectedIdList,
          indicatorClicked:
            JSON.stringify(originalMappedArray.sort()) ===
              JSON.stringify(removedSelectedIdList.sort())
              ? false
              : true
        });
      } else {
        indicatorsSelected.push(indicatorId);
        alreadySelectedIndicator.push(indicatorId);
        this.setState({
          indicatorsSelected,
          alreadySelectedIndicator,
          indicatorClicked: true
        });
      }
    } else {
      indicatorsSelected.push(indicatorId);
      alreadySelectedIndicator.push(indicatorId);
      this.setState({
        indicatorsSelected,
        indicatorClicked: true,
        alreadySelectedIndicator
      });
    }
  };

  onSubmit = async () => {
    let {
      groupcompany,
      selectedCompetency,
      indicatorsSelected,
      themeSelected,
      jobRoleId,
      workLevelId,
      companyId,
      financialYearId,
      indicatorList,
      matrixType,
      disabledIndi
    } = this.state;

    let indicatorWithLevel = [];
    indicatorList.map(indicatorList => {
      indicatorsSelected.filter(indicId => {
        if (parseInt(indicatorList.indicatorId) === parseInt(indicId)) {
          indicatorWithLevel.push(indicatorList);
        }
      });
    });

    const formData = new FormData();
    formData.append("theme", themeSelected);
    formData.append("worklevel", workLevelId);
    formData.append("jobRoleId", jobRoleId);
    formData.append("levelid", JSON.stringify(indicatorWithLevel));
    formData.append("competencyId", this.state.selectedCompetency);
    formData.append("indicators", JSON.stringify(indicatorWithLevel));
    formData.append("adminId", companyId);
    formData.append("type", "framework");
    formData.append("financial_year", financialYearId);
    formData.append("categoryType", matrixType);
    formData.append("groupcompany", localStorage.getItem("groupcompId"));
    try {
      if (disabledIndi === true) {
        this.popUp();
      } else {
        const result = await MappingMiddleware.mappedMatrixAdmin(formData);
        if (result) {
          this.props.fun_CMcompSelect(this.state.comptencySelected);
          this.setState({
            // indicatorsSelected: [],
            disabledIndi: false,
            indicatorClicked: false
          });
          Swal.fire({
            position: "center",
            type: "success",
            title: "Changes Saved Successfully",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  popUp = () => {
    Swal.fire({
      title: "Operation Not Permitted",
      type: "error",
      html:
        "<p>Assessment Rating already exists.<br>You cannot map new competencies or indicators.</p>"
    });
  };
  indicatorText = e => {
    this.setState({ indicatorText: e.target.value });
  };
  descriptionText = e => {
    this.setState({ descriptionText: e.target.value });
  };
  function = e => {
    let { levelType } = this.state;
    var lvlSelect = levelType.filter((item, i) => {
      return item.value == e;
    });
    this.setState({ lvlSelect: lvlSelect });
  };

  submit = async () => {
    const {
      groupcompany,
      work,
      type,
      selectedCompetency,
      descriptionText,
      indicatorText,
      companyId,
      themeSelected,
      jobRoleId,
      workLevelId,
      financialYearId,
      lvlSelect
    } = this.state;

    const formData = new FormData();
    formData.append("matrixType", type.label);

    formData.append("CmpId", selectedCompetency);

    formData.append("indicators", indicatorText);
    formData.append("Description", descriptionText);
    formData.append("themeId", themeSelected);

    formData.append("jobRoleId", jobRoleId);
    formData.append("financial_year", financialYearId);
    formData.append("worklevel", workLevelId);
    formData.append("level", lvlSelect[0].label);

    formData.append("adminId", companyId);
    formData.append("status", "active");
    formData.append("groupcompany", localStorage.getItem("groupcompId"));

    let result = await HrMiddleWare.mappingIndicatorUser(
      formData,
      "tbl_cmpLevel",
      "tbl_indicators",
      "mapped_matrix_to_user",
      1
    );

    if (result) {
      this.props.fun_CMcompSelect(this.state.comptencySelected);
      this.setState({ indicatorText: "", descriptionText: "" });
    }
  };

  fun = async e => {
    let push = [];
    let {
      levelType,
      type,
      comp,
      comptencySelected,
      indicatorText,
      descriptionText
    } = this.state;
    var lvlSelect = levelType.filter((item, i) => {
      return item.value == e;
    });
    this.setState({ lvlSelect: lvlSelect });
    push.push(
      // <LoginModal
      //   // buttonTitle=
      //   extraClass="fa fa-edit"
      //   title="Add New Indicator"
      //   id="level1"
      //    extraStyle={{ backgroundColor: "#56528F" }}
      //   bodyText={
      <div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label>Competency Type</label>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <SingleSelect selectedService={type} />
          </div>
          <div className="col-sm-2" />
        </div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label>Competency </label>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <SingleSelect selectedService={comptencySelected} />
          </div>
          <div className="col-sm-2" />
        </div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label>Level </label>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <SingleSelect options={levelType} selectedService={lvlSelect} />
          </div>
          <div className="col-sm-2" />
        </div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label>Indicator</label>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <input
              type="text"
              className="form-control"
              // placeholder="Enter Job Description Text "
              onChange={e => this.indicatorText(e)}
              value={indicatorText}
            />
          </div>
          <div className="col-sm-2" />
        </div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label>Description</label>
          </div>
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter Description Text "
              onChange={e => this.descriptionText(e)}
              value={descriptionText}
            />
          </div>
          <div className="col-sm-2" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-3" />
          <div className="col-sm-5">
            <button
              type="button"
              className="btncolorsubmit"
              onClick={this.submit}
            >
              Submit
            </button>
          </div>
          <div className="col-sm-2" />
        </div>
      </div>
      //   }
      // />
    );
    await this.setState({ push: push });
  };

  change = async e => {
    let body = [];
    body.push();
    this.setState({ body, button: "Update" });
  };

  update = async () => {
    let id = this.state.editIndicatorId;
    let indicators = this.state.editIndicatorName;
    this.setState({ button: "Update" });

    const formData = new FormData();
    formData.append("indicators", indicators);
    try {
      const result = await MasterMiddleWare.updateMaster(
        "tbl_indicators",
        id,
        formData
      );
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "Indicators has been updated",
          showConfirmButton: false,
          timer: 1500
        });
        this.props.fun_CMcompSelect(this.state.comptencySelected);
      }
    } catch (error) {
      console.log(error);
    }
  };

  deletes = async value => {
    let id = value;
    let previousData = [...this.state.indicatorList];
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this file!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it"
    }).then(async result => {
      if (result.value) {
        try {
          const result = await HrMiddleWare.deleteIndicatorById(
            "tbl_indicators",
            id
          );
          if (result) {
            let datas = previousData.filter((delelteid, i) => {
              if (delelteid.indicatorId !== value) {
                return delelteid;
              }
            });

            this.setState({
              indicatorList: datas
            });
          }
          if (value) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.props.fun_CMcompSelect(this.state.comptencySelected);
          }
        } catch (error) {
          Swal.fire("Cancelled", "Something Went Wrong :)", "error");

          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your file is safe :)", "error");
      }
    });
  };

  diffheading = async () => {
    if (this.props.comp && this.props.CMthemeSelect) {
      let themecheck = await HrMiddleWare.getRandomData(
        "ClusterMaster",
        `ClusterMaster.themeId=${this.props.CMthemeSelect}`,
        "ClusterMaster.themeId"
      );
      this.state = { DictionaryType: "" };
      if (themecheck.data.length != 0) {
        await this.setState({ DictionaryType: "Dictionary 2" });
      } else {
        await this.setState({ DictionaryType: "Dictionary 1" });
      }
    }
  };
  async componentWillReceiveProps(props) {
    this.state = {
      lvlSelect: [],
      disabledIndi: props.disabledIndi,
      work: props.work,
      type: props.type,
      comp: props.comp,
      indicatorsSelected: [],
      indicatorList: props.indicatorList,
      levelList: props.levelList,
      alreadySelectedIndicator: props.alreadySelectedIndicator,
      originalMappedArray: props.originalMappedArray,
      matrixType: props.techBehTypeSelected,
      selectedCompetency: props.CMcompSelect,
      themeSelected: props.CMthemeSelect,
      jobRoleId: props.CMjobSelect,
      workLevelId: props.CMworkSelect,
      companyId: props.companyid,
      financialYearId: props.CMyearList,
      levelType: props.levelType,
      button: "Submit",
      indicators: null,
      Description: null,
      index: null,
      disableSubmit: null,
      cmpdatalist: props.cmpdatalist,
      themeNamelabel: props.themeNamelabel,
      comptencySelected: props.competency
    };
  }

  render() {
    console.log();
    console.log(this.state.push, "push");
    console.log(this.state.levelList, "indicator - levelList from");

    // this.diffheading();
    let {
      levelList,
      lvlSelect,
      label,
      indicatorList,
      alreadySelectedIndicator,
      type,
      comp,
      work,
      indicatorText,
      descriptionText,
      levelType,
      push1,
      indicators,
      Description,
      disableSubmit
    } = this.props;
    let indicatorArrayLevel1 = [];
    let indicatorArrayLevel2 = [];
    let indicatorArrayLevel3 = [];
    let indicatorArrayLevel4 = [];

    if (this.state.alreadySelectedIndicator && levelList) {
      levelList.map((levels, index) => {
        indicatorList.map((indicators, key) => {

          if (levels.levelid === indicators.levelid) {
            if (
              indicators.Level.trim() === "level 1" ||
              indicators.Level.trim() === "Level 1" ||
              indicators.Level.trim() === "Level-1" ||
              indicators.Level.trim() === "level-1" ||
              indicators.Level.trim() === "level-1"

            ) {
              indicatorArrayLevel1.push(
                <div class="checkbox">
                  <i
                    class="fa fa-trash-o"
                    onClick={() => this.deletes(indicators.indicatorId)}
                  ></i>
                  <LoginModal
                    // buttonTitle = "add"
                    extraClass="fa fa-edit"
                    atagLink="true"
                    title="Edit Indicator"
                    id="editIndicator"
                    extraStyle={{ backgroundColor: "#56528F " }}
                    onClick={() => {
                      this.setState({
                        editIndicatorId: indicators.indicatorId,
                        editIndicatorName: indicators.indicators
                      });
                      setTimeout(() => this.change(indicators.indicators), 100);
                    }}
                    bodyText={
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label>Indicator</label>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm-5">
                            <div>
                              {this.state.editIndicatorName != undefined &&
                                this.state.editIndicatorName != null &&
                                this.state.editIndicatorName != "" ? (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={this.state.editIndicatorName}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              ) : (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={""}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-success"
                              data-dismiss="modal"
                              onClick={
                                this.state.button === "Update"
                                  ? this.update
                                  : this.submit
                              }
                            >
                              {this.state.button}
                            </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                      </div>
                    }
                  />
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        this.state.alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                  </label>
                  {indicators.indicators}&emsp;&emsp;&emsp;{" "}
                  {/* <a
                    // onClick={() => {
                    //   this.setState({ editJson: key });
                    // }}

                    id={"editIndicators"}
                    data-toggle="modal"
                    data-target={`editIndicator`}
                    onClick={() => this.f1}
                  >
                    {" "}
                    efiuy{" "}
                  </a> */}
                </div>
              );
            } else if (
              indicators.Level.trim() === "LEVEL 2" ||
              indicators.Level.trim() === "Level 2" ||
              indicators.Level.trim() === "level-2" ||
              indicators.Level.trim() === "Level-2"

            ) {
              indicatorArrayLevel2.push(
                <div class="checkbox">
                  <i
                    class="fa fa-trash-o"
                    onClick={() => this.deletes(indicators.indicatorId)}
                  ></i>
                  <LoginModal
                    // buttonTitle = "add"
                    extraStyle={{ backgroundColor: "#56528F " }}
                    extraClass="fa fa-edit"
                    atagLink="true"
                    title="Edit Indicator"
                    id="editIndicator"
                    onClick={() => {
                      this.setState({
                        editIndicatorId: indicators.indicatorId,
                        editIndicatorName: indicators.indicators
                      });
                      setTimeout(() => this.change(indicators.indicators), 100);
                    }}
                    bodyText={
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label>Indicator</label>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm-5">
                            <div>
                              {this.state.editIndicatorName != undefined &&
                                this.state.editIndicatorName != null &&
                                this.state.editIndicatorName != "" ? (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={this.state.editIndicatorName}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              ) : (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={""}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-success"
                              data-dismiss="modal"
                              onClick={
                                this.state.button === "Update"
                                  ? this.update
                                  : this.submit
                              }
                            >
                              {this.state.button}
                            </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                      </div>
                    }
                  />
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        this.state.alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                  </label>
                  {indicators.indicators}&emsp;&emsp;&emsp;
                </div>
              );
            } else if (
              indicators.Level.trim() === "Level-3" ||
              indicators.Level.trim() === "Level 3" ||
              indicators.Level.trim() === "LEVEL 3" ||
              indicators.Level.trim() === "level-3" ||
              indicators.Level.trim() === "LEVEL3" ||
              indicators.Level.trim() === "Level-3"

            ) {
              indicatorArrayLevel3.push(
                <div class="checkbox">

                  <i
                    class="fa fa-trash-o"
                    onClick={() => this.deletes(indicators.indicatorId)}
                  ></i>
                  <LoginModal
                    // buttonTitle = "add"
                    extraStyle={{ backgroundColor: "#56528F " }}
                    extraClass="fa fa-edit"
                    atagLink="true"
                    title="Edit Indicator"
                    id="editIndicator"
                    onClick={() => {
                      this.setState({
                        editIndicatorId: indicators.indicatorId,
                        editIndicatorName: indicators.indicators
                      });
                      setTimeout(() => this.change(indicators.indicators), 100);
                    }}
                    bodyText={
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label>Indicator</label>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm-5">
                            <div>
                              {this.state.editIndicatorName != undefined &&
                                this.state.editIndicatorName != null &&
                                this.state.editIndicatorName != "" ? (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={this.state.editIndicatorName}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              ) : (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={""}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-success"
                              data-dismiss="modal"
                              onClick={
                                this.state.button === "Update"
                                  ? this.update
                                  : this.submit
                              }
                            >
                              {this.state.button}
                            </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                      </div>
                    }
                  />
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        this.state.alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                  </label>
                  {indicators.indicators}&emsp;&emsp;&emsp;
                </div>
              );
            } else if (
              indicators.Level.trim() === "LEVEL 4" ||
              indicators.Level.trim() === "Level 4" ||
              indicators.Level.trim() === "level-4" ||
              indicators.Level.trim() === "Level-4"

            ) {
              indicatorArrayLevel4.push(
                <div class="checkbox">
                  <LoginModal
                    // buttonTitle = "add"
                    extraStyle={{ backgroundColor: "#56528F " }}
                    extraClass="fa fa-edit"
                    atagLink="true"
                    title="Edit Indicator"
                    id="editIndicator"
                    onClick={() => {
                      this.setState({
                        editIndicatorId: indicators.indicatorId,
                        editIndicatorName: indicators.indicators
                      });
                      setTimeout(() => this.change(indicators.indicators), 100);
                    }}
                    bodyText={
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label>Indicator</label>
                          </div>
                          <div className="col-sm-1" />
                          <div className="col-sm-5">
                            <div>
                              {this.state.editIndicatorName != undefined &&
                                this.state.editIndicatorName != null &&
                                this.state.editIndicatorName != "" ? (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={this.state.editIndicatorName}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              ) : (
                                <textarea
                                  // type="text"
                                  class="form-control"
                                  value={""}
                                  onChange={e => {
                                    this.setState({
                                      editIndicatorName: e.target.value
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-success"
                              data-dismiss="modal"
                              onClick={
                                this.state.button === "Update"
                                  ? this.update
                                  : this.submit
                              }
                            >
                              {this.state.button}
                            </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                      </div>
                    }
                  />
                  <i
                    class="fa fa-trash-o"
                    onClick={() => this.deletes(indicators.indicatorId)}
                  ></i>
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        this.state.alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                  </label>
                  {indicators.indicators}&emsp;&emsp;&emsp;
                </div>
              );
            }
          }
        });
      });
    }
    return (
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="panel-body">
          <br />
          <h2 style={{ color: "red" }}> {this.state.DictionaryType}</h2>
          <br />
          {indicatorArrayLevel1 && (
            <div>
              <div className="panel panel-default">
                <div className="panel-heading" style={{ padding: "0px 14px" }}>
                  <div className="row">
                    <div
                      className="col-sm-6  newview-border-header p-2"
                      style={{ fontWeight: "bolder" }}
                    >
                      {"FUNCTIONAL COMPETENCY DICTIONARY"}{" "}
                    </div>
                    <div
                      className="col-sm-6 content-border1  p-2"
                      style={{ fontWeight: "bolder" }}
                    >
                      {" "}
                      {"Function/Theme : " + this.props.themeNamelabel}
                    </div>
                  </div>
                  <div   style={{ fontWeight: "bolder" }} className="row content-border1 p-2">
                    {"Competency " +
                      " : " +
                      this.props.cmpdatalist[0].competencyname}
                  </div>

                  <div className="row form-group content-border1">
                    <div className="col-sm-6 p-2 content-border1">
                      <center>
                        <div className="text-center">
                          {"COMPETENCY DEFINITION"}
                        </div>
                      </center>
                    </div>
                    <div class="col-sm-6 py-2 content-border1">
                      <center>
                        <div className="text-center">
                          {"WHY IT IS IMPORTANT ?"}
                        </div>
                      </center>
                    </div>
                  </div>

                  <div class="row ">
                    <div className="col-sm-6">
                      {" "}
                      {this.props.cmpdatalist[0].Description}
                    </div>
                    <div className="col-sm-6">
                      {" "}
                      {this.props.cmpdatalist[0].CompetencyImportant}
                    </div>
                  </div>

                  <div class="row form-group ">
                    <div className="col-sm-3 content-border1">
                      <label>Level-1</label>

                      <LoginModal
                        atagLink="true"
                        extraClass="fa fa-plus newviewhr-newIcon"
                        title="Add New Indicator"
                        id="level1"
                        onClick={() => this.fun(1)}
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={<div>{this.state.push}</div>}
                      />
                    </div>

                    <div className="col-sm-3 content-border1">
                      <label>Level-2</label>
                      <LoginModal
                        atagLink="true"
                        extraClass="fa fa-plus newviewhr-newIcon"
                        title="Add New Indicator"
                        id="level1"
                        onClick={() => this.fun(2)}
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={<div>{this.state.push}</div>}
                      />
                    </div>

                    <div className="col-sm-3 content-border1">
                      <label>Level-3</label>
                      <LoginModal
                        atagLink="true"
                        extraClass="fa fa-plus newviewhr-newIcon"
                        title="Add New Indicator"
                        id="level1"
                        onClick={() => this.fun(3)}
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={<div>{this.state.push}</div>}
                      />
                    </div>

                    <div className="col-sm-3 content-border1">
                      <label>Level-4</label>
                      <LoginModal
                        atagLink="true"
                        extraClass="fa fa-plus newviewhr-newIcon"
                        title="Add New Indicator"
                        id="level1"
                        onClick={() => this.fun(4)}
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={<div>{this.state.push}</div>}
                      />
                    </div>

                    {/* <div className="col-sm-3">Level-2</div>
                    <div className="col-sm-3">Level-3</div>
                    <div className="col-sm-3">Level-4</div> */}
                  </div>
                </div>
                <div className="panel-body">
                  <div class="row form-group ">
                    <div className="col-sm-3">{indicatorArrayLevel1}</div>
                    <div className="col-sm-3">{indicatorArrayLevel2}</div>
                    <div className="col-sm-3">{indicatorArrayLevel3}</div>
                    <div className="col-sm-3">{indicatorArrayLevel4}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            className="btn btn-success"
            onClick={() => this.onSubmit()}
          >
            Save Changes
          </button>
        </div>
        <div>{push1}</div>
        {/* <LoginModal
          title="Edit Indicator"
          id="editIndicator"
          bodyText={
            <div>
              <div className="row form-group">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                  <label>Indicator</label>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  {JSON.stringify(this.state.editJson)}
                  <input type="text" className={"form-control"} />
                </div>
                <div className="col-sm-2" />
              </div>
            </div>
          }
        /> */}
        {/* {this.state.push != null &&
        this.state.push1 != "" &&
        this.state.push1 != undefined
          ? this.state.push1
          : null} */}
      </div>
    );
  }
}

export default ViewIndicatorList;
