import React, { Component } from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import { SingleSelect, MultiSelect } from "../../components/Form";
import Datatable from "../../components/Datatable/Datatable";
import image from "../../image/18-128.png";

class RoleandCompetencymatrix extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      textType: null,
      newData: [],
      freshData: [],
      techBehType: [
        { label: "Technical", value: 2 },
        { label: "Behavioral", value: 1 }
      ]
    };
  }

  async componentDidMount() {
    try {
      //style issue
      // document.getElementsByClassName("inputtest")[0].style.width = "inherit";
      // document.getElementsByClassName("inputtest")[0].style.height = "inherit";
      // document.getElementsByClassName("inputtest")[0].style.opacity = "inherit";

      const adminId = await localStorage.getItem("adminId");

      let result = await HrMiddleWare.getRandomData(
        "tbl_cm_theme_worklevel_competency",
        `cmid=${adminId}`,
        "*"
      );
      // console.log(result.data);
      let worklevel = await HrMiddleWare.getRandomData(
        "tbl_worklevel",
        `customerid =${adminId} and status = 'active'`,
        "worklevel as label , id as value"
      );
      let jobRole = await HrMiddleWare.getRandomData(
        "tbl_jobRole",
        `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
        "jobRoleName as label , id as value"
      );
      let competency = await HrMiddleWare.getRandomData(
        "tbl_competency",
        `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
        "competencyname as label , id as value"
      );
      this.setState({
        worklevel: worklevel.data,
        data: result.data,
        jobRole: jobRole.data,
        competency: competency.data,
        adminId
      });
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Competency",
      accessor: "competencyid",
      Cell: d =>
        this.getValueFromArray(d.original.competencyid, this.state.competency)
    },
    {
      Header: "Critical",
      accessor: "imp_comp",
      Cell: d => this.Check(d)
    },
    {
      Header: "Essential",
      accessor: "imp_comp",
      Cell: d => this.Check1(d)
    },
    {
      Header: "Desirable",
      accessor: "imp_comp",
      Cell: d => this.Check2(d)
    },
    {
      Header: "TimeLine",
      accessor: "future"
    }
  ];

  columns = [
    {
      Header: "Competency",
      accessor: "competencyid",
      Cell: d =>
        this.getValueFromArray(d.original.competencyid, this.state.competency)
    },
    {
      Header: "Critctical",
      accessor: "imp_comp",
      Cell: d => this.Check(d)
    },
    {
      Header: "Essential",
      accessor: "imp_comp",
      Cell: d => this.Check1(d)
    },
    {
      Header: "Desirable",
      accessor: "imp_comp",
      Cell: d => this.Check2(d)
    },
    {
      Header: "Future",
      accessor: "future"
    }
  ];

  Check = d => {
    if (d.original.imp_comp == "Critical") {
      return (
        <center>
          <div>
            <img src={image} style={{ width: "20px" }} />
          </div>
        </center>
      );
    } else if (d.imp_comp == null) {
      return (
        <center>
          <div>N/A</div>
        </center>
      );
    }
  };
  Check1 = d => {
    if (d.original.imp_comp == "Essential") {
      return (
        <center>
          <div>
            <img src={image} style={{ width: "20px" }} />
          </div>
        </center>
      );
    } else {
      return (
        <center>
          <div>N/A</div>
        </center>
      );
    }
  };
  Check2 = d => {
    if (d.original.imp_comp == "Desirable") {
      return (
        <center>
          <div>
            <img src={image} style={{ width: "20px" }} />
          </div>
        </center>
      );
    } else {
      return (
        <center>
          <div>N/A</div>
        </center>
      );
    }
  };

  type = d => {
    if (d == 1) {
      return "Behavioral";
    } else if (d == 2) {
      return "Technical";
    } else if (d == null) {
      return "Null";
    }
  };

  getValueFromArray = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  currentlevel = e => {
    this.setState({ currentlevel: e, levelId: e.value });
  };
  currentjob = e => {
    const { currentlevel, typeSelected } = this.state;
    this.setState({ currentjob: e, currentjobId: e.value });
    let newData = this.state.data.filter((item, i) => {
      if (
        item.future != null &&
        (item.future == "Current" || item.future == "current") &&
        typeSelected.value == item.type &&
        item.worklevelid == currentlevel.value &&
        item.jobRoleId == e.value
      ) { return item }
    });
    this.setState({ newData: newData });
  };
  level = e => {
    this.setState({ levelSelect: e, levelId: e.value });
  };
  job = e => {
    const { levelSelect, typeSelected1 } = this.state;
    this.setState({ jobRoleSelect: e, jobRoleId: e.value });
    let freshData = this.state.data.filter((item, i) => {
      return (
        item.future != null &&
        (item.future == "Future" || item.future == "future") &&
        typeSelected1.value == item.type &&
        item.worklevelid == levelSelect.value &&
        item.jobRoleId == e.value
      );
    });
    this.setState({ freshData: freshData });
  };
  typeSelect = (e, type) => {
    this.setState({ typeSelected: e, status: type });
  };
  typeSelect1 = (e, type) => {
    this.setState({ typeSelected1: e, status1: type });
  };

  render() {
    const {
      data,
      newData,
      freshData,
      textType,
      worklevel,
      currentlevel,
      currentjob,
      levelSelect,
      jobRole,
      jobRoleSelect,
      techBehType,
      typeSelected,
      typeSelected1
    } = this.state;

    return (
      <React.Fragment>
        <section>
          <div className="container mt-100 container-sm-fluid">
            <div className="row justify-content-center px-3 align-items-center">
              <div className="col-lg-8 col-12 text my-3" >
                <div className="content-border1">
                  <div className="my-3 text-center px-3">
                    <h3>Role and Competency matrix</h3>
                  </div>
                  <div className="col-12 px-2 px-lg-2">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a data-toggle="tab" href="#currentVal" className="nav-link active">
                          Current
                        </a>
                      </li>
                      <li className="nav-item">
                        <a data-toggle="tab" href="#futureVal" className="nav-link">
                          Future
                        </a>
                      </li>
                    </ul>
                    <div className="box-tools d-none pull-right">
                      <button
                        className="btn btn-box-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      id="currentVal"
                      className="tab-pane fade in active show"
                    >
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Type</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={techBehType}
                            handleChange={e =>
                              this.typeSelect(e, "current")
                            }
                            selectedService={typeSelected}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Work Level</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={worklevel}
                            handleChange={this.currentlevel}
                            selectedService={currentlevel}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Job Role</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={jobRole}
                            handleChange={this.currentjob}
                            selectedService={currentjob}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className="px-2 my-2 col-12">
                        {newData.length > 0 && newData && (
                          <Datatable
                            data={newData}
                            columnHeading={this.column}
                          />
                        )}
                      </div>
                    </div>
                    <div id="futureVal" className="tab-pane fade">
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Type</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={techBehType}
                            handleChange={e =>
                              this.typeSelect1(e, "future")
                            }
                            selectedService={typeSelected1}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Work Level</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={worklevel}
                            handleChange={this.level}
                            selectedService={levelSelect}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className='row justify-content-between align-items-center my-4  px-3 px-md-3 px-lg-5'>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className='col-lg-4 col-md-6 col-12'>
                          <label className="formLabel" htmlFor="year">Job Role</label>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                          <SingleSelect
                            options={jobRole}
                            handleChange={this.job}
                            selectedService={jobRoleSelect}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="my-1 text-end">
                        </div>
                      </div>
                      <div className="px-2 my-2 col-12">
                        {freshData.length > 0 && freshData && (
                          <Datatable
                            data={freshData}
                            columnHeading={this.columns}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default RoleandCompetencymatrix;
