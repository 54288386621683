import React from 'react';
import { Alert } from 'reactstrap';

const AddTrainer = ({ trainer, handleChange, addNewTrainer, error, alert, dismiss, disableValue, alertMsg }) => {
  return (
    <React.Fragment>
      <div>
        <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
          {alertMsg}
        </Alert>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label for="exampleInputEmail1" className='formLabel'>Trainer Name</label>
          </div>
          <div className="col-sm-7">
            <input type="text" class="form-control inputss" id="trainer" name="trainer" placeholder="Enter trainer" onChange={handleChange} value={trainer} />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="modal-error-show">{error}</span>
        </div>

        <div className="row form-group">
          <div className="col-sm-4" />
          <div className="col-sm-4">
            <button type="button" class="btn btn-primary" disabled={disableValue} onClick={addNewTrainer}>
              Add Trainer
            </button>
          </div>
          <div className="col-sm-4" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddTrainer;
