import React from "react";
import LoginModal from "../../components/Modal/Modal";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import ValidationView from "../../components/Form/ValidationView";
import Datatable from "../../components/Datatable/Datatable";
import "./framwork.css";
import './style.css'

var btoa = require("btoa");

class Dictionary extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      show: false,
      // show1: true
      // show2: false,
      dictionaryhide: false,
      textType: '',
      themeOptions: [],
      themeOptionSelected: '',
      designationOptions: [],
      designationSelected: '',
      workLevelOptions: [],
      workLevelSelected: '',
      mapped_derailers_data: [],
      chanestate: 1

    };
  }
  async componentWillMount() {
    let adminId = await localStorage.getItem("adminId");
    let userId = await localStorage.getItem("userId");
    try {


      let newthemedata = await HrMiddleWare.getRandomData('ClusterMaster,tbl_theme', 'ClusterMaster.themeId=tbl_theme.id group by themeId ', 'ClusterMaster.themeId');
      //  console.log(newthemedata.data)
      var newthemeid = '';
      newthemedata.data.map((i) => {
        //console.log(i)
        newthemeid += i.themeId + ',';
      })
      // console.log(newthemeid)
      var newThemeid = newthemeid.replace(/,\s*$/, "");

      // console.log(newthemeid,"newthemeid");
      let themeList = await HrMiddleWare.getRandomData(
        "tbl_theme",
        ` id  not in (${newThemeid}) and customerid=${adminId} and textType='Behavioral' `,
        "theme as label,id as value"
      );
      let themeList1 = await HrMiddleWare.getRandomData(
        "tbl_theme",
        ` id not in (${newThemeid}) and customerid=167 and textType='Behavioral' `,
        "theme as label,id as value"
      );
      // console.log(themeList1,"themeList1");
      // console.log(themeList,"themeList");
      let framWorkList = await HrMiddleWare.getRandomData(
        "tbl_competencyframework,tbl_competency,tbl_theme,tbl_worklevel",
        `tbl_theme.id=tbl_competencyframework.theme and tbl_competency.id=tbl_competencyframework.competency and tbl_worklevel.id=tbl_competencyframework.worklevel Order by tbl_theme.id ASC`,
        "tbl_competency.competencyname,tbl_competency.Description,tbl_theme.theme,tbl_worklevel.worklevel,tbl_competency.id as cid,tbl_theme.id as tid,tbl_worklevel.id as wid"
      );
      // console.log(framWorkList,"framWorkList");
      let defaultList = await HrMiddleWare.getRandomData(
        "tbl_competencyframework,tbl_competency,tbl_theme,tbl_worklevel",
        `tbl_theme.id=tbl_competencyframework.theme and tbl_competency.id=tbl_competencyframework.competency  and tbl_competencyframework.status='default' and tbl_worklevel.id=tbl_competencyframework.worklevel Order by tbl_theme.id ASC`,
        "tbl_competency.competencyname,tbl_competency.Description,tbl_theme.theme,tbl_worklevel.worklevel,tbl_competency.id as cid,tbl_theme.id as tid,tbl_worklevel.id as wid,tbl_competencyframework.status"
      );

      let workLeveList = await HrMiddleWare.getRandomData(
        "tbl_worklevel",
        `customerid=${adminId} order by worklevel ASC`,
        "worklevel as label,id as value"
      );
      let workLeveList1 = await HrMiddleWare.getRandomData(
        "tbl_worklevel",
        `customerid=167 order by worklevel ASC`,
        "worklevel as label,id as value"
      );
      // console.log(workLeveList1,"workLeveList1");
      // console.log(workLeveList,"workLeveList");
      this.getSelectBoxMaster(
        adminId,
        "tbl_designation",
        "id",
        "designation",
        "designationOptions"
      );


      this.getSelectBoxMaster(
        adminId,
        "tbl_worklevel",
        "id",
        "worklevel",
        "workLevelOptions"
      );


      let subThemeList = themeList.data.filter((ival, i) => {
        if (i < 5) {
          return ival;
        }
      });
      this.setState({
        themeList: themeList.data,
        themeList1: themeList1.data,
        adminId,
        userId,
        framWorkList: framWorkList.data,
        defaultList: defaultList.data,
        workLeveList: workLeveList.data,
        workLeveList1: workLeveList1.data,
        subThemeList
      });
    } catch (error) {
      console.log(error);
    }
  }
  addComp = () => {
    console.log(0);



  };

  defaultList = () => {
    this.setState({ show: true });
  };
  bacfun = () => {
    this.setState({ show: false });
  };
  onSubmit = async () => {
    let {
      themeSelect,
      workSelect,
      competency,
      competencyDef,
      competencyImp,
      adminId
    } = this.state;

    if (this.validate(themeSelect, "errorthemeSelect", "Please select Theme"))
      return true;
    if (
      this.validate(workSelect, "errorworkSelect", "Please select Work-level")
    )
      return true;
    if (
      this.validateText(
        competency,
        "errorcompetency",
        "Please enter Competency"
      )
    )
      return true;
    if (
      this.validateText(
        competencyDef,
        "errorcompetencyDef",
        "Please enter Definition"
      )
    )
      return true;
    if (
      this.validateText(
        competencyImp,
        "errorcompetencyImp",
        "Please select Importance"
      )
    )
      return true;
    let value1 = {};
    value1.themeId = themeSelect.value;
    value1.competencyname = competency;
    value1.Description = competencyDef;
    value1.CompetencyImportant = competencyImp;
    value1.textType = "Behavioral";
    value1.status = "active";
    value1.customerid = adminId;
    value1.adminId = adminId;

    let value2 = {};
    value2.theme = themeSelect.value;
    value2.worklevel = workSelect.value;
    value2.status = adminId;
    let body = {};
    body.tn1 = "tbl_competency";
    body.tn2 = "tbl_competencyframework";
    body.val1 = value1;
    body.val2 = value2;
    try {
      const result = await HrMiddleWare.multiInsert(body);
      if (result) {
        console.log(body);
        this.setState({
          themeSelect: "",
          workSelect: "",
          competency: "",
          competencyDef: "",
          competencyImp: ""
        })
      }
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };
  innerContent = (ia, ja) => {
    // console.log(ia,"ia",ja,"ja");
    // console.log(framWorkList,"framWorkList");
    let res = this.state.framWorkList.filter((ival, i) => {
      if (ival.wid === ja && ival.tid === ia) {
        return ival;
      }
    });
    // console.log(res, "res");
    let arr = [];
    if (res && res.length) {
      let result = res.map((ival, i) => {
        // console.log(ival,"ivalss");
        let val = btoa(
          `${ival.tid},${ival.wid},${ival.cid},${ival.competencyname},${ival.Description},11`
        );
        // console.log(val,"val");
        arr.push(

          <tr>
            <td
              style={{
                padding: "5px 5px 5px 5px",
                borderBottom: "0.5px solid #1d95af"
              }}
            >
              <a
                href="#"
                onClick={() => window.open(`DictionaryView?${val}`, "_blank")}
                style={{ color: "#000" }}
              >
                {ival.competencyname}
              </a>
            </td>
          </tr>
        );
      });
      return <table style={{ width: "100%" }}>{arr}</table>;
    }
    // return "--------";
  };
  innerContent1 = (ia, ja) => {
    // console.log(ia,"ia",ja,"ja");
    let res = this.state.framWorkList.filter((ival, i) => {
      if (ival.wid === ja && ival.tid === ia) {
        return ival;
      }
    });
    // console.log(res, "res");
    let arr = [];
    if (res && res.length) {
      let result = res.map((ival, i) => {
        // console.log(ival,"ivalss");
        let val = btoa(
          `${ival.tid},${ival.wid},${ival.cid},${ival.competencyname},${ival.Description},12`
        );
        // console.log(val,"val");
        arr.push(

          <tr>
            <td
              style={{
                padding: "5px 5px 5px 5px",
                borderBottom: "0.5px solid #1d95af"
              }}
            >
              <a
                href="#"
                className="text-color"
                onClick={() => window.open(`DictionaryView?${val}`, "_blank")}

              >
                {ival.competencyname}
              </a>
            </td>
          </tr>
        );
      });
      return <table style={{ width: "100%" }}>{arr}</table>;
    }
    // return "--------";
  };


  handleRadioInput = async e => {
    this.setState({ [e.target.name]: e.target.value });
    let targetValue = e.target.value;
    let adminId = this.state.adminId;
    this.setState({ targetValue: targetValue })

    try {


      let themeOptions = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `textType='${targetValue}' and adminId = ${this.state.adminId}`,
        "tbl_theme.theme as label ,tbl_theme.id as value"
      );

      if (themeOptions) {
        this.setState({
          themeOptions: themeOptions.data

        });
      }

    } catch (error) {
      console.error(error);
    }

  }
  mappedderailersview = () => {


    if (this.state.chanestate == 1) {
      this.setState({ dictionaryhide: true, chanestate: 2 })
    }
    else {
      this.setState({ dictionaryhide: false, chanestate: 1 })
    }

  }

  themeOptionselect = (e) => {
    console.log(e)
    this.setState({ themeOptionSelected: e })
  }


  viewderailers = async () => {
    const {
      groupcompId,
      workLevelId,
      adminId,
      description,
      themeOptionSelected,
      designationSelected,
      designationId,
      mapped_derailers_data,
      textType,
      targetValue
    } = this.state;

    console.log(workLevelId)
    console.log(adminId)
    console.log(textType)
    console.log(themeOptionSelected)
    console.log(designationId)


    if (
      this.validate(
        targetValue,
        "errorthemetype",
        "Please Select Type"
      )
    )
      return true;

    if (
      this.validate(
        workLevelId,
        "errorWorkLevelSelected",
        "Please Select Work Level"
      )
    )
      return true;


    if (
      this.validate(
        themeOptionSelected,
        "errorthemeOptionSelected",
        "Please Select Work Level"
      )
    )
      return true;

    if (
      this.validate(
        designationId,
        "errordesignationSelected",
        "Please Select Designation"
      )
    )
      return true;



    let getdatatable = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      // `textType='${targetValue}' and designationid=${designationId} and worklevelid=${workLevelId} and themeid=${themeOptionSelected.value}  and tbl_derailers.id=Tblderailers_map.derailersid and Tblderailers_map.customerId = ${this.state.adminId} `,
      `textType='${targetValue}' and designationid=${designationId} and worklevelid=${workLevelId} and themeid=${themeOptionSelected.value}  and tbl_derailers.id=Tblderailers_map.derailersid and Tblderailers_map.customerId = ${this.state.adminId} `,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );
    console.log(getdatatable.data)
    this.setState({ mapped_derailers_data: getdatatable.data })

  }

  column_view = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailers"
    },
    {
      Header: props => (
        <span style={{ fontWeight: "bold" }}> Description </span>
      ),
      accessor: "description"
      // Cell: d => this.NEW(d)
    }

  ];


  render() {
    let {
      themeList,
      themeList1,
      themeSelect,
      workLeveList,
      workLeveList1,
      workSelect,
      competency,
      competencyDef,
      competencyImp,
      errorthemeSelect,
      errorworkSelect,
      errorcompetency,
      errorcompetencyDef,
      errorcompetencyImp,
      framWorkList,
      subThemeList,
      defaultList,
      show,
      textType,
      themeOptions,
      themeOptionSelected,
      designationOptions,
      designationSelected,
      workLevelSelected,
      workLevelOptions,
      mapped_derailers_data,
      errorWorkLevelSelected,
      errorthemeOptionSelected,
      errordesignationSelected,
      errorthemetype
    } = this.state;
    themeList = show ? subThemeList : themeList;
    framWorkList = show ? defaultList : framWorkList;
    return (
      <section>
        <div className=" mt-100 container container-sm-fluid">
          <div className="row justify-content-center align-items-center my-lg-5 my-5">
            <div className="col-lg-12 col-12 text">
              <div className="content-border1">
                <div className="my-2 my-lg-3 px-3">
                  <h3>Competency Dictionary</h3>
                </div>
                <div className="row justify-content-lg-evenly justify-content-center align-items-center px-4 my-3">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2  text-lg-start">
                    <button
                      type="button"
                      className="newview-btn-new"
                      onClick={() => this.mappedderailersview()}
                    >
                      View Mapped Derailers
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2  text-lg-start">
                    {!this.state.show && (
                      <LoginModal
                        buttonTitle="Add Competency"
                        title="Add Competency"
                        id="Competency"
                        extraClass="newview-btn-new"
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={
                          <div className="text-start">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-4 col-md-4 col-12 my-1 my-lg-0">
                                <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Theme</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-12 my-1 my-lg-0">
                                <SingleSelect
                                  menuPortalTarget={null}
                                  menuShouldScrollIntoView={null}
                                  handleChange={d =>
                                    this.selectBoxStore("themeSelect", d)
                                  }
                                  options={themeList}
                                  selectedService={themeSelect}
                                  placeholder="Select Theme"
                                />
                              </div>
                              <div className="col-12 my-2">
                                <ValidationView errorname={errorthemeSelect} />
                              </div>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-4 col-md-4 col-12 my-1 my-lg-0">
                                <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Work-Level</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-12 my-1 my-lg-0">
                                <SingleSelect
                                  menuPortalTarget={null}
                                  menuShouldScrollIntoView={null}
                                  handleChange={d =>
                                    this.selectBoxStore("workSelect", d)
                                  }
                                  options={workLeveList}
                                  selectedService={workSelect}
                                  placeholder="Select Work-level"
                                />
                              </div>
                              <div className="col-12 my-2">
                                <ValidationView errorname={errorworkSelect} />
                              </div>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-4 col-md-4 col-12 my-1 my-lg-0">
                                <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Competency</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-12 my-1 my-lg-0">
                                <input
                                  type="text"
                                  name="competency"
                                  placeholder="Enter Competency"
                                  className="form-control inputss"
                                  onChange={this.handleUserInput}
                                  value={competency}
                                />
                              </div>
                              <div className="col-12 my-2">
                                <ValidationView errorname={errorcompetency} />
                              </div>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-4 col-md-4 col-12 my-1 my-lg-0">
                                <label style={{ paddingLeft: "0px" }} htmlFor="indicators"> Competency Definition</label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-12 my-1 my-lg-0">
                                <textarea
                                  type="text"
                                  className="form-control inputss"
                                  placeholder="Enter Competency Importaant"
                                  name="competencyImp"
                                  onChange={this.handleUserInput}
                                  value={competencyImp}
                                />
                              </div>
                              <div className="col-12 my-2">
                                <ValidationView errorname={errorcompetencyImp} />
                              </div>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-12 text-end">
                                <button
                                  type="button"
                                  className="btncolor"
                                  onClick={this.onSubmit}
                                >
                                  Add Competency
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                  {this.state.show ? (<></>) : (<div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2  text-lg-start">
                    <button
                      type="button"
                      className="newview-btn-new "
                      onClick={this.defaultList}
                    >
                      Default Competency
                    </button>
                  </div>)}
                  {/* {this.state.show ? (<></>) : (<div className="col-lg-3 col-md-6 col-sm-6 col-12 text-lg-center  my-2">
                    <button
                      type="button"
                      className="newview-btn-new px-3"
                      onClick={this.addComp}
                    >
                      Print
                    </button>
                  </div>)} */}
                  {this.state.show ? (
                    <div className="row justify-content-between align-items-center">
                      <div className="col-12 text-end">
                        {this.state.show && (
                          <button
                            type="button"
                            className="btncolor"
                            onClick={this.bacfun}
                          >
                            Back
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row justify-content-evenly align-items-center">

                    </div>
                  )}
                  <div className="col-12 text my-2" >
                    {!this.state.dictionaryhide && this.state.show == false && (<div style={{ overflowX: 'auto' }} className=" dictionary-table">
                      <table className="w-100 text table-responsive">
                        <thead>
                          <tr>
                            <th style={{}}>THEMES</th>
                            {workLeveList1 &&
                              workLeveList1.map((ival, i) => {
                                return (
                                  <th

                                  >
                                    {ival.label}
                                  </th>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {themeList1 &&
                            themeList1.map((ival, i) => {
                              return (
                                <tr className="text text-center">
                                  <td

                                  >
                                    {ival.label}
                                  </td>
                                  {workLeveList1 &&
                                    workLeveList1.map((jval, j) => {
                                      return (
                                        <td

                                        >
                                          {this.innerContent1(ival.value, jval.value)}
                                        </td>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>)}
                  </div>
                  {this.state.dictionaryhide && (
                    <div>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-12">
                          <div className="row justify-content-between align-items-start my-2">
                            <div className="col-lg-1 d-none d-lg-block" />
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Theme Type</label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <div className="d-flex">
                                <div className="d-flex align-items-center">
                                  <input type="radio" id="Behavioral" name="textType" value="Behavioral" checked={textType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                                  <label>
                                    &nbsp;&nbsp;&nbsp;Behavioral
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <input type="radio" id="Technical" name="textType" value="Technical" checked={textType === 'Technical' && true} onChange={this.handleRadioInput} />
                                  <label>
                                    &nbsp;&nbsp;&nbsp;Technical
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 my-2 text-end">
                              <ValidationView errorname={errorthemetype} />
                            </div>
                          </div>
                          <div className="row justify-content-between align-items-start my-2">
                            <div className="col-lg-1 d-none d-lg-block" />
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <label style={{ paddingLeft: "0px" }} htmlFor="indicators"> Theme/Function</label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                handleChange={this.themeOptionselect}
                                options={themeOptions}
                                selectedService={themeOptionSelected}
                              />
                            </div>
                            <div className="col-12 my-2 text-center">
                              <span className="errorMsg">{errorthemeOptionSelected}</span>
                            </div>
                          </div>
                          <div className="row justify-content-between align-items-start my-2">
                            <div className="col-lg-1 d-none d-lg-block" />
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <label style={{ paddingLeft: "0px" }} htmlFor="indicators"> Designation</label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                handleChange={this.designationSelect}
                                options={designationOptions}
                                selectedService={designationSelected}
                              />
                            </div>
                            <div className="col-12 my-2  text-center">
                              <span className="errorMsg">{errordesignationSelected}</span>
                            </div>
                          </div>
                          <div className="row justify-content-between align-items-start my-2">
                            <div className="col-lg-1 d-none d-lg-block" />
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <label style={{ paddingLeft: "0px" }} htmlFor="indicators"> Work Level</label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                handleChange={this.workLevelSelectsingal}
                                options={workLevelOptions}
                                selectedService={workLevelSelected}
                              />
                            </div>
                            <div className="col-12 my-2  text-center">
                              <span className="errorMsg">{errorWorkLevelSelected}</span>
                            </div>
                          </div>
                          <div className="row justify-content-between align-items-center">
                            <div className="col-12 text-end">
                              <button
                                type="button"
                                className="submit-btn "
                                onClick={this.viewderailers}
                              >
                                submit
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="mt-5">
                        {mapped_derailers_data && (
                          <Datatable data={mapped_derailers_data} columnHeading={this.column_view} />
                        )}
                      </div>

                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Dictionary;
