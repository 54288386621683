import React from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import { SingleSelect, MultiSelect } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import LoginModal from "../../components/Modal/Modal";
import "./style.css";
import ValidationView from "../../components/Form/ValidationView";
import Swal from "sweetalert2";
import { async } from "q";

class TechCompDictionary extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      funSelected: [],
      CompSelect: [],
      indicatorList: [],
      Indicators: [],
      levelType: [
        { label: "Level-1", value: 1 },
        { label: "Level-2", value: 2 },
        { label: "Level-3", value: 3 },
        { label: "Level-4", value: 4 },
        { label: "Level-5", value: 5 }
      ],
      key: "",
      compDetails: []
      // descriptionText: ""
    };
  }
  async componentWillMount() {
    const adminId = await localStorage.getItem("adminId");

    let newthemedata = await HrMiddleWare.getRandomData('ClusterMaster,tbl_theme', 'ClusterMaster.themeId=tbl_theme.id group by themeId ', 'ClusterMaster.themeId');
    //  console.log(newthemedata.data)
    var newthemeid = '';
    newthemedata.data.map((i) => {
      //console.log(i)
      newthemeid += i.themeId + ',';
    })
    // console.log(newthemeid)
    var newThemeid = newthemeid.replace(/,\s*$/, "");
    let themeList = await HrMiddleWare.getRandomData(
      "tbl_theme",
      ` id  not in (${newThemeid}) and customerid=${adminId} and textType='Technical' `,
      "theme as label,id as value"
    );
    // console.log(themeList,"themeList");
    let workLeveList = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `customerid=${adminId} order by worklevel ASC`,
      "worklevel as label,id as value"
    );
    const fun = await HrMiddleWare.getRandomData(
      "tbl_theme",
      `textType = 'Technical' and customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "theme as label , id as value"
    );
    const jobRole = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "jobRoleName as label , id as value"
    );
    const worklevel = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active' order by id`,
      "worklevel as label , id as value"
    );
    const yearList = await MappingMiddleware.getYear();

    this.setState({
      fun: fun.data,
      adminId,
      jobRole: jobRole.data,
      worklevel: worklevel.data,
      yearList: yearList.data,
      themeList: themeList.data,
      workLeveList: workLeveList.data,

    });
  }
  funSelected = async e => {
    // console.log(e.value, "value");
    this.setState({ funSelected: e });
    let comp = await HrMiddleWare.getRandomData(
      "tbl_technicalframework,tbl_competency",
      `tbl_technicalframework.id = ${e.value} and tbl_technicalframework.competency_id = tbl_competency.id`,
      "tbl_competency.competencyname as label , tbl_competency.id as value"
    );
    // console.log(this.state.funSelected, "enter");
    this.setState({ CompList: comp.data, CompSelect: '' });
  };
  competencyText = e => {
    this.setState({ competencyText: e.target.value });
  };
  definitionText = e => {
    this.setState({ definitionText: e.target.value });
  };
  importanceText = e => {
    this.setState({ importanceText: e.target.value });
  };
  knowledgeText = e => {
    this.setState({ knowledgeText: e.target.value });
  };
  performanceText = e => {
    this.setState({ performanceText: e.target.value });
  };

  compSubmit = async () => {
    let {
      fun,
      funSelected,
      CompList,
      CompSelect,
      indicatorList,
      competencyText,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText,
      table,
      adminId,
      themeSelect,
      workSelect,
    } = this.state;
    let value1 = {};
    // value1.themeId = funSelected.value;
    value1.themeId = themeSelect.value;
    value1.competencyname = competencyText;
    value1.Description = definitionText;
    value1.CompetencyImportant = importanceText;
    value1.textType = "Technical";
    value1.status = "active";
    value1.customerid = adminId;
    value1.adminId = adminId;
    value1.createdAt = 1;

    let value2 = {};
    // value2.theme = funSelected.value;
    value2.theme = themeSelect.value;
    // value2.competency = CompSelect.value;
    value2.defenition = definitionText;
    value2.important = importanceText;
    value2.critical_knowledge = knowledgeText;
    value2.critical_performance = performanceText;
    value2.cmpId = adminId;
    value2.status = "active";

    let value3 = {};
    // value3.function_id = funSelected.value;
    value3.function_id = themeSelect.value;
    value3.worklevel = workSelect.value;
    value3.status = "active";

    let body = {};
    body.tn1 = "tbl_competency";
    body.tn2 = "tbl_tech_comp_criticals";
    body.tn3 = "tbl_technicalframework";
    body.val1 = value1;
    body.val2 = value2;
    body.val3 = value3;

    // let result = await HrMiddleWare.insertThreeTable(body);
    // if (result) {
    //   this.setState({
    //     competencyText: "",
    //     definitionText: "",
    //     importanceText: "",
    //     knowledgeText: "",
    //     performanceText: "",
    //     workSelect: "",
    //     themeSelect: ""
    //   });

    //   Swal.fire({
    //     position: "center",
    //     type: "success",
    //     title: "Successfully Added Technical competency",
    //     showConfirmButton: false,
    //     timer: 1500
    //   });
    // }
  };

  view = async () => {
    const { funSelected, CompSelect, adminId } = this.state;
    const { data: indicatorList } = await MappingMiddleware.getIndicatorsList(
      CompSelect.value,
      adminId
    );

    // let level = await HrMiddleWare.getRandomData(
    //   "tbl_competency,tbl_cmpLevel,tbl_indicators",
    //   `tbl_competency.id=${CompSelect.value} and tbl_competency.customerid = ${adminId} and tbl_competency.adminId =${adminId} and tbl_competency.textType = "Technical" and tbl_cmpLevel.CmpId = ${CompSelect.value} and tbl_cmpLevel.matrixType = "Technical"and tbl_cmpLevel.CustomerId = ${adminId} and tbl_cmpLevel.adminId =${adminId} and tbl_indicators.themeId = tbl_cmpLevel.id and tbl_indicators.competencyId = ${CompSelect.value} and tbl_indicators.textType = "Technical" and tbl_indicators.customerid = ${adminId} and tbl_indicators.customerid = ${adminId}`,
    //   "tbl_competency.id as CompId , tbl_cmpLevel.id as LEVELID , tbl_indicators.id as IndID "
    // );
    // console.log(level.data);

    let indicatorArrayLevel1 = [];
    let indicatorArrayLevel2 = [];
    let indicatorArrayLevel3 = [];
    let indicatorArrayLevel4 = [];

    const map = new Map();
    const levelList = [];
    for (const item of indicatorList) {
      if (!map.has(item.levelid)) {
        map.set(item.levelid, true); // set any value to Map
        levelList.push({ levelid: item.levelid, Level: item.Level });
      }
    }

    levelList.map((levels, index) => {
      indicatorList.map((indicators, key) => {
        if (levels.levelid === indicators.levelid) {
          if (
            indicators.Level === "level 1" ||
            indicators.Level === "Level 1" ||
            indicators.Level === "Level-1" ||
            indicators.Level === "Level-1" ||
            indicators.Level === "LEVEL-1" ||
            indicators.Level === "LEVEL 1"
          ) {
            indicatorArrayLevel1.push(
              <div>
                <ul>
                  <li style={{ listStyleType: "disc", color: " #000" }}>
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </li>
                </ul>
              </div>
            );
          } else if (
            indicators.Level === "LEVEL 2" ||
            indicators.Level === "Level 2" ||
            indicators.Level === "Level-2"
          ) {
            indicatorArrayLevel2.push(
              <div>
                <ul>
                  <li style={{ listStyleType: "disc", color: " #000" }}>
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </li>
                </ul>
              </div>
            );
          } else if (
            indicators.Level === "Level-3" ||
            indicators.Level === "Level 3" ||
            indicators.Level === "LEVEL 3" ||
            indicators.Level === "Level-3" ||
            indicators.Level === "LEVEL3"
          ) {
            indicatorArrayLevel3.push(
              <div>
                <ul>
                  <li style={{ listStyleType: "disc", color: " #000" }}>
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </li>
                </ul>
              </div>
            );
          } else if (
            indicators.Level === "LEVEL 4" ||
            indicators.Level === "Level 4" ||
            indicators.Level === "Level-4"
          ) {
            indicatorArrayLevel4.push(
              <div>
                <ul>
                  <li style={{ listStyleType: "disc", color: " #000" }}>
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </li>
                </ul>
              </div>
            );
          }
        }
      });
    });
    this.setState({
      indicatorArrayLevel1,
      indicatorArrayLevel2,
      indicatorArrayLevel3,
      indicatorArrayLevel4
    });
    this.fun();
  };

  levelSelect = e => {
    let levelType = this.state.levelType;
    this.setState({ lvlType: levelType[e] });
  };
  indicatorText = async (e, index) => {
    if (e) {
      console.log(e.target.value);
      await this.setState({ indicatorText: e.target.value });
      this.fun(index);
    }
  };
  descriptionText = async (e, index) => {
    if (e) {
      await this.setState({ descriptionText: e.target.value });
      this.fun(index);
    }
  };
  yearList = async (e, index) => {
    this.setState({ yearListSelect: e });
    let { jobRoleSelect, worklevelSelect, CompSelect, adminId } = this.state;
    const Indicators = await HrMiddleWare.getRandomData(
      "tbl_indicators",
      `textType = 'Technical' and competencyId = ${CompSelect.value} and customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "indicators as label , id as value"
    );
    await this.setState({ Indicators: Indicators.data });
    this.fun(index);
  };
  fun = async e => {
    let index = e;
    let modal = [];
    modal.push(
      <div>
        <div class="row">
          <div class="col-lg-12">
            <section class="panel">
              <header class="panel-heading tab-bg-info"></header>
              <div>
                <ul class="nav nav-tabs">
                  <li class="active">
                    <a data-toggle="tab" href="#addIndi">
                      Add Indicator
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#mapIndi">
                      Map Indicator
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="addIndi" className="tab-pane fade in active">
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Levels</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <SingleSelect
                          options={this.state.levelType}
                          selectedService={this.state.levelType[index]}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Matrix Type</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          name="Technical"
                          value="Technical"
                          checked
                        />
                        Technical
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Indicator</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-5">
                        <textarea
                          // type="text"
                          className="form-control"
                          onChange={d => this.indicatorText(d, index)}
                          value={this.state.indicatorText}
                        />
                        <br />
                        <span className="errorMsg">{this.state.errorIndi}</span>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Description</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Enter Description Text "
                          onChange={d => this.descriptionText(d, index)}
                          value={this.state.descriptionText}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="submit-btn"
                          data-dismiss="modal"
                          onClick={this.submitTechIndicator}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </div>
                  <div id="mapIndi" className="tab-pane fade in">
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Jobrole</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <SingleSelect
                          options={this.state.jobRole}
                          handleChange={d =>
                            this.selectBoxStore("jobRoleSelect", d)
                          }
                          selectedService={this.state.jobRoleSelect}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Work Level</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <SingleSelect
                          options={this.state.worklevel}
                          handleChange={d =>
                            this.selectBoxStore("worklevelSelect", d)
                          }
                          selectedService={this.state.worklevelSelect}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Financial Year</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <SingleSelect
                          options={this.state.yearList}
                          handleChange={e => this.yearList(e, index)}
                          selectedService={this.state.yearListSelect}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label>Technical indicator</label>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-6">
                        <SingleSelect
                          options={this.state.Indicators}
                          handleChange={d =>
                            this.selectBoxStore("IndicatorsSelect", d)
                          }
                          selectedService={this.state.IndicatorsSelect}
                        />
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btncolorsubmit"
                          onClick={this.mappingTechIndicator}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
    let key = e;
    await this.setState({ modal, index: e, key: key });
  };

  submitTechIndicator = async () => {
    const {
      key,
      index,
      levelType,
      CompSelect,
      adminId,
      IndicatorsSelect,
      indicatorText,
      funSelected,
      descriptionText
    } = this.state;
    console.log(index);
    console.log(levelType);
    if (!indicatorText) {
      this.setState({ errorIndi: "Please Enter Indicator" });
      this.fun(key);
      return false;
    } else {
      this.setState({ errorIndi: " " });
      this.fun(key);
    }
    const formData = new FormData();

    formData.append("Level", levelType[index].label);
    formData.append("indicators", indicatorText);
    formData.append("Description", descriptionText);
    formData.append("competencyId", CompSelect.value);
    formData.append("themeId", funSelected.value);
    formData.append("textType", "Technical");
    formData.append("status", "active");
    formData.append("customerid", adminId);
    formData.append("adminId", adminId);
    formData.append("createdAt", 1);
    try {
      let result = await HrMiddleWare.addIndicatorsLevel(formData);
      if (result) {
        this.view();
        Swal.fire({
          position: "center",
          type: "success",
          title: "Indicators Added ",
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          indicatorText: "",
          descriptionText: ""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  mappingTechIndicator = async () => {
    let {
      funSelected,
      key,
      levelType,
      CompSelect,
      jobRoleSelect,
      yearListSelect,
      worklevelSelect,
      IndicatorsSelect,
      adminId
    } = this.state;
    let str = levelType[key].label.toString();
    var res = str.charAt(str.length - 1);
    const formData = new FormData();
    formData.append("matrixType", "Technical");
    formData.append("CmpId", CompSelect.value);
    formData.append("themeId", funSelected.value);
    formData.append("jobRoleId", jobRoleSelect.value);
    formData.append("financial_year", yearListSelect.value);
    formData.append("worklevel", worklevelSelect.value);
    formData.append("level", levelType[key].label);
    formData.append("adminId", adminId);
    formData.append("status", "active");
    formData.append("proficiency_scale", res);
    formData.append("indicatorsID", IndicatorsSelect.value);

    let result = await HrMiddleWare.mappingIndicatorUser(
      formData,
      "tbl_cmpLevel",
      1,
      "mapped_matrix_to_user",
      "mdl_individualcontributor"
    );
    if (result) {
      Swal.fire({
        position: "center",
        type: "success",
        title: "Indicators Mapped ",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  CompSelect = async e => {
    let { funSelected, adminId } = this.state;
    // console.log(funSelected.value, e.value, adminId, "Functionelect value");
    let result = await HrMiddleWare.getRandomData(
      "tbl_competency",
      `id = ${e.value}`,
      "*"
    );
    let result1 = await HrMiddleWare.getRandomData(
      "tbl_tech_comp_criticals",
      `theme = ${funSelected.value} and cmpId = ${adminId}  `,
      "*"
    );
    // console.log(result);
    // console.log(result1.data[0]);
    this.setState({
      CompSelect: e,
      compDetails: result.data[0],
      compCrit: result1.data[0]
    });
  };
  render() {
    const {
      fun,
      funSelected,
      CompList,
      CompSelect,
      indicatorList,
      competencyText,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText,
      table,
      indicatorArrayLevel1,
      indicatorArrayLevel2,
      indicatorArrayLevel3,
      indicatorArrayLevel4,
      compDetails,
      compCrit,
      themeList,
      themeSelect,
      workLeveList,
      workSelect,
    } = this.state;
    return (
      <React.Fragment>
        <section>

          <div className="container container-sm-fluid mt-100">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 col-12">
                <div className="content-border1 my-5">
                  <div className="text-center my-3">
                    <h3>Technical Competency Framework</h3>
                  </div>
                  <hr style={{ borderTop: "2px dashed var(--input-border-color)" }} />

                  <div className='row  justify-content-between px-3 align-items-center my-4   px-md-3 px-lg-5'>
                    <div className="col-lg-1 d-none d-lg-block" />
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label className="formLabel">Function</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12'>
                      <SingleSelect
                        options={fun}
                        handleChange={d => this.funSelected(d)}
                        selectedService={funSelected}
                      />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block" />

                  </div>
                  <div className='row  justify-content-between px-3 align-items-center my-4   px-md-3 px-lg-5'>
                    <div className="col-lg-1 d-none d-lg-block" />
                    <div className='col-lg-4 col-md-6 col-12'>
                      <label>Competency</label>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12'>
                      <SingleSelect
                        options={CompList}
                        handleChange={d => this.CompSelect(d)}
                        selectedService={CompSelect}
                      />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block" />

                  </div>
                  <div className='row  justify-content-between px-3 align-items-center my-4   px-md-3 px-lg-5'>
                    <div className="col-lg-1 d-none d-lg-block" />
                    <div className='col-lg-4 col-md-6 col-12'>
                      <button
                        type="button"
                        className="btncolor "
                        onClick={this.view}
                      >
                        View Competency
                      </button>
                    </div>
                    <div className='col-lg-5 col-md-6 col-12'>
                      <LoginModal
                        buttonTitle="Add technical competency"
                        extraClass="btncolor"
                        title="Add Technical Competency"
                        id="addTechComp"
                        bodyText={
                          <div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12">Competency Type</div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12 ">
                                <input
                                  type="radio"
                                  value="Technical"
                                  checked="true"
                                />
                                Technical
                              </div>
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel">Theme</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <SingleSelect
                                  handleChange={d => this.selectBoxStore("themeSelect", d)}
                                  menuPortalTarget={null}
                                  menuShouldScrollIntoView={null}
                                  options={themeList}
                                  selectedService={themeSelect}
                                  placeholder="Select Theme"
                                  
                                />

                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel">Work Level</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <SingleSelect
                                  handleChange={d =>
                                    this.selectBoxStore("workSelect", d)
                                  }
                                  menuPortalTarget={null}
                                  menuShouldScrollIntoView={null}
                                  options={workLeveList}
                                  selectedService={workSelect}
                                  placeholder="Select Work-level"
                                />
                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel">Competency</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <input
                                  type="text"
                                  className="form-control inputss"
                                  placeholder="Enter compentency"
                                  onChange={this.competencyText}
                                  value={competencyText}
                                />
                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel">Competency Definition</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <textarea
                                  type="text"
                                  className="form-control inputss"
                                  placeholder="Enter Job Description Text "
                                  onChange={this.definitionText}
                                  value={definitionText}
                                />
                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel"> Why it`s important?</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <textarea
                                  type="text"
                                  className="form-control inputss "
                                  placeholder="Enter Job Description Text "
                                  onChange={this.importanceText}
                                  value={importanceText}
                                />
                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel"> Knowledge area</label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <textarea
                                  type="text"
                                  className="form-control inputss"
                                  placeholder="Enter Job Description Text "
                                  onChange={this.knowledgeText}
                                  value={knowledgeText}
                                />
                              </div>
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                            </div>
                            <div className="row form-group ">
                              <div className="col-sm-1 col-md-1 col-lg-1" />
                              <div className="col-sm-4 col-md-4 col-lg-4 col-12 ">
                                <label className="formLabel"> Performance Parameter </label>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-12">
                                <textarea
                                  type="text"
                                  className="form-control inputss"
                                  placeholder="Enter Job Description Text "
                                  onChange={this.performanceText}
                                  value={performanceText}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-3" />
                              <div className="col-sm-5">
                                <button
                                  type="button"
                                  className="submit-btn"
                                  onClick={this.compSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="col-sm-2" />
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block" />
                    <br />
                    {indicatorArrayLevel1 && (
                      <div>
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          >
                            <div className="row">
                              <div
                                className="col-sm-6 text-center py-2 content-border1 tech-table-bg"
                                style={{ fontWeight: "bolder", }}
                              >
                                {"FUNCTIONAL COMPETENCY DICTIONARY"}{" "}
                              </div>
                              <div
                                className="col-sm-6 text-center py-2 content-border1 tech-table-bg"
                                style={{}}
                              >
                                {" "}
                                {"Function/Theme : " + funSelected.label}
                              </div>
                            </div>
                            <div className="row py-2 content-border1 ps-3 bg" >
                              {"Competency " + " : " + CompSelect.label}
                            </div>
                            <div className="row">
                              <div class="col-sm-6 content-border1 p-0 "  >
                                <div class="py-2 text-center tech-table-bg">
                                  COMPETENCY DEFINITION
                                </div>
                              </div>
                              <div class="col-sm-6 content-border1 p-0" >
                                <div class="py-2 text-center tech-table-bg " >
                                  WHY IT IS IMPORTANT ?
                                </div>
                              </div>
                            </div>
                            <div class={`row ${compDetails && compDetails.Description ? "content-border1 " : ""}`}>
                              <div class="col-sm-6 ">
                                <div class="newview-center-fix-content">
                                  {compDetails && compDetails.Description}
                                </div>
                              </div>
                              <div class="col-sm-6 ">
                                <div class="newview-center-fix-content">
                                  {compDetails &&
                                    compDetails.CompetencyImportant}
                                </div>
                              </div>
                            </div>
                            <div class="row form-group ">
                              <div className="col-sm-3 content-border1 text-center tech-table-bg" >
                                <label>Level-1</label>
                                <LoginModal
                                  atagLink="true"
                                  extraClass="fa fa-plus newviewhr-newIcon"
                                  title="Add New Indicator"
                                  id="level1"
                                  onClick={() => this.fun(0)}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  bodyText={<div>{this.state.modal}</div>}
                                />
                              </div>

                              <div className="col-sm-3 content-border1 text-center tech-table-bg" >
                                <label>Level-2</label>
                                <LoginModal
                                  atagLink="true"
                                  extraClass="fa fa-plus newviewhr-newIcon"
                                  title="Add New Indicator"
                                  id="level1"
                                  onClick={() => this.fun(1)}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  bodyText={<div>{this.state.modal}</div>}
                                />
                              </div>

                              <div className="col-sm-3 content-border1 text-center tech-table-bg">
                                <label>Level-3</label>
                                <LoginModal
                                  atagLink="true"
                                  extraClass="fa fa-plus newviewhr-newIcon"
                                  title="Add New Indicator"
                                  id="level1"
                                  onClick={() => this.fun(2)}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  bodyText={<div>{this.state.modal}</div>}
                                />
                              </div>

                              <div className="col-sm-3 content-border1 text-center tech-table-bg">
                                <label>Level-4</label>
                                <LoginModal
                                  atagLink="true"
                                  extraClass="fa fa-plus newviewhr-newIcon"
                                  title="Add New Indicator"
                                  id="level1"
                                  onClick={() => this.fun(3)}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  bodyText={<div>{this.state.modal}</div>}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="panel-body">
                            <div class="row form-group ">
                              <div className="col-sm-3">
                                {indicatorArrayLevel1}
                              </div>
                              <div className="col-sm-3">
                                {indicatorArrayLevel2}
                              </div>
                              <div className="col-sm-3">
                                {indicatorArrayLevel3}
                              </div>
                              <div className="col-sm-3">
                                {indicatorArrayLevel4}
                              </div>
                            </div>
                            <div class="row"></div>
                            <div class="row">
                              <div class="col-sm-12 newview-border ">
                                <div class="newview-center-fix py-2">
                                  CRITICAL KNOWLEDGE AREAS :{" "}
                                </div>
                              </div>
                            </div>
                            <div class={`row ${compCrit && compCrit.critical_knowledge ? "content-border1" : ""}`}>
                              <div class="ms-4">
                                {compCrit && compCrit.critical_knowledge}
                              </div>
                            </div>
                            <div class="row"></div>
                            <div class="row">
                              <div class="col-sm-12 newview-border">
                                <div class="newview-center-fix py-2">
                                  CRITICAL PERFORMANCE PARAMETERS :{" "}
                                </div>
                              </div>
                            </div>
                            <div class={`row ${compCrit && compCrit.critical_performance ? "content-border1" : ""}`}>
                              <div class="newview-center-fix-content">
                                {compCrit && compCrit.critical_performance}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

      </React.Fragment>
    );
  }
}

export default TechCompDictionary;
