import React, { Component, isValidElement } from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import { Datatable } from "../../components/Datatable";
import moment from "moment";

export default class UserViewHistoryIdp extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      EditHistory: [],
      behTech: [
        { label: "Behavioural", value: 1 },
        { label: "Technical", value: 2 }
      ],
      goalterm: [
        { label: "short", value: 1 },
        { label: "mid", value: 2 },
        { label: "long", value: 3 },
      ],
      goaltermselected: {},
      behTechSelected: {},
      financialYearSelected: {},
      ShortArray: [],
      MidArray: [],
      LongArray: [],
      column: [
        {
          Header: "GoalName",
          accessor: "goalname"
        },
        {
          Header: "Percentage",
          accessor: "percentage"
        },
        {
          Header: "status",
          accessor: "statusChange"
        },
        {
          Header: "Reason_Deviation",
          accessor: "reason_deviation"
        },
        {
          Header: "Actual_Enddate",
          accessor: "actual_end_date"
        },
        {
          Header: "EditedBy",
          accessor: "name"
        },
        {
          Header: "EditedAt",
          accessor: "createdAt"
        },
      ],
      ManageIDP: [],
    }
  }

  async componentWillMount() {
    //console.log(this.props.match.params.id);
    let id = this.props.match.params.id;
    this.setState({ userId: id })
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }
    //tbl_users.id=${id} and idp_goals_history.goal_id =tbl_idp_goalterm.id and tbl_idp_goalterm.userid =${id}
   
  }

  behTechSelect = async e => {
    this.setState({ behTechSelected: e });
    // await this.GetHistory(e.label, this.state.financialYearSelected.value ? this.state.financialYearSelected.value : null);
  }

  financialYearSelect = async e => {
    this.setState({ financialYearSelected: e });
    await this.GetHistory(this.state.behTechSelected.label ? this.state.behTechSelected.label : null, e.value);
  }


  GoaltermSelect = async e => {
    const { ShortArray, MidArray, LongArray } = this.state;
    this.setState({ goaltermselected: e });
    let Data = [];
    if (e.label == "short" && ShortArray != null) {
      let wait = await ShortArray.map(async (ival, i) => {
        let result = await HrMiddleWare.getRandomData(
          "idp_goals_history,tbl_idp_goalterm,tbl_users",
          `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
          'idp_goals_history.*,tbl_users.name'
        )
        // and idp_goals_history.tearmtype=${e.label}
        if (result) {
          result.data.map(async (jval, j) => {
            //  console.log(jval);
            Data.push(jval)
          })

        }
      })
      await Promise.all(wait);
      //this.setState({EditHistory:Data});
      // console.log(Data);

    } else if (e.label == "mid" && MidArray != null) {

      let wait = await MidArray.map(async (ival, i) => {
        let result = await HrMiddleWare.getRandomData(
          "idp_goals_history,tbl_idp_goalterm,tbl_users",
          `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
          'idp_goals_history.*,tbl_users.name'
        )
        // and idp_goals_history.tearmtype=${e.label}
        if (result) {
          result.data.map(async (jval, j) => {
            //console.log(jval);
            Data.push(jval)
          })

        }
      })
      await Promise.all(wait);
    } else if (e.label == "long" && LongArray != null) {
      let wait = await LongArray.map(async (ival, i) => {
        let result = await HrMiddleWare.getRandomData(
          "idp_goals_history,tbl_idp_goalterm,tbl_users",
          `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
          'idp_goals_history.*,tbl_users.name'
        )
        // and idp_goals_history.tearmtype=${e.label}
        if (result) {
          result.data.map(async (jval, j) => {
            // console.log(jval);
            Data.push(jval)
          })

        }
      })
      await Promise.all(wait);

    };
    Data.map((ival, i) => {
      Data[i].createdAt = moment(ival.createdAt).format("YYYY-MM-DD");
    });
    this.setState({ EditHistory: Data })

  }

  GetHistory = async (a , b) => {
    console.log(a, b, this.state.userId);
    if (a != null & b != null) {
      let ManageIDP = await HrMiddleWare.getRandomData(
        "tbl_idp_goals",
        `userid = ${this.state.userId} and yearId = ${b} and type ='${a}'`,
        '*'
      )
      console.log(ManageIDP.data,"manage idp");
      if (ManageIDP.data) {
        this.setState({ ManageIDP: ManageIDP.data });
        ManageIDP.data.map((ival, i) => {
          if (ival.short_term != null) {
            this.setState({ ShortArray: JSON.parse(ival.short_term) });
          }
          if (ival.mid_term != null) {
            console.log(ival.mid_term)
            this.setState({ MidArray: JSON.parse(ival.mid_term) });
          }
          if (ival.long_term != null) {
            this.setState({ LongArray: JSON.parse(ival.long_term) });
          }
        })


      }
    }
  }


  render() {
    //  console.log(this.state.EditHistory)
    return (
      <React.Fragment>
        <section>
          <div className="container container-sm-fluid mt-100">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 col-md-12 col-12">
                <div className="content-border1 px-lg-4 px-2 py-5 my-5 text">
                  <div className="col-12 text-center py-4">
                    <h2>View History</h2>
                  </div>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-lg-1 d-none d-lg-block" />
                    <div className="col-lg-3 col-md-6 col-12">
                      <lablel className="formLabel" style={{fontSize:"20px"}} >IDP type</lablel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <SingleSelect
                        handleChange={this.behTechSelect}
                        options={this.state.behTech}
                        selectedService={this.state.behTechSelected}
                      />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block" />
                  </div>
                  <div className="row justify-content-between align-items-center pb-5">
                    <div className="col-lg-1 d-none d-lg-block" />
                    <div className="col-lg-3 col-md-6 col-12">
                      <lablel className="formLabel"  style={{fontSize:"20px"}} >Select Year</lablel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <SingleSelect
                        handleChange={this.financialYearSelect}
                        options={this.state.yearList}
                        selectedService={this.state.financialYearSelected}
                      />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block" />
                  </div>
                  {this.state.ManageIDP.length?
                    <React.Fragment>
                      <div className="row justify-content-between align-items-center">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-3 col-md-6 col-12">
                          <lablel style={{fontSize:"20px"}} className="formLabel">Select Goal-Type</lablel>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <SingleSelect
                            handleChange={this.GoaltermSelect}
                            options={this.state.goalterm}
                            selectedService={this.state.goaltermselected}
                          />
                        </div>
                        <div className="col-lg-1 d-none d-lg-block" />
                      </div>
                    </React.Fragment> : null}
                  <div className="col-12">
                    {this.state.EditHistory.length ?
                      <div className="py-5">
                        <Datatable
                        data={this.state.EditHistory}
                        columnHeading={this.state.column}
                      />
                      </div>
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

