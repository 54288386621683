import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare, SingleSelect } from '../../../components/Form';
import Swal from 'sweetalert2';
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import { CSVLink } from "react-csv";
import WizardPrograss from './wizardPrograssbar';
import WizardNextPreviousButton from './wizardNextPreviousButton';

const tableName = `tbl_cmpLevel`;

class ProficiencyScale extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      matrixType: null,
      errormatrixType: null,
      Level: null,
      errorLevel: null,
      competencyOptions: [],
      competencySelected: null,
      CmpId: null,
      errorcompetencySelected: null,
      alertVisible: false,
      updateData: false,
      index: null,
      excelHead2: [
        { label: "SI.NO", key: "value" },
        { label: "Level", key: "label" },

      ],
      leveOption: [{ label: "Level-1", value: 1 },
      { label: "Level-2", value: 2 },
      { label: "Level-3", value: 3 },
      { label: "Level-4", value: 4 },
      { label: "Level-5", value: 5 }],
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "Matrix Type", key: "matrixType" },
        { label: "Level", key: "Level" },
        { label: "Competency", key: "competencyname" },

      ],
      excelHeadCompetency: [
        { label: "matrixType", key: "id" },
        { label: "Competency", key: "competencyname" },
        { label: "Matrix Type", key: "textType" },
        { label: "Competency Important", key: "CompetencyImportant" }
      ],
      excelHeadmultidata: [
        // { label: "SI.NO", key: "id" },
        { label: "matrixType", key: "CompetencyType" },
        { label: "CmpId", key: "Competency" },
        { label: "Level", key: "ProficiencyScale" },
      ],
      addUserColumn: [
        {
          label: "CompetencyType", value: 1, type: "selectbox", selectOptions: [
            { label: "Behavioral", value: 1 },
            { label: "Technical", value: 2 }
          ]
        },
        { label: "Competency", value: 2, type: "selectbox", selectOptions: [] },
        {
          label: "ProficiencyScale", value: 3, type: "selectbox", selectOptions: [
            { label: "Level-1", value: 1 },
            { label: "Level-2", value: 2 },
            { label: "Level-3", value: 3 },
            { label: "Level-4", value: 4 },
            { label: "Level-5", value: 5 }
          ]
        },
      ],
      statusArray: [],
      multyIndicatorsAdd: true,
      userAddRow: "",
      RowCount: "",
      showDatatable: true,
      submitDataAlert: false,

    };
  }

  async componentWillMount() {
    const adminId = await localStorage.getItem('userId');
    this.proficiencyScale(adminId);
    this.setState({
      adminId
    });
  }

  proficiencyScale = async adminId => {
    try {
      const { data } = await MasterMiddleWare.tbl_cmpLevel(adminId);
      const { data: data1competency } = await MasterMiddleWare.getMaster('tbl_competency', adminId);

      if (data) {
        this.setState({ data, data1competency });
      }
    } catch (error) {
      console.error(error);
    }
  };

  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Matrix Type',
      accessor: 'matrixType'
    },
    {
      Header: 'Level',
      accessor: 'Level'
    },
    {
      Header: 'Competency',
      accessor: 'competencyname'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  onSubmit = async () => {
    const { matrixType, CmpId, Level, adminId } = this.state;

    if (this.validate(matrixType, 'errormatrixType', 'Please select competency type')) return true;
    if (this.validate(CmpId, 'errorcompetencySelected', 'Please select competency')) return true;
    if (this.validate(Level, 'errorLevel', 'Please Enter proficiency Scale')) return true;
    const formData = new FormData();
    formData.append('matrixType', matrixType);
    formData.append('CmpId', CmpId);
    formData.append('Level', Level);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    formData.append('status', "active");
    // console.log(...formData, "formdata");
    // console.log(tableName, "tableName");
    // return false;
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Proficiency Scale has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        this.proficiencyScale(adminId);
        this.setState({
          alertVisible: true,
          matrixType: '',
          CmpId: '',
          Level: '',
          competencyOptions: [],
          competencySelected: null,
          levelOptionSelected: null
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  buttonEdit = async value => {
    const { adminId } = this.state;
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const targetValue = getData.matrixType;
    try {
      const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(targetValue, adminId);
      if (competencyOptions) {
        const competencySelected = competencyOptions.filter(value => value.value === getData.CmpId);
        const levelOptionSelected = this.state.leveOption.filter(value => value.label === getData.Level)
        this.setState({
          competencyOptions,
          competencySelected,
          levelOptionSelected
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({
      index,
      matrixType: getData.matrixType,
      errormatrixType: '',
      Level: getData.Level,
      errorLevel: '',
      CmpId: getData.CmpId,
      errorcompetencySelected: '',
      alertVisible: false,
      updateData: true
    });
  };

  onUpdate = async () => {
    let index = this.state.index;
    const { matrixType, CmpId, Level, adminId } = this.state;

    if (this.validate(matrixType, 'errormatrixType', 'Please select competency type')) return true;
    if (this.validate(CmpId, 'errorcompetencySelected', 'Please select competency')) return true;
    if (this.validate(Level, 'errorLevel', 'Please Enter proficiency Scale')) return true;
    const formData = new FormData();
    formData.append('matrixType', matrixType);
    formData.append('CmpId', CmpId);
    formData.append('Level', Level);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;

    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Proficiency Scale has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.proficiencyScale(adminId);
        this.setState({
          alertVisible: true,
          matrixType: '',
          CmpId: '',
          Level: '',
          competencyOptions: [],
          competencySelected: null,
          updateData: false,
          levelOptionSelected: null


        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  handleRadioInput = async e => {
    this.setState({ [e.target.name]: e.target.value });
    const targetValue = e.target.value;
    const adminId = this.state.adminId;
    try {
      const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(targetValue, adminId);
      if (competencyOptions) {
        this.setState({
          competencyOptions,
          competencySelected: null,
          CmpId: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  competencySelect = selectedOption => {
    this.setState({
      competencySelected: selectedOption,
      CmpId: selectedOption.value
    });
  };
  levelSelect = selectedOption => {
    this.setState({
      levelOptionSelected: selectedOption,
      Level: selectedOption.label
    });
  };

  submitcsv = async (e) => {
    console.log(e, "e");
    await this.setState({ btnDisablecsv: true })
    const groupcompId = await localStorage.getItem('groupcompId');
    const { textType, competencyId, themeId, indicators, Description, adminId, files } = this.state;
    // console.log(files, "files");
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    formData.append('groupcompId', groupcompId)
    console.log(formData, "formData");
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.csvUpload(tableName, formData);
      console.log(result, "result");
      // const result = await MasterMiddleWare.createMaster(tableName, formData);
      let Timing = 3000
      if (result.data.Message == "success") {
        if (result.data.csvHeaderErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvHeaderErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else if (result.data.csvDataErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvDataErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Proficiency Scale has been saved',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.proficiencyScale(adminId);
        this.setState({
          alertVisible: true,
          matrixType: '',
          CmpId: '',
          Level: '',
          competencyOptions: [],
          competencySelected: null,
          levelOptionSelected: null,
          btnDisablecsv: false,
          files: ''
        });
        setTimeout(() => {
          window.location.reload()
        }, Timing);
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  }

  handleFileUpload = (fileItems) => {
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  singlemultiselect = (e, val) => {
    this.setState({
      multi: val
    });
  };

  handleSelectChange = async (value, rowIndex, columnLabel) => {
    let { adminId } = this.state
    // console.log(value, "value");
    // console.log(rowIndex, "rowIndex");
    // console.log(columnLabel, "columnLabel");
    // return false
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "CompetencyType" || columnLabel === "ProficiencyScale" ? value.label : value.value } : row
      )
    }));
    if (columnLabel === "CompetencyType") {
      const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(value.label, adminId);

      // console.log(competencyOptions, "competencyOptions");
      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, CompetencyOption: competencyOptions } : row
        )
      }));
    }
  };
  addRow = (value) => {
    console.log(value, "values");
    let { userAddRow, statusArray } = this.state
    // this.setState({ statusArray: [] })
    let addIndex = value ? statusArray.length : 0

    const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
      CompetencyType: "",
      Competency: "",
      ProficiencyScale: "",
      CompetencyTypeSelect: "",
      CompetencySelect: "",
      ProficiencyScaleSelect: "",
      CompetencyOption: [],
      ProficiencyScaleOption: [
        { label: "Level-1", value: 1 },
        { label: "Level-2", value: 2 },
        { label: "Level-3", value: 3 },
        { label: "Level-4", value: 4 },
        { label: "Level-5", value: 5 }
      ],
      CompetencyTypeOption: [
        { label: "Behavioral", value: 1 },
        { label: "Technical", value: 2 }
      ],
      index: value ? addIndex : index

    }));
    this.setState(prevState => ({
      statusArray: [...prevState.statusArray, ...newItems]
    }));

    console.log(statusArray, "statusArray");
  }
  handleInputChange = (e, rowIndex, columnLabel) => {
    const { value } = e.target;
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel]: value } : row
      )
    }));
  };

  addMultySubmit = async () => {
    let { statusArray, adminId } = this.state
    return new Promise((resolve, reject) => {
      this.setState({ submitDataAlert: true });
      setTimeout(() => {
        this.setState({ submitDataAlert: false });
        resolve();
      }, 3000);
    })
      .then(() => {
        const formData = new FormData();
        formData.append("statusArray", JSON.stringify(statusArray));
        // formData.append('groupcompId', groupcompId)
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        const previousData = [...this.state.data];
        return MasterMiddleWare.insertMuldidata(tableName, formData);
      })
      .then(result => {
        if (result.data.Message == "success") {
          if (result.data.fillDataErrorMsg) {
            const maxLength = 29;
            const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
            console.log(wrappedErrorMsg, "wrappedErrorMsg");
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: wrappedErrorMsg,
              showConfirmButton: false,
              timer: 6000
            });
            // Swal.fire({
            //   position: 'center',
            //   type: 'warning',
            //   title: `${result.data.fillDataErrorMsg}`,
            //   showConfirmButton: false,
            //   timer: 2000

            // });
          } else {

            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'Proficiency Scale has been saved',
              showConfirmButton: false,
              timer: 1500
            });
            this.getMaster(tableName);
            this.setState({
              alertVisible: true,
              btnDisablecsv: false,
              statusArray: "",
              userAddRow: ""
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
          }

        }
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  render() {
    const { data, matrixType, errormatrixType, Level, errorLevel, excelHead, competencyOptions, competencySelected, errorcompetencySelected, updateData, leveOption, levelOptionSelected, excelHeadCompetency, data1competency,
      addUserColumn,
      userAddRow,
      RowCount,
      statusArray,
      showDatatable
    } = this.state;
    const customs = {
      option: (provided, state) => ({
        ...provided,

        padding: 10,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: '9999 !important',
      }),
    }
    console.log(statusArray, "statusArray");
    return (
      <React.Fragment>
        <section style={{ zIndex: 0 }}>
          <div className='container container-sm-fluid mt-100 pb-5 mb-5'>
            <div className='py-2'>
              <WizardPrograss step={9} />
            </div>
            <div className='row justify-content-center align-items-center my-2'>
              <div className='col-lg-10 col-md-12 col-12'>
                <div className='content-border px-lg-5 px-md-3 px-2'>
                  <div className='header-text'>
                    <span>Proficiency Scale</span>
                  </div>
                  <hr className='px-0' />
                  {this.state.multyIndicatorsAdd ?
                    <div>
                      <div className='row justify-content-between align-items-center py-2 text'>
                        <div className='col-lg-1 d-none d-lg-block' />
                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 text py-2'>
                          <div className="" onClick={(e) => this.singlemultiselect(e, 1)} >
                            <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">Add single proficiency scale</label>
                          </div>
                        </div>
                        <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                          <div className="" onClick={(e) => this.singlemultiselect(e, 2)}>
                            <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">  Add multiple proficiency scale</label>
                          </div>
                        </div>
                        <div className='col-lg-1 d-none d-lg-block' />
                      </div>
                      {this.state.multi === 2 ?
                        <div>
                          <div>
                            <div className='row justify-content-between align-items-center py-2'>
                              <div className='col-lg-1 d-none d-lg-block' />
                              <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                <label htmlFor="exampleInputEmail1">
                                  Upload Files:
                                </label>
                              </div>
                              <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                <FilePond
                                  // type="file"
                                  files={this.state.files}
                                  // className="custom-file-input"
                                  id="customFile"
                                  // accept='.csv'
                                  acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                                  // onChange={this.fileupload}
                                  // files={this.state.Testimonial_image}
                                  onupdatefiles={this.handleFileUpload}
                                  allowReorder={true}
                                  allowMultiple={false}
                                  width={10}
                                  maxFileSize={'300KB'}
                                  // imageValidateSizeMaxWidth={550}
                                  // imageValidateSizeMaxHeight={355}
                                  imagePreviewMinHeight="150"
                                  imagePreviewMaxHeight="150"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                              <div className='col-lg-1 d-none d-lg-block' />
                            </div>
                            <div className='row justify-content-between align-items-center py-2'>
                              <div className='col-lg-1 d-none d-lg-block' />
                              <div className='col-lg-5 col-md-6 col-sm-12 col-12 text py-2 '>
                                <button className='btncolor ' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create proficiency scale CSV file</button>
                              </div>
                              <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                <button
                                  type="button"
                                  className='btncolor '
                                  onClick={(e) => this.submitcsv(e)}
                                  disabled={this.state.btnDisablecsv ? true : false}
                                >
                                  {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                                </button>
                              </div>
                              <div className='col-lg-1 d-none d-lg-block' />
                            </div>
                          </div>
                        </div>
                        :
                        <div className='py-3'>
                          <div className='row justify-content-between align-items-center py-2'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                              <label> Competency Type</label>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                  <input type="radio" id="Behavioral" name="matrixType" value="Behavioral" checked={matrixType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                                  <label>
                                    &nbsp;&nbsp;&nbsp;Behavioral
                                  </label>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <input type="radio" id="Technical" name="matrixType" value="Technical" checked={matrixType === 'Technical' && true} onChange={this.handleRadioInput} />
                                  <label>
                                    &nbsp;&nbsp;&nbsp;Technical
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                              <span className="errorMsg">{errormatrixType}</span>
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center py-2'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                              <label>Competency</label>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                              <SingleSelect handleChange={this.competencySelect} options={competencyOptions} selectedService={competencySelected} />
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                              <span className="errorMsg">{errorcompetencySelected}</span>
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center py-2'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                              <label>Proficiency Scale</label>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                              <SingleSelect handleChange={this.levelSelect} options={leveOption} selectedService={levelOptionSelected} />
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                              <span className="errorMsg">{errorLevel}</span>
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center '>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                              {!updateData ? (
                                <button type="button" className="submit-btn " onClick={this.onSubmit}>
                                  Submit
                                </button>
                              ) : (
                                <button type="button" className="submit- btn" onClick={this.onUpdate}>
                                  Update
                                </button>
                              )}
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                          <div className='row justify-content-between align-items-center'>
                            <div className='col-lg-1 d-none d-lg-block' />
                            <div className='col-lg-3 col-md-6 col-sm-12 col-12 text'>
                            </div>
                            <div className='col-lg-5 col-md-6 col-sm-12 col-12'>
                              <WizardNextPreviousButton step={8} nextPage={"Master/Indicators"} previousPage={"Master/Competency"} />
                            </div>
                            <div className='col-lg-1 d-none d-lg-block' />
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <div>
                      <div>
                        <div className='col-12 text-start px-2 px-lg-5 px-md-2'>
                          <button className='btncolor ' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                        </div>
                        <br></br>
                        <div className='row justify-content-between align-items-center py-2'>
                          <div className='col-lg-1 d-none d-lg-block' />
                          <div className='col-lg-4 col-md-6 col-sm-12 col-12 text py-2'>
                            <label htmlFor="indicators">How many competencys add ?</label>
                          </div>
                          <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                            <input type="number" className="form-control inputss" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                          </div>
                          <div className='col-lg-1 d-none d-lg-block' />
                        </div>
                        <div className='row justify-content-between align-items-center py-2'>
                          <div className='col-lg-1 d-none d-lg-block' />
                          <div className='col-lg-4 col-md-6 col-sm-12 col-12 text py-2'>
                          </div>
                          <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                            <button className='btncolor ' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                          </div>
                          <div className='col-lg-1 d-none d-lg-block' />
                        </div>
                        {this.state.submitDataAlert ?
                          <div className='loader1'></div>
                          :
                          <>
                            {statusArray && statusArray.length > 0 && (
                              <>
                                <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                  <table className="table table-bordered" style={{ position: "relative", zIndex: 0, overflow: "visible" }}>
                                    <thead>
                                      <tr>
                                        <th>S.No </th>

                                        {addUserColumn.map((index) => (
                                          <th key={index}> {index.label}</th>
                                        ))}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                                        // console.log(ival,"type",i,"i")

                                        <tr style={{ zIndex: 0 }}>
                                          <td style={{ textAlign: "center" }} >{i + 1}</td>
                                          {addUserColumn.map((jval, index) => (
                                            <>
                                              <td style={{ textAlign: "center", zIndex: 0 }}  >{jval && jval.type === "text" ? <>
                                                <textarea
                                                  style={{ height: "50px", width: "280px" }}
                                                  // type="text"
                                                  value={ival[jval.label]}
                                                  onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                /></> : jval.type === "selectbox" ?
                                                <div style={{ width: "280px" }}>
                                                  <SingleSelect
                                                    styles={customs}
                                                    handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                    options={ival[jval.label + 'Option']}
                                                    selectedService={ival[jval.label + "Select"]}

                                                  // disabled={false}
                                                  />

                                                  {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                </div>
                                                :
                                                <>
                                                </>}
                                              </td>
                                            </>
                                          ))}
                                        </tr>
                                      ))}

                                    </tbody>
                                  </table>
                                  {this.state.submitDataAlert ?
                                    <div className='loader1'></div>
                                    :
                                    <>
                                      {statusArray && statusArray.length > 0 &&
                                        <div className='row justify-content-between align-items-center py-2'>
                                          <div className='col-lg-1 d-none d-lg-block' />
                                          <div className='col-lg-3 col-md-6 col-sm-12 col-12 text py-2'>
                                            <button className='btncolor ' onClick={() => this.addRow(1)}>Add one</button>
                                          </div>
                                          <div className='col-lg-5 col-md-6 col-sm-12 col-12 py-2'>
                                            <button type="button" className="submit-btn mt-3 me-2" onClick={this.addMultySubmit}>
                                              Submit
                                            </button>
                                            <CSVLink
                                              headers={this.state.excelHeadmultidata}
                                              data={statusArray}
                                              filename={"competency.xls"}
                                              className='btncolor '
                                              target="_blank"
                                            >
                                              Generate Excel
                                            </CSVLink>
                                          </div>
                                          <div className='col-lg-1 d-none d-lg-block' />
                                        </div>
                                      }
                                    </>
                                  }
                                </div>

                              </>
                            )}
                          </>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 px-lg-5 px-md-3 px-2 py-5 mb-5'>
            {data && data.length && showDatatable ?
              <div className="row">
                <div className="col-12 text-end my-2">
                  <CSVLink
                    headers={excelHead}
                    data={data}
                    filename={"Function.xls"}
                    className='btncolor '
                    target="_blank"
                  >
                    Generate Excel
                  </CSVLink>
                </div>
              </div>
              :
              <></>}
            {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}
          </div>
        </section>
        <Style>
          {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.filepond{
  height: 82px;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 300px;
}

.filepond--credits{
  color:#f7f7f7;
}

.filepond--root :not(text) {
  font-size: 13px;
}

.multi:hover{
  color: limegreen;
}
.css-w9616n-menu{
  // background-color:blue;
  position:absolute !important ;
  // top:20px;
  // left:40px;
  z-index:9999999 !important ;
}
.excelexportlink{
  white-space: nowrap;
  text-decoration: underline;
}
.excelexportlink:hover{
  white-space: nowrap;
  text-decoration: underline;
  color:black
}


`}

        </Style >
      </React.Fragment>
    );
  }
}

export default ProficiencyScale;
