import React from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import ValidationView from "../../components/Form/ValidationView";
import SingleSelect from "../../components/Form/SingleSelect";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import Datatable from "../../components/Datatable/Datatable";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Swal from "sweetalert2";
class Derailers extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: localStorage.getItem("adminId"),
      groupcompId: localStorage.getItem("groupcompId"),
      uderId: localStorage.getItem("userId"),
      addMap: "Submit",
      designationOptions: [],
      designationSelected: "",
      textType: null,
      themeOptions: [],
      themcol: [],
      designationId: ""
    };
  }
  async componentDidMount() {
    const { adminId, groupcompId } = this.state;

    this.getSelectBoxMaster(
      adminId,
      "tbl_worklevel",
      "id",
      "worklevel",
      "workLevelOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_jobRole",
      "id",
      "jobRoleName",
      "jobRoleOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_derailers",
      "id",
      "derailers",
      "derailerOptions"
    );

    this.getSelectBoxMaster(
      adminId,
      "tbl_designation",
      "id",
      "designation",
      "designationOptions"
    );

    let designation_data = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `customerid = ${groupcompId}`,
      "*"
    );

    // let themeOptions= await HrMiddleWare.getRandomData(
    //   "tbl_theme",
    //   `textType='Technical' and adminId = ${this.state.adminId}`,
    //   "tbl_theme.theme as label ,tbl_theme.id as value"
    // );

    // if (themeOptions) {
    //   this.setState({
    //     themeOptions:themeOptions.data

    //   });
    // }
    console.log(designation_data)
    console.log(groupcompId)
    // this.getMaster("tbl_derailers");
    // const adminId = await localStorage.getItem("userId");
    // const data1 = await HrMiddleWare.getRandomData(
    //   "",
    //   ` id  not in (${newThemeid}) and customerid=${adminId} and textType='Behavioral' `,
    //   "theme as label,id as value"
    // );
    // const { data } = await MasterMiddleWare.getMasterByGroup(
    //   "tbl_derailers",
    //   adminId,
    //   groupcompId
    // );

    let themeOptions = await HrMiddleWare.getRandomData(
      "tbl_theme",
      ` adminId = ${this.state.adminId}`,
      "tbl_theme.theme as label ,tbl_theme.id as value"
    );
    console.log(themeOptions.data);
    this.setState({ themcol: themeOptions.data })
    let data1 = await HrMiddleWare.getRandomData(
      "Tblderailers_map",
      `groupcompany = ${groupcompId}`,
      "*"
    );
    this.setState({ data1: data1.data })
    const { data } = await HrMiddleWare.getRandomData(
      "tbl_derailers",
      `adminId = ${adminId} and groupcompany = ${groupcompId}`,
      "*"
    );
    console.log(data1);
    if (data) {
      console.log(data);
      this.setState({
        data,
        adminId,
        groupcompId
      });
    }
    console.log(data);
  }
  column = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span className="input-lable-title" style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailers"
    },
    {
      Header: props => (
        <span className="input-lable-title" style={{ fontWeight: "bold" }}> Description </span>
      ),
      accessor: "description"
      // Cell: d => this.NEW(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}>Edit</span>,
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Delete </span>,
      accessor: "delete",
      Cell: d => this.delete(d, "tbl_derailers")
    }
  ];

  column1 = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Theme Type </span>,
      accessor: "textType"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Theme /Function </span>,
      accessor: "themeid",
      Cell: d => this.getValueFromArray(d.original.themeid, this.state.themcol)
    },

    {
      Header: props => <span style={{ fontWeight: "bold" }}> Designation </span>,
      accessor: "designationid",
      Cell: d => this.getValueFromArray(d.original.designationid, this.state.designationOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> worklevel </span>,
      accessor: "worklevelid",
      Cell: d => this.getValueFromArray(d.original.worklevelid, this.state.workLevelOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailersid",
      Cell: d => this.getValueFromArray(d.original.derailersid, this.state.derailerOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}>Edit</span>,
      accessor: "edit",
      Cell: (d) => this.edit1(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Delete </span>,
      accessor: "delete",
      Cell: d => this.delete(d, "Tblderailers_map")
    }
  ];
  edit1 = value => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => this.buttonEdit1(value)}
      >
        Edit
      </button>
    );
  };
  buttonEdit1 = async value => {
    console.log("calling", value);
    let work = { label: this.getValueFromArray(value.original.worklevelid, this.state.workLevelOptions), value: value.original.worklevelid }
    await this.setState({
      index: value.index,
      id: value.original.id,
      addMap: "Map Edit",
      derailerSelected: { label: this.getValueFromArray(value.original.derailersid, this.state.derailerOptions), value: value.original.derailersid },
      // description: value.original.description,
      workLevelSelected: work,
      themeOptionSelected: { label: this.getValueFromArray(value.original.themeid, this.state.themcol), value: value.original.themeid },
      designationSelected: { label: this.getValueFromArray(value.original.designationid, this.state.designationOptions), value: value.original.designationid },
      textType: value.original.textType,
      targetValue: value.original.textType,
      workLevelId: value.original.worklevelid
    });
    // console.log(textType);
    window.scrollTo(0, 0)
  };
  NEW = d => {
    console.log(d);
    return <div>{d.original.label}</div>;
  };

  buttonEdit = value => {
    this.setState({
      index: value.index,
      id: value.original.id,
      addMap: "Edit",
      derailer: value.original.derailers,
      description: value.original.description
    });
    window.scrollTo(0, 0)
  };
  changeState = addMap => {
    this.setState({ addMap });
  };
  resetState = () => {
    this.setState({ derailer: "", description: "" });
  };
  onSubmit = () => {
    const {
      groupcompId,
      workLevelId,
      // jobRoleId,
      addMap,
      adminId,
      uderId,
      derailer,
      description,
      derailerId,
      themeOptionSelected,
      designationSelected,
      designationId,
      workLevelSelected,
      derailerSelected

    } = this.state;

    if (addMap === "Submit") {
      if (this.validate(derailer, "errorDerailer", "Please enter derailer"))
        return true;
      if (
        this.validate(
          description,
          "errorDescription",
          "Please enter description"
        )
      )
        return true;

      var formData = new FormData();
      formData.append("derailers", derailer);
      formData.append("description", description);
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("adminId", localStorage.getItem("adminId"));
      formData.append("status", "active");
      formData.append("groupcompany", groupcompId);

      this.addnew("tbl_derailers", formData, "Derailers Added");
    } else if (addMap === "Map") {
      if (
        this.validate(
          workLevelId,
          "errorWorkLevelSelected",
          "Please Select Work Level"
        )
      )
        return true;

      // if (
      //   this.validate(
      //     jobRoleId,
      //     "errorJobRoleSelected",
      //     "Please Select JobRole"
      //   )
      // )
      //   return true;
      if (
        this.validate(
          derailerId,
          "errorDerailerSelected",
          "Please Select Derailer"
        )
      )
        return true;

      if (
        this.validate(
          themeOptionSelected,
          "errorthemeOptionSelected",
          "Please Select Work Level"
        )
      )
        return true;

      if (
        this.validate(
          designationId,
          "errordesignationSelected",
          "Please Select Designation"
        )
      )
        return true;


      var formData = new FormData();
      formData.append("worklevelid", workLevelId);
      formData.append("jobroleid", localStorage.getItem("jobRoleId"));
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("derailersid", derailerId);
      formData.append("groupcompany", groupcompId);
      formData.append("themeid", themeOptionSelected.value);
      formData.append("designationid", designationSelected.value);
      // formData.append("userId", "[]");
      formData.append("userId", uderId);

      formData.append("textType", this.state.targetValue);
      console.log([...formData])

      this.addnew("Tblderailers_map", formData, "Derailers Mapped");
    } else if (addMap === "Edit") {
      if (this.validate(derailer, "errorDerailer", "Please enter derailer"))
        return true;
      if (
        this.validate(
          description,
          "errorDescription",
          "Please enter description"
        )
      )
        return true;

      var formData = new FormData();
      formData.append("derailers", derailer);
      formData.append("description", description);
      formData.append("customerid", adminId);
      formData.append("adminId", adminId);
      formData.append("status", "active");
      formData.append("groupcompany", groupcompId);

      this.addnew("tbl_derailers", formData, "Derailers Updated");
    } else if (addMap === "Map Edit") {


      var formData = new FormData();
      formData.append("worklevelid", workLevelId);
      formData.append("jobroleid", localStorage.getItem("jobRoleId"));
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("derailersid", derailerId);
      formData.append("groupcompany", groupcompId);
      formData.append("themeid", themeOptionSelected.value);
      formData.append("designationid", designationSelected.value);
      // formData.append("userId", "[]");
      formData.append("userId", uderId);

      formData.append("textType", this.state.targetValue);
      console.log([...formData])

      this.addnew("Tblderailers_map", formData, "Derailers Mapped Updated");
    }
  };
  addnew = async (tableName, formData, msg) => {
    console.log([...formData]);
    // console.log(formData.get("derailers"));
    // let data = [...this.state.data];
    try {
      let addMap = this.state.addMap;
      if (addMap === "Submit" || addMap === "Map") {
        var result = await MasterMiddleWare.createMaster(tableName, formData);
      } else if (addMap === "Edit" || addMap === "Map Edit") {
        var result = await MasterMiddleWare.updateMaster(
          tableName,
          this.state.id,
          formData
        );
      }
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: msg,
          showConfirmButton: false,
          timer: 1500
        });

        // let newArray = {};
        // newArray.derailers = formData.get("derailers");
        // newArray.description = formData.get("description");

        // let newData = [newArray, ...this.state.data];
        if (addMap === "Submit" || addMap === "Map") {
          let newData111 = await HrMiddleWare.getRandomData(
            "tbl_derailers",
            `groupcompany = ${this.state.groupcompId}`,
            "*"
          );
          let newData = newData111.data.sort((a, b) => b.id - a.id);
          this.setState({ data: newData });

          let newData11 = await HrMiddleWare.getRandomData(
            "Tblderailers_map",
            `groupcompany = ${this.state.groupcompId} `,
            "*"
          );
          let newData1 = newData11.data.sort((a, b) => b.id - a.id);
          this.setState({ data1: newData1 });
        } else {
          let newArray = {};
          newArray.derailers = formData.get("derailers");
          newArray.description = formData.get("description");

          let newData = [newArray, ...this.state.data];
          this.setState({ data: newData });


        }
        this.resetState();
        setTimeout(() => {
          this.componentDidMount()
        }, 4000);
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  };
  themeOptionselect = (e) => {
    console.log(e)
    this.setState({ themeOptionSelected: e })
  }


  handleRadioInput = async e => {
    this.setState({ [e.target.name]: e.target.value });
    let targetValue = e.target.value;
    let adminId = this.state.adminId;
    this.setState({ targetValue: targetValue })

    try {


      let themeOptions = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `textType='${targetValue}' and adminId = ${this.state.adminId}`,
        "tbl_theme.theme as label ,tbl_theme.id as value"
      );

      if (themeOptions) {
        this.setState({
          themeOptions: themeOptions.data

        });
      }

    } catch (error) {
      console.error(error);
    }

  }

  render() {
    const {
      workLevelOptions,
      workLevelSelected,
      errorWorkLevelSelected,
      jobRoleOptions,
      jobRoleSelected,
      errorJobRoleSelected,
      addMap,
      description,
      derailer,
      errorDerailer,
      errorDescription,
      derailerOptions,
      derailerSelected,
      errorDerailerSelected,
      data,
      data1,
      designationSelected,
      designationOptions,
      textType,
      themeOptions,
      themeOptionSelected,
      errorthemeOptionSelected,
      errordesignationSelected
    } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className=" mt-100 container container-sm-fluid">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-10 col-12 ">
                <div className="content-border1 px-lg-5 px-2 my-5">
                  <div className=" text text-center d-railers-head my-3">
                    <h3>{addMap} Derailer</h3>
                  </div>
                  <div className="box-tools pull-right d-none">
                    <button
                      className="btn btn-box-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div className="row justify-content-evenly align-items-center">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                      <button
                        type="button"
                        className="btndefault btn-info fs-5"
                        style={{ fontWeight: "00" }}
                        onClick={() => this.changeState("Submit")}
                      >
                        Add Derailers
                      </button>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-6 text-lg-end">
                      <button
                        type="button"
                        className="warning-btn btn-warning fs-5 "
                        style={{ padding: "5px 10px", borderRadius: "4px", fontWeight: "600" }}
                        onClick={() => this.changeState("Map")}
                      >
                        Map Derailers
                      </button>
                    </div>
                  </div>
                  {addMap === "Submit" || addMap === "Edit" ? (
                    <div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label className="formLabel" style={{ paddingLeft: "0px" }} htmlFor="indicators">Derailer</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <textarea
                            className="form-control inputss"
                            rows="3"
                            id="description"
                            name="derailer"
                            placeholder="Enter derailer"
                            onChange={this.handleUserInput}
                            value={derailer}
                          ></textarea>
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorDerailer} />
                        </div>
                      </div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label className="formLabel" style={{ paddingLeft: "0px" }} htmlFor="indicators">Description</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <textarea
                            className="form-control inputss"
                            rows="3"
                            id="description"
                            name="description"
                            placeholder="Enter description"
                            onChange={this.handleUserInput}
                            value={description}
                          ></textarea>
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorDescription} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Theme Type</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-1</div>2 text my-2 my-lg-0 my-md-0">
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <input type="radio" id="Behavioral" name="textType" value="Behavioral" checked={textType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                              <label>
                                &nbsp;&nbsp;&nbsp;Behavioral
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <input type="radio" id="Technical" name="textType" value="Technical" checked={textType === 'Technical' && true} onChange={this.handleRadioInput} />
                              &nbsp;&nbsp;&nbsp;
                              <label>
                                &nbsp;&nbsp;&nbsp;Technical
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorDescription} />
                        </div>
                      </div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Theme/Function</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <SingleSelect
                            handleChange={this.themeOptionselect}
                            options={themeOptions}
                            selectedService={themeOptionSelected}
                          />
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorthemeOptionSelected} />
                        </div>
                      </div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Designation</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <SingleSelect
                            handleChange={this.designationSelect}
                            options={designationOptions}
                            selectedService={designationSelected}
                          />
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errordesignationSelected} />
                        </div>
                      </div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Work Level</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <SingleSelect
                            handleChange={this.workLevelSelect}
                            options={workLevelOptions}
                            selectedService={workLevelSelected}
                          />
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorWorkLevelSelected} />
                        </div>
                      </div>
                      <div className="row justify-content-between align-items-start my-2">
                        <div className="col-lg-1 d-none d-lg-block" />
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <label style={{ paddingLeft: "0px" }} htmlFor="indicators">Derailers</label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text my-2 my-lg-0 my-md-0">
                          <SingleSelect
                            handleChange={this.derailerSelect}
                            options={derailerOptions}
                            selectedService={derailerSelected}
                          />
                        </div>
                        <div className="col-12 my-2">
                          <ValidationView errorname={errorDerailerSelected} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row justify-content-evenly align-items-center">
                    <div className="col-12 text-end px-3">
                      <button
                        type="button"
                        className="submit-btn "
                        onClick={this.onSubmit}
                      >
                        {addMap}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Derailers;
