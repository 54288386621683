import React, { Component } from "react";
import { FormMiddleWare, SingleSelect, MultiSelect } from "../../components/Form";
import { Style } from "react-style-tag";
import Swal from 'sweetalert2';
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import { FilePond } from 'react-filepond';
import { CSVLink } from "react-csv";



// import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import Datatable from "../../components/Datatable/Datatable";


const tableName = `tbl_trainingprogram`;

class HrTraingprogramAdd extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            trainingProgram: null,
            errorTrainingProgram: null,
            trainingprogramvalue: null,
            errorTrainingprogramvalue: null,
            alertVisible: false,
            updateData: false,
            showDatatable: true,
            multyprogramsAdd: true,
            statusArray: [],
            userAddRow: "",
            RowCount: "",
            showDatatable: true,
            submitDataAlert: false,
            leveOption: [{ label: "Score-1", value: 1 },
            { label: "Score-2", value: 2 },
            { label: "Score-3", value: 3 },
            { label: "Score-4", value: 4 },
            ],
            addUserColumn: [

                { label: "TrainingProgram", value: 1, type: "selectbox", selectOptions: [] },
                {
                    label: "Score", value: 2, type: "selectbox", selectOptions: [
                        { label: "Score-1", value: 1 },
                        { label: "Score-2", value: 2 },
                        { label: "Score-3", value: 3 },
                        { label: "Score-4", value: 4 },
                    ]
                },
            ],
            addUserColumn1: [

                { label: "TrainingPrograms", value: 1, type: "text" }
            ],
            vidhyaUser: localStorage.getItem("userDetailsVidhya"),
            importvidhya: false,
        }
    }
    column = [
        {
            Header: 'Si No',
            accessor: 'si no',
            Cell: d => this.siNo(d)
        },
        {
            Header: 'Training program name',
            accessor: 'trainingProgram'
        },
        {
            Header: 'Training program Value',
            accessor: 'trainingprogramvalue'
        },
        // {
        //   Header: 'Edit',
        //   accessor: 'edit',
        //   Cell: d => this.edit(d)
        // },
        // {
        //   Header: 'Delete',
        //   accessor: 'delete',
        //   Cell: d => this.delete(d, tableName)
        // }
    ];

    siNo = d => {
        return <label>{d.index + 1}</label>;
    };

    async componentWillMount() {
        try {
            const adminId = await localStorage.getItem("adminId");
            const customerId = await localStorage.getItem("userId");
            const yearId = await localStorage.getItem("yearId");
            const { data } = await MasterMiddleWare.getMaster(tableName, adminId);
            if (data) {
                this.setState({
                    data,
                    adminId,
                    customerId
                });
            }
            this.GetUserData1();
        } catch (error) {
            console.error(error);
        }
    }


    onSubmit = async () => {
        const { trainingProgram, trainingprogramvalue, adminId, customerId, yearId } = this.state;
        // const adminId = await localStorage.getItem("userId");
        if (this.validate(trainingProgram, 'errorTrainingProgram', 'Please enter training program')) return true;
        // if (this.validate(trainingprogramvalue, 'errorTrainingprogramvalue', 'Please select training program value')) return true;
        const formData = new FormData();
        formData.append('trainingProgram', trainingProgram);
        // formData.append('trainingprogramvalue', trainingprogramvalue);
        // formData.append('yearId', yearId);
        formData.append('adminId', adminId);
        formData.append('customerid', customerId);
        formData.append('status', 'active');
        // console.log([...formData], "formdata");
        const previousData = [...this.state.data];
        try {
            // console.log(tableName, "tableName");
            const result = await MasterMiddleWare.createMaster(tableName, formData);
            // console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    trainingProgram: '',
                    // trainingprogramvalueSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            this.setState({
                data: previousData
            });
            console.log(error);
        }
    };

    singlemultiselect = (e, val) => {
        if (val == "2") {
            this.setState({ showDatatable: false })
        }
        this.setState({
            multi: val
        });
    };

    addRow = (value) => {
        console.log(value, "values");
        let { userAddRow, statusArray } = this.state
        // this.setState({ statusArray: [] })
        let addIndex = value ? statusArray.length : 0

        const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
            TrainingPrograms: "",
            TrainingProgram: "",
            TrainingProgramSelect: "",
            TrainingProgramOption: this.state.ProgramNames,
            // Score: "",
            // ScoreSelect: "",
            // ScoreOption: [
            //     { label: "Score-1", value: 1 },
            //     { label: "Score-2", value: 2 },
            //     { label: "Score-3", value: 3 },
            //     { label: "Score-4", value: 4 },
            // ],
            index: value ? addIndex : index

        }));
        this.setState(prevState => ({
            statusArray: [...prevState.statusArray, ...newItems]
        }));

        console.log(statusArray, "statusArray");
    }

    handleInputChange = (e, rowIndex, columnLabel) => {
        const { value } = e.target;
        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel]: value } : row
            )
        }));
    };

    handleSelectChange = async (value, rowIndex, columnLabel) => {
        let { adminId } = this.state
        // console.log(value, "value");
        // console.log(rowIndex, "rowIndex");
        // console.log(columnLabel, "columnLabel");
        // return false
        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "TrainingProgram" || columnLabel === "TrainingPrograms" ? value.label : value.value } : row
            )
        }));
        // if (columnLabel === "TrainingProgram") {
        //     const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(value.label, adminId);

        //     // console.log(competencyOptions, "competencyOptions");
        //     this.setState(prevState => ({
        //         statusArray: prevState.statusArray.map((row, index) =>
        //             index === rowIndex ? { ...row, CompetencyOption: competencyOptions } : row
        //         )
        //     }));
        // }
    };

    addMultySubmit = async () => {
        let { statusArray, adminId, customerId } = this.state
        let statusArray1 = []
        // console.log(statusArray,"statusArray");
        statusArray.map((ival) => {
            let result = {}
            if (ival.TrainingPrograms != "") {
                result.TrainingPrograms = ival.TrainingPrograms;
                // result.Score = ival.Score;
            } else {
                result.TrainingProgram = ival.TrainingProgram;
                // result.Score = ival.Score;
                result.TrainingProgramSelect = ival.TrainingProgramSelect;
            }

            result.index = ival.index;
            statusArray1.push(result);
        })
        // console.log(statusArray1,"statusArray1");
        return new Promise((resolve, reject) => {
            this.setState({ submitDataAlert: true });
            setTimeout(() => {
                this.setState({ submitDataAlert: false });
                resolve();
            }, 3000);
        })
            .then(() => {
                const formData = new FormData();
                // console.log(statusArray,"statusArray");
                // return false
                formData.append("statusArray", JSON.stringify(statusArray1));
                // formData.append('groupcompId', groupcompId)
                formData.append('adminId', adminId);
                formData.append('customerid', customerId);
                const previousData = [...this.state.data];
                return MasterMiddleWare.insertMuldidata(tableName, formData);
            })
            .then(result => {
                if (result.data.Message == "success") {
                    if (result.data.fillDataErrorMsg) {
                        const maxLength = 29;
                        const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
                        console.log(wrappedErrorMsg, "wrappedErrorMsg");
                        Swal.fire({
                            position: 'center',
                            type: 'warning',
                            title: wrappedErrorMsg,
                            showConfirmButton: false,
                            timer: 6000
                        });
                        // Swal.fire({
                        //   position: 'center',
                        //   type: 'warning',
                        //   title: `${result.data.fillDataErrorMsg}`,
                        //   showConfirmButton: false,
                        //   timer: 2000

                        // });
                    } else {

                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Training Programs has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        this.getMaster(tableName);
                        this.setState({
                            alertVisible: true,
                            btnDisablecsv: false,
                            statusArray: "",
                            userAddRow: ""
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                    }

                }
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    render() {
        const { errorTrainingProgram, errorTrainingprogramvalue, updateData, trainingProgram, leveOption, trainingprogramvalueSelected,
            showDatatable, data, statusArray, addUserColumn, addUserColumn1, userAddRow, ProgramNames } = this.state;
        return (
            <React.Fragment>
                <section id="sec-hr-training" className="my-5">
                    <div className="mt-100 pt-3 container container-sm-fluid">
                        <div className="col-12">
                            <div className="row justify-content-center align-items-center my-5">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div className="content-border1">
                                        {/* <div className="box-header with-border">
                                            <h3 className="box-title">Training Program</h3>
                                            <div className="box-tools pull-right">
                                                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div> */}
                                        {/* <div className="wizardCards">
                                            <div>
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="trainingProgram">Traing program name</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <input type="text" className="form-control" name="trainingProgram" id="trainingProgram" placeholder="Please enter traing program name" value={trainingProgram} onChange={this.handleUserInput} />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-5">
                                                        <span className="errorMsg">{errorTrainingProgram}</span>
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="trainingprogramvalue">Traing program  Value</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <SingleSelect handleChange={this.levelSelect} options={leveOption} selectedService={trainingprogramvalueSelected} />

                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-5">
                                                        <span className="errorMsg">{errorTrainingprogramvalue}</span>
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group ">
                                                    <div className="col-sm-4" />
                                                    <div className="col-sm-4">
                                                        {!updateData ? (
                                                            <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                                                                Submit
                                                            </button>
                                                        ) : (
                                                            <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-4" />
                                                </div>
                                            </div>

                                        </div> */}
                                        <div className="row justify-content-evenly align-items-center px-lg-3 my-3 px-2">
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 my-2 my-lg-0">
                                                <div className="" onClick={(e) => this.singlemultiselect(e, 1)} >
                                                    <label className='multi formLabel' style={{ fontSize: '16px' }} htmlFor="competency">Add single training program</label>
                                                </div>

                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 my-2 my-lg-0">
                                                <div className="" onClick={(e) => this.singlemultiselect(e, 2)} >
                                                    <label className='multi formLabel' style={{ fontSize: '16px' }} htmlFor="competency"> Add multiple training programs</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.multi == 2 ?
                                                <>
                                                    {/* <div> */}

                                                    {/* <button className='btn btn-primary' onClick={() => this.setState({ multyprogramsAdd: true, showDatatable: true })}>Back</button> */}
                                                    {/* <br></br> */}
                                                    {/* {
                                                    this.state.importvidhya == false ?
                                                        <button className='btn btn-primary' onClick={() => this.setState({ importvidhya: true, userAddRow: "", statusArray: "" })}>Import from vidhya multidata add</button>
                                                        :
                                                        <button className='btn btn-primary' onClick={() => this.setState({ importvidhya: false, userAddRow: "", statusArray: "" })}>Without import from vidhya multidata add</button>

                                                }

                                            </div> */}
                                                    {/* { */}
                                                    {/* // this.state.importvidhya ? */}
                                                    {/* <>
                                                        <div className="row form-group ">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2">
                                                                <label htmlFor="indicators">How many training Programs add ?</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                                                            </div>
                                                            <div className="col-sm-3" >
                                                                <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                                                            </div>
                                                           
                                                        </div>


                                                        <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No </th>

                                                                        {addUserColumn.map((index) => (
                                                                            <th key={index}> {index.label}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (

                                                                        <tr>
                                                                            <td style={{ textAlign: "center" }} >{i + 1}</td>
                                                                            {addUserColumn.map((jval, index) => (
                                                                                <>
                                                                                    <td style={{ textAlign: "center" }}  >{jval && jval.type === "text" ? <>
                                                                                        <textarea
                                                                                            style={{ height: "50px", width: "280px" }}
                                                                                            value={ival[jval.label]}
                                                                                            onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                        /></> : jval.type === "selectbox" ?
                                                                                        <div style={{ width: "280px" }}>
                                                                                            <SingleSelect
                                                                                                handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                                                                options={ival[jval.label + 'Option']}
                                                                                                selectedService={ival[jval.label + "Select"]}
                                                                                            />

                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                        </>}
                                                                                    </td>
                                                                                </>
                                                                            ))}
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                            {this.state.submitDataAlert ?
                                                                <div className='loader1'></div>
                                                                :
                                                                <>
                                                                    {statusArray && statusArray.length > 0 &&

                                                                        <div className="row form-group ">
                                                                            <div className="col-sm-1" >
                                                                                <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                                                                            </div>
                                                                            <div className="col-sm-1">
                                                                                <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-1">
                                                                                <CSVLink
                                                                                    headers={this.state.excelHeadmultidata}
                                                                                    data={statusArray}
                                                                                    filename={"ProficiencyScale.xls"}
                                                                                    className="btn btncolor"
                                                                                    target="_blank"
                                                                                >
                                                                                    Generate Excel
                                                                                </CSVLink>
                                                                            </div>

                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        

                                                    </>

                                                    : */}
                                                    <>
                                                        <div className="row justify-content-evenly align-items-center px-lg-2 px-2 my-3">
                                                            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 my-lg-0">
                                                                <label htmlFor="indicators">How many training Programs add ?</label>
                                                            </div>
                                                            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 my-lg-0">
                                                                <input type="number" className="form-control inputss" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                                                            </div>

                                                            {/* {statusArray && statusArray.length ?
                                        <div className="row form-group ">
                                          <div className="col-sm-10" />
                                          <div className="col-sm-2">
                                            <CSVLink
                                              headers={this.state.excelHeadmultidata}
                                              data={statusArray}
                                              filename={"ProficiencyScale.xls"}
                                              className="btn btncolor"
                                              target="_blank"
                                            >
                                              Generate Excel
                                            </CSVLink>
                                          </div>
                                        </div>
                                        :
                                        <></>} */}
                                                        </div>

                                                        <div className="row justify-content-evenly align-items-center px-lg-0 px-2 my-3">
                                                            <div className="col-lg-3 d-none d-lg-block" />
                                                            <div className="col-lg-4 my-2 my-lg-0" >
                                                                <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                                                                {/* <button className='btn btn-info' onClick={()=>this.setState({statusArray:[]})}>Clear</button> */}
                                                            </div>
                                                        </div>
                                                        <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No </th>

                                                                        {addUserColumn1.map((index) => (
                                                                            <th key={index}> {index.label}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                                                                        // console.log(ival,"type",i,"i")

                                                                        <tr>
                                                                            <td style={{ textAlign: "center" }} >{i + 1}</td>
                                                                            {addUserColumn1.map((jval, index) => (
                                                                                <>
                                                                                    <td style={{ textAlign: "center" }}  >{jval && jval.type === "text" ? <>
                                                                                        <textarea
                                                                                        className="inputss"
                                                                                            style={{ height: "50px", width: "280px" }}
                                                                                            // type="text"
                                                                                            value={ival[jval.label]}
                                                                                            onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                        /></> : jval.type === "selectbox" ?
                                                                                        <div style={{ width: "280px" }}>
                                                                                            <SingleSelect
                                                                                                handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                                                                options={ival[jval.label + 'Option']}
                                                                                                selectedService={ival[jval.label + "Select"]}
                                                                                            // disabled={false}
                                                                                            />

                                                                                            {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                        </>}
                                                                                    </td>
                                                                                </>
                                                                            ))}
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                            {this.state.submitDataAlert ?
                                                                <div className='loader1'></div>
                                                                :
                                                                <>
                                                                    {statusArray && statusArray.length > 0 &&

                                                                        <div className="row justify-content-between px-3 px-lg-5 align-items-center">
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6" >
                                                                                <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                                                                                <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                            {/* <div className="col-sm-1">
                                                                                <CSVLink
                                                                                    headers={this.state.excelHeadmultidata}
                                                                                    data={statusArray}
                                                                                    filename={"ProficiencyScale.xls"}
                                                                                    className="btn btncolor"
                                                                                    target="_blank"
                                                                                >
                                                                                    Generate Excel
                                                                                </CSVLink>
                                                                            </div> */}

                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        {/* {statusArray && statusArray.length > 0 &&
                                      <div className="row form-group " style={{ marginTop: "20px" }}>
                                        <div className="col-sm-4" />
                                        <div className="col-sm-4">
                                          <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                            Submit
                                          </button>
    
                                        </div>
                                        <div className="col-sm-4" />
                                      </div>
                                    } */}

                                                    </>
                                                    {/* } */}




                                                </>
                                                :
                                                <div>
                                                    <div className="row justify-content-evenly align-items-center px-2 px-lg-3 my-3 text-color">
                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                            <label className="text-color formLabel" htmlFor="trainingProgram">Traing program</label>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                            <input type="text" className="form-control inputss" name="trainingProgram" id="trainingProgram" placeholder="Please enter traing program name" value={trainingProgram} onChange={this.handleUserInput} />
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-evenly align-items-center  ">
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-2 ">
                                                            <span className="errorMsg">{errorTrainingProgram}</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row form-group ">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="trainingprogramvalue">Traing program  Value</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect handleChange={this.levelSelect} options={leveOption} selectedService={trainingprogramvalueSelected} />

                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group ">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2" />
                                                <div className="col-sm-5">
                                                    <span className="errorMsg">{errorTrainingprogramvalue}</span>
                                                </div>
                                                <div className="col-sm-3" />
                                            </div> */}
                                                    <div className="row justify-content-evenly align-items-center">
                                                        <div className="col-lg-4 d-none d-lg-none"/>
                                                        <div className="col-lg-4 col-md-6 text-end col-sm-12 col-12">
                                                            {!updateData ? (
                                                                <button type="button" className="submit-btn" onClick={this.onSubmit}>
                                                                    Submit
                                                                </button>
                                                            ) : (
                                                                <button type="button" className="submit-btn" onClick={this.onUpdate}>
                                                                    Update
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Style>
                    {`/*
      Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
      Template Version: 1.0
      Author: Carlos Alvarez
      Website: http://blacktie.co
      Premium: http://www.gridgum.com
      */
      
      /* Import fonts */
      @import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);
      
      /* BASIC THEME CONFIGURATION */
      .filepond{
        height: 82px;
        border-radius: 20px;
        width: 300px;
      }
      
      
      .filepond--credits{
        color:#f7f7f7;
      }
      
      .filepond--root :not(text) {
        font-size: 13px;
      }
      
      .multi:hover{
        color: limegreen;
      }
      #main-content {
        margin-left: 50px !important;
        margin-top: 50px
    }
    .wrapper{
        margin-top:0px!important;
      }
     
    @media only screen and (max-width: 600px) {
      
      }
      
      `}

                </Style >
            </React.Fragment>
        );

    }
}

export default HrTraingprogramAdd;