import React, { Component, isValidElement } from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
//import { PDFDownloadLink } from "@react-pdf/renderer";
//import ManageIDP_pdf from "../PDF/manageIDP_pdf";
import LoginModal from "../../components/Modal/Modal";
import { MDBBtn, MDBCollapse } from "mdbreact";
import Swal from "sweetalert2";
//import { FaTrash, FaPen} from 'react-icons/fa';
import Idpmodel from "../User/Idpmodel"
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import IDPPDF from "../PDF/IDPConsolidatePDF";

export default class Addidppage extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      ManageEditIdp: false,
      PDf_Data: [],
      collapseID: "",
      Editbody: {},
      skillstrue: false,
      skillstrue1: false,
      skillstrue2: false,
      SkillsData: [{ name: null }],
      SkillsData1: [{ name: null }],
      SkillsData2: [{ name: null }],
      ShortContentData: [],
      MidContentData: [],
      LongContentData: [],
      behTech: [
        { label: "Behavioural", value: 1 },
        { label: "Technical", value: 2 }
      ],
      behTechSelected: {},
      errorbehTechSelected: "",
      financialYearSelected: {},
      errorfinancialYearSelected: "",
      ManageIDP: [],
      alertVisible: false,
      pdfyearId: localStorage.getItem("yearId"),
      groupcompId: localStorage.getItem("groupcompId"),



    }
  }

  behTechSelect = async e => {
    this.setState({ behTechSelected: e });
    await this.manageIDP(e.label, this.state.financialYearSelected.value);
    if (this.props.idpYear) {
      this.financialYearSelect(this.props.idpYear)
    }
  }

  financialYearSelect = async e => {
    const { userId } = this.state;
    this.setState({ financialYearSelected: e, idpYear: e });
    let IDP_Data = await HrMiddleWare.getRandomData(
      "tbl_idp_goalterm",
      `userid = ${userId} and yearid = ${e.value} and tech_type ='${this.state.behTechSelected.label}'`,
      "*"
    );
    // console.log(IDP_Data);
    if (IDP_Data) {
      let ShortContentData = [];
      let MidContentData = [];
      let LongContentData = [];
      IDP_Data.data.map((ival, i) => {
        if (ival.termtype == "short") {
          ival.collapse = false;
          ival.startdate = moment(ival.startdate).format("YYYY-MM-DD");
          ival.enddate = moment(ival.enddate).format("YYYY-MM-DD");
          ival.actual_end_date = ival.actual_end_date ? moment(ival.actual_end_date).format("YYYY-MM-DD") : "-";
          ShortContentData.push(ival);
        } else if (ival.termtype == "mid") {
          ival.collapse = false;
          ival.startdate = moment(ival.startdate).format("YYYY-MM-DD");
          ival.enddate = moment(ival.enddate).format("YYYY-MM-DD");
          ival.actual_end_date = ival.actual_end_date ? moment(ival.actual_end_date).format("YYYY-MM-DD") : "-";
          MidContentData.push(ival);
        } else if (ival.termtype == "long") {
          ival.collapse = false;
          ival.startdate = moment(ival.startdate).format("YYYY-MM-DD");
          ival.enddate = moment(ival.enddate).format("YYYY-MM-DD");
          ival.actual_end_date = ival.actual_end_date ? moment(ival.actual_end_date).format("YYYY-MM-DD") : "-";
          LongContentData.push(ival);
        }
      })
      this.setState({ ShortContentData, MidContentData, LongContentData })
      await this.manageIDP(this.state.behTechSelected.label, e.value);
    }

  }

  manageIDP = async (a = null, b = null) => {
    const { userId, behTechSelected, financialYearSelect } = this.state;

    if (a != null && b != null) {

      let ManageIDP = await HrMiddleWare.getRandomData(
        "tbl_idp_goals",
        `userid = ${userId} and yearId = ${b} and type ='${a}'`,
        '*'
      )
      console.log(ManageIDP);
      let SkillsData = [];
      let SkillsData1 = [];
      let SkillsData2 = [];
      if (ManageIDP.data.length && ManageIDP != undefined) {
        let result = ManageIDP.data[0];
        this.setState({ ManageIDP: ManageIDP.data, PDf_Data: ManageIDP.data });
        console.log(this.state.ManageIDP)
        SkillsData = JSON.parse(result.Skills);
        SkillsData1 = JSON.parse(result.Skills_2);
        SkillsData2 = JSON.parse(result.resources_required);
        this.setState({ SkillsData, SkillsData2, SkillsData1, ManageEditIdp: true, showdownload: true });
      } else {
        this.setState({ ManageIDP: [{ data: null }], PDf_Data: [], showdownload: true });
      }



    }

  }

  async componentWillMount() {

    // const pdfhtml = IDPPDF.render()
    // console.log(pdfhtml, "pdfhtml");

    // this.getUserDetails();
    // this.getChartData();
    let userId = await localStorage.getItem("userId");
    this.setState({ userId })

    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }
    if (this.props.idpYear) {
      await this.setState({ financialYearSelected: this.props.idpYear })
    }
    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    let pdfIdp = await HrMiddleWare.getRandomData(
      "tbl_idp_goalterm",
      `userid=${userData.data[0].id} and yearid=${this.state.pdfyearId} `,

      "*"
    );
    this.setState({ pdfIdp: pdfIdp.data });
    let grcom = await HrMiddleWare.getRandomData(`tbl_gcompanies`, `tbl_gcompanies.id=${this.state.groupcompId}`, "*");
    console.log(grcom, "grcom", grcom.data, "grcomdata");
    if (grcom) {
      this.setState({ groupcompany_details: grcom.data });
    }
    //  }
  }



  onSubmit = async () => {
    const { behTechSelected, financialYearSelected,
      ShortContentData, LongContentData, MidContentData,
      SkillsData, SkillsData1, SkillsData2
    } = this.state;
    if (!behTechSelected.value) {
      this.setState({ errorbehTechSelected: "Select the IDP type" });
      return false
    } else if (!financialYearSelected.value) {
      this.setState({ errorfinancialYearSelected: "Select the Year" });
      return false
    }
    this.setState({
      errorbehTechSelected: "",
      errorfinancialYearSelected: ""
    });
    let userId = await localStorage.getItem("userId");
    let adminId = await localStorage.getItem("adminId");
    let short_term = []

    if (ShortContentData.length) {
      ShortContentData.map((ival, i) => {
        short_term.push(ival.id)
      })
    }

    let mid_term = [];
    if (MidContentData.length) {
      MidContentData.map((ival, i) => {
        mid_term.push(ival.id)
      })
    }

    let long_term = [];
    if (LongContentData.length) {
      LongContentData.map((ival, i) => {
        long_term.push(ival.id)
      })
    }


    let formData = new FormData();
    formData.append("userid", userId);
    formData.append("short_term", short_term.length ? JSON.stringify(short_term) : null);
    formData.append("mid_term", mid_term.length ? JSON.stringify(mid_term) : null);
    formData.append("long_term", long_term.length ? JSON.stringify(long_term) : null);
    formData.append("yearId", financialYearSelected.value);
    formData.append("type", behTechSelected.label);
    formData.append("Skills", JSON.stringify(SkillsData));
    formData.append("Skills_2", JSON.stringify(SkillsData1));
    formData.append("resources_required", JSON.stringify(SkillsData2));
    formData.append("companyid", adminId);
    console.log([...formData], "formData")
    console.log(this.state.ManageEditIdp, "this.state.ManageEditIdp");
    // return false
    if (this.state.ManageEditIdp) {
      console.log(this.state.ManageIDP)
      const result = await MasterMiddleWare.updateMaster("tbl_idp_goals", this.state.ManageIDP[0].id, formData);
      console.log(result, "result1");
      if (result) {
        console.log(result);
      }
      try {
        if (result) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'IDP has been saved',
            showConfirmButton: false,
            timer: 1500
          });
          this.setState({
            alertVisible: true,
            short_term: "",
            mid_term: '',
            long_term: '',
            SkillsData: '',
            SkillsData1: '',
            SkillsData2: '',
            financialYearSelected: '',
            behTechSelected: ''
          });
        }
      } catch (error) {

        console.log(error);
      }
    } else {
      let result = await MasterMiddleWare.createMaster("tbl_idp_goals", formData);
      console.log(result, "result2");
      try {
        if (result) {
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'IDP has been saved',
            showConfirmButton: false,
            timer: 1500
          });
          this.setState({
            alertVisible: true,
            short_term: "",
            mid_term: '',
            long_term: '',
            SkillsData: '',
            SkillsData1: '',
            SkillsData2: '',
            financialYearSelected: '',
            behTechSelected: ''
          });
        }
      } catch (error) {
        console.log(error);
      }
      if (result) {
        console.log(result);
      }
    }
  }

  toggleCollapse = async (collapseID, i) => {
    const { ShortContentData } = this.state;
    if (ShortContentData[i].collapse == false) {
      ShortContentData[i].collapse = true;
    } else {
      ShortContentData[i].collapse = false;
    }
    console.log(ShortContentData);
    this.setState(prevState => ({
      //collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      ShortContentData
    }));
  }

  toggleCollapse2 = async (collapseID, i) => {
    const { LongContentData } = this.state;
    if (LongContentData[i].collapse == false) {
      LongContentData[i].collapse = true;
    } else {
      LongContentData[i].collapse = false;
    }
    //console.log(ShortContentData);
    this.setState(prevState => ({
      //collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      LongContentData
    }));
  }

  toggleCollapse1 = async (collapseID, i) => {
    const { MidContentData } = this.state;
    if (MidContentData[i].collapse == false) {
      MidContentData[i].collapse = true;
    } else {
      MidContentData[i].collapse = false;
    }
    //console.log(ShortContentData);
    this.setState(prevState => ({
      //collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      MidContentData
    }));
  }

  ChangeStateData = async (s, v) => {
    const { ShortContentData, MidContentData, LongContentData } = this.state;
    //   console.log(s,v);
    if (s == "short") {
      let PreviousData = [v, ...ShortContentData];
      // console.log(PreviousData)
      this.setState({ ShortContentData: PreviousData })
    } else if (s == "mid") {
      let PreviousData = [v, ...MidContentData];
      // console.log(PreviousData)
      this.setState({ MidContentData: PreviousData })
    } else if (s == "long") {
      let PreviousData = [v, ...LongContentData];
      //console.log(PreviousData)
      this.setState({ LongContentData: PreviousData })
    }
  }

  handleSkills = async (e, i) => {
    const { SkillsData } = this.state
    SkillsData[i].name = e.target.value;
    this.setState({ SkillsData })
  }
  handleSkills1 = async (e, i) => {
    const { SkillsData1 } = this.state
    SkillsData1[i].name = e.target.value;
    this.setState({ SkillsData1 })
  }
  handleSkills2 = async (e, i) => {
    const { SkillsData2 } = this.state
    SkillsData2[i].name = e.target.value;
    this.setState({ SkillsData2 })
  }

  AddmoreSkill = async () => {
    const { SkillsData } = this.state;
    SkillsData.push({ name: null });
    this.setState({ SkillsData })
  }

  AddmoreSkill1 = async () => {
    const { SkillsData1 } = this.state;
    SkillsData1.push({ name: null });
    this.setState({ SkillsData1 })
  }

  AddmoreSkill2 = async () => {
    const { SkillsData2 } = this.state;
    SkillsData2.push({ name: null });
    this.setState({ SkillsData2 })
  }


  SkillsandKnowlege = async () => {
    this.setState({ skillstrue: !this.state.skillstrue });
  }
  SkillsandKnowlege1 = async () => {
    this.setState({ skillstrue1: !this.state.skillstrue1 });
  }
  SkillsandKnowlege2 = async () => {
    this.setState({ skillstrue2: !this.state.skillstrue2 });
  }

  EditTerm = async (a) => {
    this.setState({ Editbody: a })
  }

  AddNewTerm = async () => {
    this.setState({ Editbody: {} });
  }

  edited_state_Change = async (s, v) => {
    const { ShortContentData, MidContentData, LongContentData } = this.state;
    console.log(s, v);
    if (s == "short") {
      //let data = 
      ShortContentData.map((ival, i) => {
        if (ival.id == v.id) {
          ShortContentData[i] = v;
        }
      });
      //console.log(ShortContentData);
      this.setState({ ShortContentData })
    } else if (s == "mid") {
      MidContentData.map((ival, i) => {
        if (ival.id == v.id) {
          MidContentData[i] = v;
        }
      });
      //console.log(ShortContentData);
      this.setState({ MidContentData });
    } else {
      LongContentData.map((ival, i) => {
        if (ival.id == v.id) {
          LongContentData[i] = v;
        }
      });
      //console.log(ShortContentData);
      this.setState({ LongContentData });
    }
  }

  buttonDelete = async (d) => {
    const { ShortContentData, MidContentData, LongContentData } = this.state;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        let termtype1 = d.termtype;
        const result = await MasterMiddleWare.DeleteMasterFromTable("tbl_idp_goalterm", d.id);

        if (result) {
          console.log(result)
          if (termtype1 == "short") {
            let short = ShortContentData.filter(item => item.id != d.id);
            this.setState({ ShortContentData: short });
          } else if (termtype1 == "mid") {
            let mid = MidContentData.filter(item => item.id != d.id);
            this.setState({ ShortContentData: mid });
          } else if (termtype1 == "long") {
            let long = LongContentData.filter(item => item.id != d.id);
            this.setState({ LongContentData: long });
          }
          Swal.fire(
            'Deleted!',
            'Your goal has been deleted.',
            'success'
          );

        }
      }
    })
  }

  HistoryPage = async () => {
    let userId = await localStorage.getItem("userId");
    window.open(`/User/ViewHistory/${userId}`, '_blank');

  }

  render() {
    const { behTech,
      behTechSelected,
      yearList,
      financialYearSelected,
      pdfIdp,
      SkillsData, SkillsData2, SkillsData1,
      ShortContentData, MidContentData, LongContentData,
      groupcompany_details,
      showdownload
    } = this.state;
    // import IDPPDF from "../PDF/IDPConsolidatePDF";


    console.log(this.state.idpYear, "this.state.idpYear");
    console.log(showdownload, "showdownload");


    var report = false;
    if (this.props.report) {
      report = true
    }
    const height=window.innerHeight
    console.log(height,"height");
    return (
      <React.Fragment>
        <section className="my-5"  style={{height:height}} >
          <div className={report ? "" : "mt-100"}>
            <div className={report ? "container-fluid" : "container container-sm-fluid"}>
              <div className="row  content-border1  my-4 justify-content-center align-items-center">
                <div className={report ? "col-12" : "col-lg-12 col-md-12 col-12"}>
                  <div className={report ? "text" : "text py-lg-5 px-2 pxlg-5 px-md-3"}>
                    <div className="header-text text">
                      {report ? "" : (<h2>Manage IDP</h2>)}
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-1 d-none d-lg-block" />
                      <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                        <h5 className="formLabel">IDP type</h5>
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <SingleSelect
                          handleChange={this.behTechSelect}
                          options={behTech}
                          menuPortalTarget={document.body}
                          menuShouldScrollIntoView={false}
                          selectedService={behTechSelected}
                        />
                      </div>
                      <div className="col-lg-1 d-none d-lg-block" />
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-1 d-none d-lg-block" />
                      <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        {report ? true : (<button
                          type="button"
                          className="btncolor"
                          onClick={() => this.HistoryPage()}
                        >
                          View History
                        </button>)}
                      </div>
                      <div className="col-lg-1 d-none d-lg-block" />
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-1 d-none d-lg-block" />
                      <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <span style={{ color: "red" }}>{this.state.errorbehTechSelected}</span>
                      </div>
                      <div className="col-lg-1 d-none d-lg-block" />
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-1 d-none d-lg-block" />
                      <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                        <h5 className="formLabel text">Select Year</h5>
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <SingleSelect
                          menuPortalTarget={document.body}
                          menuShouldScrollIntoView={false}
                          handleChange={this.financialYearSelect}
                          options={yearList}
                          selectedService={financialYearSelected}
                        />
                      </div>
                      <div className="col-lg-1 d-none d-lg-block" />
                    </div>
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-1 d-none d-lg-block" />
                      <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <span style={{ color: "red" }}>{this.state.errorfinancialYearSelected}</span>
                      </div>
                      <div className="col-lg-1 d-none d-lg-block" />
                    </div>
                    <div className="col-12 text-end">
                      {(this.state.idpYear) && showdownload &&
                        (
                          <PDFDownloadLink
                            style={{ color: "white" }}
                            document={
                              <IDPPDF
                                data={pdfIdp}
                                short={ShortContentData}
                                mid={MidContentData}
                                long={LongContentData}
                                section1={SkillsData}
                                section2={SkillsData1}
                                section3={SkillsData2}
                                group={groupcompany_details}

                              />
                            }
                            fileName="IDP.pdf"
                          >
                            {/* {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            } */}
                            {/* <button type="button" className="submit-btn">
                              Download IDP PDF
                            </button> */}
                          </PDFDownloadLink>
                        )}
                    </div>
                    {financialYearSelected.value && behTechSelected.label && this.state.ManageIDP.length ?
                      <React.Fragment>
                        <ul className="nav nav-tabs">
                          <li className=" nav-item active col-lg-4 col-md-4 col-12">
                            <a
                              class=" nav-link active "
                              data-toggle="tab"
                              href="#techTheme"
                            >
                              Short-term goals
                            </a>
                          </li>
                          <li className="nav-item col-lg-4 col-md-4 col-12">
                            <a
                              class=" nav-link"
                              data-toggle="tab"
                              href="#behTheme"
                            >
                              Mid-term goals
                            </a>
                          </li>
                          <li className="nav-item col-lg-4 col-md-4 col-12">
                            <a
                              class=" nav-link"
                              data-toggle="tab"
                              href="#techComp"
                            >
                              Long-term goals
                            </a>
                          </li>

                        </ul>
                        <div className="tab-content">
                          <div id="techTheme" className="tab-pane fade in active show py-3">
                            <div className="row align-items-center">
                              <div className="">
                                <h3>Short-term goals</h3>
                              </div>
                              <div>
                                {report ? true : (<LoginModal
                                  buttonTitle="Add More Goal"
                                  title="Add Short-term Goal"
                                  id="Comments"
                                  extraClass=" btncolor"
                                  onClick={this.AddNewTerm}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  maxwidth="modal-lg"
                                  bodyText={<Idpmodel termType={"short"}
                                    ManageIDP={this.state.ManageIDP}
                                    Editbody={this.state.Editbody}
                                    tech_type={this.state.behTechSelected.label}
                                    yearid={this.state.financialYearSelected.value}
                                    these={this.ChangeStateData}
                                    these1={this.edited_state_Change}
                                  />}

                                />)}
                                {this.state.ShortContentData.length ?
                                  this.state.ShortContentData.map((ival, i) => {
                                    return (
                                      <div>
                                        <div className="row form-group"
                                          style={{ backgroundColor: "white", width: "98%", marginLeft: "12px" }}>
                                          <div className="col-sm-8" onClick={() => this.toggleCollapse("basicCollapse", i)} style={{ marginTop: "2%", cursor: "pointer", color: "black" }}><h4>
                                            {ival.goalname}
                                          </h4></div>
                                          <div className="col-sm-4"><h3 onClick={() => this.toggleCollapse("basicCollapse", i)} style={{ marginTop: "5%", textAlign: "right", cursor: "pointer", color: "black" }}>
                                            {ival.collapse ? "-" : "+"}
                                          </h3></div>
                                        </div>
                                        <MDBCollapse id="basicCollapse" isOpen={ival.collapse}>
                                          <div className="row justify-content-between align-items-center mt-5">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Goal</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.goalname}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block "></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Criteria</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.criteria}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Support Required</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.supportrequired}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Start-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.startdate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.enddate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Actual End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.actual_end_date}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Percentage</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.percentage ? ival.percentage : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Status</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.status ? ival.status : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Reason deviation</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.reason_deviation ? ival.reason_deviation : "-"}</p>
                                            </div>
                                          </div>
                                          {report ? true : (
                                            <div className="row justify-content-between align-items-center">
                                              <div className="col-lg-1 d-none d-lg-block"></div>
                                              <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  className="warning-btn "
                                                  data-toggle="modal" data-target={"#Comments"} onClick={() => this.EditTerm(ival)}
                                                >Edit</button>
                                              </div>
                                              <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  style={{ borderRadius: "8px" }}
                                                  className="delete-btn fs-15 px-3 py-1"
                                                  onClick={() => this.buttonDelete(ival)}
                                                >Delete</button>
                                              </div>
                                            </div>
                                          )}
                                        </MDBCollapse>
                                      </div>
                                    )
                                  })
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div id="behTheme" className="tab-pane fade in py-3">
                            <div className="row align-items-center">
                              <div className="">
                                <h3>Mid-term goals</h3>
                              </div>
                              <div>
                                {report ? true : (<LoginModal
                                  buttonTitle="Add More Goal"
                                  title="Add Mid-term Goal"
                                  id="mid"
                                  extraClass=" btncolor"
                                  onClick={this.AddNewTerm}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  maxwidth="modal-lg"
                                  bodyText={<Idpmodel termType={"mid"}
                                    ManageIDP={this.state.ManageIDP}
                                    Editbody={this.state.Editbody}
                                    tech_type={this.state.behTechSelected.label}
                                    yearid={this.state.financialYearSelected.value}
                                    these={this.ChangeStateData}
                                    these1={this.edited_state_Change}
                                  />}
                                />)}
                                {this.state.MidContentData.length ?
                                  this.state.MidContentData.map((ival, i) => {
                                    return (
                                      <div>
                                        <div className="row form-group"
                                          style={{ backgroundColor: "white", width: "98%", marginLeft: "12px" }}>
                                          <div className="col-sm-8" onClick={() => this.toggleCollapse1("basicCollapse", i)} style={{ marginTop: "2%", cursor: "pointer", color: "black" }}><h4>
                                            {ival.goalname}
                                          </h4></div>
                                          <div className="col-sm-4"><h3 onClick={() => this.toggleCollapse1("basicCollapse", i)} style={{ marginTop: "5%", textAlign: "right", cursor: "pointer", color: "black" }}>
                                            {ival.collapse ? "-" : "+"}
                                          </h3></div>
                                        </div>
                                        <MDBCollapse id="basicCollapse" isOpen={ival.collapse}>
                                          <div className="row justify-content-between align-items-center mt-5">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Goal</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.goalname}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block "></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Criteria</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.criteria}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Support Required</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.supportrequired}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Start-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.startdate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.enddate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Actual End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.actual_end_date}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Percentage</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.percentage ? ival.percentage : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Status</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.status ? ival.status : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Reason deviation</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.reason_deviation ? ival.reason_deviation : "-"}</p>
                                            </div>
                                          </div>
                                          {report ? true : (
                                            <div className="row justify-content-between align-items-center">
                                              <div className="col-lg-1 d-none d-lg-block"></div>
                                              <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  className="warning-btn "
                                                  data-toggle="modal" data-target={"#mid"} onClick={() => this.EditTerm(ival)}
                                                >Edit</button>
                                              </div>
                                              <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  className="danger-btn px-3 py-2"
                                                  onClick={() => this.buttonDelete(ival)}
                                                >Delete</button>
                                              </div>
                                            </div>
                                          )}
                                        </MDBCollapse>
                                      </div>
                                    )
                                  })
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div id="techComp" className="tab-pane fade in py-3">
                            <div className="row align-items-center">
                              <div className="">
                                <h3>Long-term goals</h3>
                              </div>
                              <div>
                                {report ? true : (<LoginModal
                                  buttonTitle="Add More Goal"
                                  title="Add Long-term Goal"
                                  id="long"
                                  extraClass=" btncolor"
                                  onClick={this.AddNewTerm}
                                  extraStyle={{ backgroundColor: "#56528F" }}
                                  maxwidth="modal-lg"
                                  bodyText={<Idpmodel termType={"long"}
                                    ManageIDP={this.state.ManageIDP}
                                    Editbody={this.state.Editbody}
                                    tech_type={this.state.behTechSelected.label}
                                    yearid={this.state.financialYearSelected.value}
                                    these={this.ChangeStateData}
                                    these1={this.edited_state_Change}
                                  />}

                                />)}
                                {this.state.LongContentData.length ?
                                  this.state.LongContentData.map((ival, i) => {
                                    return (
                                      <div>
                                        <div className="row form-group"
                                          style={{ backgroundColor: "white", width: "98%", marginLeft: "12px" }}>
                                          <div className="col-sm-8" onClick={() => this.toggleCollapse2("basicCollapse", i)} style={{ marginTop: "2%", cursor: "pointer", color: "black" }}><h4>
                                            {ival.goalname}
                                          </h4></div>
                                          <div className="col-sm-4"><h3 onClick={() => this.toggleCollapse2("basicCollapse", i)} style={{ marginTop: "5%", textAlign: "right", cursor: "pointer", color: "black" }}>
                                            {ival.collapse ? "-" : "+"}
                                          </h3></div>
                                        </div>
                                        <MDBCollapse id="basicCollapse" isOpen={ival.collapse}>
                                          <div className="row justify-content-between align-items-center mt-5">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Goal</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.goalname}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block "></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Criteria</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.criteria}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Support Required</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.supportrequired}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Start-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.startdate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.enddate}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Actual End-Date</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p>{ival.actual_end_date}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Percentage</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.percentage ? ival.percentage : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Status</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.status ? ival.status : "-"}</p>
                                            </div>
                                          </div>
                                          <div className="row justify-content-between align-items-center">
                                            <div className="col-lg-1 d-none d-lg-block"></div>
                                            <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                              <h5 className="idpgoal">Reason deviation</h5>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                              <p> {ival.reason_deviation ? ival.reason_deviation : "-"}</p>
                                            </div>
                                          </div>
                                          {report ? true : (
                                            <div className="row justify-content-between align-items-center">
                                              <div className="col-lg-1 d-none d-lg-block"></div>
                                              <div className="col-lg-3 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  className="warning-btn "
                                                  data-toggle="modal" data-target={"#long"} onClick={() => this.EditTerm(ival)}
                                                >Edit</button>
                                              </div>
                                              <div className="col-lg-5 col-md-6 col-12 col-sm-6 goal">
                                                <button
                                                  type="button"
                                                  className="danger-btn px-3 py-2 fs-15"
                                                  onClick={() => this.buttonDelete(ival)}
                                                >Delete</button>
                                              </div>
                                            </div>
                                          )}
                                        </MDBCollapse>
                                      </div>
                                    )
                                  })
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                      : null}
                  </div>
                  <div className="row form-group ">
                    <div className="col-sm-7 mx-3">
                      <h4 style={{ marginTop: "4%" }}>
                        Skills and knowledge to be acquired :
                      </h4>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  <div
                    className="row form-group"
                    style={{
                      backgroundColor: "lightgray",
                      width: "98%",
                      marginLeft: "12px",
                    }}
                  >
                    <div
                      className="col-sm-8"
                      onClick={() => this.SkillsandKnowlege("basicCollapse")}
                      style={{ marginTop: "2%", cursor: "pointer", color: "#000" }}
                    >
                      <h4>
                        Add Skills and knowledge to be acquired. What specifically do I
                        need to learn? :
                      </h4>
                    </div>
                    <div className="col-sm-4">
                      <h3
                        onClick={() => this.SkillsandKnowlege("basicCollapse")}
                        style={{
                          marginTop: "5%",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                      >
                        {this.state.skillstrue ? "-" : "+"}
                      </h3>
                    </div>
                  </div>
                  <MDBCollapse id="basicCollapse" isOpen={this.state.skillstrue}>
                    {this.state.SkillsData.length ? (
                      <React.Fragment>
                        {this.state.SkillsData.map((ival, i) => {
                          return (
                            <React.Fragment>
                              <div className="row align-items-center form-group px-lg-5 px-2">
                                <h4 className="col-lg-4 col-md-6 col-12 my-2">Skills-{i + 1}</h4>
                                <div className="col-sm-5">
                                  <input
                                    className="form-control"
                                    rows="3"
                                    id="goal"
                                    name="skills"
                                    placeholder="Enter the skill"
                                    onChange={(e) => this.handleSkills(e, i)}
                                    value={ival.name}
                                  />
                                </div>
                                {report ? (
                                  true
                                ) : (
                                  <div className="col-sm-3">
                                    {i == 0 ? (
                                      <button
                                        type="button"
                                        className="submit-btn"
                                        onClick={this.AddmoreSkill}
                                      >
                                        Add more skills{" "}
                                      </button>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : null}
                  </MDBCollapse>
                  <div className="row form-group ">
                    <div className="col-sm-7 mx-3">
                      <h4 style={{ marginTop: "4%" }}>
                        Next Steps - How am I going to acquire the skills and knowledge?
                        :
                      </h4>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  <div
                    className="row form-group"
                    style={{
                      backgroundColor: "lightgray",
                      width: "98%",
                      marginLeft: "12px",
                      color: "#000"
                    }}
                  >
                    <div
                      className="col-sm-8"
                      onClick={() => this.SkillsandKnowlege1("basicCollapse")}
                      style={{ marginTop: "2%", cursor: "pointer" }}
                    >
                      <h4>
                        Next Steps - How am I going to acquire the skills and knowledge?
                        :
                      </h4>
                    </div>
                    <div className="col-sm-4">
                      <h3
                        onClick={() => this.SkillsandKnowlege1("basicCollapse")}
                        style={{
                          marginTop: "5%",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                      >
                        {this.state.skillstrue1 ? "-" : "+"}
                      </h3>
                    </div>
                  </div>
                  <MDBCollapse id="basicCollapse" isOpen={this.state.skillstrue1}>
                    {this.state.SkillsData1.length ? (
                      <React.Fragment>
                        {this.state.SkillsData1.map((ival, i) => {
                          return (
                            <React.Fragment>
                              <div className="row align-items-center form-group px-lg-5 px-2 ">
                                <h4 className="col-lg-4 col-md-6 col-12">Details-{i + 1}</h4>
                                <div className="col-lg-5 col-md-6 col-12">
                                  <input
                                    className="form-control"
                                    rows="3"
                                    id="goal"
                                    name="skills"
                                    placeholder="Enter the Details"
                                    onChange={(e) => this.handleSkills1(e, i)}
                                    value={ival.name}
                                  />
                                </div>
                                {report ? (
                                  true
                                ) : (
                                  <div className="col-lg-3 col-md-12 col-12">
                                    {i == 0 ? (
                                      <button
                                        type="button"
                                        className="submit-btn"
                                        onClick={this.AddmoreSkill1}
                                      >
                                        Add more Details{" "}
                                      </button>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : null}
                  </MDBCollapse>

                  <div className="row form-group ">
                    <div className="col-sm-7 mx-3">
                      <h4 style={{ marginTop: "4%" }}>What resources do I need? :</h4>
                    </div>
                    <div className="col-sm-2" />
                  </div>

                  <div
                    className="row form-group"
                    style={{
                      backgroundColor: "lightgray",
                      width: "98%",
                      marginLeft: "12px",
                    }}
                  >
                    <div
                      className="col-sm-7"
                      onClick={() => this.SkillsandKnowlege2("basicCollapse")}
                      style={{ marginTop: "2%", cursor: "pointer", color: "#000" }}
                    >
                      <h4>What resources do I need? :</h4>
                    </div>
                    <div className="col-sm-5">
                      <h3
                        onClick={() => this.SkillsandKnowlege2("basicCollapse")}
                        style={{
                          marginTop: "5%",
                          textAlign: "right",
                          cursor: "pointer",
                        }}
                      >
                        {this.state.skillstrue2 ? "-" : "+"}
                      </h3>
                    </div>
                  </div>
                  <MDBCollapse id="basicCollapse" isOpen={this.state.skillstrue2}>
                    {this.state.SkillsData2.length ? (
                      <React.Fragment>
                        {this.state.SkillsData2.map((ival, i) => {
                          return (
                            <React.Fragment>
                              <div className="row align-items-center form-group px-lg-5 px-2">
                                <h4 className="col-lg-4 col-md-6 col-12 my-2">Resources-{i + 1}</h4>
                                <div className="col-lg-5 col-md-6 col-12">
                                  <input
                                    className="form-control"
                                    rows="3"
                                    id="goal"
                                    name="skills"
                                    placeholder="Enter the resources"
                                    onChange={(e) => this.handleSkills2(e, i)}
                                    value={ival.name}
                                  />
                                </div>
                                {report ? (
                                  true
                                ) : (
                                  <div className="col-lg-3 col-md-12 col-12">
                                    {i == 0 ? (
                                      <button
                                        type="button"
                                        className="submit-btn"
                                        onClick={this.AddmoreSkill2}
                                      >
                                        Add resources{" "}
                                      </button>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}

                      </React.Fragment>
                    ) : null}
                  </MDBCollapse>

                  {report ? (
                    true
                  ) : (
                    <div className="row form-group ">
                      <div className="col-sm-5" />
                      <div className="col-sm-4">
                        <button
                          type="button"
                          className="submit-btn mb-2"
                          onClick={this.onSubmit}
                        >
                          Save IDP{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>)
  }
}