import React from 'react';

const ValidationView = ({errorname}) => {
  return (
    <React.Fragment>
      <div className="row form-group ">
        <div className="col-lg-1  d-none d-lg-block" />
        <div className="col-lg-5 col-md-6 col-sm-12 col-12" />
        <div className="col-lg-4 col-md-6 col-sm-12 col-12 px-2 mt-sm-2 mt-md-0 mt-lg-0 mt-2 ">
          <span className="errorMsg ">{errorname}</span>
        </div>
        <div className="col-lg-1  d-none d-lg-block" />
      </div>
    </React.Fragment>
    
  );
};

export default ValidationView;
