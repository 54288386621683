import React, { Component } from "react";
import './style.css'


export default class preLoader extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <React.Fragment>
        <div id="" style={{ display: 'none', left: '43%', position: 'absolute', top: '43%' }} >
          <div class="la-ball-triangle-path">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}
