import React, { Component } from "react";
import moment from "moment";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import "./Style.css";
import Swal from "sweetalert2";

class FormMiddleWare extends Component {
  getMaster = async tableName => {
    try {
      const adminId = await localStorage.getItem("userId");
      const { data } = await MasterMiddleWare.getMaster(tableName, adminId);
      if (data) {
        this.setState({
          data,
          adminId
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  validate = (state, errorState, errorMessage) => {
    console.log(state, errorState, errorMessage)
    if (!state) {
      console.log(state,"error state")
      this.setState({
        [errorState]: [errorMessage]
      });
      return true;
    } else {
      console.log(state,"error state")
      this.setState({
        [errorState]: null
      });
      return false;
    }
  };

  validateText = (state, errorState, errorMessage) => {
    if (state && state.toString().trim()) {
      this.setState({
        [errorState]: null
      });
      return false;
    } else {
      this.setState({
        [errorState]: [errorMessage]
      });
      return true;
    }
  };

  validateUserName = async (state, errorState, errorMessage) => {
    try {
      const { data } = await MasterMiddleWare.checkUserName("tbl_users", state);
      if (data.length) {
        this.setState({
          [errorState]: "UserName Already exists"
        });
        return true;
      } else {
        this.setState({
          [errorState]: ""
        });
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  validateMobile = async (state, errorState, errorMessage) => {
    let mobNumberFormat = new RegExp(/^[789]\d{9}$/);
    if (!mobNumberFormat.test(state)) {
      this.setState({
        [errorState]: [errorMessage]
      });
      return true;
    } else {
      try {
        const { data } = await MasterMiddleWare.checkMobile("tbl_users", state);
        if (data.length) {
          this.setState({
            [errorState]: "Mobile Number Already exists"
          });
          return true;
        } else {
          this.setState({
            [errorState]: null
          });
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  validateEmail = async (state, errorState, errorMessage) => {
    let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailFormat.test(String(state).toLowerCase())) {
      this.setState({
        [errorState]: [errorMessage]
      });
      return true;
    } else {
      try {
        const { data } = await MasterMiddleWare.checkEmail("tbl_users", state);
        if (data.length) {
          this.setState({
            [errorState]: "Email-id Already exists"
          });
          return true;
        } else {
          this.setState({
            [errorState]: null
          });
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleUserInput = e => {
    const adminId = localStorage.getItem("userId");
    if (e.target.name === "email") {
      this.setState({ updateEmail: true });
    }
    if (e.target.name === "financialType") {
      this.getSelectBoxMaster(
        adminId,
        "tbl_worklevel",
        "id",
        "worklevel",
        "workLevelOptions"
      );
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  selectImage = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleChange = selectedOption => {
    let selectedValue = "";
    selectedOption.map(values => (selectedValue += `${values.value},`));
    selectedValue = selectedValue.replace(/,\s*$/, "");
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue
    });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };
  
  dataTableButton = (info, con, func) => {
    let classes = "btn";
    classes += info;
    return (

      <button type="button" className={classes} onClick={func}>
        {con}
      </button>

    );
  };

  getValueFromArray = (d, array) => {

    // console.log(d,array)
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  siNo = d => {
    return <label>{d.index + 1}</label>;
  };

  edit = value => {
    return (
      <button
        type="button"
        className="table-warning-btn "
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  delete = (value, tableName) => {
    return (
      <button
        type="button"
        className="table-danger-btn "
        onClick={() => this.buttonDelete(value, tableName)}
      >
        Delete
      </button>
    );
  };

  buttonDelete = async (value, tableName) => {
    const id = value.original.id;
    const previousData = [...this.state.data];
    const data = previousData.filter(value => value.id !== id);
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this file!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it"
    }).then(async result => {
      if (result.value) {
        try {
          this.setState({
            data
          });
          const result = await MasterMiddleWare.deleteMaster(tableName, id);
          if (result) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        } catch (error) {
          Swal.fire("Cancelled", "Something Went Wrong :)", "error");
          this.setState({
            data: previousData
          });
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your file is safe :)", "error");
        this.setState({
          data: previousData
        });
      }
    });
  };

  changeDate = (date, startDate = "startDate") => {
    console.log("called");
    console.log(date);
    // let changeDate = moment(date).format('MM-DD-YYYY HH:mm:ss');
    this.setState({
      [startDate]: date
    });
  };

  getSelectBoxMaster = async (
    adminId,
    tableName,
    forId,
    forvalue,
    stateValue
  ) => {
    const { data } = await MasterMiddleWare.getSelectBoxMaster(
      adminId,
      tableName,
      forId,
      forvalue
    );
    if (data) {
      this.setState({ [stateValue]: data });
    }
  };

  selectBoxStore = (stVal, data) => {
    this.setState({ [stVal]: data });
  };

  behTechSelect = selectedOption => {
    this.setState({
      behTechSelected: selectedOption,
      behTechId: selectedOption.value,
      behTechName: selectedOption.label
    });
  };

  groupCompanySelect = selectedOption => {
    this.setState({
      groupCompanySelected: selectedOption,
      groupCompanyId: selectedOption.value,
      groupCompanyName: selectedOption.label
    });
  };

  departmentSelect = selectedOption => {
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentName: selectedOption.label
    });
  };

  jobRoleSelect = selectedOption => {
    this.setState({
      jobRoleSelected: selectedOption,
      jobRoleId: selectedOption.value,
      jobRoleLabelName: selectedOption.label
    });
  };

  designationSelect = selectedOption => {
    this.setState({
      designationSelected: selectedOption,
      designationId: selectedOption.value,
      designationLabelName: selectedOption.label
    });
  };

  locationSelect = selectedOption => {
    this.setState({
      locationSelected: selectedOption,
      locationId: selectedOption.value,
      locationLabelName: selectedOption.label
    });
  };
  workLevelSelect = async (selectedOption) => {

    if (selectedOption) {
      // let selectedValue = "";
      // selectedOption.map(values => (selectedValue += `${values.value},`));
      // selectedValue = selectedValue.replace(/,\s*$/, "");
      await this.setState({
        workLevel_Selected: selectedOption,
        // workLevelId: selectedValue
        workLevelId: selectedOption.value
      });
    } else {
      await this.setState({ workLevel_Selected: "", workLevelId: "" });
    }
  };
  workLevelSelectsingal = async (selectedOption) => {

    if (selectedOption) {
      await this.setState({
        workLevelSelected: selectedOption,
        workLevelId: selectedOption.value,
        workLevelLabelName: selectedOption.label

      });
    }
  };





  competencySelect = selectedOption => {
    this.setState({
      competencySelected: selectedOption,
      competencyId: selectedOption.value,
      competencyLabelName: selectedOption.label
    });
  };

  financialYearSelect = selectedOption => {
    this.setState({
      financialYearSelected: selectedOption,
      financialYearId: selectedOption.value,
      financialYearLabelName: selectedOption.label
    });
  };

  functionSelect = selectedOption => {
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
  };

  derailerSelect = selectedOption => {
    this.setState({
      derailerSelected: selectedOption,
      derailerId: selectedOption.value,
      derailerLabelName: selectedOption.label
    });
  };
}


export default FormMiddleWare;
