import http from "./httpMiddleWare";
import { ACCESS_POINT ,VIDHYA_ACCESS_POINT} from "../config";

const userDetails = async userId => {
  const result = await http.get(`${ACCESS_POINT}/hr/userDetails/${userId}`);
  return result;
};
const userJobOption = async userId => {
  const result = await http.get(`${ACCESS_POINT}/hr/userJobOption/${userId}`);
  return result;
};

const getPromoteOption = async (userID,yearId) => {
  const result = await http.get(`${ACCESS_POINT}/hr/getPromoteOption/${userID}/${yearId}`);
  return result;
};
const getUserOption = async (userID,yearId) => {
  const result = await http.get(`${ACCESS_POINT}/hr/getUserOption/${userID}/${yearId}`);
  return result;
};
const employeeList = async adminId => {
  const result = await http.get(`${ACCESS_POINT}/hr/employeeList/${adminId}`);
  return result;
};

const getMappedUserHr = async (userId, yearId, groupcompId,jobRoleId) => {
  const result = await http.get(
    `${ACCESS_POINT}/hr/getMappedUserHr/${userId}/${yearId}/${groupcompId}/${jobRoleId}`
  );
  return result;
};
const getMappedUser = async (userId, yearId, groupcompId,jobRoleId) => {
// console.log(jobRoleId,"jobRoleId");
  const result = await http.get(
    `${ACCESS_POINT}/hr/getMappedUser/${userId}/${yearId}/${groupcompId}/${jobRoleId}`
  );
  return result;
};

const userValue = async userId => {
  const result = await http.get(`${ACCESS_POINT}/hr/userValue/${userId}`);
  return result;
};

const getUserDetails = async userId => {
  let year = localStorage.getItem("yearId");
  console.log(year)
  const result = await http.get(
    `${ACCESS_POINT}/hr/getUserDetails/${userId}/${year}`
  );
  return result;
};
const getUserDetailsyearbased = async (userId,jobName) => {
  let year = localStorage.getItem("yearId");
  // let jobName = localStorage.getItem("jobRoleId")?localStorage.getItem("jobRoleId"):"NOJOB";
  console.log(jobName,"jobName");

  const result = await http.get(
    `${ACCESS_POINT}/hr/getUserDetailsYearBased/${userId}/${year}/${jobName}`
  );
  return result;
};

const CreateDownloadPDF = async userId => {
  let year = localStorage.getItem("yearId");
  const result = await http.get(
    `${ACCESS_POINT}/hr/DownloadPdf/${userId}/${year}`
  );
  return result;
};

const insertAssessmentRating = async data => {
  const result = await http.post(
    ACCESS_POINT + `/hr/insertAssessmentRating`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const alreadyAssessment = async (empid, rateType, yearid, adminid,jobroleid) => {
  const result = await http.get(
    `${ACCESS_POINT}/hr/alreadyAssessment/${empid}/${rateType}/${yearid}/${adminid}/${jobroleid}`
  );
  return result;
};

const getChartData = async userId => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = localStorage.getItem("jobRoleId");
  // console.log(jobRoleId,"jobRoleId")

  const result = await http.get(
    `${ACCESS_POINT}/hr/getChartData/mappedEmployee/${userId}/${year}/${jobRoleId}`
  );
  return result;
};
const getChartDataEmployee = async value => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = value[1]
  let userId = value[0]
  const result = await http.get(
    `${ACCESS_POINT}/hr/getChartData/mappedEmployee/${userId}/${year}/${jobRoleId}`
  );
  return result;
};

const getCompetencyChartData = async userId => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = localStorage.getItem("jobRoleId");
  const result = await http.get(
    `${ACCESS_POINT}/hr/getCompetencyChartData/${userId}/${year}/${jobRoleId}`
  );
  return result;
};
const getCompetencyChartDataemployee = async value => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = value[1]
  let userId = value[0]
  const result = await http.get(
    `${ACCESS_POINT}/hr/getCompetencyChartData/${userId}/${year}/${jobRoleId}`
  );
  return result;
};
const getRandomData = async (afterF, afterW, beforeF) => {
  let value = {};
  value.beforeF = beforeF;
  value.afterF = afterF;
  value.afterW = afterW;
  //console.log(value);
  const result = await http.put(
    `${ACCESS_POINT}/hr/getRandomData/RandomData`,
    value
  );
  return result;
};
const mapBechComp = async data => {
  const result = await http.post(ACCESS_POINT + `/hr/insertMapBechComp`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return result;
};
const addMaster = async (tableName, body) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/addMaster/${tableName}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const CompetencyMaster = async (
  formData,
  tableName1,
  tableName2,
  tableName3,
  tableName4
) => {
  const result = await http.post(
    ACCESS_POINT +
      `/hr/twoMaster/${tableName1}/${tableName2}/${tableName3}/${tableName4}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const multiInsert = async data => {
  const result = await http.put(`${ACCESS_POINT}/hr/multiInsert`, data);
  return result;
};
const mappingIndicatorUser = async (
  formData,
  tableName1,
  tableName2,
  tableName3,
  tableName4
) => {
  const result = await http.post(
    ACCESS_POINT +
      `/hr/mappingIndicatorUser/${tableName1}/${tableName2}/${tableName3}/${tableName4}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const section1 = async id => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = localStorage.getItem("jobRoleId");

  const result = await http.put(ACCESS_POINT + `/hr/section1Data`, {
    id,
    year,
    jobRoleId
  });
  return result;
};

const section1Employee = async (value) => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = value[1]
  let id = value[0]

  const result = await http.put(ACCESS_POINT + `/hr/section1Data`, {
    id,
    year,
    jobRoleId
  });
  return result;
};
const section2 = async userId => {
  let year = localStorage.getItem("yearId");
  let jobRoleId = localStorage.getItem("jobRoleId");

  const result = await http.put(ACCESS_POINT + `/hr/section2Data`, {
    userId,
    year,
    jobRoleId
  });
  return result;
};

const deleteIndicatorById = async (tableName, id) => {
  const result = await http.delete(
    `${ACCESS_POINT}/hr/deleteIndicatorById/${tableName}/${id}`
  );
  return result;
};
const insertThreeTable = async data => {
  const result = await http.put(`${ACCESS_POINT}/hr/insertThreeTable`, data);
  return result;
};
const addIndicatorsLevel = async data => {
  const result = await http.put(`${ACCESS_POINT}/hr/addIndicatorsLevel`, data);
  return result;
};
const updateProfileAddImage = async (userId,name,data) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/updateProfileAddImage/${userId}/${name}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};


const gettrainingprgmmap = async (yearId,adminId,section1Data) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/gettrainingprgmmap/${yearId}/${adminId}`,section1Data
  );
  // console.log(result,"result");
  return result;
};

const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    VIDHYA_ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  return result;
};

const getCoursesList = async (data, id) => {
  const result = await http.post(VIDHYA_ACCESS_POINT + `/cmsContent/Coromandel/listmore/${id}`, data);
  return result;
};

const getVideoContentById = async (themeid) => {
  const result = await http.get(
    VIDHYA_ACCESS_POINT + `/cmsContent/videoContent/${themeid}`
  );
  return result;
};

const nominadurresourcecost = async (id,prgmid,adminId) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/nominadurresourcecost/${id}/${prgmid}/${adminId}`
  );
  return result;
};

const nominateformclassroomprogram = async (id,adminId) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/nominateformclassroomprogram/${id}/${adminId}`
  );
  return result;
};

const createMasterforQR = async (tableName, data) => {
  // console.log(data,"dataaa");
  const result = await http.post(ACCESS_POINT + `/hr/createMasterforQR/${tableName}`, data);
  return result;
};

const CompQuizAttended = async (quizid, userid, where = 'flase', page = 'false') => {
  const result = await http.get(VIDHYA_ACCESS_POINT + `/cmsContent/Coromandel/CompQuizAttended?userid=${userid}&quizid=${quizid}&where=${where}&pageid=${page}`);
  return result;
};
const getThemebyid = async (themeid) => {
  const result = await http.get(
    VIDHYA_ACCESS_POINT + `/cmsContent/getPage/${themeid}`
  );
  return result;
};
const insertQuizAnswer = async (quizAnswerJson, tableName) => {
  const result = await http.post(
    VIDHYA_ACCESS_POINT + `/cmsContent/compInsertquizAnswer/${tableName}`,
    quizAnswerJson
  );
  return result;
};
const updateMaster = async (tableName, id, categoryArray, column = "id") => {
  const result = await http.put(
    VIDHYA_ACCESS_POINT + `/cmsContent/master/${tableName}/${column}`,
    { id: id, categoryArray }
  );
  return result;
};
const insertMaster = async (t, d) => {
  const result = await http.post(
    VIDHYA_ACCESS_POINT + `/cmsContent/insertMaster/${t}`,
    d
  );
  return result;
};



export default {
  updateProfileAddImage,
  userDetails,
  employeeList,
  getMappedUser,
  userValue,
  getUserDetails,
  getUserDetailsyearbased,
  insertAssessmentRating,
  alreadyAssessment,
  getChartData,
  getChartDataEmployee,
  getCompetencyChartData,
  getCompetencyChartDataemployee,
  getRandomData,
  mapBechComp,
  addMaster,
  CompetencyMaster,
  multiInsert,
  mappingIndicatorUser,
  section1,
  section1Employee,
  section2,
  deleteIndicatorById,
  insertThreeTable,
  addIndicatorsLevel,
  CreateDownloadPDF,
  userJobOption,
  getMappedUserHr,
  getPromoteOption,
  getUserOption,
  gettrainingprgmmap,
  getFreedom,
  getCoursesList,
  getVideoContentById,
  nominadurresourcecost,
  nominateformclassroomprogram,
  createMasterforQR,
  CompQuizAttended,
  getThemebyid,
  insertQuizAnswer,
  updateMaster,
  insertMaster
};
