import React from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import Datatable from "../../components/Datatable/Datatable";
import LoginModal from "../../components/Modal/Modal";
import PreLoader from './preloader';
import { CSVLink } from "react-csv";
import "./style.css";
// import BehaviouralCompetencies from "./BehaviouralCompetencies";
// const inputRef = React.useRef(null);

class EmployeeList extends FormMiddleWare {

  constructor(props) {
    super(props);
    // this.fileUpload = React.createRef()
    this.state = {
      headers: [
        { label: "Name", key: "name" },
        { label: "Address", key: "address" },
        { label: "Mobile", key: "mobile_no" },

        { label: "Jobrole", key: "jobrole" },
        { label: "E-mail", key: "email" },

        { label: "Qualification", key: "qualification" },
        { label: "E-Worl Level", key: "worklevelName" },

        { label: "Competency Name", key: "competencyname" },
        { label: "Level", key: "level" },
        { label: "Indicator Names", key: "indicatorname" },
        { label: "RE", key: "repotee" },
        { label: "SP", key: "supervisor" },
        { label: "PR", key: "peer" },
        { label: "CS", key: "customer" },
        { label: "Rating Average", key: "avg" }
      ],
      data1: []
    };
  }

  componentWillMount() {
    this.getMaster();
  }

  getMaster = async () => {
    try {
      const adminId = await localStorage.getItem("adminId");
      const groupcompanyid = await localStorage.getItem("groupcompId");
      console.log(adminId);
      const { data } = await HrMiddleWare.getRandomData(
        "tbl_users,tbl_jobRole,tbl_worklevel,tbl_department",
        `tbl_users.adminId = ${adminId} and tbl_users.groupcomp =${groupcompanyid} and tbl_users.userTypeId = 5 and tbl_users.status = 'active' and tbl_department.id =tbl_users.department and tbl_jobRole.id = tbl_users.jobRole and tbl_worklevel.id = tbl_users.worklevel`,
        "tbl_users.*,tbl_jobRole.jobRoleName as jobroleName,tbl_worklevel.worklevel as worklevelName,tbl_department.department as departmentName"
      );
      // this.excel1(data)

      if (data) {
        this.setState({
          data,
          adminId
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  //  excel1 = async value => {
  //     // console.log(value); 
  //     let data2 = [];
  //     value.map(async (val,v)=>{
  //      let userData = await HrMiddleWare.getUserDetails(val.id);
  //     let section1Data = await HrMiddleWare.section1(val.id);

  //     //  console.log(userData.data,section1Data.data);
  //     // console.log(userData);
  //     if( userData.data[0].userFullIndicatorList != null){
  //        userData.data[0].userFullIndicatorList.map((item, index) => {
  //       // console.log(item);

  //       let indi = [];
  //       if(section1Data){
  //         section1Data.data.map((element, key) => {
  //         if (item.competencyid == element.cmpid) {
  //           // console.log( item.indicators);

  //           item.indicators.map((ival, i) => {
  //             // console.log(ival);
  //             data2.push({
  //               id:val.id,
  //               name:val.name,
  //               address:val.address,
  //               mobile_no:val.mobile_no,
  //               jobrole:val.jobroleName,
  //               email:val.email_id,
  //               qualification:val.qualification,
  //               worklevelName:val.worklevelName,
  //               competencyname: item.competencyname,
  //               level: "level",
  //               indicatorname: ival.indicators,
  //               repotee: element.reportee,
  //               supervisor: element.supervisor,
  //               peer: element.peer,
  //               customer: element.customer,
  //               avg: element.avg.toString()
  //             });
  //           });
  //         }
  //       });
  //       }

  //     });
  //     }
  //   //  console.log(data2);
  //    this.setState({ data2 });
  //     })

  //     // console.log(data2);
  //     // this.setState({ data2 });
  //   };

  column = [
    {
      Header: props => <span style={{ fontSize: "18px" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Name </span>,
      accessor: "name"
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Mobile </span>,

      accessor: "mobile_no"
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Email Id </span>,

      accessor: "email_id"
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Worklevel </span>,

      accessor: "worklevelName"
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Jobrole </span>,

      accessor: "jobroleName"
    },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Department </span>,

      accessor: "departmentName"
    },

    {
      Header: props => <span style={{ fontSize: "18px" }}> Profile </span>,
      Cell: d => this.viewPro(d),
      width: 150
    }
    , {
      Header: props => <span style={{ fontSize: "18px" }}> Theme </span>,
      Cell: d => this.viewTheme(d, "themeID"),
      width: 150
    },
    // {
    //   Header: props => <span style={{ fontSize: "18px" }}> Print </span>,

    //   Cell: d => this.viewPrint(d),
    //   width: 150
    // },
    {
      Header: props => <span style={{ fontSize: "18px" }}> Excel </span>,

      Cell: d => this.viewExcel(d),
      width: 150
    },

  ];

  viewPro = d => {
    return (
      <div>
        {" "}
        <center>
          <button
            type="button"
            className="table-btn"
            onClick={() =>
              window.open(
                "/hr/ViewProfile" + "?" + "id=" + d.original.id,
                "_blank"
              )
            }
          >
            View
          </button>
        </center>{" "}
      </div>
    );
  };

  viewTheme = (d, modalWindowId) => {
    return (
      <div >
        <LoginModal
          buttonTitle="View Theme"
          title="Theme"
          id={modalWindowId}
          extraClass=" table-btn"
          onClick={() => this.theme(d)}
          extraStyle={{ backgroundColor: "#56528F" }}
          maxwidth="modal-lg"
          bodyText={
            <div>
              <div className="centered header-color">{this.state.name}</div>
              {this.state.Themepush}
            </div>
          }
        />
      </div>
    );
  };

  theme = async value => {
    let userData = await HrMiddleWare.getUserDetails(value.original.id);

    let Themepush = [];

    if (userData.data[0].userFullIndicatorList) {
      userData.data[0].userFullIndicatorList.map((item, i) => {
        let indi = [];
        item.indicators.map((element, key) => {
          indi.push(
            <div className="row">
              <div
                className="col-sm-3 tab-text-content"
                style={{
                  color: "#",
                  fontWeight: "700",
                  marginTop: "3px"
                }}
              >
                Indicator Name
              </div>
              <div className="col-sm-6">{element.indicators}</div>
            </div>
          );
        });
        Themepush.push(
          <div>
            <div className="row">
              <div
                className="col-sm-3 tab-tex-content"
                style={{
                  color: "",
                  fontWeight: "700",
                  marginTop: "3px"
                }}
              >
                Theme
              </div>
              <div className="col-sm-6">{item.theme}</div>
            </div>
            <div className="row">
              <div
                className="col-sm-3 tab-tex-content"
                style={{
                  color: "#",
                  fontWeight: "700",
                  marginTop: "3px"
                }}
              >
                Competency Name
              </div>
              <div className="col-sm-6">{item.competencyname}</div>
            </div>
            <div>{indi}</div>
          </div>
        );
      });
    } else {
      Themepush.push(
        <div className="row">
          <div className="centered" style={{ fontWeight: "700" }}>
            No Theme for {userData.data[0].name}
          </div>
        </div>
      );
    }
    this.setState({ Themepush, name: userData.data[0].name });
  };

  viewPrint = d => {
    return (
      <div>
        <center>
          <button
            type="button"
            className="btncolor"
            onClick={() =>
              window.open("/hr/Report" + "?" + "id=" + d.original.id, "_blank")
            }
          >
            Print
          </button>
        </center>
      </div>
    );
  };

  viewExcel = d => {
    const { headers, data1 } = this.state;
    return (
      <div>
        <button className="table-btn" onClick={() => { this.excel(d) }}>Generate Excel</button>
      </div>
    );
  };
  //    handleClick = () => {

  //     this.fileUpload.current.click();
  //  }

  //   excel = async (value) => {
  //     console.log(value, "value");
  //     let userData = await HrMiddleWare.getUserDetails(value.original.id);
  //     const yearid = localStorage.getItem("yearId")
  //     // let section1Data = await HrMiddleWare.getMappedUserHr(value);
  //     const section1Data = await HrMiddleWare.getMappedUserHr(
  //       value.original.id,
  //       yearid,
  //       value.original.groupcomp,
  //       value.original.jobRole
  //     );
  //     let data1 = [];
  //     console.log(section1Data, "testdata");
  //     const datum = userData.data[0];
  //     console.log(datum, "daturn");

  //     const supervisorName = section1Data.data[0]?.supervisor[0].name;
  //     const customerName = section1Data.data[1].customer[0].name;
  //     const reporteeName = section1Data.data[2].reportee[0].name;
  //     // const peer = section1Data.data[3].peer[0].name;
  //     const competencyName = userData.data[0].userFullIndicatorList.competencyname
  //     const indicators = userData.data[0].userFullIndicatorList

  //     userData.data[0].userFullIndicatorList.map((item, index) => {
  //       console.log(item,"testitems");
  //       if (item.competencyid) {
  //         item.indicators.map((ival, j) => {
  //           item.indicators.map((valuess,ind)=>{
  //             data1.push({
  //               name: value.original.name,
  //               address: value.original.address,
  //               mobile_no: value.original.mobile_no,
  //               jobrole: value.original.jobroleName,
  //               email: value.original.email_id,
  //               qualification: value.original.qualification,
  //               worklevelName: value.original.worklevelName,
  //               competencyname: item.competencyname,
  //               level: "level",
  //               indicatorname: valuess.indicators, 
  //               repotee: reporteeName,
  //               supervisor: supervisorName,
  //               peer: "element.peer",
  //               customer: customerName,
  //               avg: 5
  //             });
  //           })

  //         });
  //       }
  //     });

  //     this.setState({data1})
  // console.log(data1,"testdata1");
  //     console.log(indicators, "sectionindicators");

  //     // userData.data[0].userFullIndicatorList.map((item, index) => {
  //     //   let indi = [];
  //     //   section1Data.data.map((element, key) => {
  //     //     if (item.competencyid == element.cmpid) {
  //     //       item.indicators.map((ival, i) => {

  //     //         data1.push({
  //     //           name: value.original.name,
  //     //           address: value.original.address,
  //     //           mobile_no: value.original.mobile_no,
  //     //           jobrole: value.original.jobroleName,
  //     //           email: value.original.email_id,
  //     //           qualification: value.original.qualification,
  //     //           worklevelName: value.original.worklevelName,
  //     //           competencyname: item.competencyname,
  //     //           level: "level",
  //     //           indicatorname: ival.indicators,
  //     //           repotee: element.reportee,
  //     //           supervisor: element.supervisor,
  //     //           peer: element.peer,
  //     //           customer: element.customer,
  //     //           avg: element.avg.toString()
  //     //         });
  //     //       });
  //     //     }
  //     //   });
  //     // });
  //     // this.fileUpload.current.click();
  //   };

  excel = async value => {
    let userData = await HrMiddleWare.getUserDetails(value.original.id);
    const value1 = [value.original.id,value.original.jobRole]
    let section1Data = await HrMiddleWare.section1Employee(value1);
    let data1 = [];
    userData.data[0].userFullIndicatorList.map((item, index) => {

      let indi = [];
      section1Data.data.map((element, key) => {
        if (item.competencyid == element.cmpid) {
          item.indicators.map((ival, i) => {
            data1.push({
              name: value.original.name,
              address: value.original.address,
              mobile_no: value.original.mobile_no,
              jobrole: value.original.jobroleName,
              email: value.original.email_id,
              qualification: value.original.qualification,
              worklevelName: value.original.worklevelName,
              competencyname: item.competencyname,
              level: "level",
              indicatorname: ival.indicators,
              repotee: element.reportee,
              supervisor: element.supervisor,
              peer: element.peer,
              customer: element.customer,
              avg: element.avg.toString()
            });
          });
        }
      });
    });
    this.setState({ data1 });
    if (this.state.data1.length > 0) {
      this.setState({ IsDataReady: true })
    }
  };
  changeState = () => {
    this.setState({ IsDataReady: false })
  }
  render() {
    const { data } = this.state;

    if (!data) {
      return (<PreLoader />)
    }
    else {
      let height = window.innerHeight;
      const { headers, data1 } = this.state;
      return (
        <React.Fragment>
          <section>
            <div className=" mt-100 px-lg-4 container-sm-fluid">
              <div className="col-12 my-5">
                <div className="content-border1 px-2 py-5 my-5 ">
                  <h2 className="my-2">EmployeeList</h2>
                  {this.state.IsDataReady ?
                    <div className="text-end">
                      <CSVLink
                        headers={headers}
                        data={data1}
                        onClick={this.changeState}
                        filename={"my-file.xls"}
                        className="submit-btn mx-3"
                        target="_blank"
                      >
                        Download Excel
                      </CSVLink>
                    </div>
                    :
                    <></>
                  }
                  <div className="pb-5 pt-3 px-3">
                    {data && <Datatable data={data} columnHeading={this.column} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }

  }
}

export default EmployeeList;
